import Button from "../../../globalComponents/Button";
import SectionContainer from "../../../shared/section_container/section_container";
import WSBottomContainer from "../../w_s_bottom_container/w_s_bottom_container";
import ChoiceBasicWS from "./choice_basic_w_s/choice_basic_w_s";
import CWorkTemplate from "./c_work_template/c_work_template";
import {
  OtSelfWorkingStandardHoursEntity,
  SelfDeterminedWorkHourBasicBasicEntity,
  useGetSelfDeterminedWorkHourInfoLazyQuery
} from "../../../../generated/graphql";
import { useContext, useEffect, useState } from "react";
import handleApolloLog from "../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import { WS_STATE } from "../../../../screens/asonic-working-system.screen";

function ChoiceWS() {
  const wSState = useContext(WS_STATE);
  const [basicInfo, setBasicInfo] = useState<
    SelfDeterminedWorkHourBasicBasicEntity | undefined
  >();
  const [checkedTemplateList, setCheckedTemplateList] = useState<
    OtSelfWorkingStandardHoursEntity[]
  >([]);
  const [otWSHoursList, setOtWSHoursList] = useState<
    OtSelfWorkingStandardHoursEntity[]
  >([]);
  const [getSelfDeterminedWorkHour] = useGetSelfDeterminedWorkHourInfoLazyQuery(
    {
      onError(error) {
        handleApolloLog(error);
      },
      onCompleted(data) {
        if (data.getSelfDeterminedWorkHourInfo.otWorkingStandardBasic) {
          setBasicInfo(
            data.getSelfDeterminedWorkHourInfo.otWorkingStandardBasic
          );
        }
        if (data.getSelfDeterminedWorkHourInfo.otWSHoursList) {
          setOtWSHoursList(data.getSelfDeterminedWorkHourInfo.otWSHoursList);
        }
      }
    }
  );

  useEffect(() => {
    if (wSState?.setWorkingTimeName) {
      wSState?.setWorkingTimeName(
        checkedTemplateList.map(item => item.workingTimeName)
      );
    }
  }, [wSState?.setWorkingTimeName, checkedTemplateList]);

  useEffect(() => {
    if (wSState?.workingTemplateIdx) {
      getSelfDeterminedWorkHour({
        variables: {
          workingTemplateIdx: wSState.workingTemplateIdx
        }
      });
    }
  }, [getSelfDeterminedWorkHour, wSState?.workingTemplateIdx]);

  return (
    <SectionContainer>
      <ChoiceBasicWS
        basicInfo={basicInfo}
        checkedTemplateList={checkedTemplateList}
      />
      <CWorkTemplate
        otWSHoursList={otWSHoursList}
        checkedTemplateList={checkedTemplateList}
        setCheckedTemplateList={setCheckedTemplateList}
      />
      <WSBottomContainer>
        <Button
          maxWidth="100%"
          customMinWidth="100%"
          onClick={() => {
            wSState?.handleIsSave(true);
          }}
        >
          {`저장`}
        </Button>
      </WSBottomContainer>
    </SectionContainer>
  );
}

export default ChoiceWS;
