import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import AsonicTable from "../../../../asonic-table/asonic-table";
import * as ReactTable from "react-table";
import useDnd from "../../../../../hooks/use-dnd/use-dnd";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import StyleInput from "../../../../inputs/style-input";
import Button from "../../../../globalComponents/Button";
import ChoiceFormWS from "../choice_form_w_s/choice_form_w_s";
import styled from "styled-components";
import ButtonContainer from "../../../../shared/button_container/button_container";
import CWorkTemplateHelp from "./c_work_template_help";
import {
  AddSelfDeterminedWorkHourTemplateMutationVariables,
  Day_Type,
  Is_Use,
  Operating_Period_Type,
  OtSelfWorkingStandardHoursEntity,
  Work_Day_Of_Week,
  Work_Hours_Setting_Type,
  useAddSelfDeterminedWorkHourTemplateMutation,
  useDeleteSelfDeterminedWorkHourTemplateMutation,
  useUpdateSelfDeterminedWorkHourTemplateMutation
} from "../../../../../generated/graphql";
import {
  Cell,
  Row
} from "../../../../../../types/@react-table/react-table/react-table";
import moment from "moment";
import { WS_STATE } from "../../../../../screens/asonic-working-system.screen";
import handleErrorLog from "../../../../../Utils/handle_error_log/handle_error_log";
import handleApolloErrorLog from "../../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";

interface IProps {
  otWSHoursList: OtSelfWorkingStandardHoursEntity[];
  checkedTemplateList: OtSelfWorkingStandardHoursEntity[];
  setCheckedTemplateList: React.Dispatch<
    React.SetStateAction<OtSelfWorkingStandardHoursEntity[]>
  >;
}

const TableContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 200px;
`;

const title = "근무 템플릿";

function CWorkTemplate({
  otWSHoursList,
  checkedTemplateList,
  setCheckedTemplateList
}: IProps) {
  const wSState = useContext(WS_STATE);
  const [template, setTemplate] =
    useState<AddSelfDeterminedWorkHourTemplateMutationVariables>();

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();
  const [addSelfDeterminedWorkHourTemplate, { client }] =
    useAddSelfDeterminedWorkHourTemplateMutation({
      onError(error) {
        handleApolloErrorLog(error);
      },
      update(_, { data }) {
        if (data?.addSelfDeterminedWorkHourTemplate.ok) {
          client.resetStore();
        }
        if (
          !data?.addSelfDeterminedWorkHourTemplate.ok &&
          data?.addSelfDeterminedWorkHourTemplate.error
        ) {
          handleToast(
            data?.addSelfDeterminedWorkHourTemplate.error,
            MessageTypes.WARNING
          );
          handleErrorLog(data?.addSelfDeterminedWorkHourTemplate.error);
        }
      }
    });

  const [updateSelfDeterminedWorkHourTemplate] =
    useUpdateSelfDeterminedWorkHourTemplateMutation({
      onError(error) {
        handleApolloErrorLog(error);
      },
      update(_, { data }) {
        if (data?.updateSelfDeterminedWorkHourTemplate.ok) {
          client.resetStore();
        }
        if (
          !data?.updateSelfDeterminedWorkHourTemplate.ok &&
          data?.updateSelfDeterminedWorkHourTemplate.error
        ) {
          handleToast(
            data?.updateSelfDeterminedWorkHourTemplate.error,
            MessageTypes.ERROR
          );
          handleErrorLog(data?.updateSelfDeterminedWorkHourTemplate.error);
        }
      }
    });

  const [deleteSelfDeterminedWorkHourTemplate] =
    useDeleteSelfDeterminedWorkHourTemplateMutation({
      onError(error) {
        handleApolloErrorLog(error);
      },
      update(_, { data }) {
        if (data?.deleteSelfDeterminedWorkHourTemplate.ok) {
          client.resetStore();
        }
        if (
          !data?.deleteSelfDeterminedWorkHourTemplate.ok &&
          data?.deleteSelfDeterminedWorkHourTemplate.error
        ) {
          handleErrorLog(data?.deleteSelfDeterminedWorkHourTemplate.error);
        }
      }
    });

  const handleDays = useCallback((dayList: Day_Type[]) => {
    const parsedDayList = dayList.map(item => {
      switch (item) {
        case Day_Type.Mon:
          return "월";
        case Day_Type.Tue:
          return "화";
        case Day_Type.Wed:
          return "수";
        case Day_Type.Thr:
          return "목";
        case Day_Type.Fri:
          return "금";
        case Day_Type.Sat:
          return "토";
        case Day_Type.Sun:
          return "일";
      }
    });
    return parsedDayList.join(",");
  }, []);

  const columns: ReactTable.Column<OtSelfWorkingStandardHoursEntity>[] =
    useMemo(() => {
      const width = 100;
      const checkboxColumn: ReactTable.Column<OtSelfWorkingStandardHoursEntity> =
        {
          Header(
            header: React.PropsWithChildren<
              ReactTable.HeaderProps<OtSelfWorkingStandardHoursEntity>
            >
          ) {
            return (
              <StyleInput
                type="checkbox"
                checked={checkedTemplateList.length === header.rows.length}
                onChange={event => {
                  event.stopPropagation();
                  setCheckedTemplateList(list => {
                    if (list.length < header.rows.length) {
                      return header.data;
                    }
                    return [];
                  });
                }}
                name="useFlag"
              />
            );
          },
          accessor: "useFlag",
          width: 40,
          Cell(cell: Cell<OtSelfWorkingStandardHoursEntity>) {
            const foundRowIndex = checkedTemplateList.findIndex(
              item => item.workingTimeName === cell.row.original.workingTimeName
            );
            return (
              <StyleInput
                type="checkbox"
                checked={foundRowIndex >= 0}
                onChange={event => {
                  event.stopPropagation();
                  setCheckedTemplateList(list => {
                    let newList = [...list];
                    const foundRow = newList.find(
                      row =>
                        row.workingTimeName ===
                        cell.row.original.workingTimeName
                    );
                    if (foundRow) {
                      newList = newList.filter(row => {
                        return (
                          row.workingTimeName !==
                          cell.row.original.workingTimeName
                        );
                      });
                    } else {
                      newList.push(cell.row.original);
                    }
                    return newList;
                  });
                }}
                name={cell.value}
              />
            );
          }
        };
      return [
        checkboxColumn,
        { Header: "근무시간 템플릿 명", accessor: "workingTimeName" },
        {
          Header: "운영기간",
          accessor: "operatingPeriodStartDate",
          width: 180,
          Cell(cell: Cell<OtSelfWorkingStandardHoursEntity>) {
            let operatingPeriodDate = `${cell.row.original.operatingPeriodStartDate} ~ ${cell.row.original.operatingPeriodEndDate}`;
            if (
              cell.row.original.operatingPeriodType ===
              Operating_Period_Type.Always
            ) {
              operatingPeriodDate = `상시적용`;
            }
            return <span>{operatingPeriodDate}</span>;
          }
        },
        {
          Header: "근무요일",
          accessor: "dayOfWeek",
          width,
          Cell(cell: Cell<OtSelfWorkingStandardHoursEntity>) {
            if (
              cell.row.original.dayOfWeekType === Work_Day_Of_Week.SelfForWorker
            ) {
              return "근무자 자율편성";
            }
            return <span>{handleDays(cell.row.original.dayOfWeek)}</span>;
          }
        },
        {
          Header: "근무시간",
          accessor: "startTime",
          width,
          Cell(cell: Cell<OtSelfWorkingStandardHoursEntity>) {
            if (
              cell.row.original.workHoursType ===
              Work_Hours_Setting_Type.SelfForWorker
            ) {
              return "근무자 자율편성";
            }
            const startTime = moment(
              cell.row.original.startTime,
              "HH:mm:ss"
            ).format("HH:mm");
            const endTime = moment(
              cell.row.original.endTime,
              "HH:mm:ss"
            ).format("HH:mm");
            const time = startTime && endTime ? `${startTime}~${endTime}` : "";
            return <span>{time}</span>;
          }
        },
        {
          Header: "의무근무시간",
          accessor: "dutyStartTime",
          width,
          Cell(cell: Cell<OtSelfWorkingStandardHoursEntity>) {
            if (cell.row.original.dutyWorkHoursType === Is_Use.UnUse) {
              return "-";
            }
            const dutyStartTime = moment(
              cell.row.original.dutyStartTime,
              "HH:mm:ss"
            ).format("HH:mm");
            const dutyEndTime = moment(
              cell.row.original.dutyEndTime,
              "HH:mm:ss"
            ).format("HH:mm");
            const time =
              dutyStartTime && dutyEndTime
                ? `${dutyStartTime}~${dutyEndTime}`
                : "없음";
            return <span>{time}</span>;
          }
        },
        {
          Header: "휴게시간",
          accessor: "startBreakTime",
          width,
          Cell(cell: Cell<OtSelfWorkingStandardHoursEntity>) {
            const startBreakTime = moment(
              cell.row.original.startBreakTime,
              "HH:mm:ss"
            ).format("HH:mm");
            const endBreakTime = moment(
              cell.row.original.endBreakTime,
              "HH:mm:ss"
            ).format("HH:mm");
            const breakTime =
              startBreakTime && endBreakTime
                ? `${startBreakTime}~${endBreakTime}`
                : "";
            return <span>{breakTime}</span>;
          }
        }
      ];
    }, [checkedTemplateList, handleDays]);

  const list: OtSelfWorkingStandardHoursEntity[] = useMemo(() => {
    return otWSHoursList ?? [];
  }, [otWSHoursList]);

  useEffect(() => {
    if (list) {
      setCheckedTemplateList(originList => {
        return list.filter(item => item.useFlag === Is_Use.Use) ?? originList;
      });
    }
  }, [list, setCheckedTemplateList]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows,
    toggleHideColumn,
    columns: cWorkTemplateColumn,
    visibleColumns,
    setColumnOrder
  } = ReactTable.useTable<OtSelfWorkingStandardHoursEntity>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const { moveColumn } = useDnd<OtSelfWorkingStandardHoursEntity>({
    columns: visibleColumns,
    setColumnOrder,
    title: `${title}-for-ordering-column`
  });

  const selectedRow:
    | ReactTable.Row<OtSelfWorkingStandardHoursEntity>
    | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  const newColumns = useMemo(() => {
    const cl = cWorkTemplateColumn.filter((_, index) => {
      return index !== 0;
    });
    return cl;
  }, [cWorkTemplateColumn]);

  return (
    <>
      <TableContainer>
        <AsonicTable<OtSelfWorkingStandardHoursEntity>
          title={title}
          total={10}
          handleSelectRow={() => {}}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          selectedRow={selectedRow}
          columns={
            newColumns as TColumn<
              Omit<OtSelfWorkingStandardHoursEntity, "useFlag">
            >[]
          }
          toggleHideColumn={toggleHideColumn}
          moveColumn={moveColumn}
          listOfFlexForHeader={["근무시간 템플릿 명", "운영기간"]}
          isLastFlex={false}
        >
          <CWorkTemplateHelp />
        </AsonicTable>
      </TableContainer>
      <ChoiceFormWS selectedRow={selectedRow} setTemplate={setTemplate} />
      <ButtonContainer flex={1}>
        <Button
          customMinWidth="50px"
          maxWidth="50px"
          customMinHeight="20px"
          disabled={!wSState?.workingTemplateIdx || !template?.workingTimeName}
          onClick={() => {
            if (template?.workingTimeName) {
              addSelfDeterminedWorkHourTemplate({
                variables: template
              });
            }
          }}
        >{`추가`}</Button>
        <Button
          customMinWidth="50px"
          maxWidth="50px"
          customMinHeight="20px"
          disabled={!selectedRow || !wSState?.workingTemplateIdx}
          onClick={() => {
            if (selectedRow?.original.workingTimeName && template) {
              if (
                selectedRow.original.workingTimeName !==
                template.workingTimeName
              ) {
                handleToast(
                  "근무 템플릿명은 변경하실수 없습니다.",
                  MessageTypes.WARNING
                );
              } else {
                updateSelfDeterminedWorkHourTemplate({
                  variables: {
                    oldWorkingTimeName: selectedRow?.original.workingTimeName,
                    ...template
                  }
                });
              }
            }
          }}
        >{`수정`}</Button>
        <Button
          customMinWidth="50px"
          maxWidth="50px"
          customMinHeight="20px"
          disabled={
            !wSState?.workingTemplateIdx || checkedTemplateList.length < 1
          }
          onClick={() => {
            const workingTimeNameList = checkedTemplateList.map(
              item => item.workingTimeName
            );
            if (wSState?.workingTemplateIdx && workingTimeNameList.length > 0) {
              deleteSelfDeterminedWorkHourTemplate({
                variables: {
                  workingTemplateIdx: wSState?.workingTemplateIdx,
                  workingTimeNameList
                }
              });
            }
          }}
        >{`삭제`}</Button>
      </ButtonContainer>
      <ToastMessage
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        messageTypes={toastMessageType}
      />
    </>
  );
}

export default CWorkTemplate;
