import styled from "styled-components";
import { TColumn } from "../hooks/use-hide-columns/use-hide-columns";
import * as ReactTable from "react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SearchFieldValues } from "../components/asonic-table/asonic-search";
import Button from "../components/globalComponents/Button";
import routes from "../routes";
import AsonicDialog from "../components/asonic-dialog/asonic-dialog";
import useOpenDialog from "../hooks/use-open-dialog";
import WorkSystemDialog from "../components/work_system_dialog/work_system_dialog";
import {
  Is_Use,
  WorkingTemplateEntity,
  Wt_Search_Type,
  useDeleteWtBasicInfoMutation,
  useGetListAllWorkingTemplateLazyQuery
} from "../generated/graphql";
import useNewSortBy from "../hooks/use-new-sort-by/use-new-sort-by";
import handleApolloLog from "../Utils/handle_apollo_error_log/handle_apollo_error_log";
import ConfirmDialog from "../components/confirm-dialog/confirm-dialog";
import useConfirmDialog from "../hooks/confirm-dialog-hook/use-confirm-dialog";
import ToastMessage, {
  MessageTypes
} from "../components/toast-message/toast-message";
import useOpenToastMessage from "../hooks/toast-message-hook/use-open-toast-message";
import { Cell } from "../../types/@react-table/react-table/react-table";
import useFixedColumn from "../hooks/use_fixed_column/use_fixed_column";
import TableLayoutContainer from "../components/table_layout/table_layout_container";
import { AutoSizer } from "react-virtualized";
import GroupTableV2 from "../components/group_table_v2/group_table_v2";
import { useSticky } from "react-table-sticky";
import WorkingSystemHeader from "../components/working_system_header/working_system_header";
import { IRow } from "../components/group_table_v2/g_render_row";
import { IAsonicRow } from "../components/asonic-table/asonic-render-row";

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
`;

const TableContainer = styled.div`
  display: flex;
  flex: 2;
  overflow-x: hidden;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-top: 1px solid ${props => props.theme.colors.frameGrey};
  padding-top: 10px;
  button {
    padding: 4px;
  }
`;

const WorkTimeLink = styled.span`
  text-decoration: underline;
  :hover {
    color: ${props => props.theme.colors.darkBlue};
  }
`;

const title = "근무제 목록";

export interface WorkingTemplateEntityColumn extends WorkingTemplateEntity {
  basic?: string;
  workingTime?: string;
  leftSeat?: string;
  basicWorkingTime?: string;
  parentWType?: string;
}

const COLUMN_FOR_VALUE = {
  parentWType: "",
  wType: "유형",
  basic: "기본정보",
  name: "근무제명",
  workingTime: "근무시간",
  timeArray: "근무 가능시간",
  dutyWorkingTime: "의무근무시간",
  settlementSectionHour: "근무편성기간",
  basicWorkingTime: "기본근무시간",
  dayMaxWorkHour: "일",
  weekMaxWorkHour: "주",
  leftSeat: "이석설정",
  isLeftSeat: "사용여부",
  leftSeatBaseMin: "기준시간",
  leftSeatReasonUse: "사유입력",
  leftSeatPlaceInput: "장소입력",
  leftSeatBlackScreenUse: "화면잠금"
} as const;

type COLUMN_FOR_VALUE =
  (typeof COLUMN_FOR_VALUE)[keyof typeof COLUMN_FOR_VALUE];

function WorkingSystemScreen() {
  const [searchType, setSearchType] = useState<Wt_Search_Type>(
    Wt_Search_Type.WType
  );
  const [searchValue, setSearchValue] = useState("");
  const [dialogTitle, setDialogTitle] = useState<string>("표준");
  const { isOpen, handleOpenDialog } = useOpenDialog();
  const columnsForFixed = useMemo(() => {
    return [
      "parentWType",
      "basic",
      "workingTime",
      "basicWorkingTime",
      "leftSeat"
    ];
  }, []);

  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName,
    isActiveFilter,
    setIsActiveFilter
  } = useFixedColumn();

  const {
    fieldSort,
    handleFieldSort,
    listOfColumnDisabled,
    handleListOfColumnDisabled
  } = useNewSortBy();

  const {
    confirmTitle,
    confirmParagraph,
    isOpen: confirmIsOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const listOfSearchType = useMemo(() => {
    return [
      {
        value: Wt_Search_Type.WType,
        name: "유형"
      },
      {
        value: Wt_Search_Type.Name,
        name: "근무제명"
      }
    ];
  }, []);

  const [getListWorkingTemplate, { data, loading }] =
    useGetListAllWorkingTemplateLazyQuery({
      onError(error) {
        console.log(error);
      }
    });

  const [deleteWTBasicInfo, { client }] = useDeleteWtBasicInfoMutation({
    onError(error) {
      handleApolloLog(error);
    },
    update(_, { data }) {
      if (data?.deleteWTBasicInfo.ok) {
        client.resetStore();
        handleToast(
          "성공적으로 선택된 근무제들을 삭제하였습니다.",
          MessageTypes.SUCCESS
        );
      } else if (data?.deleteWTBasicInfo.error) {
        handleToast(data?.deleteWTBasicInfo.error, MessageTypes.ERROR);
      }
    }
  });

  const handleSearch = useCallback((data: SearchFieldValues) => {
    if (data.type) {
      setSearchType(data.type as Wt_Search_Type);
      setSearchValue(data.value as string);
    }
  }, []);

  const columns: ReactTable.Column<WorkingTemplateEntityColumn>[] =
    useMemo(() => {
      const width = 70;
      const lWidth = 120;
      let newFixedColumnName: string[] = [];
      if (!isNaN(Number(selectedFixedColumnNumber))) {
        newFixedColumnName = fixedColumnName.slice(
          0,
          Number(selectedFixedColumnNumber)
        );
      }

      let isParentWTypeSticky = "";
      if (
        newFixedColumnName.includes("parentWType") ||
        newFixedColumnName.includes("wType")
      ) {
        isParentWTypeSticky = "left";
      }

      let isBasicSticky = "";
      if (
        newFixedColumnName.includes("basic") ||
        newFixedColumnName.includes("name")
      ) {
        isBasicSticky = "left";
      }

      let isWorkingTimeSticky = "";
      if (
        newFixedColumnName.includes("workingTime") ||
        newFixedColumnName.includes("timeArray") ||
        newFixedColumnName.includes("dutyWorkingTime") ||
        newFixedColumnName.includes("settlementSectionHour")
      ) {
        isWorkingTimeSticky = "left";
      }

      let isBasicWorkingTimeSticky = "";
      if (
        newFixedColumnName.includes("basicWorkingTime") ||
        newFixedColumnName.includes("dayMaxWorkHour") ||
        newFixedColumnName.includes("weekMaxWorkHour")
      ) {
        isBasicWorkingTimeSticky = "left";
      }
      let isLeftSeatSticky = "";
      if (
        newFixedColumnName.includes("leftSeat") ||
        newFixedColumnName.includes("isLeftSeat") ||
        newFixedColumnName.includes("leftSeatBaseMin") ||
        newFixedColumnName.includes("leftSeatReasonUse") ||
        newFixedColumnName.includes("leftSeatPlaceInput") ||
        newFixedColumnName.includes("leftSeatBlackScreenUse")
      ) {
        isLeftSeatSticky = "left";
      }

      return [
        {
          Header: "",
          accessor: "parentWType",
          width,
          sticky: isParentWTypeSticky,
          columns: [
            {
              Header: "유형",
              accessor: "wType",
              width,
              sticky: newFixedColumnName.includes("wType") ? "left" : ""
            }
          ]
        },
        {
          Header: "기본정보",
          accessor: "basic",
          sticky: isBasicSticky,
          columns: [
            {
              Header: "근무제명",
              accessor: "name"
            }
          ]
        },
        {
          Header: "근무시간",
          accessor: "workingTime",
          sticky: isWorkingTimeSticky,
          columns: [
            {
              Header: "근무 가능시간",
              accessor: "timeArray",
              Cell(cell: Cell<WorkingTemplateEntity>) {
                let timeArray;
                if (cell.row.original?.timeArray) {
                  timeArray = JSON.parse(cell.row.original?.timeArray);
                }
                return timeArray && timeArray[0] ? (
                  <WorkTimeLink
                    onClick={event => {
                      setDialogTitle(cell.row.original.wType);
                      handleOpenDialog(true);
                      if (cell.toggleAllRowsSelected) {
                        cell?.toggleAllRowsSelected(false);
                      }
                      cell.row.toggleRowSelected(true);
                      event.stopPropagation();
                    }}
                  >
                    {timeArray[0]}
                  </WorkTimeLink>
                ) : (
                  ""
                );
              },
              width: lWidth
            },
            {
              Header: "의무근무시간",
              accessor: "dutyWorkingTime",
              Cell(cell: Cell<WorkingTemplateEntity>) {
                let timeArray;
                if (cell.row.original?.timeArray) {
                  timeArray = JSON.parse(cell.row.original?.timeArray);
                }
                return timeArray && timeArray[1] ? (
                  <span> {timeArray[1]}</span>
                ) : (
                  ""
                );
              },
              width: lWidth
            },
            {
              Header: "근무편성기간",
              accessor: "settlementSectionHour",
              Cell(cell: Cell<WorkingTemplateEntity>) {
                let timeArray;
                if (cell.row.original?.timeArray) {
                  timeArray = JSON.parse(cell.row.original?.timeArray);
                }
                return timeArray && timeArray[2] ? (
                  <span>{timeArray[2]}</span>
                ) : (
                  ""
                );
              },
              width: lWidth
            }
          ]
        },
        {
          Header: "기본근무시간",
          accessor: "basicWorkingTime",
          sticky: isBasicWorkingTimeSticky,
          columns: [
            {
              Header: "일",
              accessor: "dayMaxWorkHour",
              width
            },
            {
              Header: "주",
              accessor: "weekMaxWorkHour",
              width
            }
          ]
        },
        {
          Header: "이석설정",
          accessor: "leftSeat",
          sticky: isLeftSeatSticky,
          columns: [
            {
              Header: "사용여부",
              accessor: "isLeftSeat",
              width,
              Cell(cell: Cell<WorkingTemplateEntity>) {
                return (
                  <span>{cell.value === Is_Use.Use ? "사용" : "안함"}</span>
                );
              }
            },
            {
              Header: "기준시간",
              accessor: "leftSeatBaseMin",
              width
            },
            {
              Header: "사유입력",
              accessor: "leftSeatReasonUse",
              width,
              Cell(cell: Cell<WorkingTemplateEntity>) {
                return (
                  <span>{cell.value === Is_Use.Use ? "사용" : "안함"}</span>
                );
              }
            },
            {
              Header: "장소입력",
              accessor: "leftSeatPlaceInput",
              width,
              Cell(cell: Cell<WorkingTemplateEntity>) {
                return (
                  <span>{cell.value === Is_Use.Use ? "사용" : "안함"}</span>
                );
              }
            },
            {
              Header: "화면잠금",
              accessor: "leftSeatBlackScreenUse",
              width,
              Cell(cell: Cell<WorkingTemplateEntity>) {
                return (
                  <span>{cell.value === Is_Use.Use ? "사용" : "안함"}</span>
                );
              }
            }
          ]
        }
      ];
    }, [sFixedColumnNumber, fixedColumnName, fixedColumnNumber]);

  const list: WorkingTemplateEntityColumn[] = useMemo(() => {
    return data?.getListAllWorkingTemplate.list ?? [];
  }, [data]);

  const table = ReactTable.useTable<WorkingTemplateEntityColumn>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useColumnOrder,
    ReactTable.useRowSelect,
    useSticky
  );

  const handleConfirm = useCallback(() => {
    deleteWTBasicInfo({
      variables: {
        workingTemplateIdxList: table.selectedFlatRows.map(
          item => item.original.workingTemplateIdx
        )
      }
    });
    handleIsOpen(false);
  }, [deleteWTBasicInfo, table.selectedFlatRows, handleIsOpen]);

  const handleSelectRow = useCallback(
    (row?: IAsonicRow<WorkingTemplateEntityColumn>) => {},
    []
  );
  const selectedRow: ReactTable.Row<WorkingTemplateEntityColumn> | undefined =
    useMemo(() => {
      if (table.selectedFlatRows.length > 0) {
        return table.selectedFlatRows[table.selectedFlatRows.length - 1];
      }
      return;
    }, [table.selectedFlatRows]);

  const newColumns = useMemo(() => {
    const cl = table.columns
      .filter((_, index) => index < 2)
      .map(item => item.columns)
      .filter((_, index) => {
        return index !== 2;
      });
    return cl.flat();
  }, [table.columns]);

  const listOfFlexForHeader = useMemo(() => {
    if (table.visibleColumns) {
    }
    const isFind = table.visibleColumns.findIndex(
      item => item.Header === "근무제명"
    );
    if (isFind === -1) {
      return [
        "기본정보",
        "근무제명",
        "이석설정",
        "사용여부",
        "기준시간",
        "사유입력",
        "장소입력",
        "화면잠금"
      ];
    }
    return ["기본정보", "근무제명"];
  }, [table.visibleColumns]);

  useEffect(() => {
    handleListOfColumnDisabled([
      "leftSeat",
      "parentWType",
      "basic",
      "workingTime",
      "basicWorkingTime",
      "leaveSetting",
      "status",
      "dutyWorkingTime"
    ]);
  }, [handleListOfColumnDisabled]);

  useEffect(() => {
    getListWorkingTemplate({
      variables: {
        searchValue,
        searchType,
        fieldSort
      }
    });
  }, [getListWorkingTemplate, searchValue, searchType, fieldSort]);

  useEffect(() => {
    const listOfColumn = columnsForFixed;
    setFixedColumnName(listOfColumn);
  }, [setFixedColumnName]);

  useEffect(() => {
    if (isActiveFilter) {
      setIsActiveFilter(false);
      let newColumns = table.visibleColumns.map(item => item.id);
      setFixedColumnName(newColumns);
    }
  }, [isActiveFilter, table.visibleColumns]);
  return (
    <Container>
      <TableContainer>
        <WorkingSystemHeader<WorkingTemplateEntityColumn>
          columns={newColumns as TColumn<WorkingTemplateEntityColumn>[]}
          table={table}
          title={title}
          headerTitleList={Object.values(COLUMN_FOR_VALUE)}
          count={list.length ?? 0}
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
          listOfSearchType={listOfSearchType}
          handleSearch={handleSearch}
          setIsActiveFilter={setIsActiveFilter}
          toggleHideColumn={table.toggleHideColumn}
        />
        <TableLayoutContainer>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <GroupTableV2
                  table={table}
                  title={title}
                  isLoading={loading}
                  total={data?.getListAllWorkingTemplate.list?.length ?? 0}
                  listOfSearchType={listOfSearchType}
                  handleSearch={handleSearch}
                  selectedRow={selectedRow}
                  handleFieldSort={handleFieldSort}
                  fieldSort={fieldSort}
                  listOfFlexForHeader={listOfFlexForHeader}
                  listOfColumnDisabled={listOfColumnDisabled}
                  isLastFlex={false}
                  isGroup
                  height={height}
                  width={width}
                />
              );
            }}
          </AutoSizer>
        </TableLayoutContainer>
        {/* <GroupTable<WorkingTemplateEntity>
          title={title}
          isLoading={loading}
          total={data?.getListAllWorkingTemplate.list?.length ?? 0}
          listOfSearchType={listOfSearchType}
          handleSearch={handleSearch}
          prepareRow={table.prepareRow}
          getTableProps={table.getTableProps}
          headerGroups={table.headerGroups}
          getTableBodyProps={table.getTableBodyProps}
          rows={table.rows}
          selectedRow={selectedRow}
          handleFieldSort={handleFieldSort}
          fieldSort={fieldSort}
          columns={table.columns as TColumn<WorkingTemplateEntity>[]}
          toggleHideColumn={table.toggleHideColumn}
          listOfFlexForHeader={["기본정보", "근무제명"]}
          listOfColumnDisabled={listOfColumnDisabled}
          isLastFlex={false}
          isGroup
        >
          <WorkingSystemHelp />
        </GroupTable> */}
      </TableContainer>
      <ButtonContainer>
        <Button
          customMinWidth="50px"
          maxWidth="50px"
          customMinHeight="0px"
          onClick={() => {
            window.open(
              `${routes.pageRoutes.asonicWorkingSystem}`,
              "",
              "width=1230, height=820, scrollbars=yes"
            );
          }}
        >{`추가`}</Button>
        <Button
          customMinWidth="50px"
          maxWidth="50px"
          customMinHeight="0px"
          onClick={() => {
            window.open(
              `${routes.pageRoutes.asonicWorkingSystem}/${selectedRow?.original.workingTemplateIdx}`,
              "",
              "width=1400, height=820, scrollbars=yes"
            );
          }}
          disabled={!selectedRow?.original.workingTemplateIdx}
        >{`수정`}</Button>
        <Button
          customMinWidth="50px"
          maxWidth="50px"
          customMinHeight="0px"
          disabled={!selectedRow?.original.workingTemplateIdx}
          onClick={() => {
            handleIsOpen(true);
            handleConfirmMessage({
              title: "근무제 삭제",
              p: "선택된 근무제들을 하시겠습니까?",
              messageTypes: MessageTypes.WARNING
            });
          }}
        >{`삭제`}</Button>
      </ButtonContainer>
      {confirmIsOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.WARNING}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      {isOpen && (
        <AsonicDialog
          title={`${dialogTitle} 근무가능시간`}
          height={dialogTitle === "자율(출근)" ? "" : "400px"}
          minHeight={dialogTitle === "자율(출근)" ? "" : "400px"}
          handleClose={(value: boolean) => {
            handleOpenDialog(value);
          }}
        >
          <WorkSystemDialog type={dialogTitle} selectedRow={selectedRow} />
        </AsonicDialog>
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default WorkingSystemScreen;
