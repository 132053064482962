import styled from "styled-components";

export const TitleBar = styled.div`
  display: flex;
`;

export const TableSection = styled.div`
  display: flex;
  flex: 5;
`;

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

export const FormSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  :nth-child(2) {
    align-self: flex-start;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
`;
