import { useMemo, useState } from "react";
import styled from "styled-components";

export interface IPageSetProps {
  take: number;
  handleTake: (value: string) => void;
}

const SelectContainer = styled.div`
  display: flex;
  gap: 5px;
  label {
    display: flex;
    align-items: center;
    text-align: center;
    min-width: max-content;
  }
`;

function PageSet({ take, handleTake }: IPageSetProps) {
  const [takePage, setTakePage] = useState<number>(take);

  const rows = useMemo(() => [10, 20, 30], []);
  return (
    <SelectContainer>
      <label htmlFor="row">페이지당 수: </label>
      <select
        id="row"
        value={takePage}
        onChange={event => {
          const currentPage = parseInt(event.currentTarget.value);
          setTakePage(currentPage);
          handleTake(event.currentTarget.value);
        }}
      >
        {rows.map((item, index) => (
          <option key={index} value={item}>
            {item}개
          </option>
        ))}
      </select>
    </SelectContainer>
  );
}

export default PageSet;
