import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSONObject: any;
};

/**
 * 공용 및 미공용 활성화 값
 *   0: 미공용, 1: 공용
 */
export enum Allowed_Flag_Type {
  Allow = 'ALLOW',
  NotAllow = 'NOT_ALLOW'
}

/** 연차 설정 정보 타입 */
export enum Annual_Accrual_Type {
  BasedOnFiscalYear = 'BASED_ON_FISCAL_YEAR',
  BasedOnTheDateOfJoining = 'BASED_ON_THE_DATE_OF_JOINING'
}

/** 연차발생방식설정 */
export enum Annual_Accrual_Type_For_Less_Than_A_Year {
  FixDate = 'FIX_DATE',
  Monthly = 'MONTHLY'
}

/** 연차발생방식설정 타입 */
export enum Annual_Accrual_Type_For_Next_Year_Of_Employment {
  ApplyAdminIndividual = 'APPLY_ADMIN_INDIVIDUAL',
  ApplyTheSameBasicDay = 'APPLY_THE_SAME_BASIC_DAY',
  GiveFixedDate = 'GIVE_FIXED_DATE',
  Monthly = 'MONTHLY'
}

/** 연차확정설정 */
export enum Annual_Fix_Type {
  AutoConfirmNextYear = 'AUTO_CONFIRM_NEXT_YEAR',
  FullAutoConfirm = 'FULL_AUTO_CONFIRM',
  IndividualConfirmByManager = 'INDIVIDUAL_CONFIRM_BY_MANAGER',
  MoreThanAnYearConfirm = 'MORE_THAN_AN_YEAR_CONFIRM'
}

/** 대시보드 결재목록 미리보기에 대한 설정 타입 */
export enum Approval_Admin_Setting_Preview_Type {
  UnUsed = 'UN_USED',
  Used = 'USED'
}

/** 결재 상태별 목록에 대한 타입 */
export enum Approval_For_List_Type {
  Complete = 'COMPLETE',
  My = 'MY',
  Receive = 'RECEIVE',
  Reference = 'REFERENCE',
  Waiting = 'WAITING'
}

/** 결재 종류 */
export enum Approval_Sort {
  ApprovalLine = 'APPROVAL_LINE',
  AutoApproval = 'AUTO_APPROVAL'
}

/** 결재 결과 타입 */
export enum Approval_Status {
  Approval = 'APPROVAL',
  InTheMiddleOfReturn = 'IN_THE_MIDDLE_OF_RETURN',
  Outstanding = 'OUTSTANDING',
  Return = 'RETURN',
  Waiting = 'WAITING'
}

/** 사용자 결재 타입(기안자, 전결, 합의, 결재) */
export enum Approval_User_Type {
  Agreement = 'AGREEMENT',
  Approval = 'APPROVAL',
  DecideArbitrarily = 'DECIDE_ARBITRARILY',
  Drafter = 'DRAFTER'
}

/** 근무제(이석관리) Entity */
export type AbsenceManagementEntity = {
  __typename?: 'AbsenceManagementEntity';
  /** 연장근무시이석[미사용(0)/사용(1)] */
  extendLeftSeatAutoCommuteOutUse: Is_Use;
  /** 근무시간이석적용여부[미사용(0)/사용(1)] */
  leftSeatApplyTimeUse: Is_Use;
  /** 사유입력설정(참여자(동행자)입력)[미체크(0)/체크(1)] */
  leftSeatAttendeeInput: Is_Use;
  /** 자동이석감지사용[미사용(0)/사용(1)] */
  leftSeatAutoUse: Is_Use;
  /** 이석기준시간 */
  leftSeatBaseMin: Scalars['Int'];
  /** 사유입력설정(블랙스크린적용)[미체크(0),체크(1)] */
  leftSeatBlackScreenUse: Is_Use;
  /** 사유입력설정(이동장소입력)[미체크(0)/체크(1)] */
  leftSeatPlaceInput: Is_Use;
  /** 이석해제시사유입력[미사용(0)/사용(1)] */
  leftSeatReasonUse: Is_Use;
  /** 근무제 idx */
  workingTemplateIdx: Scalars['Int'];
};

/** 근무제[이석관리] - 이석(자리비움)관리, 자동이석(자리비움)관리 저장 요청 결과 */
export type AddAbsenceManagementInfoOutput = {
  __typename?: 'AddAbsenceManagementInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 알람 추가 요청 결과 */
export type AddAlarmEventOutput = {
  __typename?: 'AddAlarmEventOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 알람 ID */
  id?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 엑셀로부터 연차현황 정보 추가 요청 결과 */
export type AddAnnualStatusFormExcelOutput = {
  __typename?: 'AddAnnualStatusFormExcelOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재선 추가 요청 결과 */
export type AddApprovalLineOutput = {
  __typename?: 'AddApprovalLineOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 결재선 idx */
  lineIdx?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 템플릿 추가 요청 결과 */
export type AddApprovalNotificationTemplateOutput = {
  __typename?: 'AddApprovalNotificationTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 결재정보 알림 템플릿 ID */
  id?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 근무제[근무시간] < ( 자율출근제 > 근무시간 > 기본설정) - 삭제 후 추가 요청 결과 */
export type AddAutonomyWorkingHourInfoOutput = {
  __typename?: 'AddAutonomyWorkingHourInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 채팅 방 추가하기 요청 결과 */
export type AddChatRoomOutput = {
  __typename?: 'AddChatRoomOutput';
  /** 채팅방 Idx */
  chatRoomIdx?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  /** 채팅방 이름 */
  nameOfRoom?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 출퇴근 기록 추가 요청 결과 */
export type AddCommuteDateTimeOutput = {
  __typename?: 'AddCommuteDateTimeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 정보 추가 요청 결과 */
export type AddCommuteGpsInfoOutput = {
  __typename?: 'AddCommuteGpsInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 사용자 출퇴근 지역 정보 추가 ID */
  id?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 근무제[출퇴근기록] - 출 / 퇴근 기록 설정, 기타설정 저장 요청 결과 */
export type AddCommuteRecordInfoOutput = {
  __typename?: 'AddCommuteRecordInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무제[제어(알림)] - 제어(알림)설정 저장 요청 결과 */
export type AddControlNotificationOutput = {
  __typename?: 'AddControlNotificationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 정책 적용된 직원 추가 요청 결과 */
export type AddDepartmentAppliedToTheTemplateOutput = {
  __typename?: 'AddDepartmentAppliedToTheTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 정책에 적용할 직원 추가 요청 결과 */
export type AddEmployeeAppliedToTheTemplateOutput = {
  __typename?: 'AddEmployeeAppliedToTheTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무제[기타운영시간] - 기타운영시간설정 저장 요청 결과 */
export type AddEtcOperatingHoursOutput = {
  __typename?: 'AddEtcOperatingHoursOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무제[근무시간] 추가/수정( 탄력근무시간제 > 근무시간 > 기본설정, 주차별 근무시간 설정) - 삭제 후 추가 요청 */
export type AddFlexibleWorkingHourInfoOutput = {
  __typename?: 'AddFlexibleWorkingHourInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 계획 추가 요청 결과 */
export type AddListOfApprovalNotificationScheduleByTemplateOutput = {
  __typename?: 'AddListOfApprovalNotificationScheduleByTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 결재정보 알림 계획 ID */
  id?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 예약목록 추가 요청 결과 */
export type AddReservationCategoryOutput = {
  __typename?: 'AddReservationCategoryOutput';
  /** 예약목록 IDX */
  categoryIdx: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약 등록을 위한 요청 */
export type AddReservationOutput = {
  __typename?: 'AddReservationOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 예약 Idx */
  infoIdx?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 예약종류 추가 요청 */
export type AddReservationTypeOutput = {
  __typename?: 'AddReservationTypeOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 예약그룹 idx */
  groupIdx?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무제[근무시간] ( 선택근무시간제  > 근무시간 > 근무시간 템플릿) - 추가 요청 결과 */
export type AddSelfDeterminedWorkHourTemplateOutput = {
  __typename?: 'AddSelfDeterminedWorkHourTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무제[근무시간] 추가/수정( 선택근무시간제  > 근무시간 > 기본설정, 근무시간 템플릿 체크 항목 저장) - 추가/수정 요청 결과 */
export type AddSelfDeterminedWorkHoursOutput = {
  __typename?: 'AddSelfDeterminedWorkHoursOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무제[근무시간] 추가/수정( 표준/시차 출퇴근제 > 근무시간 > 기본설정) - 삭제 후 추가 요청 결과 */
export type AddStandardWtBasicInfoOutput = {
  __typename?: 'AddStandardWTBasicInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 템플릿 근무 계획 정보 요청 결과 */
export type AddTemplateScheduleInfoOutput = {
  __typename?: 'AddTemplateScheduleInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 근무계획 ID */
  id?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 근무 템플릿 추가 요청 결과 */
export type AddTimeTemplateOutput = {
  __typename?: 'AddTimeTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 근무 템플릿 ID */
  id?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 직원 일별 근무 정보 추가 (같은날 정보가 있으면 지우고 다시 추가) 결과 */
export type AddUserDailyWorkInfoOutput = {
  __typename?: 'AddUserDailyWorkInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type AddUserDailyWorkInfoType = {
  /** 휴게시간(추가인정)-4시간당 30분 */
  breakTimeAdjustmentMin?: InputMaybe<Scalars['String']>;
  /** 날짜 */
  date: Scalars['String'];
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 퇴근 시간 */
  endTime: Scalars['String'];
  /** 출근 시간 */
  startTime: Scalars['String'];
};

/** 대화 상대 초대 요청 결과 */
export type AddUserFromChatRoomOutput = {
  __typename?: 'AddUserFromChatRoomOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 운행정보 추가를 위한 요청 결과 */
export type AddVehicleDrivingInformationOutput = {
  __typename?: 'AddVehicleDrivingInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** logIdx */
  logIdx?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

export type AddVehicleInformationOutput = {
  __typename?: 'AddVehicleInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무제[기본정보] 추가 요청 결과 */
export type AddWtBasicInfoOutput = {
  __typename?: 'AddWTBasicInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 근무제 idx */
  workingTemplateIdx?: Maybe<Scalars['Int']>;
};

/** 근무제[위젯설정] - 위젯설정 저장 요청 결과 */
export type AddWidgetSettingInfoOutput = {
  __typename?: 'AddWidgetSettingInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 알람 설정 정보 */
export type AlarmSettingEntity = {
  __typename?: 'AlarmSettingEntity';
  /** 알람 설명 */
  description?: Maybe<Scalars['String']>;
  /** 알람 종류 */
  eventType?: Maybe<Scalars['Int']>;
  /** 링크 URL */
  linkUrl?: Maybe<Scalars['String']>;
  /** 알람 시간 */
  pushMin?: Maybe<Scalars['Int']>;
  /** 알람 설정 index */
  settingIndex: Scalars['Int'];
  /** 적용 장치 */
  targetDevice?: Maybe<Scalars['Int']>;
  /** 알람 이름 */
  title?: Maybe<Scalars['String']>;
};

/** 알람 ON/OFF 상태값 */
export enum AlarmStatus {
  Off = 'OFF',
  On = 'ON'
}

/** 연차 설정 정보 Entity */
export type AnnualEnvInfoEntity = {
  __typename?: 'AnnualEnvInfoEntity';
  /** 회계기준일 */
  accountingMonth: Scalars['Int'];
  /** 연차 발생기준 설정 값 */
  annualAccrualType: Annual_Accrual_Type;
  /** 연차차감우선순위 */
  annualDeductionPriorityType: Scalars['Int'];
  /** 연차 확정 설정 */
  annualFixType: Annual_Fix_Type;
  /** 최대연차제한 */
  annualMaxCount: Scalars['Int'];
};

/** 변경된 연차 이력 */
export type AnnualHistoryEntity = {
  __typename?: 'AnnualHistoryEntity';
  /** 가산일수 */
  addDay: Scalars['Int'];
  /** 귀속년도 */
  applyYear: Scalars['String'];
  /** 기본일수 */
  basicDay: Scalars['Int'];
  /** 기본조정 */
  basicModifyDay: Scalars['Int'];
  /** 생성자 */
  createSubject: Scalars['String'];
  /** 사용자 사번 */
  employeeId: Scalars['String'];
  /** 이월연차 */
  leaveCarriedForwardDay: Scalars['Int'];
  /** logIdx */
  logIdx: Scalars['Int'];
  /** 변경 날짜 및 시간 */
  modifyDatetime: Scalars['String'];
  /** 변경사유 */
  modifyReason?: Maybe<Scalars['String']>;
  /** 연차 종료일 */
  periodateEnd: Scalars['String'];
  /** 연차 시작일 */
  periodateStart: Scalars['String'];
  /** 상태 */
  statusFlag: Scalars['String'];
  /** 사용조정 */
  useModifyDay: Scalars['Int'];
};

/** 결재 데이터 양식 */
export type AnnualStatusEntity = {
  __typename?: 'AnnualStatusEntity';
  /** 총 발생일 */
  annualDay?: Maybe<Scalars['Float']>;
  /** 잔여일 */
  annualRemainingDay?: Maybe<Scalars['Float']>;
  /** 사용일 */
  annualUseDay?: Maybe<Scalars['Float']>;
  /** 귀속년도 */
  applyYear?: Maybe<Scalars['String']>;
  /** 부서이름 */
  deptName?: Maybe<Scalars['String']>;
  /** 비고 */
  descr?: Maybe<Scalars['String']>;
  /** 사용자 이름 */
  empName?: Maybe<Scalars['String']>;
  /** 사번 */
  employeeId: Scalars['String'];
  /** 입사 날짜 */
  joiningDate?: Maybe<Scalars['String']>;
};

/** 결재 상신 요청 결과 */
export type ApplicationApprovalOutput = {
  __typename?: 'ApplicationApprovalOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재선 양식 추가 필드 정보 */
export type ApprovalAdditionalFieldEntity = {
  __typename?: 'ApprovalAdditionalFieldEntity';
  /** 잔여일 */
  leaveDay: Scalars['Int'];
  /** 총 발생일 */
  totDay: Scalars['Int'];
  /** 사용일 */
  useDay: Scalars['Int'];
};

/** 결재 양식 상세 정보 */
export type ApprovalDetailInformationEntity = {
  __typename?: 'ApprovalDetailInformationEntity';
  /** 하루 기준 할당일 */
  allocateDay?: Maybe<Scalars['String']>;
  /** 연차정보 총발생일/사용일/잔여일 */
  annualInfo?: Maybe<Scalars['String']>;
  /** 결재 idx */
  approvalRequestIdx: Scalars['Int'];
  /** 결재 제목 */
  approvalTitle?: Maybe<Scalars['String']>;
  /** 첨부 파일 링크 */
  attachedFileLink?: Maybe<Scalars['String']>;
  /** 첨부 파일 이름 */
  attachedFileName?: Maybe<Scalars['String']>;
  /** 정보 구분 이름 */
  categoryName: Scalars['String'];
  /** 부서 이름 */
  departmentName: Scalars['String'];
  /** 문서 번호 */
  docNumber?: Maybe<Scalars['String']>;
  /** 기안자 ID */
  employeeIdRequest: Scalars['String'];
  /** 사용자 이름 */
  employeeName: Scalars['String'];
  /** 상세 설명 */
  extendDescription?: Maybe<Scalars['String']>;
  /** 연장 종료 날짜 */
  extendEDate?: Maybe<Scalars['String']>;
  /** 연장 종료 시간 */
  extendEDateTime?: Maybe<Scalars['String']>;
  /** 연장 시작 날짜 */
  extendSDate?: Maybe<Scalars['String']>;
  /** 연장 시작 시간 */
  extendSDateTime?: Maybe<Scalars['String']>;
  /** 제 목 */
  formName?: Maybe<Scalars['String']>;
  /** 결재양식 관리 이름 */
  formTemplateTitle: Scalars['String'];
  /** 신청일 */
  signUpDateTime?: Maybe<Scalars['String']>;
  /** 작성일 */
  signUpDateTimeTemp?: Maybe<Scalars['String']>;
  /** 보존년한 */
  storePeriod?: Maybe<Scalars['String']>;
  /** 임시 저장 상세 설명 */
  temporaryExtendDescription?: Maybe<Scalars['String']>;
  /** 사용자 직책 */
  userPosition?: Maybe<Scalars['String']>;
};

/** 결재 데이터 양식 */
export type ApprovalEntity = {
  __typename?: 'ApprovalEntity';
  /** 하루 기준 할당일 */
  allocateDay?: Maybe<Scalars['String']>;
  /** 연차정보 총발생일/사용일/잔여일 */
  annualInfo?: Maybe<Scalars['String']>;
  /** 결재 ID */
  approvalRequestIdx: Scalars['Int'];
  /** 결재 상태 */
  approvalStatus: ApprovalStatus;
  /** 결재 제목 */
  approvalTitle?: Maybe<Scalars['String']>;
  /** 결재선 타입 */
  approvalType: Approval_Sort;
  /** 첨부 파일 링크 */
  attachedFileLink?: Maybe<Scalars['String']>;
  /** 첨부 파일 이름 */
  attachedFileName?: Maybe<Scalars['String']>;
  /** 결재 카테고리 ID */
  categoryId: Scalars['Int'];
  /** 부서명 */
  departName: Scalars['String'];
  /** 문서 번호 */
  docNumber?: Maybe<Scalars['String']>;
  /** 신청사유 */
  extendDescr?: Maybe<Scalars['String']>;
  /** 적용 종료 날짜 */
  extendEndDate: Scalars['String'];
  /** 적용 종료 날짜 시간 */
  extendEndDateTime: Scalars['String'];
  /** 적용 시작 날짜 */
  extendStartDate: Scalars['String'];
  /** 적용 시작 날짜 시간 */
  extendStartDateTime: Scalars['String'];
  /** 근무 결재신청양식 IDX */
  formIdx?: Maybe<Scalars['Int']>;
  /** 결재선 템플릿 idx */
  formTemplateIdx: Scalars['Int'];
  /** 기안자 */
  name: Scalars['String'];
  /** 승인 및 반려 사유 */
  resultDescr?: Maybe<Scalars['String']>;
  /** 신청일 */
  signUpDateTime?: Maybe<Scalars['String']>;
  /** 작성일 */
  signUpDateTimeTemp?: Maybe<Scalars['String']>;
};

/** 출퇴근 인정 여부 enum 타입 */
export enum ApprovalFlag {
  CorrectedVersion = 'CORRECTED_VERSION',
  NoRecognition = 'NO_RECOGNITION',
  OriginRecognition = 'ORIGIN_RECOGNITION'
}

/** 결재 이력 정보 */
export type ApprovalHistoryEntity = {
  __typename?: 'ApprovalHistoryEntity';
  /** 결재 결과 */
  approvalResult?: Maybe<Approval_Status>;
  /** 결재 시간 */
  approvalTime?: Maybe<Scalars['String']>;
  /** 부서 이름 */
  departmentName: Scalars['String'];
  /** 결재 승인 및 반려 사유 */
  descriptionComment?: Maybe<Scalars['String']>;
  /** 사용자 이름 */
  empName: Scalars['String'];
  /** 결재선 순서 */
  numRank: Scalars['Int'];
  /** 사용자 직책 */
  userPosition?: Maybe<Scalars['String']>;
  /** 열람 시간 */
  viewTime?: Maybe<Scalars['String']>;
};

/** 결재 이력 정보 요청 결과 */
export type ApprovalHistoryOutput = {
  __typename?: 'ApprovalHistoryOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 이력 정보 목록 */
  list?: Maybe<Array<ApprovalHistoryEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재선 추가, 수정, 삭제를 위한 타입 */
export enum ApprovalLineControlType {
  Add = 'ADD',
  Delete = 'DELETE',
  Edit = 'EDIT'
}

/** 결재선 정보 */
export type ApprovalLineEntity = {
  __typename?: 'ApprovalLineEntity';
  /** 기본결재 */
  basicLine: Is_Default_Approval_Line;
  /** 결재선 idx */
  lineIdx: Scalars['Int'];
  /** 근무 시간 타입 이름 */
  newTitle: Scalars['String'];
};

/** 결재선 양식 정보 */
export type ApprovalLineFormEntity = {
  __typename?: 'ApprovalLineFormEntity';
  /** 결재선 타입 */
  approvalType: Approval_Sort;
  /** 결재선 양식 설명 */
  descr?: Maybe<Scalars['String']>;
  /** 결재선 양식 idx */
  formIdx: Scalars['Int'];
  /** 양식 이름 */
  formName: Scalars['String'];
  /** 결재선 템플릿 idx */
  formTemplateIdx: Scalars['Int'];
  /** 이름 */
  name: Scalars['String'];
  /** 결재선 순서 */
  numRank: Scalars['Int'];
  /** 결재선 타이틀 */
  templateTitle: Scalars['String'];
};

/** 결재선 사용자 정보 */
export type ApprovalLineUserEntity = {
  /** 사용자 id */
  employeeid: Scalars['String'];
  /** 사용자 결재선 순서 */
  order: Scalars['Int'];
  /** 사용자 결재선 타입 */
  type: Approval_User_Type;
};

/** 정책적용된 부서 정보 */
export type ApprovalNotificationDepartment = {
  __typename?: 'ApprovalNotificationDepartment';
  /** 알람 이름 */
  alarmName: Scalars['String'];
  /** 부서 이름 */
  departName: Scalars['String'];
  /** 부서 ID */
  departmentId: Scalars['String'];
  /** 템플릿 Idx */
  templateIdx: Scalars['Int'];
};

/** 결재정보 알림 계획 */
export type ApprovalNotificationScheduleByTemplate = {
  __typename?: 'ApprovalNotificationScheduleByTemplate';
  /** 결재 시작/종료 시간 기준 선택 */
  approvalTimeType?: Maybe<ApprovalTimeType>;
  /** 구분 ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** 알림 날짜 */
  dayCount?: Maybe<Scalars['Int']>;
  /** 알림 날짜 시작/종료 구분 */
  dayType?: Maybe<ApprovalTimeType>;
  /** 알림 계획 상세설명 */
  description?: Maybe<Scalars['String']>;
  /** APP 알림 사용유무 */
  isApp?: Maybe<IsUsingNotification>;
  /** Mail 알림 사용유무 */
  isMail?: Maybe<IsUsingNotification>;
  /** PC 알림 사용유무 */
  isPc?: Maybe<IsUsingNotification>;
  /** 알림 시간 */
  minCount?: Maybe<Scalars['Int']>;
  /** 알림 시간 시작/종료 구분 */
  minType?: Maybe<ApprovalTimeType>;
  /** 결재정보 알림 계획 식별정보 */
  scheduleIdx: Scalars['Int'];
  /** 결재정보 알림 계획의 템플릿 식별정보 */
  templateIdx: Scalars['Int'];
};

/** 결재정보 알림 템플릿 정보 */
export type ApprovalNotificationTemplate = {
  __typename?: 'ApprovalNotificationTemplate';
  /** 결재정보 알림 이름 */
  alarmName: Scalars['String'];
  /** 결재정보 알림 상세설명 */
  description?: Maybe<Scalars['String']>;
  /** 결재정보 알림 템플릿 index */
  templateIdx: Scalars['Int'];
};

/** 결재 비밀번호 및 서명 설정 정보 */
export type ApprovalPasswordAndSignSettingInfoEntity = {
  __typename?: 'ApprovalPasswordAndSignSettingInfoEntity';
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 결재 이메일 알림 사용여부 */
  isSignEmailAlarm: Is_Sign_Alarm_Type;
  /** 결재 모바일 알림 사용여부 */
  isSignMobileAlarm: Is_Sign_Alarm_Type;
  /** 결재 비밀번호 사용여부 */
  isSignPassword: Is_Sign_Password;
  /** 사용자 서명 이미지 */
  signImage?: Maybe<Scalars['String']>;
  /** 결재 비밀번호 */
  signPassword: Scalars['String'];
  /** 사용자 서명 텍스트 */
  signText?: Maybe<Scalars['String']>;
  /** 서명 타입 */
  signType: SignType;
  /** 서명 image */
  sign_image?: Maybe<Scalars['String']>;
};

/** 결재 검색 타입 */
export enum ApprovalSearchType {
  All = 'ALL',
  App = 'APP',
  ApprovalForm = 'APPROVAL_FORM',
  DocumentNumber = 'DOCUMENT_NUMBER',
  Drafter = 'DRAFTER',
  Title = 'TITLE'
}

/** 결재 상태 타입 */
export enum ApprovalStatus {
  Approval = 'APPROVAL',
  InTheMiddleOfReturn = 'IN_THE_MIDDLE_OF_RETURN',
  Outstanding = 'OUTSTANDING',
  Return = 'RETURN',
  Waiting = 'WAITING'
}

/** 결재현황 정보 Entity */
export type ApprovalStatusEntity = {
  __typename?: 'ApprovalStatusEntity';
  /** 정보구분 */
  categoryName?: Maybe<Scalars['String']>;
  /** 부서이름 */
  deptName?: Maybe<Scalars['String']>;
  /** 사용자 이름 */
  empName?: Maybe<Scalars['String']>;
  /** 사번 */
  employeeId: Scalars['String'];
  /** 신청종료일시 */
  endDateTime?: Maybe<Scalars['String']>;
  /** 처리시간 */
  processDateTime?: Maybe<Scalars['String']>;
  /** 처리사유 */
  processDescr?: Maybe<Scalars['String']>;
  /** 처리결과 */
  processStatus?: Maybe<Scalars['String']>;
  /** 작성일 */
  requestDateTime?: Maybe<Scalars['String']>;
  /** 신청사유 */
  requestDescr?: Maybe<Scalars['String']>;
  /** 신청시작일시 */
  startDateTime?: Maybe<Scalars['String']>;
};

/** 결재 양식 */
export type ApprovalTemplateEntity = {
  __typename?: 'ApprovalTemplateEntity';
  /** 결재 종류 */
  approvalType: Approval_Sort;
  /** 근무 시간 타입 id */
  categoryId: Scalars['Int'];
  /** 상세설명 */
  description?: Maybe<Scalars['String']>;
  /** 결재 양식 idx */
  formIdx: Scalars['Int'];
  /** 결재 양식 이름 */
  formName: Scalars['String'];
  /** 결재 템플릿 idx */
  formTemplateIdx: Scalars['Float'];
  /** 근무 타입 이름 */
  name: Scalars['String'];
  /** 양식 랭크 */
  numRank: Scalars['Float'];
  /** 결재 템플릿 이름 */
  templateTitle: Scalars['String'];
};

/** 결재 시작/종료 시간 구분 */
export enum ApprovalTimeType {
  End = 'END',
  Start = 'START'
}

/** AttendanceDetailsEntity 정보 */
export type AttendanceDetailsEntity = {
  __typename?: 'AttendanceDetailsEntity';
  /** 날짜 */
  dateValue: Scalars['String'];
  /** 요일 */
  dayOfWeek: Scalars['String'];
  /** 부서 이름 */
  departName: Scalars['String'];
  /** 사용자 이름 */
  empName: Scalars['String'];
  /** 사번 */
  employeeId: Scalars['String'];
  /** 기간 */
  periodInformation: Scalars['String'];
  /** 휴게시간 */
  restMin?: Maybe<Scalars['String']>;
  /** 시간외(잔여시간) */
  totExtPossibleLeaveMin?: Maybe<Scalars['String']>;
  /** 시간외(가능시간) */
  totExtPossibleMin?: Maybe<Scalars['String']>;
  /** 소정 + 시간외(가능시간) */
  totPossibleMin?: Maybe<Scalars['String']>;
  /** 소정근무(잔여시간) */
  totWorkPossibleLeaveMin?: Maybe<Scalars['String']>;
  /** 소정근무(가능시간) */
  totWorkPossibleMin?: Maybe<Scalars['String']>;
  /** 시간외(인정시간) */
  useExtMin?: Maybe<Scalars['String']>;
  /** 소정근무(인정시간) */
  useWorkMin?: Maybe<Scalars['String']>;
  /** 퇴근시간 */
  workEndTime?: Maybe<Scalars['String']>;
  /** 출근시간 */
  workStartTime?: Maybe<Scalars['String']>;
  /** 근무제 이름 */
  workingTemplateName?: Maybe<Scalars['String']>;
};

/** 휴게시간적용 타입 */
export enum Break_Time_Type {
  Basic = 'BASIC',
  SeparateSetting = 'SEPARATE_SETTING'
}

/** 사용자 정보 */
export type BasicUserInfoEntity = {
  __typename?: 'BasicUserInfoEntity';
  /** 부서 id */
  departmentId: Scalars['Int'];
  /** 부서 이름 */
  departmentName: Scalars['String'];
  /** 사용자 이름 */
  empName: Scalars['String'];
  /** 입사 일자 */
  joiningDate?: Maybe<Scalars['String']>;
  /** 퇴사 일자 */
  leavingDate?: Maybe<Scalars['String']>;
  /** 사용자 사진 */
  myPicture?: Maybe<Scalars['String']>;
  /** 사용자 사진 */
  my_picture?: Maybe<Array<Scalars['String']>>;
  /** 서명(한자) */
  nameChinese?: Maybe<Scalars['String']>;
  /** 서명(영문) */
  nameEnglish?: Maybe<Scalars['String']>;
  /** 전화번호 */
  phoneNum?: Maybe<Scalars['String']>;
  /** 이메일 */
  userEmail?: Maybe<Scalars['String']>;
  /** 직책 */
  userJob?: Maybe<Scalars['String']>;
  /** 직위 */
  userPosition?: Maybe<Scalars['String']>;
  /** 직급(호봉) */
  userRank?: Maybe<Scalars['String']>;
};

/** 이월연자적용범위 타입 */
export enum Carriedforward_Range_Type {
  MinusAndPlusAnnualLeave = 'MINUS_AND_PLUS_ANNUAL_LEAVE',
  MinusAnnualLeaveOnly = 'MINUS_ANNUAL_LEAVE_ONLY',
  PlusAnnualLeaveOnly = 'PLUS_ANNUAL_LEAVE_ONLY'
}

/** 이월 연차 적용 설정 타입 */
export enum Carriedforward_Type {
  Apply = 'APPLY',
  NotApplied = 'NOT_APPLIED'
}

/** 채팅 메시지 타입 */
export type ChatMessageEntity = {
  __typename?: 'ChatMessageEntity';
  /** chat room attendee user ID */
  chatroom_attendee_employeeid: Scalars['String'];
  /** chat room Idx */
  chatroom_idx: Scalars['Int'];
  /** message */
  chatroom_message_data?: Maybe<Scalars['String']>;
  /** The date of message created */
  chatroom_message_datetime?: Maybe<Scalars['String']>;
  message_idx?: Maybe<Scalars['Int']>;
  /** user name */
  name: Scalars['String'];
  /** The count of message unread */
  notreadcount?: Maybe<Scalars['Int']>;
};

/** 출퇴근 지역 반경설정 ON/OFF 타입 */
export enum CommuteAreaUseType {
  Off = 'OFF',
  On = 'ON'
}

/** 출퇴근관리 데이터 타입 */
export type CommuteManagement = {
  __typename?: 'CommuteManagement';
  /** 출퇴근 인정여부 */
  approvalFlag: ApprovalFlag;
  /** 출퇴근 날짜 및 시간 */
  checkDateTime?: Maybe<Scalars['String']>;
  /** 수정된 출퇴근 날짜 및 시간 */
  checkDateTimeModify?: Maybe<Scalars['String']>;
  /** 출퇴근 주소 */
  commuteAddress?: Maybe<Scalars['String']>;
  /** 출퇴근타입 */
  commuteType: CommuteType;
  /** 날짜 */
  date?: Maybe<Scalars['String']>;
  /** 출퇴근 기기 */
  deviceType: Scalars['String'];
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 위도 */
  gpsLat?: Maybe<Scalars['String']>;
  /** 경도 */
  gpsLong?: Maybe<Scalars['String']>;
  /** 사용자 이름 */
  name: Scalars['String'];
  /** 시간 */
  time?: Maybe<Scalars['String']>;
};

/** 근무제 기본정보 Entity */
export type CommuteRecordInfoEntity = {
  __typename?: 'CommuteRecordInfoEntity';
  /** 출/퇴근 기록장치(모바일) */
  appUse?: Maybe<Is_Use>;
  /** 연장근무시간 연결처리 여부 */
  approvalDataType?: Maybe<Is_Use>;
  /** 출근체크시 출근계획시간변경[미체크(0)/체크(1)] */
  checkInStartWorkTimeSetFlag: Is_Use;
  /** 퇴근체크시 퇴근계획시간변경[미체크(0)/체크(1)] */
  checkoutEndWorkTimeSetFlag: Is_Use;
  /** 근무제 idx */
  commuteTemplateIdx?: Maybe<Scalars['Int']>;
  /** 요일 선택 */
  dayOfWeek?: Maybe<Array<Day_Type>>;
  /** 퇴근 가능 시간(분) */
  endWorkingMin?: Maybe<Scalars['Int']>;
  /** 출/퇴근 기록장치(pc) */
  pcUse?: Maybe<Is_Use>;
  /** 출근 가능 시간(분) */
  startWorkingMin?: Maybe<Scalars['Int']>;
  /** 퇴근 미 체크 시 자동 체크[미사용(0)/퇴근계획시간(1)/설정시간(2)] */
  unCheckoutAutoCheckoutFlag: Is_Check_Auto_Time;
  /** 퇴근 미 체크 시 자동 체크(시간) */
  unCheckoutAutoCheckoutTime: Scalars['String'];
};

/** 출퇴근 enum 타입 */
export enum CommuteType {
  Attendance = 'ATTENDANCE',
  Leave = 'LEAVE'
}

/** 회사 정보 entity */
export type CompanyEntity = {
  __typename?: 'CompanyEntity';
  /** 회사 코드 */
  companyCode: Scalars['String'];
  /** 회사 이름 */
  companyName: Scalars['String'];
};

/** 결재 비밀번호 일치 확인 요청 결과 */
export type ConfirmIsApprovalPasswordOutput = {
  __typename?: 'ConfirmIsApprovalPasswordOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 제어 알림 Entity */
export type ControlAlertEntity = {
  __typename?: 'ControlAlertEntity';
  /** 퇴근시간 이후[제어(알림)] */
  afterEndWorkingCtrlMsg?: Maybe<Scalars['Int']>;
  /** 퇴근시간[제어(알림)] */
  beforeEndWorkingCtrlMsg?: Maybe<Scalars['Int']>;
  /** 퇴근시간전(분) */
  beforeEndWorkingMin?: Maybe<Scalars['Int']>;
  /** 출근시간[제어(알림)] */
  beforeStartWorkingCtrlMsg?: Maybe<Scalars['Int']>;
  /** 출근시간전(분) */
  beforeStartWorkingMin: Scalars['Int'];
  /** 휴게시간 제어(알림) */
  breakTimeCtrlMsg?: Maybe<Scalars['Int']>;
  /** 근무시간초과 3분전 [제어(알림)] */
  exceedCtrlMsgAfter?: Maybe<Scalars['Int']>;
  /** 근무시간초과1분전 */
  exceedCtrlMsgBeforeMinOne?: Maybe<Scalars['Int']>;
  /** 근무시간초과 2분전 */
  exceedCtrlMsgBeforeMinTwo?: Maybe<Scalars['Int']>;
  /** 근무시간초과 1분전 [제어(알림)] */
  exceedCtrlMsgBeforeOne?: Maybe<Scalars['Int']>;
  /** 근무시간초과 2분전 [제어(알림)] */
  exceedCtrlMsgBeforeTwo?: Maybe<Scalars['Int']>;
  /** 근무제 idx */
  workingTemplateIdx: Scalars['Int'];
};

/** 결재 양식 추가 요청 결과 */
export type ControlApprovalFormOutput = {
  __typename?: 'ControlApprovalFormOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 양식 템플릿 idx */
  formTemplateIdx?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 모든 결재 목록 카운트 요청 결과 */
export type CountAllListOfApprovalOutput = {
  __typename?: 'CountAllListOfApprovalOutput';
  /** 결재 완료 목록 카운트 */
  completedCount?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  /** 나의 결재 목록 카운트 */
  myCount?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
  /** 결재 수신 목록 카운트 */
  receiveCount?: Maybe<Scalars['Int']>;
  /** 결재 참조 목록 카운트 */
  referenceCount?: Maybe<Scalars['Int']>;
  /** 결재 대기 목록 카운트 */
  waitingCount?: Maybe<Scalars['Int']>;
};

/** 적책적용된 부서 목록 요청 결과 */
export type CountListOfApprovalNotificationDepartmentOutput = {
  __typename?: 'CountListOfApprovalNotificationDepartmentOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 정책적용된 부서 카운트한 정보 */
  total?: Maybe<Scalars['Float']>;
};

/** 결재 목록 카운트 요청 결과 */
export type CountListOfApprovalOutput = {
  __typename?: 'CountListOfApprovalOutput';
  /** 결재 목록 카운트 */
  count?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 임시 저장 목록 카운트 요청 결과 */
export type CountListOfApprovalTemporaryStorageOutput = {
  __typename?: 'CountListOfApprovalTemporaryStorageOutput';
  /** 결재 임시 저장 목록 카운트 */
  count?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 채팅방 목록 카운트값 요청 결과 */
export type CountListOfChatRoomOutput = {
  __typename?: 'CountListOfChatRoomOutput';
  /** 카운트 채팅방 목록 */
  count?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 목록 카운트 정보 요청 결과 */
export type CountListOfCommuteGpsInfoOutput = {
  __typename?: 'CountListOfCommuteGpsInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 사용자 출퇴근 지역 목록 카운트 값 */
  total?: Maybe<Scalars['Int']>;
};

/** 정책적용직원 카운트 요청 결과 */
export type CountListOfEmployeeAppliedToTheTemplateOutput = {
  __typename?: 'CountListOfEmployeeAppliedToTheTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  total?: Maybe<Scalars['Float']>;
};

/** 출퇴근 관리정보 카운트 요청 결과 */
export type CountListOfEmployeeCommuteManagementOutput = {
  __typename?: 'CountListOfEmployeeCommuteManagementOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 출퇴근 관리정보 카운트 데이터 */
  total?: Maybe<Scalars['Float']>;
};

/** 직원 정보 목록 카운트 요청 결과 */
export type CountListOfEmployeeInformationOutput = {
  __typename?: 'CountListOfEmployeeInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 직원 정보 목록 카운트한 값 */
  total?: Maybe<Scalars['Float']>;
};

/** PC 정보 목록 카운트 요청 결과 */
export type CountListOfPcInformationOutput = {
  __typename?: 'CountListOfPcInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** PC 정보 목록 카운트한 값 */
  total?: Maybe<Scalars['Float']>;
};

/** 이석관리 정보 목록 카운트 요청 결과 */
export type CountListOfPlaceFreeManagementOutput = {
  __typename?: 'CountListOfPlaceFreeManagementOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 이석관리 정보 총 개수 */
  total?: Maybe<Scalars['Float']>;
};

/** 결재관리 처리 및 조회 목록 카운트 요청 결과 */
export type CountListOfProcessingInquiryOutput = {
  __typename?: 'CountListOfProcessingInquiryOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 처리 및 조회 데이터 카운트 정보 */
  total?: Maybe<Scalars['Float']>;
};

/** 결재관리 신청 및 조회 목록 카운트 요청 결과 */
export type CountListOfRequestInquiryOutput = {
  __typename?: 'CountListOfRequestInquiryOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 처리 및 조회 데이터 목록 카운트 정보 */
  total?: Maybe<Scalars['Float']>;
};

/** 근무관리 정보 목록 카운트 요청 결과 */
export type CountListOfWorkManagementOutput = {
  __typename?: 'CountListOfWorkManagementOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 근무관리정보 목록 카운트 결과 */
  total?: Maybe<Scalars['Float']>;
};

/** 통계 정보 카운트 요청 결과 */
export type CountStatisticsInfoOutput = {
  __typename?: 'CountStatisticsInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 통계 자료 카운트 정보 */
  total?: Maybe<Scalars['Float']>;
};

/** 직원 월별 근무 계획 조회(총 수량) 결과 */
export type CountUserMonthlyWorkPlanOutput = {
  __typename?: 'CountUserMonthlyWorkPlanOutput';
  /** 직원 월별 근무 계획 총수량 */
  count?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 제어(알림)템플릿 Entity */
export type CtrlMsgTemplateEntity = {
  __typename?: 'CtrlMsgTemplateEntity';
  /** 제어(알림)템플릿 idx */
  ctrlMsgTemplateIdx: Scalars['Float'];
  /** 제어(알림)템플릿 이름 */
  name: Scalars['String'];
};

/** 근무요일 설정 타입 */
export enum Day_Of_Week_Type {
  All = 'ALL',
  InputMethod = 'INPUT_METHOD',
  MonToFri = 'MON_TO_FRI',
  MonToSat = 'MON_TO_SAT'
}

/** 요일 타입 */
export enum Day_Type {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thr = 'THR',
  Tue = 'TUE',
  Wed = 'WED'
}

/** 출퇴근 장치 타입 */
export enum Device_Type {
  App = 'APP',
  Desktop = 'DESKTOP'
}

/** Dashboard 정보 */
export type DashBoard = {
  __typename?: 'DashBoard';
  /** 기간 */
  dataPerio: Scalars['String'];
  /** 부서명 */
  departName: Scalars['String'];
  /** 사용자 ID */
  empId: Scalars['String'];
  /** 사용자 이름 */
  empName: Scalars['String'];
  /** 연장근무 */
  nameOne?: Maybe<Scalars['String']>;
  /** 기타 */
  nameThree?: Maybe<Scalars['String']>;
  /** 휴가 */
  nameTwo?: Maybe<Scalars['String']>;
  /** 연장 시간 */
  otExtTime?: Maybe<Scalars['String']>;
  /** 연장 시간 구간 */
  otExtTimeSection: Scalars['String'];
  /** 근로 시간 */
  otTotTime?: Maybe<Scalars['String']>;
  /** 근로 시간 구간 */
  otTotTimeSection: Scalars['String'];
  /** 정규 시간 */
  otWorkTime?: Maybe<Scalars['String']>;
  /** 정규 시간 구간 */
  otWorkTimeSection: Scalars['String'];
  /** 정규근무 */
  workOx: Scalars['String'];
};

/** Dashboard 엑셀 결과 */
export type DashBoardExcelExcelOutput = {
  __typename?: 'DashBoardExcelExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무 요일 그래프 Entity */
export type DayGraphEntity = {
  __typename?: 'DayGraphEntity';
  /** 휴게 종료 시간 */
  endBreakTime: Scalars['String'];
  /** 근무 종료 시간 */
  endTime?: Maybe<Scalars['String']>;
  /** 휴게 시작 시간 */
  startBreakTime: Scalars['String'];
  /** 근무 시작 시간 */
  startTime?: Maybe<Scalars['String']>;
};

/** 알람 삭제 요청 결과 */
export type DeleteAlarmEventOutput = {
  __typename?: 'DeleteAlarmEventOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재선 삭제 요청 결과 */
export type DeleteApprovalLineOutput = {
  __typename?: 'DeleteApprovalLineOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 템플릿 삭제 요청 결과 */
export type DeleteApprovalNotificationTemplateOutput = {
  __typename?: 'DeleteApprovalNotificationTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 회수를 위한 요청 결과 */
export type DeleteApprovalRequestOutput = {
  __typename?: 'DeleteApprovalRequestOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 서명 이미지 또는 텍스트 삭제 요청 결과 */
export type DeleteApprovalSignImageOrTextOutput = {
  __typename?: 'DeleteApprovalSignImageOrTextOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 템플릿 삭제 요청 결과 */
export type DeleteApprovalTemplateFormOutput = {
  __typename?: 'DeleteApprovalTemplateFormOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 임시저장 데이터 삭제 요청 결과 */
export type DeleteApprovalTemporaryStorageOutput = {
  __typename?: 'DeleteApprovalTemporaryStorageOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 정보 삭제 요청 결과 */
export type DeleteCommuteGpsInfoOutput = {
  __typename?: 'DeleteCommuteGpsInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 정책 적용된 직원 삭제 요청 결과 */
export type DeleteDepartmentAppliedToTheTemplateOutput = {
  __typename?: 'DeleteDepartmentAppliedToTheTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 정책에 적용된 직원 삭제 요청 결과 */
export type DeleteEmployeeAppliedOutput = {
  __typename?: 'DeleteEmployeeAppliedOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 계획 삭제 요청 결과 */
export type DeleteListOfApprovalNotificationScheduleByTemplateOutput = {
  __typename?: 'DeleteListOfApprovalNotificationScheduleByTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 메인 토큰 정보 삭제 요청 결과 */
export type DeleteMainTokenInfoOutput = {
  __typename?: 'DeleteMainTokenInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약 목록을 삭제하는 요청 결과 */
export type DeleteReservationCategoryOutput = {
  __typename?: 'DeleteReservationCategoryOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약정보 삭제하는 요청 */
export type DeleteReservationOutput = {
  __typename?: 'DeleteReservationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약종류 삭제 요청 결과 */
export type DeleteReservationTypeOutput = {
  __typename?: 'DeleteReservationTypeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무제[근무시간] ( 선택근무시간제  > 근무시간 > 근무시간 템플릿) - 삭제 요청 */
export type DeleteSelfDeterminedWorkHourTemplateOutput = {
  __typename?: 'DeleteSelfDeterminedWorkHourTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 통계 정보 카운트 삭제 결과 */
export type DeleteStatisticsInfoOutput = {
  __typename?: 'DeleteStatisticsInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 서브 토큰 정보 삭제 요청 결과 */
export type DeleteSubTokenInfoOutput = {
  __typename?: 'DeleteSubTokenInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 템플릿 근무 계획 삭제 요청 결과 */
export type DeleteTemplateScheduleInfoOutput = {
  __typename?: 'DeleteTemplateScheduleInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무 템플릿 삭제 요청 결과 */
export type DeleteTimeTemplateOutput = {
  __typename?: 'DeleteTimeTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 직원 일별 근무 정보 삭제 */
export type DeleteUserDailyWorkInfoOutput = {
  __typename?: 'DeleteUserDailyWorkInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 운행정보 삭제하기 위한 요청 결과 */
export type DeleteVehicleDrivingInformationOutput = {
  __typename?: 'DeleteVehicleDrivingInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type DeleteVehicleInformationOutput = {
  __typename?: 'DeleteVehicleInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무제[기본정보] 삭제 요청 결과 */
export type DeleteWtBasicInfoOutput = {
  __typename?: 'DeleteWTBasicInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 변경된 연차 정보 엑셀 다운로드 요청 결과 */
export type DownloadAnnualHistoryAsExcelOutput = {
  __typename?: 'DownloadAnnualHistoryAsExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 변경된 연차 정보 엑셀 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약상세정보 엑셀로 다운로드 요청 */
export type DownloadExcelListOfReservationDetailInformationOutput = {
  __typename?: 'DownloadExcelListOfReservationDetailInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 예약상세정보 엑셀정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약종류 목록 엑셀로 다운로드 요청 결과 */
export type DownloadExcelListOfReservationInformationOutput = {
  __typename?: 'DownloadExcelListOfReservationInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 예약종류 목록 엑셀 정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 정책적용부서 목록 엑셀 요청 결과 */
export type DownloadListOfApprovalNotificationDepartmentExcelOutput = {
  __typename?: 'DownloadListOfApprovalNotificationDepartmentExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 정책적용직원 엑셀 다운로드 요청 결과 */
export type DownloadListOfEmployeeAppliedToTheTemplateExcelOutput = {
  __typename?: 'DownloadListOfEmployeeAppliedToTheTemplateExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 출퇴근 관리정보 엑셀 다운로드 요청 결과 */
export type DownloadListOfEmployeeCommuteManagementExcelOutput = {
  __typename?: 'DownloadListOfEmployeeCommuteManagementExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 출퇴근 관리정보 엑셀 데이터 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 직원정보목록 엑셀 요청 결과 */
export type DownloadListOfEmployeeInformationExcelOutput = {
  __typename?: 'DownloadListOfEmployeeInformationExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** PC정보목록 엑셀 요청 결과 */
export type DownloadListOfPcInformationExcelOutput = {
  __typename?: 'DownloadListOfPcInformationExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 이석 관리 정보 엑셀로 다운로드 하는 요청 결과 */
export type DownloadListOfPlaceFreeManagementExcelOutput = {
  __typename?: 'DownloadListOfPlaceFreeManagementExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 이석관리 엑셀 정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재관리 처리 및 조회 목록 엑셀 다운로드 요청 결과 */
export type DownloadListOfProcessingInquiryToExcelOutput = {
  __typename?: 'DownloadListOfProcessingInquiryToExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 처리 및 조회 데이터 엑셀 정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재관리 신청 및 조회 목록 엑셀 다운로드 요청 결과 */
export type DownloadListOfRequestInquiryToExcelOutput = {
  __typename?: 'DownloadListOfRequestInquiryToExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 처리 및 조회 데이터 목록 엑셀 정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무관리정보 엑셀 다운로드 요청 결과 */
export type DownloadListOfWorkManagementExcelOutput = {
  __typename?: 'DownloadListOfWorkManagementExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 통계 정보 엑셀 요청 결과 */
export type DownloadStatisticsToExcelOutput = {
  __typename?: 'DownloadStatisticsToExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 통계 자료 엑셀 정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 차량 주행 정보 목록 엑셀 요청 결과 */
export type DownloadVehicleDrivingInformationExcelOutput = {
  __typename?: 'DownloadVehicleDrivingInformationExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 차량 정보 목록 엑셀 요청 결과 */
export type DownloadVehicleInformationExcelOutput = {
  __typename?: 'DownloadVehicleInformationExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 연차현황 정보 추가, 수정 그리고 삭제 요청 */
export type EditAnnualStatusInput = {
  /** 총 발생일 */
  annualDay?: InputMaybe<Scalars['Float']>;
  /** 비고 */
  descr?: InputMaybe<Scalars['String']>;
  /** 사용자 아이디 */
  employeeIdList: Array<Scalars['String']>;
  /** 귀속년도 */
  searchYear: Scalars['String'];
};

/** 연차현황 정보 추가, 수정 그리고 삭제 요청 결과 */
export type EditAnnualStatusOutput = {
  __typename?: 'EditAnnualStatusOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 대시보드 결재목록 설정 수정 요청 결과 */
export type EditApprovalAdminSettingOutput = {
  __typename?: 'EditApprovalAdminSettingOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재선 수정 요청 결과 */
export type EditApprovalLineOutput = {
  __typename?: 'EditApprovalLineOutput';
  error?: Maybe<Scalars['String']>;
  /** 추가된 결재선 idx */
  lineIdx?: Maybe<Scalars['Int']>;
  ok: Scalars['Boolean'];
};

/** 직원 일별 근무 정보 수정(확인유무) 요청 결과 */
export type EditUserDailyCheckInfoOutput = {
  __typename?: 'EditUserDailyCheckInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 직원 일별 근무 정보 수정(근무시간) 결과 */
export type EditUserDailyWorkInfoOutput = {
  __typename?: 'EditUserDailyWorkInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type EditUserMonthlyWorkInfo = {
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 0:미마감/1:마감 */
  isClosingStatus: Is_Active;
  /** 0:미전송/1:전송 */
  isSendStatus: Is_Active;
  /** 수정 하는 월 */
  monthDate: Scalars['String'];
};

/** 직원 월별 근무 정보 수정 결과 */
export type EditUserMonthlyWorkInfoOutput = {
  __typename?: 'EditUserMonthlyWorkInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type EmployeeAppliedToTheTemplate = {
  __typename?: 'EmployeeAppliedToTheTemplate';
  /** 알람 이름 */
  alarmName: Scalars['String'];
  /** 부서 이름 */
  departName: Scalars['String'];
  /** 직원 ID */
  employeeId: Scalars['String'];
  /** 정책 에외 */
  isExpect: IsException;
  /** 직원 이름 */
  name: Scalars['String'];
  /** 템플릿 Idx */
  templateIdx: Scalars['Float'];
};

/** 직원 정보 */
export type EmployeeInformation = {
  __typename?: 'EmployeeInformation';
  /** 승인 정의 */
  approvalDefine: Scalars['Float'];
  /** 근무시간계획정책 */
  asTimeTemplateName: Scalars['String'];
  /** col1 */
  col1: Scalars['String'];
  /** col2 */
  col2: Scalars['String'];
  /** col3 */
  col3: Scalars['String'];
  /** col4 */
  col4: Scalars['String'];
  /** col5 */
  col5: Scalars['String'];
  /** col6 */
  col6: Scalars['String'];
  /** col7 */
  col7: Scalars['String'];
  /** col8 */
  col8: Scalars['String'];
  /** col9 */
  col9: Scalars['String'];
  /** col10 */
  col10: Scalars['String'];
  /** col11 */
  col11: Scalars['String'];
  /** col12 */
  col12: Scalars['String'];
  /** col13 */
  col13: Scalars['String'];
  /** col14 */
  col14: Scalars['String'];
  /** col15 */
  col15: Scalars['String'];
  /** 부서명 */
  departName: Scalars['String'];
  /** 부서 아이디 */
  departmentId: Scalars['String'];
  /** 직원 아이디 */
  employeeId: Scalars['String'];
  /** Endpoint 수량 */
  endpointCount: Scalars['Float'];
  /** 전체 부서명 */
  fullDepartmentName: Scalars['String'];
  /** 입력구분(0: 수동, 1:자동) */
  insertType: Scalars['Float'];
  /** 직원 이름 */
  name: Scalars['String'];
  /** 금일 근무 시간 */
  todayWorkingTime?: Maybe<Scalars['String']>;
  /** 직급 */
  userPosition?: Maybe<Scalars['String']>;
  /** 총 연장근무 가능 시간 */
  weekMaxExtWorkHour?: Maybe<Scalars['String']>;
  /** 총 근무 가능시간 */
  weekMaxWorkHour?: Maybe<Scalars['String']>;
  /** 근무정책명 */
  workingName: Scalars['String'];
  /** 근무정책 Idx */
  workingTemplateIdx?: Maybe<Scalars['Float']>;
  /** 총 연장근무한 시간 */
  wtAsextto?: Maybe<Scalars['String']>;
  /** 총 근무한 시간 */
  wtTot?: Maybe<Scalars['String']>;
};

export type EtcOperatingHourEntity = {
  __typename?: 'EtcOperatingHourEntity';
  /** 제어(알림) */
  ctrlName: Scalars['String'];
  /** 요일/날짜 */
  dayDateOrWeek: Scalars['String'];
  /** 구분(요일/날짜) */
  daySetType: Scalars['String'];
  /** 종료시간 */
  endDateTime: Scalars['String'];
  /** 기타운영시간 식별번호 */
  holidayTemplateIdx: Scalars['Int'];
  /** 이름 */
  name: Scalars['String'];
  /** 요일/시작시간 */
  startDateTime: Scalars['String'];
  /** 적용추차(월) */
  weekSequence: Scalars['String'];
};

export type FieldSort = {
  name: Scalars['String'];
  sort: SortColumType;
};

/** 근무제(이석관리) 정보 요청 결과 */
export type GetAbsenceManagementInfoOutput = {
  __typename?: 'GetAbsenceManagementInfoOutput';
  error?: Maybe<Scalars['String']>;
  info?: Maybe<AbsenceManagementEntity>;
  ok: Scalars['Boolean'];
};

/** 총 발생일, 잔여일 그리고 사용일 정보 요청 결과 */
export type GetAdditionalFieldApprovalFormOutput = {
  __typename?: 'GetAdditionalFieldApprovalFormOutput';
  error?: Maybe<Scalars['String']>;
  /** 총 발생일, 잔여일 그리고 사용일 정보 */
  info?: Maybe<ApprovalAdditionalFieldEntity>;
  ok: Scalars['Boolean'];
};

/** 알람 상태 정보 요청 결과 */
export type GetAlarmEventStatusOutput = {
  __typename?: 'GetAlarmEventStatusOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 알람 상태값 */
  status?: Maybe<AlarmStatus>;
};

/** 연차 설정 정보 요청 결과 */
export type GetAnnualEnvInfoOutput = {
  __typename?: 'GetAnnualEnvInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 회사 연차 설정 정보 */
  info?: Maybe<AnnualEnvInfoEntity>;
  ok: Scalars['Boolean'];
};

/** 변경된 연차 정보 이력 요청 결과 */
export type GetAnnualHistoryOutput = {
  __typename?: 'GetAnnualHistoryOutput';
  error?: Maybe<Scalars['String']>;
  history?: Maybe<Array<AnnualHistoryEntity>>;
  ok: Scalars['Boolean'];
};

/** 연차현황 목록 카운트 정보 요청 결과 */
export type GetAnnualStatusListCountOutput = {
  __typename?: 'GetAnnualStatusListCountOutput';
  count?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 연차현황 목록 엑셀로 다운로드 요청 결과 */
export type GetAnnualStatusListExcelOutput = {
  __typename?: 'GetAnnualStatusListExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 연차현황 목록 엑셀 정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 연차현황 목록 정보 요청 결과 */
export type GetAnnualStatusListOutput = {
  __typename?: 'GetAnnualStatusListOutput';
  error?: Maybe<Scalars['String']>;
  list?: Maybe<Array<AnnualStatusEntity>>;
  ok: Scalars['Boolean'];
};

/** 대시보드 결재목록 설정값 요청 결과 */
export type GetApprovalAdminSettingOutput = {
  __typename?: 'GetApprovalAdminSettingOutput';
  /** 결재목록 개수 */
  dashboardListCount?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 미리보기 값 */
  preview?: Maybe<Approval_Admin_Setting_Preview_Type>;
};

/** 결재 양식 상세 데이터 요청 결과 */
export type GetApprovalFormDetailOutput = {
  __typename?: 'GetApprovalFormDetailOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 양식 상세 정보 */
  information?: Maybe<ApprovalDetailInformationEntity>;
  /** 결재자 목록 */
  listOfApprovalUser?: Maybe<Array<ListOfApprovalUser>>;
  /** 수신 부서 정보 */
  listOfReceiveDepartment?: Maybe<Array<ListOfReceiveAndReferenceDepartmentInformation>>;
  /** 수신자 정보 */
  listOfReceiveUser?: Maybe<Array<ListOfReceiveAndReferenceUserInformation>>;
  /** 참조 부서 정보 */
  listOfReferenceDepartment?: Maybe<Array<ListOfReceiveAndReferenceDepartmentInformation>>;
  /** 참조자 정보 */
  listOfReferenceUser?: Maybe<Array<ListOfReceiveAndReferenceUserInformation>>;
  ok: Scalars['Boolean'];
};

/** 결재 비밀번호 및 서명 설정 정보 요청 결과 */
export type GetApprovalPasswordAndSignSettingInfoOutput = {
  __typename?: 'GetApprovalPasswordAndSignSettingInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 비밀번호 및 서명 설정 정보 */
  info?: Maybe<ApprovalPasswordAndSignSettingInfoEntity>;
  ok: Scalars['Boolean'];
};

/** 결재현황 목록 카운드 정보 요청 결과 */
export type GetApprovalStatusListCountOutput = {
  __typename?: 'GetApprovalStatusListCountOutput';
  count?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재현황 목록 엑셀 정보 요청 결과 */
export type GetApprovalStatusListExcelOutput = {
  __typename?: 'GetApprovalStatusListExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재현황 목록 엑셀 정보 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재현황 목록 정보 요청 결과 */
export type GetApprovalStatusListOutput = {
  __typename?: 'GetApprovalStatusListOutput';
  error?: Maybe<Scalars['String']>;
  list?: Maybe<Array<ApprovalStatusEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재 임시 저장 상세 정보 요청 결과 */
export type GetApprovalTemporaryStorageDetailInformationOutput = {
  __typename?: 'GetApprovalTemporaryStorageDetailInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 양식 상세 정보 */
  information?: Maybe<ApprovalDetailInformationEntity>;
  /** 결재자 목록 */
  listOfApprovalUser?: Maybe<Array<ListOfApprovalUser>>;
  /** 수신 부서 정보 */
  listOfReceiveDepartment?: Maybe<Array<ListOfReceiveAndReferenceDepartmentInformation>>;
  /** 수신자 정보 */
  listOfReceiveUser?: Maybe<Array<ListOfReceiveAndReferenceUserInformation>>;
  /** 참조 부서 정보 */
  listOfReferenceDepartment?: Maybe<Array<ListOfReceiveAndReferenceDepartmentInformation>>;
  /** 참조자 정보 */
  listOfReferenceUser?: Maybe<Array<ListOfReceiveAndReferenceUserInformation>>;
  ok: Scalars['Boolean'];
};

/** 근태상세 목록 엑셀로 다운로드 요청 결과 */
export type GetAttendanceDetailsListExcelOutput = {
  __typename?: 'GetAttendanceDetailsListExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 근태상세 목록 엑셀 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근태상세 목록 요청 결과 */
export type GetAttendanceDetailsListOutput = {
  __typename?: 'GetAttendanceDetailsListOutput';
  error?: Maybe<Scalars['String']>;
  /** 근태상세 목록 결과 */
  list?: Maybe<Array<AttendanceDetailsEntity>>;
  ok: Scalars['Boolean'];
};

/** 근무제[근무시간] > (자율출근제 > 근무시간) 상세정보조회 요청 결과 */
export type GetAutonomyWorkingHourInfoOutput = {
  __typename?: 'GetAutonomyWorkingHourInfoOutput';
  basicInfo?: Maybe<OtWorkingFreeStartBasicEntity>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 정보 요청 결과 */
export type GetBasicUserInfoOutput = {
  __typename?: 'GetBasicUserInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  user?: Maybe<BasicUserInfoEntity>;
};

/** 채팅 서버 ip 요청 결과 */
export type GetChatServerIpOutput = {
  __typename?: 'GetChatServerIpOutput';
  /** 채팅 서버 IP */
  chatServerIp?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 반경설정 on/off 값 요청 결과 */
export type GetCommuteGpsAreaUseTypeOutput = {
  __typename?: 'GetCommuteGpsAreaUseTypeOutput';
  /** 사용자 출퇴근 지역 정보 추가 ID */
  commuteAreaUseType?: Maybe<CommuteAreaUseType>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무제[출퇴근기록] 상세정보조회 정보 요청 결과 */
export type GetCommuteRecordInfoOutput = {
  __typename?: 'GetCommuteRecordInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 근무제[출퇴근기록] */
  info?: Maybe<CommuteRecordInfoEntity>;
  ok: Scalars['Boolean'];
};

/** 회사 정보 요청 */
export type GetCompanyListOutput = {
  __typename?: 'GetCompanyListOutput';
  error?: Maybe<Scalars['String']>;
  /** 회사 정보 목록 */
  list?: Maybe<Array<CompanyEntity>>;
  ok: Scalars['Boolean'];
};

/** 근무제[제어(알림)] 정보 요청 결과 */
export type GetControlAlertOutput = {
  __typename?: 'GetControlAlertOutput';
  error?: Maybe<Scalars['String']>;
  info?: Maybe<ControlAlertEntity>;
  ok: Scalars['Boolean'];
};

/** 제어(알림)템플릿 조회 결과 */
export type GetControlNotificationCategoryOutput = {
  __typename?: 'GetControlNotificationCategoryOutput';
  error?: Maybe<Scalars['String']>;
  /** 제어(알림)템플릿 목록 */
  list?: Maybe<Array<CtrlMsgTemplateEntity>>;
  ok: Scalars['Boolean'];
};

/** 통계의 근태현황 총 수량 요청 결과 */
export type GetCountStatisticsConditionOutput = {
  __typename?: 'GetCountStatisticsConditionOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 통계의 근태현황 카운트 정보 */
  total?: Maybe<Scalars['Float']>;
};

/** DashBoard 데이터 목록 요청 결과 */
export type GetDashBoardOutput = {
  __typename?: 'GetDashBoardOutput';
  error?: Maybe<Scalars['String']>;
  /** Dashboard 데이터 목록 */
  listOfDashBoard?: Maybe<Array<DashBoard>>;
  ok: Scalars['Boolean'];
};

/** DashBoard 총 데이터 수 요청 결과 */
export type GetDashBoardTotalOutput = {
  __typename?: 'GetDashBoardTotalOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** DashBoard 총 데이터 수 */
  total?: Maybe<Scalars['Float']>;
};

/** 요일 그래프 정보 요청 */
export type GetDayGraphOutput = {
  __typename?: 'GetDayGraphOutput';
  /** 요일 그래프 정보 */
  dayGraph?: Maybe<DayGraphEntity>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 직원 정보 요청 결과 */
export type GetEmployeeInformationOutput = {
  __typename?: 'GetEmployeeInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 직원 정보 목록 */
  listOfEmployee?: Maybe<Array<EmployeeInformation>>;
  ok: Scalars['Boolean'];
};

/** 기타운영시간 리스트 조회 요청 결과 */
export type GetEtcOperatingHourListOutput = {
  __typename?: 'GetEtcOperatingHourListOutput';
  error?: Maybe<Scalars['String']>;
  /** 목록 */
  list?: Maybe<Array<EtcOperatingHourEntity>>;
  ok: Scalars['Boolean'];
};

export type GetEtcOperatingHoursEntity = {
  __typename?: 'GetEtcOperatingHoursEntity';
  /** 근무제 idx */
  holidayTemplateIdx: Scalars['Int'];
  /** 근무제 idx */
  workingTemplateIdx: Scalars['Int'];
};

/** 근무제[기타운영시간] - 상세정보조회 요청 결과 */
export type GetEtcOperatingHoursOutput = {
  __typename?: 'GetEtcOperatingHoursOutput';
  error?: Maybe<Scalars['String']>;
  info?: Maybe<Array<GetEtcOperatingHoursEntity>>;
  ok: Scalars['Boolean'];
};

/** 근무제[근무시간] > (탄력근무시간제 > 근무시간) 상세정보조회 요청 결과 */
export type GetFlexibleWorkingHourInfoOutput = {
  __typename?: 'GetFlexibleWorkingHourInfoOutput';
  basicInfo?: Maybe<OtWorkingFlexibleBasicEntity>;
  error?: Maybe<Scalars['String']>;
  extraList?: Maybe<Array<OtWorkingFlexibleHoursEntity>>;
  ok: Scalars['Boolean'];
};

/** 그룹 정보 요청 결과 */
export type GetGroupOutput = {
  __typename?: 'GetGroupOutput';
  error?: Maybe<Scalars['String']>;
  listOfGroup?: Maybe<Array<GroupEntity>>;
  ok: Scalars['Boolean'];
};

/** 만 1년 미만(입사년도) 정보 요청 결과 */
export type GetInfoLessThanAYearOutput = {
  __typename?: 'GetInfoLessThanAYearOutput';
  error?: Maybe<Scalars['String']>;
  /** 만 1년 미만(입사년도) 정보 */
  info?: Maybe<InfoLessThanAYearEntity>;
  ok: Scalars['Boolean'];
};

/** 만 1년 이상 설정 정보 요청 결과 */
export type GetInfoMoreThanAYearOutput = {
  __typename?: 'GetInfoMoreThanAYearOutput';
  error?: Maybe<Scalars['String']>;
  /** 만 1년 이상 설정 정보 */
  info?: Maybe<InfoMoreThanAYearEntity>;
  ok: Scalars['Boolean'];
};

/** 만 1년미만(입사차기년도) 정보 요청 결과 */
export type GetInfoNextYearOfEmploymentOutput = {
  __typename?: 'GetInfoNextYearOfEmploymentOutput';
  error?: Maybe<Scalars['String']>;
  /** 만 1년미만(입사차기년도) 정보 */
  info?: Maybe<InfoNextYearOfEmploymentEntity>;
  ok: Scalars['Boolean'];
};

/** 전체 근무제 목록 요청 결과 */
export type GetListAllWorkingTemplateOutput = {
  __typename?: 'GetListAllWorkingTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 근무제 템플릿 목록 */
  list?: Maybe<Array<WorkingTemplateEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재 양식 목록 요청 결과 */
export type GetListOtApprovalFormOutput = {
  __typename?: 'GetListOTApprovalFormOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 양식 목록 */
  list?: Maybe<Array<OtApprovalFormEntity>>;
  ok: Scalars['Boolean'];
};

/** 알람 목록 요청 결과 */
export type GetListOfAlarmOutput = {
  __typename?: 'GetListOfAlarmOutput';
  error?: Maybe<Scalars['String']>;
  /** 알람 종류 */
  list?: Maybe<Array<AlarmSettingEntity>>;
  ok: Scalars['Boolean'];
};

/** 기본 결재선 사용자 목록 요청 결과 */
export type GetListOfApprovalBasicLineUserOutput = {
  __typename?: 'GetListOfApprovalBasicLineUserOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재선 사용자 목록 */
  list?: Maybe<Array<UserOfSearchedApprovalLineEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재선 양식 목록 정보 요청 결과 */
export type GetListOfApprovalLineFormOutput = {
  __typename?: 'GetListOfApprovalLineFormOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재선 양식 목록 */
  list?: Maybe<Array<ApprovalLineFormEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재선 목록 요청 결과 */
export type GetListOfApprovalLineOutput = {
  __typename?: 'GetListOfApprovalLineOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재선 목록 */
  list?: Maybe<Array<ApprovalLineEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 템플릿 목록 요청 결과 */
export type GetListOfApprovalNotificationScheduleByTemplateOutput = {
  __typename?: 'GetListOfApprovalNotificationScheduleByTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재정보 알림 계획 목록 */
  list?: Maybe<Array<ApprovalNotificationScheduleByTemplate>>;
  ok: Scalars['Boolean'];
};

/** DashBoard 데이터 목록 요청 결과 */
export type GetListOfApprovalNotificationTemplateOutput = {
  __typename?: 'GetListOfApprovalNotificationTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재정보 알림 템플릿 목록 */
  list?: Maybe<Array<ApprovalNotificationTemplate>>;
  ok: Scalars['Boolean'];
};

/** 결재 템플릿 목록 요청 */
export type GetListOfApprovalTemplateOutput = {
  __typename?: 'GetListOfApprovalTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 템플릿 목록 */
  list?: Maybe<Array<ApprovalTemplateEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재 임시저장 정보 목록 요청 결과 */
export type GetListOfApprovalTemporaryStorageOutput = {
  __typename?: 'GetListOfApprovalTemporaryStorageOutput';
  error?: Maybe<Scalars['String']>;
  list?: Maybe<Array<ListOfApprovalTemporaryStorageEntity>>;
  ok: Scalars['Boolean'];
};

/** 채팅방 이미지 목록 요청 결과 */
export type GetListOfChatImageOutput = {
  __typename?: 'GetListOfChatImageOutput';
  error?: Maybe<Scalars['String']>;
  /** 채팅방 이미지 목록 */
  listOfImage?: Maybe<Array<ListOfChatImageEntity>>;
  ok: Scalars['Boolean'];
};

/** 메시지 목록 요청 결과 */
export type GetListOfChatMessageOutput = {
  __typename?: 'GetListOfChatMessageOutput';
  error?: Maybe<Scalars['String']>;
  /** 메시지 목록 */
  list?: Maybe<Array<ChatMessageEntity>>;
  ok: Scalars['Boolean'];
};

/** 채팅방 목록 요청 결과 */
export type GetListOfChatRoomOutput = {
  __typename?: 'GetListOfChatRoomOutput';
  error?: Maybe<Scalars['String']>;
  /** 채팅방 목록 */
  list?: Maybe<Array<ListOfChatRoom>>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 목록 정보 요청 결과 */
export type GetListOfCommuteGpsInfoOutput = {
  __typename?: 'GetListOfCommuteGpsInfoOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 사용자 출퇴근 지역 목록 */
  list?: Maybe<Array<MapRegisterEntity>>;
  ok: Scalars['Boolean'];
};

/** 채팅을 위한 부서 목록 요청 결과 */
export type GetListOfDepartmentForChatOutput = {
  __typename?: 'GetListOfDepartmentForChatOutput';
  error?: Maybe<Scalars['String']>;
  /** 채팅을 위한 부서 목록 */
  list?: Maybe<Array<ListOfDepartmentForChatEntity>>;
  ok: Scalars['Boolean'];
};

/** 근무 시간 타입 카테고리 목록 요청 결과 */
export type GetListOfOtTImeTypeCategoryOutput = {
  __typename?: 'GetListOfOtTImeTypeCategoryOutput';
  error?: Maybe<Scalars['String']>;
  /** 근무 시간 타입 카테고리 목록 */
  list?: Maybe<Array<OtTimeTypeCategoryEntity>>;
  ok: Scalars['Boolean'];
};

/** 차량을 위한 근무 카테고리 목록 요청 결과 */
export type GetListOfOtTimeTypeCategoryForVehicleOutput = {
  __typename?: 'GetListOfOtTimeTypeCategoryForVehicleOutput';
  error?: Maybe<Scalars['String']>;
  /** 근무 시간 목록 */
  list?: Maybe<Array<OtTimeTypeCategory>>;
  ok: Scalars['Boolean'];
};

/** 결재관리 처리 및 조회 목록 요청 결과 */
export type GetListOfProcessingInquiryOutput = {
  __typename?: 'GetListOfProcessingInquiryOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 처리 및 조회 데이터 목록 */
  listOfProcessingInquiry?: Maybe<Array<ProcessingInquiry>>;
  ok: Scalars['Boolean'];
};

/** 결재관리 신청 및 조회 목록 요청 결과 */
export type GetListOfRequestInquiryOutput = {
  __typename?: 'GetListOfRequestInquiryOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 처리 및 조회 데이터 목록 */
  listOfRequestInquiry?: Maybe<Array<RequestInquiry>>;
  ok: Scalars['Boolean'];
};

/** 예약 목록 가져오는 요청 결과 */
export type GetListOfReservationCategoryOutput = {
  __typename?: 'GetListOfReservationCategoryOutput';
  error?: Maybe<Scalars['String']>;
  /** 예약 목록 */
  list?: Maybe<Array<ReservationCategoryEntity>>;
  ok: Scalars['Boolean'];
};

/** 예약상세정보 목록 요청 결과 */
export type GetListOfReservationDetailInformationOutput = {
  __typename?: 'GetListOfReservationDetailInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 예약상세정보 목록 */
  list?: Maybe<Array<ReservationDetailInformationEntity>>;
  ok: Scalars['Boolean'];
};

/** 예약정보 목록 요청 결과 */
export type GetListOfReservationInformationOutput = {
  __typename?: 'GetListOfReservationInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 예약정보 목록 */
  list?: Maybe<Array<ReservationInformationEntity>>;
  ok: Scalars['Boolean'];
};

/** 예약종류 목록 가져오는 요청 결과 */
export type GetListOfReservationTypeOutput = {
  __typename?: 'GetListOfReservationTypeOutput';
  error?: Maybe<Scalars['String']>;
  /** 예약종류 목록 */
  list?: Maybe<Array<ReservationTypeEntity>>;
  ok: Scalars['Boolean'];
};

/** 선택된 결재선 사용자 목록 요청 결과 */
export type GetListOfSearchedApprovalLineUserOutput = {
  __typename?: 'GetListOfSearchedApprovalLineUserOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재선 사용자 목록 */
  list?: Maybe<Array<UserOfSearchedApprovalLineEntity>>;
  ok: Scalars['Boolean'];
};

/** 템플릿 근무 계획 정보 목록 요청 결과 */
export type GetListOfTemplateScheduleInfoOutput = {
  __typename?: 'GetListOfTemplateScheduleInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 템플릿 근무 계획 정보 목록 */
  list?: Maybe<Array<TemplateTimeScheduleInfo>>;
  ok: Scalars['Boolean'];
};

/** 근무 계획 타입 카테고리 타입 목록 요청 결과 */
export type GetListOfTimeTypeCategoryOutput = {
  __typename?: 'GetListOfTimeTypeCategoryOutput';
  error?: Maybe<Scalars['String']>;
  /** 근무 계획 타입 카테고리 목록 */
  list?: Maybe<Array<TimeTypeCategory>>;
  ok: Scalars['Boolean'];
};

/** 차량 주행 정보 목록 요청 결과 */
export type GetListOfVehicleDrivingInformationOutput = {
  __typename?: 'GetListOfVehicleDrivingInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 차량 주행 정보 목록 */
  list?: Maybe<Array<VehicleDrivingInformationEntity>>;
  ok: Scalars['Boolean'];
};

/** 차량 정보 */
export type GetListOfVehicleInformationOutput = {
  __typename?: 'GetListOfVehicleInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 차량정보 */
  list?: Maybe<Array<VehicleInformationEntity>>;
  ok: Scalars['Boolean'];
};

/** 메인 토큰 정보 요청 결과 */
export type GetMainTokenInfoOutput = {
  __typename?: 'GetMainTokenInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 메인 토큰 정보 결과 */
  info?: Maybe<Array<MainTokenInfoEntity>>;
  ok: Scalars['Boolean'];
};

/** 직원 월별 근무 정보 앱 전송 결과 */
export type GetMobilWebViewInfoOutput = {
  __typename?: 'GetMobilWebViewInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 모바일 웹뷰 메뉴 값 */
  webViewInfo?: Maybe<MobileWebViewEntity>;
};

/** PC 정보 요청 결과 */
export type GetPcInformationOutput = {
  __typename?: 'GetPcInformationOutput';
  error?: Maybe<Scalars['String']>;
  /** 선택된 사용자의 PC 목록 정보 */
  listOfPcInformation?: Maybe<Array<PcInformation>>;
  ok: Scalars['Boolean'];
};

/** 개인 근무 템플릿 목록 요청 결과 */
export type GetPersonalTimeTemplateOutput = {
  __typename?: 'GetPersonalTimeTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 개인 근무 템플릿 목록 */
  list?: Maybe<Array<PersonalTimeTemplate>>;
  ok: Scalars['Boolean'];
};

/** 공용 근무 템플릿 목록 요청 결과 */
export type GetPublicTimeTemplateOutput = {
  __typename?: 'GetPublicTimeTemplateOutput';
  error?: Maybe<Scalars['String']>;
  /** 공용 근무 템플릿 목록 */
  list?: Maybe<Array<PublicTimeTemplate>>;
  ok: Scalars['Boolean'];
};

/** 금일 및 금주 인정시간 데이터 요청 결과 */
export type GetRecognitionWorkTimeOutput = {
  __typename?: 'GetRecognitionWorkTimeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 금주 인정시간 데이터 */
  thisWeekRecognitionWorkTime?: Maybe<RecognitionWorkTimeEntity>;
  /** 금일 인정시간 데이터 */
  todayRecognitionWorkTime?: Maybe<RecognitionWorkTimeEntity>;
};

/** 요일 그래프 정보(선택근무시간제) 요청 */
export type GetSelfDeterminedDayGraphOutput = {
  __typename?: 'GetSelfDeterminedDayGraphOutput';
  /** 요일 그래프 정보 */
  dayGraph?: Maybe<DayGraphEntity>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무제[근무시간] > (선택근무시간제 > 근무시간) 상세정보조회 전체 요청 결과 */
export type GetSelfDeterminedWorkInfoOutput = {
  __typename?: 'GetSelfDeterminedWorkInfoOutput';
  basicInfo?: Maybe<WtGraphBasicEntity>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  otWSHoursList?: Maybe<Array<OtSelfWorkingStandardHoursEntity>>;
  otWorkingStandardBasic?: Maybe<SelfDeterminedWorkHourBasicBasicEntity>;
};

/** 근무제[근무시간] > (선택근무시간제 > 근무시간) 템플릿 정보 조회 요청 결과 */
export type GetSelfDeterminedWorkTemplateHoursOutput = {
  __typename?: 'GetSelfDeterminedWorkTemplateHoursOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  template?: Maybe<OtSelfWorkingStandardHoursEntity>;
};

/** 근무제[근무시간] > (표준/시차 출퇴근제 > 근무시간) 상세정보조회 요청 결과 */
export type GetStandardWtBasicInfoOutput = {
  __typename?: 'GetStandardWTBasicInfoOutput';
  basicInfo?: Maybe<StandardBasicInfo>;
  error?: Maybe<Scalars['String']>;
  extraDaySettingList?: Maybe<Array<OtWorkingStandardHoursEntity>>;
  ok: Scalars['Boolean'];
};

/** 통계의 근태현황 목록 엑셀로 다운로드 요청 결과 */
export type GetStatisticsConditionExcelOutput = {
  __typename?: 'GetStatisticsConditionExcelOutput';
  error?: Maybe<Scalars['String']>;
  /** 통계의 근태현황 목록 엑셀 */
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 통계의 근태현황 목록 요청 결과 */
export type GetStatisticsConditionListOutput = {
  __typename?: 'GetStatisticsConditionListOutput';
  error?: Maybe<Scalars['String']>;
  /** 통계의 근태현황 목록 결과 */
  list?: Maybe<Array<StatisticsConditionEntity>>;
  ok: Scalars['Boolean'];
};

/** 서브 토큰 정보 요청 결과 */
export type GetSubTokenInfoOutput = {
  __typename?: 'GetSubTokenInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 서브 토큰 정보 */
  info?: Maybe<Array<SubTokenInfoEntity>>;
  ok: Scalars['Boolean'];
};

/** 예약 종류 목록 total 정보 요청 결과 */
export type GetToTalListOfReservationTypeOutput = {
  __typename?: 'GetToTalListOfReservationTypeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 예약 종류 목록 total */
  total?: Maybe<Scalars['Int']>;
};

/** 예약상세정보 목록 total정보 요청 결과 */
export type GetTotalListOfReservationDetailInformationOutput = {
  __typename?: 'GetTotalListOfReservationDetailInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 예약상세정보 total 정보 */
  total?: Maybe<Scalars['Int']>;
};

/** 차량 주행 목록 합계 정보 요청 결과 */
export type GetTotalNumberOfVehicleDrivingInformationOutput = {
  __typename?: 'GetTotalNumberOfVehicleDrivingInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 차량 주행 목록 합계 정보 */
  total?: Maybe<Scalars['Int']>;
};

/** 차량 합계 정보 요청 결과 */
export type GetTotalNumberOfVehicleInformationOutput = {
  __typename?: 'GetTotalNumberOfVehicleInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 차량 합계 정보 */
  total?: Maybe<Scalars['Float']>;
};

/** 사용자 연차 정보 요청 결과 */
export type GetUserAnnualInfoOutput = {
  __typename?: 'GetUserAnnualInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 사용자 연차 정보 */
  userAnnualInfo?: Maybe<UserAnnualInfoEntity>;
};

/** 직원 일별 근무 계획 조회(총 수량) 결과 */
export type GetUserDailyWorkPlanCountOutput = {
  __typename?: 'GetUserDailyWorkPlanCountOutput';
  /** 직원 일별 근무 계획 총수량 */
  count?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 엑셀 다운로드를 위한 직원 일별 근무 계획 조회 결과 */
export type GetUserDailyWorkPlanExcelOutput = {
  __typename?: 'GetUserDailyWorkPlanExcelOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 직원 일별 근무 계획 조회 결과 */
export type GetUserDailyWorkPlanOutput = {
  __typename?: 'GetUserDailyWorkPlanOutput';
  error?: Maybe<Scalars['String']>;
  /** 직원 일별 근무 계획 목록 */
  list?: Maybe<Array<UserDailyWorkPlanEntity>>;
  ok: Scalars['Boolean'];
};

/** 직원 월별 근무 계획 조회(총 수량) 결과 */
export type GetUserMonthlyWorkExcelPlanOutput = {
  __typename?: 'GetUserMonthlyWorkExcelPlanOutput';
  error?: Maybe<Scalars['String']>;
  excel?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 직원 월별 근무 계획 조회 결과 */
export type GetUserMonthlyWorkPlanOutput = {
  __typename?: 'GetUserMonthlyWorkPlanOutput';
  error?: Maybe<Scalars['String']>;
  /** 직원 일별 근무 계획 목록 */
  list?: Maybe<Array<UserMonthlyWorkPlanEntity>>;
  ok: Scalars['Boolean'];
};

/** 사용자 정보 요청 결과 */
export type GetUserOutput = {
  __typename?: 'GetUserOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  user?: Maybe<Array<UserEntity>>;
};

/** 사용자 기본 정책정보 요청 */
export type GetUserPolicyBasicInfoOutput = {
  __typename?: 'GetUserPolicyBasicInfoOutput';
  /** 사용자 기본 정책정보 */
  basicInfo?: Maybe<UserPolicyBasicInfoEntity>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무자 선택정보 요청 결과 */
export type GetUserPolicySelectedInfoOutput = {
  __typename?: 'GetUserPolicySelectedInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 근무자 선택정보 */
  info?: Maybe<UserPolicySelectedInfoEntity>;
  ok: Scalars['Boolean'];
};

/** 근무제[기본정보] 상세정보조회 정보 요청 결과 */
export type GetWtBasicInfoOutput = {
  __typename?: 'GetWTBasicInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 근무제[기본정보] */
  wTBasicInfo?: Maybe<WtBasicInfoEntity>;
};

/** 사용자의 근무제 idx얻기 수집된 as_working_templete_idx 요청 결과 */
export type GetWtfFromEmployeeOutput = {
  __typename?: 'GetWTFFromEmployeeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 근무제 idx */
  workingTemplateIdx?: Maybe<Scalars['String']>;
};

/** 기본, 주 그래프 정보(탄력근무시간제) 요청 결과 */
export type GetWtGraphOutput = {
  __typename?: 'GetWTGraphOutput';
  /** 근무시간제 그래프 기본정보 */
  basicInfo?: Maybe<WtGraphBasicEntity>;
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 근무시간제 그래프 기본정보 */
  weekDayOfWeekInfo?: Maybe<Array<WtGraphWeekEntity>>;
};

/** 근무제[위젯설정] - 위젯설정 정보 요청 */
export type GetWidgetSettingInfoOutput = {
  __typename?: 'GetWidgetSettingInfoOutput';
  error?: Maybe<Scalars['String']>;
  info?: Maybe<WidgetSettingInfoEntity>;
  ok: Scalars['Boolean'];
};

/** 근무시간 유형 정보 목록 요청 결과 */
export type GetWorkingTimeListOutput = {
  __typename?: 'GetWorkingTimeListOutput';
  error?: Maybe<Scalars['String']>;
  list?: Maybe<Array<WorkingTimeInfoEntity>>;
  ok: Scalars['Boolean'];
};

/** 그룹 정보 */
export type GroupEntity = {
  __typename?: 'GroupEntity';
  /** 전체 직원수 */
  countEmployee?: Maybe<Scalars['Float']>;
  /** 전체 하위 그룹수 */
  countEndpoint?: Maybe<Scalars['Float']>;
  /** 부서 ID */
  departmentId: Scalars['Int'];
  /** 그룹 종류 */
  deptSort: Scalars['Float'];
  /** 전체 부서명 */
  fullGroupName: Scalars['String'];
  /** Insert type */
  insertType: Scalars['Float'];
  /** 레벨 */
  level: Scalars['String'];
  /** 메모 1 */
  memo1?: Maybe<Scalars['String']>;
  /** 메모 1 */
  memo2?: Maybe<Scalars['String']>;
  /** 부서 ID */
  name: Scalars['String'];
  /** 부모 부서 ID */
  parentDepartmentId: Scalars['Int'];
  /** 근무 정책 */
  workingName?: Maybe<Scalars['String']>;
};

/** 결재현황 목록 검색 타입 */
export enum IApprovalStatusSearchType {
  CategoryName = 'categoryName',
  DeptName = 'deptName',
  EmpName = 'empName',
  EmployeeId = 'employeeId',
  EndDateTime = 'endDateTime',
  ProcessDateTime = 'processDateTime',
  ProcessDescr = 'processDescr',
  ProcessStatus = 'processStatus',
  RequestDateTime = 'requestDateTime',
  RequestDescr = 'requestDescr',
  StartDateTime = 'startDateTime'
}

export type IListOfApprovalLineUser = {
  /** 사용자 ID */
  employee_id: Scalars['String'];
  /** 결재 순서 */
  order: Scalars['Int'];
  /** 결재 타입 */
  type: Approval_User_Type;
};

export type IReceiveAndReferenceForDepartment = {
  /** 사용자 부서 */
  department_id: Scalars['Int'];
};

export type IReceiveAndReferenceForUser = {
  /** 사용자 ID */
  employee_id: Scalars['String'];
};

/** 0또는 1값을 통해 상태가 변하는 값 */
export enum Is_Active {
  Active = 'ACTIVE',
  UnActive = 'UN_ACTIVE'
}

/** 퇴근 미 체크 시 자동체크 타입 */
export enum Is_Check_Auto_Time {
  LeavingPlansTime = 'LEAVING_PLANS_TIME',
  SetTime = 'SET_TIME',
  UnUse = 'UN_USE'
}

/** 기본 결재선 미사용 및 사용 타입 */
export enum Is_Default_Approval_Line {
  UnUsed = 'UN_USED',
  Used = 'USED'
}

/** 1또는 2값을 통해 상태가 변하는 값 */
export enum Is_Show {
  Hide = 'HIDE',
  Show = 'SHOW'
}

/** 결재 알림 사용 타입 */
export enum Is_Sign_Alarm_Type {
  UnUsed = 'UN_USED',
  Used = 'USED'
}

/** 결재 비밀번호 사용 타입 */
export enum Is_Sign_Password {
  UnUsed = 'UN_USED',
  Used = 'USED'
}

/** 값의 참과 거짓 구분 값 */
export enum Is_Truthy_Falsy {
  Falsy = 'FALSY',
  Truthy = 'TRUTHY'
}

/** 항목별 사용 여부 타입 */
export enum Is_Use {
  UnUse = 'UN_USE',
  Use = 'USE'
}

/** 1년 미만 설정 정보 Entity */
export type InfoLessThanAYearEntity = {
  __typename?: 'InfoLessThanAYearEntity';
  /** 연차발생방식설정 */
  annualAccrualType: Annual_Accrual_Type_For_Less_Than_A_Year;
  /** 고정일부여일자 */
  fixCount: Scalars['Float'];
  /** 마이너스연차허용일 */
  minusAnnualDayCount: Scalars['Float'];
  /** 마이너스연차적용설정 */
  minusAnnualType: Minus_Annual_Type_For_Less_Than_A_Year;
  /** 월별발생기준시점 */
  monthAccrualType: Month_Accrual_Type;
};

/** 만 1년 이상 */
export type InfoMoreThanAYearEntity = {
  __typename?: 'InfoMoreThanAYearEntity';
  /** 가산일수설정(일추가부여) */
  addedDayCount: Scalars['Float'];
  /** 최대가산일수 */
  addedMaxDayCount: Scalars['Float'];
  /** 가산일수설정(년마다) */
  addedYearCount: Scalars['Float'];
  /** 이월연차적용범위 */
  carriedforwardRangeType: Carriedforward_Range_Type;
  /** 이월연차적용설정 */
  carriedforwardType: Carriedforward_Type;
  /** 기본일수설정 */
  defaultDayCount: Scalars['Float'];
  /** 마이너스연차허용일 */
  minusAnnualDayCount: Scalars['Float'];
  /** 마이너스 연차 적용 설정 */
  minusAnnualType: Minus_Annual_Type;
};

/** 만 1년미만(입사차기년도) 정보 */
export type InfoNextYearOfEmploymentEntity = {
  __typename?: 'InfoNextYearOfEmploymentEntity';
  /** 연차발생방식설정 */
  annualAccrualType: Annual_Accrual_Type_For_Next_Year_Of_Employment;
  /** 이월연차적용범위 */
  carriedforwardRangeType: Carriedforward_Range_Type;
  /** 이월연차적용설정 */
  carriedforwardType: Carriedforward_Type;
  /** 고정일부여일자 */
  fixCount: Scalars['Float'];
  /** 마이너스연차허용일 */
  minusAnnualDayCount: Scalars['Float'];
  /** 마이너스 연차 적용 설정 */
  minusAnnualType: Minus_Annual_Type;
  /** 월별발생기준시점 */
  monthAccrualType: Month_Accrual_Type_For_Next_Year_Of_Employment;
};

/** 결재 비밀번호 사용여부 확인 요청 결과 */
export type IsActiveApprovalPasswordOutput = {
  __typename?: 'IsActiveApprovalPasswordOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 정책 사용 여부 타입 */
export enum IsControl {
  Disable = 'DISABLE',
  Enable = 'ENABLE'
}

/** 정책 사용 여부 타입 */
export enum IsEnable {
  Disable = 'DISABLE',
  Enable = 'ENABLE'
}

/** 정책에외 포함 및 에외 타입 */
export enum IsException {
  Exception = 'EXCEPTION',
  Including = 'INCLUDING'
}

/** On Off 타입 */
export enum IsOnOff {
  Off = 'OFF',
  On = 'ON'
}

/** 알림 사용 여부 */
export enum IsUsingNotification {
  False = 'FALSE',
  True = 'TRUE'
}

/** 방에서 나가기위한 요청 */
export type LeavingTheRoomOutput = {
  __typename?: 'LeavingTheRoomOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 적책적용된 부서 목록 요청 결과 */
export type ListOfApprovalNotificationDepartmentOutput = {
  __typename?: 'ListOfApprovalNotificationDepartmentOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 정책적용된 부서 목록 */
  list?: Maybe<Array<ApprovalNotificationDepartment>>;
  ok: Scalars['Boolean'];
};

/** 결재 목록 데이터 요청 결과 */
export type ListOfApprovalOutput = {
  __typename?: 'ListOfApprovalOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 목록 */
  list?: Maybe<Array<ApprovalEntity>>;
  ok: Scalars['Boolean'];
};

/** 결재 임시저장 목록 데이터 양식 */
export type ListOfApprovalTemporaryStorageEntity = {
  __typename?: 'ListOfApprovalTemporaryStorageEntity';
  /** 결재 ID */
  approvalRequestIdx: Scalars['Int'];
  /** 결재 제목 */
  approvalTitle?: Maybe<Scalars['String']>;
  /** 결재선 타입 */
  approvalType: Approval_Sort;
  /** 첨부 파일 링크 */
  attachedFileLink?: Maybe<Scalars['String']>;
  /** 첨부 파일 이름 */
  attachedFileName?: Maybe<Scalars['String']>;
  /** 결재 카테고리 ID */
  categoryId: Scalars['Int'];
  /** 연장 종료 날짜 */
  extendEndDate: Scalars['String'];
  /** 연장 종료 날짜 시간 */
  extendEndDateTime: Scalars['String'];
  /** 연장 시작 날짜 */
  extendStartDate: Scalars['String'];
  /** 연장 시작 날짜 시간 */
  extendStartDateTime: Scalars['String'];
  /** 근무 결재신청양식 IDX */
  formIdx?: Maybe<Scalars['Int']>;
  /** 결재 템플릿 양식 idx */
  formTemplateIdx: Scalars['Int'];
  /** 기안자 */
  name: Scalars['String'];
  /** 작성일 */
  signUpDatetime?: Maybe<Scalars['String']>;
};

/** 결재 양식 결재자 정보 */
export type ListOfApprovalUser = {
  __typename?: 'ListOfApprovalUser';
  /** 결재 순서 */
  approvalOrder: Scalars['Int'];
  /** 결재 idx */
  approvalRequestIdx: Scalars['Int'];
  /** 결재 결과 */
  approvalResult?: Maybe<Approval_Status>;
  /** 결재 시간 */
  approvalTime?: Maybe<Scalars['String']>;
  /** 결재자 ID */
  approverId: Scalars['String'];
  /** 사용자 결재 타입 */
  approverType: Approval_User_Type;
  /** 결재자 이름 */
  name: Scalars['String'];
  /** 사용자 서명 이미지 */
  signImage?: Maybe<Scalars['String']>;
  /** 서명 text */
  signText?: Maybe<Scalars['String']>;
  /** 서명 타입 */
  signType?: Maybe<SignType>;
  /** 서명 image */
  sign_image?: Maybe<Array<Scalars['String']>>;
  /** 열람 시간 */
  viewTime?: Maybe<Scalars['String']>;
};

/** 채팅방 이미지 타입 */
export type ListOfChatImageEntity = {
  __typename?: 'ListOfChatImageEntity';
  /** 사용자 ID */
  employee_id: Scalars['String'];
  /** 사용자 사진 */
  myPicture?: Maybe<Scalars['String']>;
  /** 사용자 사진 buffer 타입 */
  my_picture?: Maybe<Array<Scalars['String']>>;
  /** 사용자 이름 */
  name: Scalars['String'];
};

/** 채팅 룸 */
export type ListOfChatRoom = {
  __typename?: 'ListOfChatRoom';
  /** 채팅방 IDX */
  chatroom_idx: Scalars['Int'];
  /** 채팅방 메시지 */
  chatroom_message_data?: Maybe<Scalars['String']>;
  /** 읽지 않은 메시지 카운트 */
  noreadcount?: Maybe<Scalars['Int']>;
  /** 채팅방 이름 */
  roomname: Scalars['String'];
};

/** 채팅에서 사용할 부서 및 사용자 목록 */
export type ListOfDepartmentForChatEntity = {
  __typename?: 'ListOfDepartmentForChatEntity';
  /** 부서 ID */
  departmentId: Scalars['Int'];
  /** 전체 부서명 */
  fullName?: Maybe<Scalars['String']>;
  /** 부서명 */
  name?: Maybe<Scalars['String']>;
  /** 부모 부서 ID */
  parentDepartmentId: Scalars['Int'];
};

/** 출퇴근 관리 정보 요청 결과 */
export type ListOfEmployeeCommuteManagementOutput = {
  __typename?: 'ListOfEmployeeCommuteManagementOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 출퇴근 관리 정보 목록 */
  list?: Maybe<Array<CommuteManagement>>;
  ok: Scalars['Boolean'];
};

/** 정책적용직원 정보 요청 결과 */
export type ListOfEmployeesAppliedToTheTemplateOutput = {
  __typename?: 'ListOfEmployeesAppliedToTheTemplateOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 정책적용된 직원 목록 */
  listEmployeesAppliedToTheTemplate?: Maybe<Array<EmployeeAppliedToTheTemplate>>;
  ok: Scalars['Boolean'];
};

/** 이석관리 정보 목록 요청 결과 */
export type ListOfPlaceFreeManagementOutput = {
  __typename?: 'ListOfPlaceFreeManagementOutput';
  /** ID for Cache */
  cacheId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** 이석관리 정보 목록 */
  list?: Maybe<Array<PlaceFreeManagement>>;
  ok: Scalars['Boolean'];
};

/** 수신 및 참조 부서 정보 */
export type ListOfReceiveAndReferenceDepartmentInformation = {
  __typename?: 'ListOfReceiveAndReferenceDepartmentInformation';
  /** 사용자 부서 ID */
  departmentId: Scalars['Int'];
  /** 사용자 부서 이름 */
  departmentName: Scalars['String'];
};

/** 수신자 및 참조자 정보 */
export type ListOfReceiveAndReferenceUserInformation = {
  __typename?: 'ListOfReceiveAndReferenceUserInformation';
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 사용자 이름 */
  employeeName: Scalars['String'];
};

/** 근무관리 정보 목록 요청 결과 */
export type ListOfWorkManagementOutput = {
  __typename?: 'ListOfWorkManagementOutput';
  error?: Maybe<Scalars['String']>;
  /** 근무관리정보 목록 */
  list?: Maybe<Array<WorkManagement>>;
  ok: Scalars['Boolean'];
};

/** 마이너스 연차 적용 설정 타입 */
export enum Minus_Annual_Type {
  LimitByBasicDayWithMinusAnnual = 'LIMIT_BY_BASIC_DAY_WITH_MINUS_ANNUAL',
  LimitByMinusAllowedAnnual = 'LIMIT_BY_MINUS_ALLOWED_ANNUAL',
  UnLimit = 'UN_LIMIT',
  UnUsed = 'UN_USED'
}

/** 마이너스연차적용설정 */
export enum Minus_Annual_Type_For_Less_Than_A_Year {
  Apply = 'APPLY',
  NotApplied = 'NOT_APPLIED'
}

/** 월별발생기준시점 */
export enum Month_Accrual_Type {
  AFullMonthsEmployment = 'A_FULL_MONTHS_EMPLOYMENT',
  TheBaseDateForJoining = 'THE_BASE_DATE_FOR_JOINING',
  TheMonthOfEmployment = 'THE_MONTH_OF_EMPLOYMENT'
}

/** 월별발생기준시점 타입 */
export enum Month_Accrual_Type_For_Next_Year_Of_Employment {
  AFullMonthsEmployment = 'A_FULL_MONTHS_EMPLOYMENT',
  TheBaseDateForJoining = 'THE_BASE_DATE_FOR_JOINING',
  TheMonthOfEmployment = 'THE_MONTH_OF_EMPLOYMENT'
}

/** 메인 토큰 정보 */
export type MainTokenInfoEntity = {
  __typename?: 'MainTokenInfoEntity';
  /**
   * 공용 및 미공용 활성화 값
   *     0: 미공용, 1: 공용
   */
  allowFlag: Allowed_Flag_Type;
  /** 토큰 이름 */
  tokenField: Scalars['String'];
};

/** 사용자 출퇴근 정보 */
export type MapRegisterEntity = {
  __typename?: 'MapRegisterEntity';
  /** 사용자 출퇴근 지역 정보 키값(areaIdx) */
  areaIdx: Scalars['Int'];
  /** 장소 이름 */
  areaName: Scalars['String'];
  customerSend: Scalars['Int'];
  /** gps에 따른 상세주소 */
  gpsAddress: Scalars['String'];
  /** gps 위도 */
  gpsLatitude: Scalars['String'];
  /** gps 위도 */
  gpsLongitude: Scalars['String'];
  /** 적용 반경 */
  radiusMiter: Scalars['Int'];
};

/** 웹뷰 메뉴 설정 (결재관리 Show/Hide확인) Entity */
export type MobileWebViewEntity = {
  __typename?: 'MobileWebViewEntity';
  /** 웹뷰 버튼 링크주소 */
  appWebViewButtonLink: Scalars['String'];
  /** 앱 웹뷰 버튼 이름 */
  appWebViewButtonName: Scalars['String'];
  /** 웹뷰 버튼 링크주소 */
  appWebViewMonthWorkInfoStatus: Is_Show;
  /**  앱_웹뷰 버튼(1:Show/2:Hide) */
  appWebviewButtonStatus: Is_Show;
  /** 웹_결재관리 메뉴 이름 */
  webApprovalButtonName: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAbsenceManagementInfo: AddAbsenceManagementInfoOutput;
  addAlarmEvent: AddAlarmEventOutput;
  addAnnualStatusFormExcel: AddAnnualStatusFormExcelOutput;
  addApprovalLine: AddApprovalLineOutput;
  addApprovalNotificationDepartment: AddDepartmentAppliedToTheTemplateOutput;
  addApprovalNotificationSchedule: AddListOfApprovalNotificationScheduleByTemplateOutput;
  addApprovalNotificationTemplate: AddApprovalNotificationTemplateOutput;
  addApprovalTemporaryStorage: ApplicationApprovalOutput;
  addAutonomyWorkingHourInfo: AddAutonomyWorkingHourInfoOutput;
  addChatRoom: AddChatRoomOutput;
  addCommuteDateTime: AddCommuteDateTimeOutput;
  addCommuteGpsInfo: AddCommuteGpsInfoOutput;
  addCommuteRecordInfo: AddCommuteRecordInfoOutput;
  addControlNotification: AddControlNotificationOutput;
  addEmployeeAppliedToTheTemplate: AddEmployeeAppliedToTheTemplateOutput;
  addEtcOperatingHours: AddEtcOperatingHoursOutput;
  addFlexibleWorkingHourInfo: AddFlexibleWorkingHourInfoOutput;
  addReservation: AddReservationOutput;
  addReservationCategory: AddReservationCategoryOutput;
  addReservationType: AddReservationTypeOutput;
  addSelfDeterminedWorkHourTemplate: AddSelfDeterminedWorkHourTemplateOutput;
  addSelfDeterminedWorkHours: AddSelfDeterminedWorkHoursOutput;
  addStandardWTBasicInfo: AddStandardWtBasicInfoOutput;
  addTemplateScheduleInfo: AddTemplateScheduleInfoOutput;
  addTimeTemplate: AddTimeTemplateOutput;
  addUserDailyWorkInfo: AddUserDailyWorkInfoOutput;
  addUserFromChatRoom: AddUserFromChatRoomOutput;
  addVehicleDrivingInformation: AddVehicleDrivingInformationOutput;
  addVehicleInformation: AddVehicleInformationOutput;
  addWTBasicInfo: AddWtBasicInfoOutput;
  addWidgetSettingInfo: AddWidgetSettingInfoOutput;
  applicationApproval: ApplicationApprovalOutput;
  controlApprovalForm: ControlApprovalFormOutput;
  deleteAlarmEvent: DeleteAlarmEventOutput;
  deleteApprovalLine: DeleteApprovalLineOutput;
  deleteApprovalNotificationDepartment: DeleteDepartmentAppliedToTheTemplateOutput;
  deleteApprovalNotificationSchedule: DeleteListOfApprovalNotificationScheduleByTemplateOutput;
  deleteApprovalNotificationTemplate: DeleteApprovalNotificationTemplateOutput;
  deleteApprovalRequest: DeleteApprovalRequestOutput;
  deleteApprovalSignImageOrText: DeleteApprovalSignImageOrTextOutput;
  deleteApprovalTemplateForm: DeleteApprovalTemplateFormOutput;
  deleteApprovalTemporaryStorage: DeleteApprovalTemporaryStorageOutput;
  deleteCommuteGpsInfo: DeleteCommuteGpsInfoOutput;
  deleteEmployeeApplied: DeleteEmployeeAppliedOutput;
  deleteMainTokenInfo: DeleteMainTokenInfoOutput;
  deleteReservation: DeleteReservationOutput;
  deleteReservationCategory: DeleteReservationCategoryOutput;
  deleteReservationType: DeleteReservationTypeOutput;
  deleteSelfDeterminedWorkHourTemplate: DeleteSelfDeterminedWorkHourTemplateOutput;
  deleteStatisticsInfo: DeleteStatisticsInfoOutput;
  deleteSubTokenInfo: DeleteSubTokenInfoOutput;
  deleteTemplateScheduleInfo: DeleteTemplateScheduleInfoOutput;
  deleteTimeTemplate: DeleteTimeTemplateOutput;
  deleteUserDailyWorkInfo: DeleteUserDailyWorkInfoOutput;
  deleteVehicleDrivingInformation: DeleteVehicleDrivingInformationOutput;
  deleteVehicleInformation: DeleteVehicleInformationOutput;
  deleteWTBasicInfo: DeleteWtBasicInfoOutput;
  editAnnualStatus: EditAnnualStatusOutput;
  editApprovalAdminSetting: EditApprovalAdminSettingOutput;
  editApprovalLine: EditApprovalLineOutput;
  editUserDailyWorkCheckInfo: EditUserDailyCheckInfoOutput;
  editUserDailyWorkInfo: EditUserDailyWorkInfoOutput;
  editUserMonthlyWorkInfo: EditUserMonthlyWorkInfoOutput;
  leavingTheRoom: LeavingTheRoomOutput;
  rejectApproval: RejectApprovalOutput;
  requestForApproval: RequestForApprovalOutput;
  saveApprovalPasswordAndSignSetting: SaveApprovalPasswordAndSignSettingOutput;
  saveStatisticsInfo: SaveStatisticsInfoOutput;
  saveUserPolicySelectedInfo: SaveUserPolicySelectedInfOutput;
  sendUserMonthlyWorkInfo: SendUserMonthlyWorkInfoOutput;
  signApproval: SignApprovalOutput;
  updateAlarmEvent: UpdateAlarmEventOutput;
  updateAlarmEventStatus: UpdateAlarmEventStatusOutput;
  updateAnnualEnvInfo: UpdateAnnualEnvInfoOutput;
  updateApprovalNotificationSchedule: UpdateListOfApprovalNotificationScheduleByTemplateOutput;
  updateApprovalNotificationTemplate: UpdateApprovalNotificationTemplateOutput;
  updateBasicUserinfo: UpdateBasicUserInfoOutput;
  updateCommuteDateTime: UpdateCommuteDateTimeOutput;
  updateCommuteGpsAreaUseType: UpdateCommuteGpsAreaUseTypeOutput;
  updateCommuteGpsInfo: UpdateCommuteGpsInfoOutput;
  updateInfoLessThanYear: UpdateInfoLessThanAYearOutput;
  updateInfoMoreThanAYear: UpdateInfoMoreThanAYearOutput;
  updateInfoNextYearOfEmployment: UpdateInfoNextYearOfEmploymentOutput;
  updateMainTokenAllowFlag: UpdateMainTokenAllowedFlagOutput;
  updatePlaceFreeManagement: UpdatePlaceFreeManagementOutput;
  updateProcessingInQuiryResult: UpdateProcessingInquiryResultOutput;
  updateReservation: UpdateReservationOutput;
  updateReservationCategory: UpdateReservationCategoryOutput;
  updateReservationType: UpdateReservationTypeOutput;
  updateSelfDeterminedWorkHourTemplate: UpdateSelfDeterminedWorkHourTemplateOutput;
  updateSubTokenAllowedFlag: UpdateSubTokenAllowedFlagOutput;
  updateTemplateScheduleInfo: UpdateTemplateScheduleInfoOutput;
  updateTimeTemplate: UpdateTimeTemplateOutput;
  updateUserAnnualInfo: UpdateUserAnnualInfoOutput;
  updateVehicleDrivingInformation: UpdateVehicleDrivingInformationOutput;
  updateVehicleInformation: UpdateVehicleInformationOutput;
  updateWTBasicInfo: UpdateWtBasicInfoOutput;
  updateWorkingTemplate: UpdateWorkingTemplateOutput;
};


export type MutationAddAbsenceManagementInfoArgs = {
  extendLeftSeatAutoCommuteOutUse: Is_Use;
  leftSeatApplyTimeUse: Is_Use;
  leftSeatAttendeeInput: Is_Use;
  leftSeatAutoUse: Is_Use;
  leftSeatBaseMin: Scalars['Int'];
  leftSeatBlackScreenUse: Is_Use;
  leftSeatPlaceInput: Is_Use;
  leftSeatReasonUse: Is_Use;
  workingTemplateIdx: Scalars['Int'];
};


export type MutationAddAlarmEventArgs = {
  description?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<Scalars['Int']>;
  linkUrl?: InputMaybe<Scalars['String']>;
  pushMin?: InputMaybe<Scalars['Int']>;
  targetDevice?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationAddAnnualStatusFormExcelArgs = {
  info: Array<EditAnnualStatusInput>;
};


export type MutationAddApprovalLineArgs = {
  approvalLineControlType: ApprovalLineControlType;
  approvalLineName: Scalars['String'];
  count: Scalars['Int'];
  isDefaultApprovalLine: Is_Default_Approval_Line;
  list: Array<ApprovalLineUserEntity>;
  user: Scalars['String'];
};


export type MutationAddApprovalNotificationDepartmentArgs = {
  selectedDepartmentId: Scalars['String'];
  templateIdx: Scalars['Int'];
};


export type MutationAddApprovalNotificationScheduleArgs = {
  approvalTimeType: ApprovalTimeType;
  categoryId: Scalars['Int'];
  dayCount: Scalars['Int'];
  dayType?: InputMaybe<ApprovalTimeType>;
  isApp: IsUsingNotification;
  isMail: IsUsingNotification;
  minCount?: InputMaybe<Scalars['Int']>;
  minType?: InputMaybe<ApprovalTimeType>;
  templateIdx: Scalars['Int'];
};


export type MutationAddApprovalNotificationTemplateArgs = {
  alarmName: Scalars['String'];
};


export type MutationAddApprovalTemporaryStorageArgs = {
  description?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['String'];
  endDate: Scalars['String'];
  endTime?: InputMaybe<Scalars['String']>;
  formTemplateIdx: Scalars['Int'];
  listOfApprovalLineUser?: InputMaybe<Array<IListOfApprovalLineUser>>;
  listOfReceiveDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment>>;
  listOfReceiver?: InputMaybe<Array<IReceiveAndReferenceForUser>>;
  listOfReferencedPerson?: InputMaybe<Array<IReceiveAndReferenceForUser>>;
  listOfReferencedPersonDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment>>;
  replacementRequestEndDate?: InputMaybe<Scalars['String']>;
  replacementRequestEndTime?: InputMaybe<Scalars['String']>;
  replacementRequestStartDate?: InputMaybe<Scalars['String']>;
  replacementRequestStartTime?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
  startTime?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationAddAutonomyWorkingHourInfoArgs = {
  breakTimeType: Break_Time_Type;
  dayOfWeek: Array<Day_Type>;
  dayOfWeekType: Day_Of_Week_Type;
  endBreakTime: Scalars['String'];
  endTime: Scalars['String'];
  startBreakTime: Scalars['String'];
  startTime: Scalars['String'];
  workingTemplateIdx: Scalars['Int'];
};


export type MutationAddChatRoomArgs = {
  partnerId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAddCommuteDateTimeArgs = {
  checkDateTime: Scalars['String'];
  commuteType: CommuteType;
  deviceType: Device_Type;
  selectedEmployeeId: Array<Scalars['String']>;
};


export type MutationAddCommuteGpsInfoArgs = {
  address: Scalars['String'];
  gps: Scalars['String'];
  name: Scalars['String'];
  radius: Scalars['Int'];
};


export type MutationAddCommuteRecordInfoArgs = {
  appUse: Is_Use;
  approvalDataType: Is_Use;
  checkInStartWorkTimeSetFlag: Is_Use;
  checkoutEndWorkTimeSetFlag: Is_Use;
  dayOfWeek: Array<Day_Type>;
  endWorkingMin: Scalars['Int'];
  name: Scalars['String'];
  pcUse: Is_Use;
  startWorkingMin: Scalars['Int'];
  unCheckoutAutoCheckoutFlag: Is_Check_Auto_Time;
  unCheckoutAutoCheckoutTime: Scalars['String'];
  workingTemplateIdx: Scalars['Int'];
};


export type MutationAddControlNotificationArgs = {
  afterEndWorkingCtrlMsg?: InputMaybe<Scalars['Int']>;
  beforeEndWorkingCtrlMsg?: InputMaybe<Scalars['Int']>;
  beforeEndWorkingMin?: InputMaybe<Scalars['Int']>;
  beforeStartWorkingCtrlMsg?: InputMaybe<Scalars['Int']>;
  beforeStartWorkingMin?: InputMaybe<Scalars['Int']>;
  breakTimeCtrlMsg?: InputMaybe<Scalars['Int']>;
  exceedCtrlMsgAfter?: InputMaybe<Scalars['Int']>;
  exceedCtrlMsgBeforeMinOne?: InputMaybe<Scalars['Int']>;
  exceedCtrlMsgBeforeMinTwo?: InputMaybe<Scalars['Int']>;
  exceedCtrlMsgBeforeOne?: InputMaybe<Scalars['Int']>;
  exceedCtrlMsgBeforeTwo?: InputMaybe<Scalars['Int']>;
  workingTemplateIdx: Scalars['Int'];
};


export type MutationAddEmployeeAppliedToTheTemplateArgs = {
  isExpect: IsException;
  selectedEmployeeId: Scalars['String'];
  templateIdx: Scalars['Float'];
};


export type MutationAddEtcOperatingHoursArgs = {
  holidayTemplateIdx: Array<Scalars['Int']>;
  otHolidayTemplateNewAutoUse: Is_Use;
  workingTemplateIdx: Scalars['Int'];
};


export type MutationAddFlexibleWorkingHourInfoArgs = {
  dayOfWeek: Array<Day_Type>;
  dayOfWeekType: Day_Of_Week_Type;
  endBreakTime: Scalars['String'];
  endTime: Scalars['String'];
  otWorkingFlexibleHours: Array<OtWorkingFlexibleHours>;
  settlementSectionHour: Scalars['Int'];
  startBreakTime: Scalars['String'];
  startDate: Scalars['String'];
  startTime: Scalars['String'];
  workHoursType: Work_Hours_Type;
  workingTemplateIdx: Scalars['Int'];
};


export type MutationAddReservationArgs = {
  categoryIdx: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['String'];
  endTime: Scalars['String'];
  requestEmployeeId: Scalars['String'];
  reserveTitle: Scalars['String'];
  startDate: Scalars['String'];
  startTime: Scalars['String'];
};


export type MutationAddReservationCategoryArgs = {
  categoryDescription: Scalars['String'];
  categoryName: Scalars['String'];
  groupIdx: Scalars['Int'];
};


export type MutationAddReservationTypeArgs = {
  groupName: Scalars['String'];
};


export type MutationAddSelfDeterminedWorkHourTemplateArgs = {
  breakTimeType: Break_Time_Type;
  dayOfWeek: Array<Day_Type>;
  dayOfWeekType: Work_Day_Of_Week;
  dutyEndTime: Scalars['String'];
  dutyStartTime: Scalars['String'];
  dutyWorkHoursType: Is_Use;
  endBreakTime: Scalars['String'];
  endTime: Scalars['String'];
  operatingPeriodEndDate: Scalars['String'];
  operatingPeriodStartDate: Scalars['String'];
  operatingPeriodType: Operating_Period_Type;
  startBreakTime: Scalars['String'];
  startTime: Scalars['String'];
  workHoursType: Work_Hours_Setting_Type;
  workingTemplateIdx: Scalars['Int'];
  workingTimeName: Scalars['String'];
};


export type MutationAddSelfDeterminedWorkHoursArgs = {
  endBreakTime: Scalars['String'];
  startBreakTime: Scalars['String'];
  workingTemplateIdx: Scalars['Int'];
  workingTimeName: Array<Scalars['String']>;
};


export type MutationAddStandardWtBasicInfoArgs = {
  dayOfWeek: Array<Day_Type>;
  dayOfWeekType: Day_Of_Week_Type;
  endBreakTime: Scalars['String'];
  endTime: Scalars['String'];
  otWorkingStandardHourList?: InputMaybe<Array<Ot_Working_Standard_Hours>>;
  startBreakTime: Scalars['String'];
  startTime: Scalars['String'];
  workWeekSelect: Array<Scalars['Int']>;
  workWeekType: Work_Week_Type;
  workingTemplateIdx: Scalars['Int'];
};


export type MutationAddTemplateScheduleInfoArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['String'];
  isFriday?: InputMaybe<Scalars['Int']>;
  isMonday?: InputMaybe<Scalars['Int']>;
  isSaturday?: InputMaybe<Scalars['Int']>;
  isSunday?: InputMaybe<Scalars['Int']>;
  isThursday?: InputMaybe<Scalars['Int']>;
  isTuesDay?: InputMaybe<Scalars['Int']>;
  isWednesday?: InputMaybe<Scalars['Int']>;
  periodDateEnd?: InputMaybe<Scalars['String']>;
  periodDateStart: Scalars['String'];
  startTime: Scalars['String'];
  timeTemplateIdx: Scalars['Int'];
};


export type MutationAddTimeTemplateArgs = {
  timeName: Scalars['String'];
};


export type MutationAddUserDailyWorkInfoArgs = {
  info: Array<AddUserDailyWorkInfoType>;
};


export type MutationAddUserFromChatRoomArgs = {
  chatRoomIdx: Scalars['Int'];
  inviteeName?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};


export type MutationAddVehicleDrivingInformationArgs = {
  categoryId: Scalars['Int'];
  endDateTime: Scalars['String'];
  endEmployeeId: Scalars['String'];
  endGpsAddress: Scalars['String'];
  endKm: Scalars['Int'];
  startDateTime: Scalars['String'];
  startEmployeeId: Scalars['String'];
  startGpsAddress: Scalars['String'];
  startKm: Scalars['Int'];
  vehicleNumber: Scalars['String'];
};


export type MutationAddVehicleInformationArgs = {
  vehicleName: Scalars['String'];
  vehicleNumber: Scalars['String'];
};


export type MutationAddWtBasicInfoArgs = {
  dayMaxWorkHour: Scalars['Int'];
  dayOfWeekStart: Day_Type;
  mandatoryBreakMin: Scalars['Int'];
  mandatoryBreakTermHour: Scalars['Int'];
  name: Scalars['String'];
  otHoursTypeCategoryIdx?: InputMaybe<Scalars['Float']>;
  periodUnitType?: InputMaybe<Period_Unit_Type>;
  timeDifferenceMin?: InputMaybe<Scalars['Float']>;
  weekMaxExtWorkHour: Scalars['Int'];
  weekMaxWorkHour: Scalars['Int'];
  workType: Work_Type;
};


export type MutationAddWidgetSettingInfoArgs = {
  asonicWidgetAutoStart: Is_Use;
  asonicWidgetUse: Widget_Setting_Type;
  workingTemplateIdx: Scalars['Int'];
};


export type MutationApplicationApprovalArgs = {
  description?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['String'];
  endDate: Scalars['String'];
  endTime?: InputMaybe<Scalars['String']>;
  formTemplateIdx: Scalars['Int'];
  listOfApprovalLineUser?: InputMaybe<Array<IListOfApprovalLineUser>>;
  listOfReceiveDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment>>;
  listOfReceiver?: InputMaybe<Array<IReceiveAndReferenceForUser>>;
  listOfReferencedPerson?: InputMaybe<Array<IReceiveAndReferenceForUser>>;
  listOfReferencedPersonDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment>>;
  replacementRequestEndDate?: InputMaybe<Scalars['String']>;
  replacementRequestEndTime?: InputMaybe<Scalars['String']>;
  replacementRequestStartDate?: InputMaybe<Scalars['String']>;
  replacementRequestStartTime?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
  startTime?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationControlApprovalFormArgs = {
  approvalSort: Approval_Sort;
  categoryId: Scalars['Int'];
  controlApprovalFormType: Type_Control_Approval_Form;
  description?: InputMaybe<Scalars['String']>;
  formIdx: Scalars['Int'];
  formTemplateIdx?: InputMaybe<Scalars['Int']>;
  templateName: Scalars['String'];
};


export type MutationDeleteAlarmEventArgs = {
  settingIndex: Scalars['Int'];
};


export type MutationDeleteApprovalLineArgs = {
  lineIdx: Scalars['Int'];
  user: Scalars['String'];
};


export type MutationDeleteApprovalNotificationDepartmentArgs = {
  selectedDepartmentId: Scalars['String'];
  templateIdx: Scalars['Int'];
};


export type MutationDeleteApprovalNotificationScheduleArgs = {
  scheduleIdx: Scalars['Int'];
};


export type MutationDeleteApprovalNotificationTemplateArgs = {
  templateIdx: Scalars['Int'];
};


export type MutationDeleteApprovalRequestArgs = {
  approvalRequestIdx: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['String'];
  endDate: Scalars['String'];
  endTime?: InputMaybe<Scalars['String']>;
  formTemplateIdx: Scalars['Int'];
  listOfApprovalLineUser?: InputMaybe<Array<IListOfApprovalLineUser>>;
  listOfReceiveDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment>>;
  listOfReceiver?: InputMaybe<Array<IReceiveAndReferenceForUser>>;
  listOfReferencedPerson?: InputMaybe<Array<IReceiveAndReferenceForUser>>;
  listOfReferencedPersonDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment>>;
  replacementRequestEndDate?: InputMaybe<Scalars['String']>;
  replacementRequestEndTime?: InputMaybe<Scalars['String']>;
  replacementRequestStartDate?: InputMaybe<Scalars['String']>;
  replacementRequestStartTime?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
  startTime?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationDeleteApprovalSignImageOrTextArgs = {
  deleteSignType: SignType;
  employeeId: Scalars['String'];
};


export type MutationDeleteApprovalTemplateFormArgs = {
  listOfFormTemplateIdx: Array<Scalars['Int']>;
};


export type MutationDeleteApprovalTemporaryStorageArgs = {
  approvalRequestIdx: Scalars['Int'];
};


export type MutationDeleteCommuteGpsInfoArgs = {
  areaIdx: Scalars['Int'];
};


export type MutationDeleteEmployeeAppliedArgs = {
  selectedEmployeeId: Scalars['String'];
  templateIdx: Scalars['Float'];
};


export type MutationDeleteMainTokenInfoArgs = {
  tokenField: Scalars['String'];
};


export type MutationDeleteReservationArgs = {
  listOfInfoIdx: Array<Scalars['Int']>;
};


export type MutationDeleteReservationCategoryArgs = {
  listOfCategoryIdx: Array<Scalars['Int']>;
};


export type MutationDeleteReservationTypeArgs = {
  listOfGroupIdx: Array<Scalars['Int']>;
};


export type MutationDeleteSelfDeterminedWorkHourTemplateArgs = {
  workingTemplateIdx: Scalars['Int'];
  workingTimeNameList: Array<Scalars['String']>;
};


export type MutationDeleteStatisticsInfoArgs = {
  index: Scalars['Float'];
};


export type MutationDeleteSubTokenInfoArgs = {
  employeeId: Scalars['String'];
  tokenField: Scalars['String'];
};


export type MutationDeleteTemplateScheduleInfoArgs = {
  timeIdx: Scalars['Int'];
};


export type MutationDeleteTimeTemplateArgs = {
  timeTemplateIdx: Scalars['Int'];
};


export type MutationDeleteUserDailyWorkInfoArgs = {
  info: Array<UserDailyWorkInfo>;
};


export type MutationDeleteVehicleDrivingInformationArgs = {
  listOfLogIdx: Array<Scalars['Int']>;
};


export type MutationDeleteVehicleInformationArgs = {
  listOfVehicleNumber: Array<Scalars['String']>;
};


export type MutationDeleteWtBasicInfoArgs = {
  workingTemplateIdxList: Array<Scalars['Int']>;
};


export type MutationEditAnnualStatusArgs = {
  annualDay?: InputMaybe<Scalars['Float']>;
  descr?: InputMaybe<Scalars['String']>;
  employeeIdList: Array<Scalars['String']>;
  searchYear: Scalars['String'];
};


export type MutationEditApprovalAdminSettingArgs = {
  count: Scalars['Int'];
  preview: Approval_Admin_Setting_Preview_Type;
};


export type MutationEditApprovalLineArgs = {
  approvalLineControlType: ApprovalLineControlType;
  approvalLineName: Scalars['String'];
  count: Scalars['Int'];
  isDefaultApprovalLine: Is_Default_Approval_Line;
  lineIdx: Scalars['Int'];
  list: Array<ApprovalLineUserEntity>;
  user: Scalars['String'];
};


export type MutationEditUserDailyWorkCheckInfoArgs = {
  dailyCheckInfo: Array<UserDailyCheckInfo>;
  employeeId: Scalars['String'];
};


export type MutationEditUserDailyWorkInfoArgs = {
  beforeDate: Scalars['String'];
  breakTimeAdjustmentMin?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
  employeeId: Scalars['String'];
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};


export type MutationEditUserMonthlyWorkInfoArgs = {
  info: Array<EditUserMonthlyWorkInfo>;
};


export type MutationLeavingTheRoomArgs = {
  chatRoomIdx: Scalars['Int'];
  employeeId: Scalars['String'];
};


export type MutationRejectApprovalArgs = {
  approvalRequestIdx: Scalars['Int'];
  customEmployeeId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
};


export type MutationRequestForApprovalArgs = {
  comment: Scalars['String'];
  endAlterWorkDate?: InputMaybe<Scalars['String']>;
  endAlterWorkTime?: InputMaybe<Scalars['String']>;
  endDayOffDate: Scalars['String'];
  endDayOffTime: Scalars['String'];
  listOfApprover: Array<Scalars['String']>;
  overtimeCategoryId: Scalars['String'];
  startAlterWorkDate?: InputMaybe<Scalars['String']>;
  startAlterWorkTime?: InputMaybe<Scalars['String']>;
  startDayOffDate: Scalars['String'];
  startDayOffTime: Scalars['String'];
};


export type MutationSaveApprovalPasswordAndSignSettingArgs = {
  employeeId: Scalars['String'];
  isSignEmailAlarm: Is_Sign_Alarm_Type;
  isSignMobileAlarm: Is_Sign_Alarm_Type;
  isSignPassword: Is_Sign_Password;
  signImage?: InputMaybe<Array<Scalars['String']>>;
  signPassword?: InputMaybe<Scalars['String']>;
  signText?: InputMaybe<Scalars['String']>;
  signType: SignType;
};


export type MutationSaveStatisticsInfoArgs = {
  queryContent: QueryContent;
};


export type MutationSaveUserPolicySelectedInfoArgs = {
  dayOfWeek: Array<Day_Type>;
  employeeId: Scalars['String'];
  endBreakTime: Scalars['String'];
  endTime: Scalars['String'];
  startBreakTime: Scalars['String'];
  startTime: Scalars['String'];
  workingTemplateIdx: Scalars['Int'];
  workingTimeName: Scalars['String'];
};


export type MutationSendUserMonthlyWorkInfoArgs = {
  info: Array<SendUserMonthlyWorkInfo>;
};


export type MutationSignApprovalArgs = {
  approvalRequestIdx: Scalars['Int'];
  customEmployeeId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
};


export type MutationUpdateAlarmEventArgs = {
  description?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<Scalars['Int']>;
  linkUrl?: InputMaybe<Scalars['String']>;
  pushMin?: InputMaybe<Scalars['Int']>;
  settingIndex: Scalars['Int'];
  targetDevice?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAlarmEventStatusArgs = {
  alarmUse: AlarmStatus;
};


export type MutationUpdateAnnualEnvInfoArgs = {
  accountingMonth: Scalars['Int'];
  annualAccrualType: Annual_Accrual_Type;
  annualDeductionPriorityType: Scalars['Int'];
  annualFixType: Annual_Fix_Type;
  annualMaxCount: Scalars['Int'];
  companyCode: Scalars['String'];
};


export type MutationUpdateApprovalNotificationScheduleArgs = {
  approvalTimeType: ApprovalTimeType;
  categoryId: Scalars['Int'];
  dayCount: Scalars['Int'];
  dayType?: InputMaybe<ApprovalTimeType>;
  isApp: IsUsingNotification;
  isMail: IsUsingNotification;
  minCount?: InputMaybe<Scalars['Int']>;
  minType?: InputMaybe<ApprovalTimeType>;
  scheduleIdx: Scalars['Int'];
  templateIdx: Scalars['Int'];
};


export type MutationUpdateApprovalNotificationTemplateArgs = {
  alarmName: Scalars['String'];
  templateIdx: Scalars['Int'];
};


export type MutationUpdateBasicUserinfoArgs = {
  employeeId: Scalars['String'];
  employeeName: Scalars['String'];
  joiningDate?: InputMaybe<Scalars['String']>;
  leavingDate?: InputMaybe<Scalars['String']>;
  myPicture: Scalars['String'];
  nameChinese?: InputMaybe<Scalars['String']>;
  nameEnglish?: InputMaybe<Scalars['String']>;
  phoneNum?: InputMaybe<Scalars['String']>;
  userEmail?: InputMaybe<Scalars['String']>;
  userJob?: InputMaybe<Scalars['String']>;
  userPosition?: InputMaybe<Scalars['String']>;
  userRank?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCommuteDateTimeArgs = {
  approvalFlag: ApprovalFlag;
  checkDateTime: Scalars['String'];
  checkDateTimeModify: Scalars['String'];
  selectedEmployeeId: Scalars['String'];
};


export type MutationUpdateCommuteGpsAreaUseTypeArgs = {
  commuteAreaUseType: CommuteAreaUseType;
};


export type MutationUpdateCommuteGpsInfoArgs = {
  address: Scalars['String'];
  areaIdx: Scalars['Int'];
  gps: Scalars['String'];
  name: Scalars['String'];
  radius: Scalars['Int'];
};


export type MutationUpdateInfoLessThanYearArgs = {
  annualAccrualType: Annual_Accrual_Type_For_Less_Than_A_Year;
  companyCode: Scalars['String'];
  fixCount: Scalars['Float'];
  minusAnnualDayCount: Scalars['Float'];
  minusAnnualType: Minus_Annual_Type_For_Less_Than_A_Year;
  monthAccrualType: Month_Accrual_Type;
};


export type MutationUpdateInfoMoreThanAYearArgs = {
  addedDayCount: Scalars['Int'];
  addedMaxDayCount: Scalars['Int'];
  addedYearCount: Scalars['Int'];
  carriedforwardRangeType: Carriedforward_Range_Type;
  carriedforwardType: Carriedforward_Type;
  companyCode: Scalars['String'];
  defaultDayCount: Scalars['Int'];
  minusAnnualDayCount: Scalars['Int'];
  minusAnnualType: Minus_Annual_Type;
};


export type MutationUpdateInfoNextYearOfEmploymentArgs = {
  annualAccrualType: Annual_Accrual_Type_For_Next_Year_Of_Employment;
  carriedforwardRangeType: Carriedforward_Range_Type;
  carriedforwardType: Carriedforward_Type;
  companyCode: Scalars['String'];
  fixCount: Scalars['Float'];
  minusAnnualDayCount: Scalars['Float'];
  minusAnnualType: Minus_Annual_Type;
  monthAccrualType: Month_Accrual_Type_For_Next_Year_Of_Employment;
};


export type MutationUpdateMainTokenAllowFlagArgs = {
  allowedFlag: Allowed_Flag_Type;
  tokenField: Scalars['String'];
};


export type MutationUpdatePlaceFreeManagementArgs = {
  categoryId: Scalars['Float'];
  employeeId: Scalars['String'];
  endDateTime: Scalars['String'];
  leftSeatLogIdx: Scalars['Float'];
  logAttendee?: InputMaybe<Scalars['String']>;
  logDesc?: InputMaybe<Scalars['String']>;
  logPlace: Scalars['String'];
  startDateTime: Scalars['String'];
};


export type MutationUpdateProcessingInQuiryResultArgs = {
  approvalRequestIndex: Scalars['String'];
  approvalResult: Rejected_Or_Approved_Or_Others;
  approverId: Scalars['String'];
  comment: Scalars['String'];
};


export type MutationUpdateReservationArgs = {
  description?: InputMaybe<Scalars['String']>;
  endDate: Scalars['String'];
  endTime: Scalars['String'];
  infoIdx: Scalars['Int'];
  requestEmployeeId: Scalars['String'];
  reserveTitle: Scalars['String'];
  startDate: Scalars['String'];
  startTime: Scalars['String'];
};


export type MutationUpdateReservationCategoryArgs = {
  categoryDescription: Scalars['String'];
  categoryIdx: Scalars['Int'];
  categoryName: Scalars['String'];
};


export type MutationUpdateReservationTypeArgs = {
  groupIdx: Scalars['Int'];
  groupName: Scalars['String'];
};


export type MutationUpdateSelfDeterminedWorkHourTemplateArgs = {
  breakTimeType: Break_Time_Type;
  dayOfWeek: Array<Day_Type>;
  dayOfWeekType: Work_Day_Of_Week;
  dutyEndTime: Scalars['String'];
  dutyStartTime: Scalars['String'];
  dutyWorkHoursType: Is_Use;
  endBreakTime: Scalars['String'];
  endTime: Scalars['String'];
  oldWorkingTimeName: Scalars['String'];
  operatingPeriodEndDate: Scalars['String'];
  operatingPeriodStartDate: Scalars['String'];
  operatingPeriodType: Operating_Period_Type;
  startBreakTime: Scalars['String'];
  startTime: Scalars['String'];
  workHoursType: Work_Hours_Setting_Type;
  workingTemplateIdx: Scalars['Int'];
  workingTimeName: Scalars['String'];
};


export type MutationUpdateSubTokenAllowedFlagArgs = {
  allowedFlag: Allowed_Flag_Type;
  employeeId: Scalars['String'];
  tokenField: Scalars['String'];
};


export type MutationUpdateTemplateScheduleInfoArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['String'];
  isFriday?: InputMaybe<Scalars['Int']>;
  isMonday?: InputMaybe<Scalars['Int']>;
  isSaturday?: InputMaybe<Scalars['Int']>;
  isSunday?: InputMaybe<Scalars['Int']>;
  isThursday?: InputMaybe<Scalars['Int']>;
  isTuesDay?: InputMaybe<Scalars['Int']>;
  isWednesday?: InputMaybe<Scalars['Int']>;
  periodDateEnd?: InputMaybe<Scalars['String']>;
  periodDateStart: Scalars['String'];
  startTime: Scalars['String'];
  timeIdx: Scalars['Int'];
};


export type MutationUpdateTimeTemplateArgs = {
  timeName: Scalars['String'];
  timeTemplateIdx: Scalars['Int'];
};


export type MutationUpdateUserAnnualInfoArgs = {
  addDay: Scalars['Int'];
  applyYear: Scalars['String'];
  basicDay: Scalars['Int'];
  basicModifyDay: Scalars['Int'];
  createSubject: Scalars['String'];
  createSubjectEmployeeId: Scalars['String'];
  employeeId: Scalars['String'];
  leaveCarriedForwardDay: Scalars['Int'];
  modifyReason?: InputMaybe<Scalars['String']>;
  periodateEnd: Scalars['String'];
  periodateStart: Scalars['String'];
  statusFlag?: InputMaybe<Scalars['String']>;
  useModifyDay: Scalars['Int'];
};


export type MutationUpdateVehicleDrivingInformationArgs = {
  categoryId: Scalars['Int'];
  endDateTime: Scalars['String'];
  endEmployeeId: Scalars['String'];
  endGpsAddress: Scalars['String'];
  endKm: Scalars['Int'];
  logIdx: Scalars['Int'];
  startDateTime: Scalars['String'];
  startEmployeeId: Scalars['String'];
  startGpsAddress: Scalars['String'];
  startKm: Scalars['Int'];
};


export type MutationUpdateVehicleInformationArgs = {
  updateVehicleNumber: Scalars['String'];
  vehicleName: Scalars['String'];
  vehicleNumber: Scalars['String'];
};


export type MutationUpdateWtBasicInfoArgs = {
  dayMaxWorkHour: Scalars['Int'];
  dayOfWeekStart: Day_Type;
  mandatoryBreakMin: Scalars['Int'];
  mandatoryBreakTermHour: Scalars['Int'];
  name: Scalars['String'];
  otHolidayTemplateNewAutoUse: Is_Use;
  otHoursTypeCategoryIdx?: InputMaybe<Scalars['Float']>;
  periodUnitType?: InputMaybe<Period_Unit_Type>;
  timeDifferenceMin?: InputMaybe<Scalars['Float']>;
  weekMaxExtWorkHour: Scalars['Int'];
  weekMaxWorkHour: Scalars['Int'];
  workType: Work_Type;
  workingTemplateIdx?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateWorkingTemplateArgs = {
  employeeIdList: Array<Scalars['String']>;
  workingTemplateIdx?: InputMaybe<Scalars['Int']>;
};

/** 운영기간 설정 타입 */
export enum Operating_Period_Type {
  Always = 'ALWAYS',
  SetPeriod = 'SET_PERIOD'
}

/** 근무유형(표준/시차 근무제) */
export type Ot_Working_Standard_Hours = {
  breaktime_type: Break_Time_Type;
  day_of_week: Array<Day_Type>;
  day_of_week_type: Day_Of_Week_Type;
  /** 휴게시간설정(종료시간) */
  end_breaktime: Scalars['String'];
  /** 퇴근시간 */
  end_time: Scalars['String'];
  /** 휴게시간설정(시작시간) */
  start_breaktime: Scalars['String'];
  /** 출근시간 */
  start_time: Scalars['String'];
  /** 추가설정 순서 */
  time_sequence: Scalars['Int'];
  /** 근무주차 선택 */
  work_week_select: Array<Scalars['Int']>;
  work_week_type: Work_Week_Type;
};

/** 결재 양식 */
export type OtApprovalFormEntity = {
  __typename?: 'OtApprovalFormEntity';
  /** 결재 양식 idx */
  formIdx: Scalars['Int'];
  /** 결재 양식 이름 */
  formName: Scalars['String'];
};

/** 근무제[근무시간] > (선택근무시간제 > 근무시간) 표준 근무 시간 Entity */
export type OtSelfWorkingStandardHoursEntity = {
  __typename?: 'OtSelfWorkingStandardHoursEntity';
  breakTimeType: Break_Time_Type;
  dayOfWeek: Array<Day_Type>;
  /** 근무요일설정 [ 0 : 지정(월~금), 1 : 지정(월~토), 2 : 지정(직접선택), 3 : 근무자 자율펼성] */
  dayOfWeekType: Work_Day_Of_Week;
  /** 의무 퇴근 시간 (HH:MM) */
  dutyEndTime: Scalars['String'];
  /** 의무 출근 시간 (HH:MM) */
  dutyStartTime: Scalars['String'];
  /** 의무 근무시간 [ 0 : 미사용, 1 : 사용 ] */
  dutyWorkHoursType: Is_Use;
  /** 휴게시간설정(종료시간) */
  endBreakTime: Scalars['String'];
  /** 퇴근시간 */
  endTime: Scalars['String'];
  /** 운영기간 종료일 (YYYY-MM-DD) */
  operatingPeriodEndDate: Scalars['String'];
  /** 운영기간 시작일 (YYYY-MM-DD) */
  operatingPeriodStartDate: Scalars['String'];
  /** 운영기간 설정 [ 0 : 상시적용, 1 : 기간설정 ] */
  operatingPeriodType: Operating_Period_Type;
  /** 휴게시간설정(시작시간) */
  startBreakTime: Scalars['String'];
  /** 출근시간 */
  startTime: Scalars['String'];
  /** 0(미사용)이면 체크박스 해제, 1이면 체크 */
  useFlag?: Maybe<Is_Use>;
  /** 근무시간 설정 [ 0 : 지정시간, 1 : 근무자 자율편성] */
  workHoursType: Work_Hours_Setting_Type;
  /** 근무제 idx */
  workingTemplateIdx?: Maybe<Scalars['Int']>;
  /** 근무시간 템플릿 이름 */
  workingTimeName: Scalars['String'];
};

/** 초과 근무 카테고리 정보 */
export type OtTimeTypeCategory = {
  __typename?: 'OtTimeTypeCategory';
  /** alLocateDay */
  alLocateDay?: Maybe<Scalars['Int']>;
  /** alLocateDayType */
  alLocateDayType: Scalars['Int'];
  /** beforeEndCtrlMsgTemplateIdx */
  beforeEndCtrlMsgTemplateIdx?: Maybe<Scalars['Int']>;
  /** beforeEndWorkingMin */
  beforeEndWorkingMin?: Maybe<Scalars['Int']>;
  /** category ID */
  categoryId: Scalars['Int'];
  /** ctrlMsgTemplateIdx */
  ctrlMsgTemplateIdx?: Maybe<Scalars['Int']>;
  /** fpProcess */
  fpProcess: Scalars['Int'];
  /** fpProcessTwo */
  fpProcessTwo: Scalars['Int'];
  /** isBasicData */
  isBasicData?: Maybe<Scalars['Int']>;
  /** leftSeatApplyTimeUse */
  leftSeatApplyTimeUse: Scalars['Int'];
  /** 카테고리 이름 */
  name?: Maybe<Scalars['String']>;
  /** policyUpdateStatus */
  policyUpdateStatus: Scalars['Int'];
  /** policyUpdateStatusTwo */
  policyUpdateStatusTwo: Scalars['Int'];
  /** priorityRank */
  priorityRank: Scalars['Int'];
  /** timeCalcType */
  timeCalcType: Scalars['Int'];
  /** useTableType */
  useTableType: Scalars['Int'];
};

/** 근무 시간 타입 */
export type OtTimeTypeCategoryEntity = {
  __typename?: 'OtTimeTypeCategoryEntity';
  /** 근무 시간 타입 id */
  categoryId: Scalars['Int'];
  /** 근무 시간 타입 이름 */
  name: Scalars['String'];
};

export type OtWorkingFlexibleBasicEntity = {
  __typename?: 'OtWorkingFlexibleBasicEntity';
  dayOfWeek: Array<Day_Type>;
  dayOfWeekType: Day_Of_Week_Type;
  /** 휴게시간설정(종료시간) */
  endBreakTime: Scalars['String'];
  /** 퇴근시간 */
  endTime: Scalars['String'];
  /** 근무 편성 기간 설정(주)[1,, 2, 3, 4] */
  settlementSectionHour: Scalars['Int'];
  /** 휴게시간설정(시작시간) */
  startBreakTime: Scalars['String'];
  /** 시작 주차 선택 */
  startDate: Scalars['String'];
  /** 출근시간 */
  startTime: Scalars['String'];
  workHoursType: Work_Hours_Type;
  /** 근무제 idx */
  workingTemplateIdx?: Maybe<Scalars['Int']>;
};

/** 근무유형(탄력근무제) */
export type OtWorkingFlexibleHours = {
  day_of_week: Day_Type;
  /** 휴게시간설정(퇴근시간) */
  end_breaktime: Scalars['String'];
  /** 퇴근시간 */
  end_time: Scalars['String'];
  /** 휴게시간설정(시작시간) */
  start_breaktime: Scalars['String'];
  /** 출근시간 */
  start_time: Scalars['String'];
  /** 몇번째 주 */
  week_sequence: Scalars['Int'];
  /** 근무시간 */
  work_hour: Scalars['Int'];
};

/** 근무유형(탄력근무제) */
export type OtWorkingFlexibleHoursEntity = {
  __typename?: 'OtWorkingFlexibleHoursEntity';
  dayOfWeek: Day_Type;
  /** 휴게시간설정(퇴근시간) */
  endBreakTime: Scalars['String'];
  /** 퇴근시간 */
  endTime: Scalars['String'];
  /** 휴게시간설정(시작시간) */
  startBreakTime: Scalars['String'];
  /** 출근시간 */
  startTime: Scalars['String'];
  /** 몇번째 주 */
  weekSequence: Scalars['Int'];
  /** 근무시간 */
  workHour: Scalars['Int'];
  /** 근무제 idx */
  workingTemplateIdx?: Maybe<Scalars['Int']>;
};

export type OtWorkingFreeStartBasicEntity = {
  __typename?: 'OtWorkingFreeStartBasicEntity';
  /** 휴게시간 적용 타입 */
  breakTimeType: Break_Time_Type;
  /** 근무요일 목록 */
  dayOfWeek: Array<Day_Type>;
  /** 근무요일 설정 타입 */
  dayOfWeekType: Day_Of_Week_Type;
  /** 휴게시간설정(종료시간) */
  endBreakTime: Scalars['String'];
  /** 출근가능 종료시간 */
  endTime: Scalars['String'];
  /** 휴게시간설정(시작시간) */
  startBreakTime: Scalars['String'];
  /** 출근가능 시작시간 */
  startTime: Scalars['String'];
  /** 근무제 idx */
  workingTemplateIdx: Scalars['Int'];
};

/** 표준/시차 출퇴근제 근무일 추가설정 Entity */
export type OtWorkingStandardHoursEntity = {
  __typename?: 'OtWorkingStandardHoursEntity';
  breakTimeType: Break_Time_Type;
  dayOfWeek: Array<Day_Type>;
  dayOfWeekType: Day_Of_Week_Type;
  /** 휴게시간설정(종료시간) */
  endBreakTime: Scalars['String'];
  /** 퇴근시간 */
  endTime: Scalars['String'];
  /** 휴게시간설정(시작시간) */
  startBreakTime: Scalars['String'];
  /** 출근시간 */
  startTime: Scalars['String'];
  /** 추가설정 순서 */
  timeSequence: Scalars['Int'];
  /** 근무주차 선택 */
  workWeekSelect: Array<Scalars['Int']>;
  workWeekType: Work_Week_Type;
  /** 근무제 idx */
  workingTemplateIdx?: Maybe<Scalars['Int']>;
};

/** 근무 단위 설정 타입 */
export enum Period_Unit_Type {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

/** PC 정보 */
export type PcInformation = {
  __typename?: 'PcInformation';
  /** autoUpdateFlag */
  autoUpdateFlag: IsEnable;
  /** 컴퓨터 이름 */
  computerName: Scalars['String'];
  /** 정책 사용 여부 */
  controlFlag: IsControl;
  /** 부서명 */
  departName: Scalars['String'];
  /** 부서 아이디 */
  departmentId: Scalars['String'];
  /** 컴퓨터 설명 */
  description?: Maybe<Scalars['String']>;
  /** 장치 이름 */
  deviceName: Scalars['String'];
  /** 장치 타입 */
  deviceType?: Maybe<Scalars['Int']>;
  /** 사용자 아이디 */
  employeeId?: Maybe<Scalars['String']>;
  /** endpoint 아이디 */
  endpointId: Scalars['String'];
  /** 전체 컴퓨터 이름 */
  fullName?: Maybe<Scalars['String']>;
  /** OS 설치날짜 */
  installDate?: Maybe<Scalars['String']>;
  /** 온라인 상태 */
  isOnOff?: Maybe<IsOnOff>;
  /** 마지막 부팅 시간 */
  lastBootTime: Scalars['String'];
  /** 로컬아이피 */
  localIp: Scalars['String'];
  /** 로컬아이피2 */
  localIp2?: Maybe<Scalars['String']>;
  /** 로컬아이피3 */
  localIp3?: Maybe<Scalars['String']>;
  /** 로컬 Mac */
  localMac: Scalars['String'];
  /** 로컬 Mac2 */
  localMac2?: Maybe<Scalars['String']>;
  /** 로컬 Mac3 */
  localMac3?: Maybe<Scalars['String']>;
  /** 관리자 메모2 */
  memo1?: Maybe<Scalars['String']>;
  /** 관리자 메모2 */
  memo2?: Maybe<Scalars['String']>;
  /** 성명 */
  name?: Maybe<Scalars['String']>;
  /** 새 데이터 */
  newData?: Maybe<Scalars['String']>;
  /** 설치 시간 */
  newDataTime: Scalars['String'];
  /** OS Bit */
  osBit?: Maybe<Scalars['String']>;
  /** OS 이름 */
  osName?: Maybe<Scalars['String']>;
  /** 플랫폼 타입 */
  platformType?: Maybe<Scalars['Int']>;
  /** redirectSignFlag */
  redirectSignFlag: IsEnable;
  /** selfProtectFlag */
  selfProtectFlag: IsEnable;
  /** verDiffCount */
  verDiffCount?: Maybe<Scalars['Float']>;
  /** 작업 그룹 */
  workGroup: Scalars['String'];
  /** 근무 정책 */
  workingName?: Maybe<Scalars['String']>;
  /** 작업 템플릿 Idx */
  workingTemplateIdx?: Maybe<Scalars['Int']>;
};

/** 개인 근무 템플릿 */
export type PersonalTimeTemplate = {
  __typename?: 'PersonalTimeTemplate';
  /** 부서 이름 */
  departmentName?: Maybe<Scalars['String']>;
  /** 사용자 ID */
  employeeId?: Maybe<Scalars['String']>;
  /** 사용자 이름 */
  employeeName?: Maybe<Scalars['String']>;
  /** 근무 템플릿 Idx */
  timeTemplateIdx: Scalars['Int'];
};

/** 이석관리 정보 타입 */
export type PlaceFreeManagement = {
  __typename?: 'PlaceFreeManagement';
  /** 카테고리 이름 */
  categoryName: Scalars['String'];
  /** 수정된 카테고리 이름 */
  categoryNameModify?: Maybe<Scalars['String']>;
  /** 부서 이름 */
  departName: Scalars['String'];
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 이석 종료 날짜 및 시간 */
  endDateTime: Scalars['String'];
  /** 수정된 이석 종료 날짜 및 시간 */
  endDateTimeModify?: Maybe<Scalars['String']>;
  /** 이석관리 테이블 Idx */
  leftSeatLogIdx: Scalars['String'];
  /** 이석 참여자 */
  logAttendee: Scalars['String'];
  /** 수정된 이석 참여자 */
  logAttendeeModify?: Maybe<Scalars['String']>;
  /** 이석 사유 */
  logDesc: Scalars['String'];
  /** 수정된 이석 사유 */
  logDescModify?: Maybe<Scalars['String']>;
  /** 이석 장소 */
  logPlace: Scalars['String'];
  /** 수정된 이석 장소 */
  logPlaceModify?: Maybe<Scalars['String']>;
  /** 사용자 이름 */
  name: Scalars['String'];
  /** 이석 시작 날짜 및 시간 */
  startDateTime: Scalars['String'];
  /** 수정된 이석 시작 날짜 및 시간 */
  startDateTimeModify?: Maybe<Scalars['String']>;
};

/** 이석관리 정보 폼 타입 */
export type PlaceFreeManagementForm = {
  __typename?: 'PlaceFreeManagementForm';
  /** 카테고리 ID */
  categoryId: Scalars['Float'];
  /** 이석 종료 날짜 및 시간 */
  endDateTime: Scalars['String'];
  /** 이석관리 테이블 Idx */
  leftSeatLogIdx: Scalars['String'];
  /** 이석 참여자 */
  logAttendee?: Maybe<Scalars['String']>;
  /** 이석 사유 */
  logDesc?: Maybe<Scalars['String']>;
  /** 이석 장소 */
  logPlace: Scalars['String'];
  /** 이석 시작 날짜 및 시간 */
  startDateTime: Scalars['String'];
};

/** 이석관리정보 타입 정보 */
export type PlaceFreeManagementFormType = {
  __typename?: 'PlaceFreeManagementFormType';
  categoryId: Scalars['Float'];
  categoryName: Scalars['String'];
};

/** 이석 관리 정보 폼 데이터 요청 결과 */
export type PlaceFreeManagementOutput = {
  __typename?: 'PlaceFreeManagementOutput';
  error?: Maybe<Scalars['String']>;
  /** 이석 관리 정보 폼 데이터 */
  formData?: Maybe<PlaceFreeManagementForm>;
  /** 이석관리 폼 타입 목록 */
  listOfFormType?: Maybe<Array<PlaceFreeManagementFormType>>;
  ok: Scalars['Boolean'];
};

/** 결재관리 - 처리및조회 정보 데이터 타입 */
export type ProcessingInquiry = {
  __typename?: 'ProcessingInquiry';
  /** 결재 문서 idx */
  approvalRequestIdx: Scalars['String'];
  /** 근무 카테고리 ID */
  categoryId: Scalars['Float'];
  /** 결재처리 사유 */
  comment?: Maybe<Scalars['String']>;
  /** 결재 대기자 */
  currentApproverName?: Maybe<Scalars['String']>;
  /** 부서 ID */
  departmentId: Scalars['String'];
  /** 부서명 */
  departmentName?: Maybe<Scalars['String']>;
  /** 요청된 사용자 ID */
  employeeIdRequest: Scalars['String'];
  /** 신청사유 */
  extendDescription: Scalars['String'];
  /** 신청 종료일 */
  extendEndDate?: Maybe<Scalars['String']>;
  /** 신청 종료시간 */
  extendEndDateTime: Scalars['String'];
  /** 신청 시작일 */
  extendStartDate?: Maybe<Scalars['String']>;
  /** 신청 시작시간 */
  extendStartDateTime: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  oesExtendEndDate?: Maybe<Scalars['String']>;
  oesExtendEndDateTime?: Maybe<Scalars['String']>;
  oesExtendStartDate?: Maybe<Scalars['String']>;
  oesExtendStartDateTime?: Maybe<Scalars['String']>;
  /** 결재 상태 값 */
  originResultValue?: Maybe<Scalars['Float']>;
  overTimeCategoryTimeCalcType?: Maybe<Scalars['Float']>;
  /** 연장근무 카테고리 이름 */
  overtimeCategoryName?: Maybe<Scalars['String']>;
  /** 결재 일시 */
  resultDateTime?: Maybe<Scalars['String']>;
  /** 결재비고 */
  resultDescription?: Maybe<Scalars['String']>;
  /** 결재 상태 */
  resultValue?: Maybe<Scalars['String']>;
  /** 작성일 */
  signUpDateTime?: Maybe<Scalars['String']>;
};

/** 처리 및 조회 검색 타입 */
export enum ProcessingInquirySearchType {
  EmployeeId = 'EMPLOYEE_ID',
  Name = 'NAME'
}

/** 근무 템플릿(공용) */
export type PublicTimeTemplate = {
  __typename?: 'PublicTimeTemplate';
  /** 템플릿 이름 */
  timeName?: Maybe<Scalars['String']>;
  /** 근무 템플릿 Idx */
  timeTemplateIdx: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  confirmIsApprovalPassword: ConfirmIsApprovalPasswordOutput;
  countAllListOfApproval: CountAllListOfApprovalOutput;
  countListEmployeeAppliedToTheTemplate: CountListOfEmployeeAppliedToTheTemplateOutput;
  countListOfApproval: CountListOfApprovalOutput;
  countListOfApprovalTemporaryStorage: CountListOfApprovalTemporaryStorageOutput;
  countListOfChatRoom: CountListOfChatRoomOutput;
  countListOfCommuteGpsInfo: CountListOfCommuteGpsInfoOutput;
  countListOfEmployeeCommuteManagement: CountListOfEmployeeCommuteManagementOutput;
  countListOfEmployeeInformation: CountListOfEmployeeInformationOutput;
  countListOfPcInformation: CountListOfPcInformationOutput;
  countListOfPlaceFreeManagement: CountListOfPlaceFreeManagementOutput;
  countListOfProcessingInquiry: CountListOfProcessingInquiryOutput;
  countListOfRequestInquiry: CountListOfRequestInquiryOutput;
  countListOfWorkManagement: CountListOfWorkManagementOutput;
  countStatisticsInfo: CountStatisticsInfoOutput;
  countUserMonthlyWorkPlan: CountUserMonthlyWorkPlanOutput;
  countlistOfApprovalNotificationDepartment: CountListOfApprovalNotificationDepartmentOutput;
  dashBoardExcel: DashBoardExcelExcelOutput;
  downloadAnnualHistoryAsExcel: DownloadAnnualHistoryAsExcelOutput;
  downloadExcelListOfReservationDetailInformation: DownloadExcelListOfReservationDetailInformationOutput;
  downloadListOfEmployeeAppliedToTheTemplateExcel: DownloadListOfEmployeeAppliedToTheTemplateExcelOutput;
  downloadListOfEmployeeCommuteManagementExcel: DownloadListOfEmployeeCommuteManagementExcelOutput;
  downloadListOfEmployeeInformationExcel: DownloadListOfEmployeeInformationExcelOutput;
  downloadListOfPcInformationExcel: DownloadListOfPcInformationExcelOutput;
  downloadListOfProcessingInquiryToExcel: DownloadListOfProcessingInquiryToExcelOutput;
  downloadListOfRequestInquiryToExcel: DownloadListOfRequestInquiryToExcelOutput;
  downloadListOfReservationInformation: DownloadExcelListOfReservationInformationOutput;
  downloadListOfWorkManagementExcel: DownloadListOfWorkManagementExcelOutput;
  downloadListOfePlaceFreeManagementExcel: DownloadListOfPlaceFreeManagementExcelOutput;
  downloadStatisticsToExcel: DownloadStatisticsToExcelOutput;
  downloadVehicleDrivingInformationExcel: DownloadVehicleDrivingInformationExcelOutput;
  downloadVehicleInformationExcel: DownloadVehicleInformationExcelOutput;
  downloadlistOfApprovalNotificationDepartmentExcel: DownloadListOfApprovalNotificationDepartmentExcelOutput;
  getAbsenceManagementInfo: GetAbsenceManagementInfoOutput;
  getAdditionalFieldInApprovalForm: GetAdditionalFieldApprovalFormOutput;
  getAlarmEventStatus: GetAlarmEventStatusOutput;
  getAllEmployeeInformationInDepartment: GetEmployeeInformationOutput;
  getAllEmployeeInformationInDepartmentForAllEmployees: GetEmployeeInformationOutput;
  getAnnualEnvInfo: GetAnnualEnvInfoOutput;
  getAnnualHistory: GetAnnualHistoryOutput;
  getAnnualStatusList: GetAnnualStatusListOutput;
  getAnnualStatusListCount: GetAnnualStatusListCountOutput;
  getAnnualStatusListExcel: GetAnnualStatusListExcelOutput;
  getApprovalAdminSetting: GetApprovalAdminSettingOutput;
  getApprovalFormDetail: GetApprovalFormDetailOutput;
  getApprovalPasswordAndSignSettingInfo: GetApprovalPasswordAndSignSettingInfoOutput;
  getApprovalStatusList: GetApprovalStatusListOutput;
  getApprovalStatusListCount: GetApprovalStatusListCountOutput;
  getApprovalStatusListExcel: GetApprovalStatusListExcelOutput;
  getApprovalTemporaryStorageDetailInformation: GetApprovalTemporaryStorageDetailInformationOutput;
  getAttendanceDetailsList: GetAttendanceDetailsListOutput;
  getAttendanceDetailsListExcel: GetAttendanceDetailsListExcelOutput;
  getAutonomyWorkingHourInfo: GetAutonomyWorkingHourInfoOutput;
  getBasicUserInfo: GetBasicUserInfoOutput;
  getChatServerIp: GetChatServerIpOutput;
  getCommuteGpsAreaUseType: GetCommuteGpsAreaUseTypeOutput;
  getCommuteRecordInfo: GetCommuteRecordInfoOutput;
  getCompanyList: GetCompanyListOutput;
  getControlAlert: GetControlAlertOutput;
  getControlNotificationCategory: GetControlNotificationCategoryOutput;
  getCountStatisticsCondition: GetCountStatisticsConditionOutput;
  getDashBoard: GetDashBoardOutput;
  getDashBoardTotal: GetDashBoardTotalOutput;
  getEmployeeInformation: GetEmployeeInformationOutput;
  getEtcOperatingHourList: GetEtcOperatingHourListOutput;
  getEtcOperatingHours: GetEtcOperatingHoursOutput;
  getFlexibleWorkingHourInfo: GetFlexibleWorkingHourInfoOutput;
  getGroups: GetGroupOutput;
  getGroupsForAll: GetGroupOutput;
  getInfoLessThanAYear: GetInfoLessThanAYearOutput;
  getInfoMoreThanAYear: GetInfoMoreThanAYearOutput;
  getInfoNextYearOfEmployment: GetInfoNextYearOfEmploymentOutput;
  getListAllWorkingTemplate: GetListAllWorkingTemplateOutput;
  getListOfAlarm: GetListOfAlarmOutput;
  getListOfApproval: ListOfApprovalOutput;
  getListOfApprovalBasicLineUser: GetListOfApprovalBasicLineUserOutput;
  getListOfApprovalForm: GetListOtApprovalFormOutput;
  getListOfApprovalHistory: ApprovalHistoryOutput;
  getListOfApprovalLine: GetListOfApprovalLineOutput;
  getListOfApprovalLineForm: GetListOfApprovalLineFormOutput;
  getListOfApprovalNotificationScheduleByTemplate: GetListOfApprovalNotificationScheduleByTemplateOutput;
  getListOfApprovalNotificationTemplate: GetListOfApprovalNotificationTemplateOutput;
  getListOfApprovalTemplate: GetListOfApprovalTemplateOutput;
  getListOfApprovalTemporaryStorage: GetListOfApprovalTemporaryStorageOutput;
  getListOfChatImage: GetListOfChatImageOutput;
  getListOfChatMessage: GetListOfChatMessageOutput;
  getListOfChatRoom: GetListOfChatRoomOutput;
  getListOfCommuteGpsInfo: GetListOfCommuteGpsInfoOutput;
  getListOfDepartmentForChat: GetListOfDepartmentForChatOutput;
  getListOfOtTimeTypeCategory: GetListOfOtTImeTypeCategoryOutput;
  getListOfOtTimeTypeCategoryForVehicle: GetListOfOtTimeTypeCategoryForVehicleOutput;
  getListOfProcessingInquiry: GetListOfProcessingInquiryOutput;
  getListOfRequestInquiry: GetListOfRequestInquiryOutput;
  getListOfReservationCategory: GetListOfReservationCategoryOutput;
  getListOfReservationDetailInformation: GetListOfReservationDetailInformationOutput;
  getListOfReservationInformation: GetListOfReservationInformationOutput;
  getListOfReservationType: GetListOfReservationTypeOutput;
  getListOfSearchedApprovalLineUser: GetListOfSearchedApprovalLineUserOutput;
  getListOfTemplateScheduleInfo: GetListOfTemplateScheduleInfoOutput;
  getListOfTimeTypeCategory: GetListOfTimeTypeCategoryOutput;
  getListOfVehicleDrivingInformation: GetListOfVehicleDrivingInformationOutput;
  getListOfVehicleInformation: GetListOfVehicleInformationOutput;
  getMainTokenInfo: GetMainTokenInfoOutput;
  getMobilWebViewInfo: GetMobilWebViewInfoOutput;
  getPcInformation: GetPcInformationOutput;
  getPersonalTimeTemplate: GetPersonalTimeTemplateOutput;
  getPublicTimeTemplate: GetPublicTimeTemplateOutput;
  getRecognitionWorkTime: GetRecognitionWorkTimeOutput;
  getSelfDeterminedDayGraph: GetSelfDeterminedDayGraphOutput;
  getSelfDeterminedWHGraph: GetSelfDeterminedWhGraphOutput;
  getSelfDeterminedWorkHourInfo: GetSelfDeterminedWorkInfoOutput;
  getSelfDeterminedWorkTemplateHours: GetSelfDeterminedWorkTemplateHoursOutput;
  getStandardWTBasicInfo: GetStandardWtBasicInfoOutput;
  getStatisticsConditionExcel: GetStatisticsConditionExcelOutput;
  getStatisticsConditionList: GetStatisticsConditionListOutput;
  getSubTokenInfo: GetSubTokenInfoOutput;
  getTotalListOfReservationDetailInformation: GetTotalListOfReservationDetailInformationOutput;
  getTotalListOfReservationType: GetToTalListOfReservationTypeOutput;
  getTotalNumberOfVehicleDrivingInformation: GetTotalNumberOfVehicleDrivingInformationOutput;
  getTotalNumberOfVehicleInformation: GetTotalNumberOfVehicleInformationOutput;
  getUser: GetUserOutput;
  getUserAnnualInfo: GetUserAnnualInfoOutput;
  getUserDailyWorkPlan: GetUserDailyWorkPlanOutput;
  getUserDailyWorkPlanCount: GetUserDailyWorkPlanCountOutput;
  getUserDailyWorkPlanExcel: GetUserDailyWorkPlanExcelOutput;
  getUserMonthlyWorkExcelPlan: GetUserMonthlyWorkExcelPlanOutput;
  getUserMonthlyWorkPlan: GetUserMonthlyWorkPlanOutput;
  getUserPolicyBasicInfo: GetUserPolicyBasicInfoOutput;
  getUserPolicySelectedInfo: GetUserPolicySelectedInfoOutput;
  getWTBasicInfo: GetWtBasicInfoOutput;
  getWTDayGraph: GetDayGraphOutput;
  getWTGraph: GetWtGraphOutput;
  getWTIFromEmployee: GetWtfFromEmployeeOutput;
  getWidgetSettingInfo: GetWidgetSettingInfoOutput;
  getWorkingTimeList: GetWorkingTimeListOutput;
  isActiveApprovalPassword: IsActiveApprovalPasswordOutput;
  listOfApprovalNotificationDepartment: ListOfApprovalNotificationDepartmentOutput;
  listOfEmployeeAppliedToTheTemplate: ListOfEmployeesAppliedToTheTemplateOutput;
  listOfEmployeeCommuteInformation: ListOfEmployeeCommuteManagementOutput;
  listOfPlaceFreeManagement: ListOfPlaceFreeManagementOutput;
  listOfWorkManagement: ListOfWorkManagementOutput;
  placeFreeManagementForm: PlaceFreeManagementOutput;
  statisticsInfo: StatisticsInfoOutput;
};


export type QueryConfirmIsApprovalPasswordArgs = {
  password: Scalars['String'];
};


export type QueryCountAllListOfApprovalArgs = {
  employeeId?: InputMaybe<Scalars['String']>;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryCountListEmployeeAppliedToTheTemplateArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  searchType?: InputMaybe<Type_Of_Employee_Applied_To_The_Template>;
  searchValue?: InputMaybe<Scalars['String']>;
  templateIdx: Scalars['Float'];
};


export type QueryCountListOfApprovalArgs = {
  approvalType: Approval_For_List_Type;
  customEmployeeId?: InputMaybe<Scalars['String']>;
  searchType: ApprovalSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryCountListOfApprovalTemporaryStorageArgs = {
  searchType: ApprovalSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryCountListOfChatRoomArgs = {
  employeeId: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryCountListOfCommuteGpsInfoArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryCountListOfEmployeeCommuteManagementArgs = {
  endDate: Scalars['String'];
  listOfEmployeeId: Array<Scalars['String']>;
  startDate: Scalars['String'];
};


export type QueryCountListOfEmployeeInformationArgs = {
  searchType?: InputMaybe<Type_Of_Employee_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  selectedDepartmentId: Scalars['String'];
};


export type QueryCountListOfPcInformationArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  listOfSelectedUserId?: InputMaybe<Array<Scalars['String']>>;
  searchType?: InputMaybe<Type_Of_Pc_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  selectedDepartmentId: Scalars['String'];
};


export type QueryCountListOfPlaceFreeManagementArgs = {
  endDate: Scalars['String'];
  listOfEmployeeId: Array<Scalars['String']>;
  startDate: Scalars['String'];
};


export type QueryCountListOfProcessingInquiryArgs = {
  categoryId: Scalars['String'];
  endDateToSearch: Scalars['String'];
  originResultValue: Scalars['String'];
  searchType: ProcessingInquirySearchType;
  searchValue: Scalars['String'];
  startDateToSearch: Scalars['String'];
};


export type QueryCountListOfRequestInquiryArgs = {
  categoryId: Scalars['String'];
  endDateToSearch: Scalars['String'];
  resultValue: Scalars['String'];
  startDateToSearch: Scalars['String'];
};


export type QueryCountListOfWorkManagementArgs = {
  employeeIds: Array<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryCountStatisticsInfoArgs = {
  queryContent: QueryContent;
};


export type QueryCountUserMonthlyWorkPlanArgs = {
  employeeIdList: Array<Scalars['String']>;
  endDate: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryCountlistOfApprovalNotificationDepartmentArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  searchType?: InputMaybe<Type_Of_Approval_Notification_Department>;
  searchValue?: InputMaybe<Scalars['String']>;
  templateIdx: Scalars['Int'];
};


export type QueryDashBoardExcelArgs = {
  date?: InputMaybe<Scalars['String']>;
  searchType?: InputMaybe<Search_Db_Type_For_Dash_Board>;
  searchValue?: InputMaybe<Scalars['String']>;
  searchValueForTime?: InputMaybe<Search_Date_Type_For_Dash_Board>;
  searchValueForTruthyFalsy?: InputMaybe<Is_Truthy_Falsy>;
};


export type QueryDownloadAnnualHistoryAsExcelArgs = {
  applyYear: Scalars['String'];
  employeeId: Scalars['String'];
};


export type QueryDownloadExcelListOfReservationDetailInformationArgs = {
  categoryIdx: Scalars['Int'];
  searchType?: InputMaybe<Search_Type_For_Reservation_Detail_Information_Type>;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryDownloadListOfEmployeeAppliedToTheTemplateExcelArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  searchType?: InputMaybe<Type_Of_Employee_Applied_To_The_Template>;
  searchValue?: InputMaybe<Scalars['String']>;
  templateIdx: Scalars['Float'];
};


export type QueryDownloadListOfEmployeeCommuteManagementExcelArgs = {
  endDate: Scalars['String'];
  listOfEmployeeId: Array<Scalars['String']>;
  startDate: Scalars['String'];
};


export type QueryDownloadListOfEmployeeInformationExcelArgs = {
  searchType?: InputMaybe<Type_Of_Employee_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  selectedDepartmentId: Scalars['String'];
};


export type QueryDownloadListOfPcInformationExcelArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  listOfSelectedUserId?: InputMaybe<Array<Scalars['String']>>;
  searchType?: InputMaybe<Type_Of_Pc_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  selectedDepartmentId: Scalars['String'];
};


export type QueryDownloadListOfProcessingInquiryToExcelArgs = {
  categoryId: Scalars['String'];
  endDateToSearch: Scalars['String'];
  originResultValue: Scalars['String'];
  searchType: ProcessingInquirySearchType;
  searchValue: Scalars['String'];
  startDateToSearch: Scalars['String'];
};


export type QueryDownloadListOfRequestInquiryToExcelArgs = {
  categoryId: Scalars['String'];
  endDateToSearch: Scalars['String'];
  resultValue: Scalars['String'];
  startDateToSearch: Scalars['String'];
};


export type QueryDownloadListOfReservationInformationArgs = {
  searchType?: InputMaybe<Search_Type_For_Reservation_Information_Type>;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryDownloadListOfWorkManagementExcelArgs = {
  employeeIds: Array<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryDownloadListOfePlaceFreeManagementExcelArgs = {
  endDate: Scalars['String'];
  listOfEmployeeId: Array<Scalars['String']>;
  startDate: Scalars['String'];
};


export type QueryDownloadStatisticsToExcelArgs = {
  queryContent: QueryContent;
};


export type QueryDownloadVehicleDrivingInformationExcelArgs = {
  searchType?: InputMaybe<Search_Type_For_Vehicle_Driving_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  vehicleNumber: Scalars['String'];
};


export type QueryDownloadVehicleInformationExcelArgs = {
  searchType?: InputMaybe<Search_Type_For_Vehicle_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryDownloadlistOfApprovalNotificationDepartmentExcelArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  searchType?: InputMaybe<Type_Of_Approval_Notification_Department>;
  searchValue?: InputMaybe<Scalars['String']>;
  templateIdx: Scalars['Int'];
};


export type QueryGetAbsenceManagementInfoArgs = {
  workingTemplateIdx: Scalars['Int'];
};


export type QueryGetAdditionalFieldInApprovalFormArgs = {
  categoryId: Scalars['Float'];
  employeeId: Scalars['String'];
  year: Scalars['String'];
};


export type QueryGetAllEmployeeInformationInDepartmentArgs = {
  selectedDepartmentId: Scalars['String'];
};


export type QueryGetAllEmployeeInformationInDepartmentForAllEmployeesArgs = {
  selectedDepartmentId: Scalars['String'];
};


export type QueryGetAnnualEnvInfoArgs = {
  companyCode: Scalars['String'];
};


export type QueryGetAnnualHistoryArgs = {
  applyYear: Scalars['String'];
  employeeId: Scalars['String'];
};


export type QueryGetAnnualStatusListArgs = {
  employeeIdList: Array<Scalars['String']>;
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchYear: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetAnnualStatusListCountArgs = {
  employeeIdList: Array<Scalars['String']>;
  searchYear: Scalars['String'];
};


export type QueryGetAnnualStatusListExcelArgs = {
  employeeIdList: Array<Scalars['String']>;
  searchYear: Scalars['String'];
};


export type QueryGetApprovalFormDetailArgs = {
  approvalRequestIdx: Scalars['Int'];
  employeeId: Scalars['String'];
};


export type QueryGetApprovalPasswordAndSignSettingInfoArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetApprovalStatusListArgs = {
  employeeIdList: Array<Scalars['String']>;
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchEndDate: Scalars['String'];
  searchStartDate: Scalars['String'];
  searchType: IApprovalStatusSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetApprovalStatusListCountArgs = {
  employeeIdList: Array<Scalars['String']>;
  searchEndDate: Scalars['String'];
  searchStartDate: Scalars['String'];
  searchType: IApprovalStatusSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryGetApprovalStatusListExcelArgs = {
  employeeIdList: Array<Scalars['String']>;
  searchEndDate: Scalars['String'];
  searchStartDate: Scalars['String'];
  searchType: IApprovalStatusSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryGetApprovalTemporaryStorageDetailInformationArgs = {
  approvalRequestIdx: Scalars['Int'];
  employeeId: Scalars['String'];
};


export type QueryGetAttendanceDetailsListArgs = {
  employeeId?: InputMaybe<Scalars['String']>;
  fieldSort?: InputMaybe<FieldSort>;
  listOfDepartmentId: Array<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetAttendanceDetailsListExcelArgs = {
  employeeId?: InputMaybe<Scalars['String']>;
  fieldSort?: InputMaybe<FieldSort>;
  listOfDepartmentId: Array<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetAutonomyWorkingHourInfoArgs = {
  workingTemplateIdx: Scalars['Int'];
};


export type QueryGetBasicUserInfoArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetCommuteRecordInfoArgs = {
  workingTemplateIdx: Scalars['Int'];
};


export type QueryGetControlAlertArgs = {
  workingTemplateIdx: Scalars['Int'];
};


export type QueryGetCountStatisticsConditionArgs = {
  employeeId?: InputMaybe<Scalars['String']>;
  listOfDepartmentId: Array<Scalars['String']>;
  startDate: Scalars['String'];
};


export type QueryGetDashBoardArgs = {
  date?: InputMaybe<Scalars['String']>;
  departmentId: Scalars['String'];
  employeeId: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Search_Db_Type_For_Dash_Board>;
  searchValue?: InputMaybe<Scalars['String']>;
  searchValueForTime?: InputMaybe<Search_Date_Type_For_Dash_Board>;
  searchValueForTruthyFalsy?: InputMaybe<Is_Truthy_Falsy>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetDashBoardTotalArgs = {
  date?: InputMaybe<Scalars['String']>;
  departmentId: Scalars['String'];
  employeeId: Scalars['String'];
  searchType?: InputMaybe<Search_Db_Type_For_Dash_Board>;
  searchValue?: InputMaybe<Scalars['String']>;
  searchValueForTime?: InputMaybe<Search_Date_Type_For_Dash_Board>;
  searchValueForTruthyFalsy?: InputMaybe<Is_Truthy_Falsy>;
};


export type QueryGetEmployeeInformationArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Type_Of_Employee_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  selectedDepartmentId: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetEtcOperatingHoursArgs = {
  workingTemplateIdx: Scalars['Int'];
};


export type QueryGetFlexibleWorkingHourInfoArgs = {
  workingTemplateIdx: Scalars['Int'];
};


export type QueryGetGroupsArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetInfoLessThanAYearArgs = {
  companyCode: Scalars['String'];
};


export type QueryGetInfoMoreThanAYearArgs = {
  companyCode: Scalars['String'];
};


export type QueryGetInfoNextYearOfEmploymentArgs = {
  companyCode: Scalars['String'];
};


export type QueryGetListAllWorkingTemplateArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  searchType?: InputMaybe<Wt_Search_Type>;
  searchValue: Scalars['String'];
};


export type QueryGetListOfApprovalArgs = {
  approvalType: Approval_For_List_Type;
  customEmployeeId?: InputMaybe<Scalars['String']>;
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType: ApprovalSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfApprovalBasicLineUserArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetListOfApprovalHistoryArgs = {
  approvalRequestIdx: Scalars['Int'];
};


export type QueryGetListOfApprovalLineArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetListOfApprovalLineFormArgs = {
  employeeId: Scalars['String'];
  formTemplateIdx: Scalars['Int'];
};


export type QueryGetListOfApprovalNotificationScheduleByTemplateArgs = {
  templateIdx: Scalars['Int'];
};


export type QueryGetListOfApprovalTemplateArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetListOfApprovalTemporaryStorageArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType: ApprovalSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfChatImageArgs = {
  chatroom_idx: Scalars['Int'];
  imageCount?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfChatMessageArgs = {
  chatRoomIdx: Scalars['Int'];
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  userId: Scalars['String'];
};


export type QueryGetListOfChatRoomArgs = {
  employeeId: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfCommuteGpsInfoArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfDepartmentForChatArgs = {
  parentDepartmentId: Scalars['Int'];
};


export type QueryGetListOfOtTimeTypeCategoryArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  name?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfProcessingInquiryArgs = {
  categoryId: Scalars['String'];
  endDateToSearch: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  originResultValue: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  searchType: ProcessingInquirySearchType;
  searchValue: Scalars['String'];
  startDateToSearch: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfRequestInquiryArgs = {
  categoryId: Scalars['String'];
  endDateToSearch: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  resultValue: Scalars['String'];
  startDateToSearch: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfReservationCategoryArgs = {
  groupIdx: Scalars['Int'];
};


export type QueryGetListOfReservationDetailInformationArgs = {
  categoryIdx: Scalars['Int'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Search_Type_For_Reservation_Detail_Information_Type>;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfReservationInformationArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Search_Type_For_Reservation_Information_Type>;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetListOfSearchedApprovalLineUserArgs = {
  employeeId: Scalars['String'];
  lineIdx: Scalars['Int'];
};


export type QueryGetListOfTemplateScheduleInfoArgs = {
  timeTemplateIdx: Scalars['Int'];
};


export type QueryGetListOfVehicleDrivingInformationArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Search_Type_For_Vehicle_Driving_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
  vehicleNumber: Scalars['String'];
};


export type QueryGetListOfVehicleInformationArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Search_Type_For_Vehicle_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetMainTokenInfoArgs = {
  employeeId?: InputMaybe<Scalars['String']>;
  tokenField?: InputMaybe<Scalars['String']>;
};


export type QueryGetPcInformationArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  listOfSelectedUserId?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Type_Of_Pc_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  selectedDepartmentId: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetPersonalTimeTemplateArgs = {
  employeeId?: InputMaybe<Scalars['String']>;
};


export type QueryGetRecognitionWorkTimeArgs = {
  employeeId?: InputMaybe<Scalars['String']>;
};


export type QueryGetSelfDeterminedDayGraphArgs = {
  dayOfWeek: Day_Type;
  workingTemplateIdx: Scalars['Int'];
  workingTimeName: Scalars['String'];
};


export type QueryGetSelfDeterminedWhGraphArgs = {
  workingTemplateIdx: Scalars['Int'];
  workingTimeName: Scalars['String'];
};


export type QueryGetSelfDeterminedWorkHourInfoArgs = {
  workingTemplateIdx: Scalars['Int'];
};


export type QueryGetSelfDeterminedWorkTemplateHoursArgs = {
  workingTemplateIdx: Scalars['Int'];
  workingTimeName: Scalars['String'];
};


export type QueryGetStandardWtBasicInfoArgs = {
  workingTemplateIdx: Scalars['Int'];
};


export type QueryGetStatisticsConditionExcelArgs = {
  employeeId?: InputMaybe<Scalars['String']>;
  listOfDepartmentId: Array<Scalars['String']>;
  startDate: Scalars['String'];
};


export type QueryGetStatisticsConditionListArgs = {
  employeeId?: InputMaybe<Scalars['String']>;
  fieldSort?: InputMaybe<FieldSort>;
  listOfDepartmentId: Array<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetSubTokenInfoArgs = {
  tokenFiled: Scalars['String'];
};


export type QueryGetTotalListOfReservationDetailInformationArgs = {
  categoryIdx: Scalars['Int'];
  searchType?: InputMaybe<Search_Type_For_Reservation_Detail_Information_Type>;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryGetTotalListOfReservationTypeArgs = {
  searchType?: InputMaybe<Search_Type_For_Reservation_Information_Type>;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryGetTotalNumberOfVehicleDrivingInformationArgs = {
  searchType?: InputMaybe<Search_Type_For_Vehicle_Driving_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  vehicleNumber: Scalars['String'];
};


export type QueryGetTotalNumberOfVehicleInformationArgs = {
  searchType?: InputMaybe<Search_Type_For_Vehicle_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserArgs = {
  departmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  like?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserAnnualInfoArgs = {
  annualYear: Scalars['String'];
  employeeId: Scalars['String'];
};


export type QueryGetUserDailyWorkPlanArgs = {
  employeeIdList: Array<Scalars['String']>;
  endDate: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetUserDailyWorkPlanCountArgs = {
  employeeIdList: Array<Scalars['String']>;
  endDate: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetUserDailyWorkPlanExcelArgs = {
  employeeIdList: Array<Scalars['String']>;
  endDate: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetUserMonthlyWorkExcelPlanArgs = {
  employeeIdList: Array<Scalars['String']>;
  endDate: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetUserMonthlyWorkPlanArgs = {
  employeeIdList: Array<Scalars['String']>;
  endDate: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryGetUserPolicyBasicInfoArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetUserPolicySelectedInfoArgs = {
  employeeId: Scalars['String'];
  workingTimeName?: InputMaybe<Scalars['String']>;
};


export type QueryGetWtBasicInfoArgs = {
  workingTemplateIdx: Scalars['Int'];
};


export type QueryGetWtDayGraphArgs = {
  dayOfWeek: Day_Type;
  workWeekSelect?: InputMaybe<Scalars['Int']>;
  workingTemplateIdx: Scalars['Int'];
};


export type QueryGetWtGraphArgs = {
  workingTemplateIdx: Scalars['Int'];
};


export type QueryGetWtiFromEmployeeArgs = {
  employeeId: Scalars['String'];
};


export type QueryGetWidgetSettingInfoArgs = {
  workingTemplateIdx: Scalars['Int'];
};


export type QueryListOfApprovalNotificationDepartmentArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Type_Of_Approval_Notification_Department>;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
  templateIdx: Scalars['Int'];
};


export type QueryListOfEmployeeAppliedToTheTemplateArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  searchType?: InputMaybe<Type_Of_Employee_Applied_To_The_Template>;
  searchValue?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Float']>;
  templateIdx: Scalars['Float'];
};


export type QueryListOfEmployeeCommuteInformationArgs = {
  endDate: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  listOfEmployeeId: Array<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryListOfPlaceFreeManagementArgs = {
  endDate: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  listOfEmployeeId: Array<Scalars['String']>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryListOfWorkManagementArgs = {
  employeeIds: Array<Scalars['String']>;
  endDate: Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  take?: InputMaybe<Scalars['Float']>;
};


export type QueryPlaceFreeManagementFormArgs = {
  leftSeatLogIdx: Scalars['String'];
};


export type QueryStatisticsInfoArgs = {
  fieldSort?: InputMaybe<FieldSort>;
  page?: InputMaybe<Scalars['Float']>;
  queryContent: QueryContent;
  take?: InputMaybe<Scalars['Float']>;
};

/** QueryContent 정보 */
export type QueryContent = {
  /** 기본 쿼리 */
  base_query?: InputMaybe<Scalars['String']>;
  /** 부서 정보 */
  departments: Array<Scalars['Float']>;
  /** 상세 설명 */
  description?: InputMaybe<Scalars['String']>;
  /** 사용자 정보 */
  employees: Array<Scalars['String']>;
  /** 종료 날짜 */
  end_date?: InputMaybe<Scalars['String']>;
  /** Index */
  index: Scalars['Float'];
  /** 시작 날짜 */
  start_date?: InputMaybe<Scalars['String']>;
  /** 쿼리 이름 */
  title?: InputMaybe<Scalars['String']>;
};

/** 결재 처리를 위한 값 */
export enum Rejected_Or_Approved_Or_Others {
  Approved = 'APPROVED',
  Reject = 'REJECT',
  RejectedByOthers = 'REJECTED_BY_OTHERS'
}

/** 인정근무시간 정보 */
export type RecognitionWorkTimeEntity = {
  __typename?: 'RecognitionWorkTimeEntity';
  /** 실제 연장근무시간 */
  realextmin?: Maybe<Scalars['String']>;
  /** 실제 근무시간 */
  realworkmin?: Maybe<Scalars['String']>;
};

/** 결재 반려 요청 결과 */
export type RejectApprovalOutput = {
  __typename?: 'RejectApprovalOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 신청 요청 결과 */
export type RequestForApprovalOutput = {
  __typename?: 'RequestForApprovalOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재관리 - 처리및조회 정보 데이터 타입 */
export type RequestInquiry = {
  __typename?: 'RequestInquiry';
  /** 결재 문서 idx */
  approvalRequestIdx: Scalars['String'];
  /** 근무 카테고리 ID */
  categoryId: Scalars['Float'];
  /** 결재처리 사유 */
  comment?: Maybe<Scalars['String']>;
  /** 결재 대기자 */
  currentApproverName?: Maybe<Scalars['String']>;
  /** 부서 ID */
  departmentId: Scalars['String'];
  /** 부서명 */
  departmentName?: Maybe<Scalars['String']>;
  /** 요청된 사용자 ID */
  employeeIdRequest: Scalars['String'];
  /** 신청사유 */
  extendDescription: Scalars['String'];
  /** 신청 종료일 */
  extendEndDate?: Maybe<Scalars['String']>;
  /** 신청 종료시간 */
  extendEndDateTime: Scalars['String'];
  /** 신청 시작일 */
  extendStartDate?: Maybe<Scalars['String']>;
  /** 신청 시작시간 */
  extendStartDateTime: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  oesExtendEndDate?: Maybe<Scalars['String']>;
  oesExtendEndDateTime?: Maybe<Scalars['String']>;
  oesExtendStartDate?: Maybe<Scalars['String']>;
  oesExtendStartDateTime?: Maybe<Scalars['String']>;
  /** 결재 상태 값 */
  originResultValue?: Maybe<Scalars['Float']>;
  overTimeCategoryTimeCalcType?: Maybe<Scalars['Float']>;
  /** 연장근무 카테고리 이름 */
  overtimeCategoryName?: Maybe<Scalars['String']>;
  /** 결재 일시 */
  resultDateTime?: Maybe<Scalars['String']>;
  /** 결재비고 */
  resultDescription?: Maybe<Scalars['String']>;
  /** 결재 상태 */
  resultValue?: Maybe<Scalars['String']>;
  /** 작성일 */
  signUpDateTime?: Maybe<Scalars['String']>;
};

/** 예약 목록 entity */
export type ReservationCategoryEntity = {
  __typename?: 'ReservationCategoryEntity';
  /** 예약목록 상세 설명 */
  categoryDescription: Scalars['String'];
  /** 예약목록 IDX */
  categoryIdx: Scalars['Int'];
  /** 예약 목록 이름 */
  categoryName: Scalars['String'];
  /** 예약그룹 IDX */
  groupIdx: Scalars['Int'];
};

/** 예약상세정보 */
export type ReservationDetailInformationEntity = {
  __typename?: 'ReservationDetailInformationEntity';
  /** 예약목록 이름 */
  categoryName?: Maybe<Scalars['String']>;
  /** 상세설명 */
  description?: Maybe<Scalars['String']>;
  /** 예약종료일 */
  endDate?: Maybe<Scalars['String']>;
  /** 예약종료시간 */
  endTime?: Maybe<Scalars['String']>;
  /** 예약그룹 이름 */
  groupName?: Maybe<Scalars['String']>;
  /** 신청 IDX */
  infoIdx: Scalars['Int'];
  /** 신청사원이름 */
  name?: Maybe<Scalars['String']>;
  /** 신청사번 */
  requestEmployeeId?: Maybe<Scalars['String']>;
  /** 예약명/목적 */
  reserveTitle?: Maybe<Scalars['String']>;
  /** 예약시작일 */
  startDate?: Maybe<Scalars['String']>;
  /** 예약시작시간 */
  startTime?: Maybe<Scalars['String']>;
};

/** 예약 정보 */
export type ReservationInformationEntity = {
  __typename?: 'ReservationInformationEntity';
  /** 카테고리 상세설명 */
  categoryDescription?: Maybe<Scalars['String']>;
  /** 카테고리 번호 */
  categoryIdx: Scalars['Int'];
  /** 카테고리 이름 */
  categoryName: Scalars['String'];
  /** 그룹 IDX */
  groupIdx: Scalars['Int'];
  /** 그룹 이름 */
  groupName: Scalars['String'];
  /** 부모그룹 IDX */
  parentGroupIdx: Scalars['Int'];
};

/** 예약 종류 */
export type ReservationTypeEntity = {
  __typename?: 'ReservationTypeEntity';
  /** 그룹 IDX */
  groupIdx: Scalars['Int'];
  /** 그룹 이름 */
  groupName: Scalars['String'];
  /** 부모그룹 IDX */
  parentGroupIdx: Scalars['Int'];
};

/** DashBoard 시간에 따른 검색을 위한 타입 */
export enum Search_Date_Type_For_Dash_Board {
  LessThan_6Hours = 'LESS_THAN_6_HOURS',
  LessThan_34Hours = 'LESS_THAN_34_HOURS',
  LessThan_40Hours = 'LESS_THAN_40_HOURS',
  MoreThan_6HoursLessThan_8Hours = 'MORE_THAN_6_HOURS_LESS_THAN_8_HOURS',
  MoreThan_8HoursLessThan_10Hours = 'MORE_THAN_8_HOURS_LESS_THAN_10_HOURS',
  MoreThan_10HoursLessThan_12Hours = 'MORE_THAN_10_HOURS_LESS_THAN_12_HOURS',
  MoreThan_12Hours = 'MORE_THAN_12_HOURS',
  MoreThan_34HoursLessThan_36Hours = 'MORE_THAN_34_HOURS_LESS_THAN_36_HOURS',
  MoreThan_36HoursLessThan_38Hours = 'MORE_THAN_36_HOURS_LESS_THAN_38_HOURS',
  MoreThan_38HoursLessThan_40Hours = 'MORE_THAN_38_HOURS_LESS_THAN_40_HOURS',
  MoreThan_40Hours = 'MORE_THAN_40_HOURS',
  MoreThan_40HoursLessThan_45Hours = 'MORE_THAN_40_HOURS_LESS_THAN_45_HOURS',
  MoreThan_45HoursLessThan_50Hours = 'MORE_THAN_45_HOURS_LESS_THAN_50_HOURS',
  MoreThan_50HoursLessThan_52Hours = 'MORE_THAN_50_HOURS_LESS_THAN_52_HOURS',
  MoreThan_52Hours = 'MORE_THAN_52_HOURS'
}

/** DashBoard DB 검색을 위한 타입 */
export enum Search_Db_Type_For_Dash_Board {
  All = 'all',
  DataPerio = 'dataPerio',
  DepartName = 'departName',
  EmpId = 'empId',
  EmpName = 'empName',
  NameOne = 'nameOne',
  NameThree = 'nameThree',
  NameTwo = 'nameTwo',
  OtExtTime = 'otExtTime',
  OtExtTimeSection = 'otExtTimeSection',
  OtTotTime = 'otTotTime',
  OtTotTimeSection = 'otTotTimeSection',
  OtWorkTime = 'otWorkTime',
  OtWorkTimeSection = 'otWorkTimeSection',
  WorkOx = 'workOx'
}

/** 예약상세정보 검색 타입 */
export enum Search_Type_For_Reservation_Detail_Information_Type {
  CategoryName = 'categoryName',
  EndDate = 'endDate',
  GroupName = 'groupName',
  Name = 'name',
  StartDate = 'startDate'
}

/** 예약정보 검색 타입 */
export enum Search_Type_For_Reservation_Information_Type {
  CategoryDescription = 'categoryDescription',
  CategoryName = 'categoryName',
  GroupName = 'groupName'
}

/** 차량 주행 정보 검색을 위한 타입 */
export enum Search_Type_For_Vehicle_Driving_Information {
  EndEmployeeId = 'endEmployeeId',
  EndEmployeeName = 'endEmployeeName',
  EndGpsAddress = 'endGpsAddress',
  StartEmployeeId = 'startEmployeeId',
  StartEmployeeName = 'startEmployeeName',
  StartGpsAddress = 'startGpsAddress',
  VehicleName = 'vehicleName',
  VehicleNumber = 'vehicleNumber'
}

/** 차량 정보 검색을 위한 타입 */
export enum Search_Type_For_Vehicle_Information {
  VehicleCompany = 'vehicleCompany',
  VehicleName = 'vehicleName',
  VehicleNumber = 'vehicleNumber'
}

/** 결재 비밀번호 및 서명 설정 정보 저장 요청 결과 */
export type SaveApprovalPasswordAndSignSettingOutput = {
  __typename?: 'SaveApprovalPasswordAndSignSettingOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 통계 정보 저장 요청 결과 */
export type SaveStatisticsInfoOutput = {
  __typename?: 'SaveStatisticsInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 저장된 통계 정보 ID */
  id?: Maybe<Scalars['Float']>;
  ok: Scalars['Boolean'];
};

/** 근무제 선택정보 > 저장 (선택근무제 선택 후 저장) 요청 결과 */
export type SaveUserPolicySelectedInfOutput = {
  __typename?: 'SaveUserPolicySelectedInfOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type SelfDeterminedWeekGraphEntity = {
  __typename?: 'SelfDeterminedWeekGraphEntity';
  /** 근무 요일 유형 */
  dayOfWeek?: Maybe<Day_Type>;
  /** 총 근무 시간 */
  totHour?: Maybe<Scalars['Float']>;
};

/** 근무제[근무시간] > (표준/시차 출퇴근제 > 근무시간) 기본정보 */
export type SelfDeterminedWorkHourBasicBasicEntity = {
  __typename?: 'SelfDeterminedWorkHourBasicBasicEntity';
  /** 휴게시간설정(종료시간) */
  endBreakTime: Scalars['String'];
  /** 휴게시간설정(시작시간) */
  startBreakTime: Scalars['String'];
  /** 근무제 idx */
  workingTemplateIdx: Scalars['Int'];
};

export type SendUserMonthlyWorkInfo = {
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 수정 하는 월 */
  monthDate: Scalars['String'];
};

/** 직원 월별 근무 정보 앱 전송 결과 */
export type SendUserMonthlyWorkInfoOutput = {
  __typename?: 'SendUserMonthlyWorkInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 승인 요청 결과 */
export type SignApprovalOutput = {
  __typename?: 'SignApprovalOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재 서명 타입 */
export enum SignType {
  Image = 'IMAGE',
  Text = 'TEXT'
}

/** 컬럼 정렬 타입 */
export enum SortColumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** 표준/시차 출퇴근제 기본근무시간 설정 Entity */
export type StandardBasicInfo = {
  __typename?: 'StandardBasicInfo';
  dayOfWeek: Array<Day_Type>;
  dayOfWeekType: Day_Of_Week_Type;
  /** 휴게시간설정(종료시간) */
  endBreakTime: Scalars['String'];
  /** 퇴근시간 */
  endTime: Scalars['String'];
  /** 휴게시간설정(시작시간) */
  startBreakTime: Scalars['String'];
  /** 출근시간 */
  startTime: Scalars['String'];
  /** 근무주차 선택 */
  workWeekSelect: Array<Scalars['Int']>;
  workWeekType: Work_Week_Type;
  /** 근무제 idx */
  workingTemplateIdx?: Maybe<Scalars['Int']>;
};

/** StatisticsConditionEntity 정보 */
export type StatisticsConditionEntity = {
  __typename?: 'StatisticsConditionEntity';
  /** 부서 이름 */
  departName: Scalars['String'];
  /** 사용자 이름 */
  empName: Scalars['String'];
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 기간 */
  periodInformation: Scalars['String'];
  /** 시간외(잔여시간) */
  totExtPossibleLeaveMin?: Maybe<Scalars['String']>;
  /** 시간외(가능시간) */
  totExtPossibleMin?: Maybe<Scalars['String']>;
  /** 결근 */
  totMonAbsentDay?: Maybe<Scalars['String']>;
  /** 출장 */
  totMonBusinessTripDay?: Maybe<Scalars['String']>;
  /** 조퇴 */
  totMonEarlyDay?: Maybe<Scalars['String']>;
  /** 교육 */
  totMonEducationDay?: Maybe<Scalars['String']>;
  /** 휴직 */
  totMonFurloughDay?: Maybe<Scalars['String']>;
  /** 휴일 */
  totMonHoliDay?: Maybe<Scalars['String']>;
  /** 지각 */
  totMonLateDay?: Maybe<Scalars['String']>;
  /** 정상근무 */
  totMonNormalWorkDay?: Maybe<Scalars['String']>;
  /** 병가 */
  totMonSickDay?: Maybe<Scalars['String']>;
  /** 휴가 */
  totMonVacationDay?: Maybe<Scalars['String']>;
  /** 총근무일 */
  totMonWorkDay?: Maybe<Scalars['String']>;
  /** 소정 + 시간외(가능시간) */
  totPossibleMin?: Maybe<Scalars['String']>;
  /** 소정근무(잔여시간) */
  totWorkPossibleLeaveMin?: Maybe<Scalars['String']>;
  /** 소정근무(가능시간) */
  totWorkPossibleMin?: Maybe<Scalars['String']>;
  /** 시간외(인정시간) */
  useExtMin?: Maybe<Scalars['String']>;
  /** 소정근무(인정시간) */
  useWorkMin?: Maybe<Scalars['String']>;
  /** 근무제 이름 */
  workingTemplateName?: Maybe<Scalars['String']>;
};

/** 통계 정보 요청 결과 */
export type StatisticsInfoOutput = {
  __typename?: 'StatisticsInfoOutput';
  error?: Maybe<Scalars['String']>;
  /** 통계 정보 */
  list?: Maybe<Array<Scalars['JSONObject']>>;
  ok: Scalars['Boolean'];
};

/** 서브 토큰 정보 */
export type SubTokenInfoEntity = {
  __typename?: 'SubTokenInfoEntity';
  /**
   * 공용 및 미공용 활성화 값
   *     0: 미공용, 1: 공용
   */
  allowFlag: Allowed_Flag_Type;
  /** 부서명 */
  departmentName: Scalars['String'];
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 사용자 이름 */
  name: Scalars['String'];
  /** 토큰 이름 */
  tokenField: Scalars['String'];
};

/** 결재 템플릿 추가, 수정, 삭제를 위한 타입 */
export enum Type_Control_Approval_Form {
  Add = 'ADD',
  Delete = 'DELETE',
  Edit = 'EDIT'
}

/** 정책적용된 부서 검색 타입 */
export enum Type_Of_Approval_Notification_Department {
  AlarmName = 'alarmName',
  DepartName = 'departName'
}

/** 정책적용직원 검색 타입 */
export enum Type_Of_Employee_Applied_To_The_Template {
  AlarmName = 'alarmName',
  DepartName = 'departName',
  EmployeeId = 'employeeId',
  IsExpect = 'isExpect',
  Name = 'name'
}

/** 직원정보 타입 */
export enum Type_Of_Employee_Information {
  ApprovalDefine = 'approvalDefine',
  AsTimeTemplateName = 'asTimeTemplateName',
  Col1 = 'col1',
  Col2 = 'col2',
  Col3 = 'col3',
  Col4 = 'col4',
  Col5 = 'col5',
  Col6 = 'col6',
  Col7 = 'col7',
  Col8 = 'col8',
  Col9 = 'col9',
  Col10 = 'col10',
  Col11 = 'col11',
  Col12 = 'col12',
  Col13 = 'col13',
  Col14 = 'col14',
  Col15 = 'col15',
  DepartName = 'departName',
  DepartmentId = 'departmentId',
  EmployeeId = 'employeeId',
  EndpointCount = 'endpointCount',
  FullDepartmentName = 'fullDepartmentName',
  InsertType = 'insertType',
  Name = 'name',
  TodayWorkingTime = 'todayWorkingTime',
  WeekMaxExtWorkHour = 'weekMaxExtWorkHour',
  WeekMaxWorkHour = 'weekMaxWorkHour',
  WorkingName = 'workingName',
  WorkingTemplateIdx = 'workingTemplateIdx',
  WtAsextto = 'wtAsextto',
  WtTot = 'wtTot'
}

/** PC정보 타입 */
export enum Type_Of_Pc_Information {
  ComputerName = 'computerName',
  DepartName = 'departName',
  DeviceName = 'deviceName',
  IsOnOff = 'isOnOff',
  Name = 'name',
  WorkingName = 'workingName'
}

/** 템플릿의 근무 계획 정보 */
export type TemplateTimeScheduleInfo = {
  __typename?: 'TemplateTimeScheduleInfo';
  /** 근무계획 구분 ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** 종료 시간 */
  endTime: Scalars['String'];
  /** 금요일 */
  isFriday?: Maybe<Scalars['Int']>;
  /** 월요일 */
  isMonday?: Maybe<Scalars['Int']>;
  /** 토요일 */
  isSaturday?: Maybe<Scalars['Int']>;
  /** 일요일 */
  isSunday?: Maybe<Scalars['Int']>;
  /** 목요일 */
  isThursday?: Maybe<Scalars['Int']>;
  /** 화요일 */
  isTuesDay?: Maybe<Scalars['Int']>;
  /** 수요일 */
  isWednesday?: Maybe<Scalars['Int']>;
  /** 운영기간 종료 */
  periodDateEnd?: Maybe<Scalars['String']>;
  /** 운영기간 시작 */
  periodDateStart: Scalars['String'];
  /** 시작 시간 */
  startTime: Scalars['String'];
  /** 템플릿 근무 계획 Idx */
  timeIdx: Scalars['Int'];
  /** 근무 템플릿 Idx */
  timeTemplateIdx: Scalars['Int'];
};

/** 근무 계획 카테고리 타입 정보 */
export type TimeTypeCategory = {
  __typename?: 'TimeTypeCategory';
  /** 카테고리 ID */
  categoryId: Scalars['Int'];
  /** 카테고리 이름 */
  name?: Maybe<Scalars['String']>;
  /** 계산된 시간 타입 */
  timeCalcType: Scalars['String'];
  /** 테이블 타입 */
  useTableType: Scalars['Int'];
};

/** 알람 수정 요청 결과 */
export type UpdateAlarmEventOutput = {
  __typename?: 'UpdateAlarmEventOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 알람 상태값 수정 요청 결과 */
export type UpdateAlarmEventStatusOutput = {
  __typename?: 'UpdateAlarmEventStatusOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 연차 정보 설정 갱신 및 저장을 위한 요청 결과 */
export type UpdateAnnualEnvInfoOutput = {
  __typename?: 'UpdateAnnualEnvInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 템플릿 수정 요청 결과 */
export type UpdateApprovalNotificationTemplateOutput = {
  __typename?: 'UpdateApprovalNotificationTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 기본 정보 갱신 요청 결과 */
export type UpdateBasicUserInfoOutput = {
  __typename?: 'UpdateBasicUserInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 출퇴근 날짜 및 시간 변경 요청 결과 */
export type UpdateCommuteDateTimeOutput = {
  __typename?: 'UpdateCommuteDateTimeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 반경설정 on/off 값 변경 요청 결과 */
export type UpdateCommuteGpsAreaUseTypeOutput = {
  __typename?: 'UpdateCommuteGpsAreaUseTypeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 출퇴근 지역 정보 수정 요청 결과 */
export type UpdateCommuteGpsInfoOutput = {
  __typename?: 'UpdateCommuteGpsInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 만 1년 미만(입사년도) 정보 갱신 및 저장 요청 결과 */
export type UpdateInfoLessThanAYearOutput = {
  __typename?: 'UpdateInfoLessThanAYearOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 만 1년 이상 설정 정보 갱신 및 저장 요청 */
export type UpdateInfoMoreThanAYearOutput = {
  __typename?: 'UpdateInfoMoreThanAYearOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 만 1년차미만(입사차기년도) 정보 갱신 및 저장 요청 결과 */
export type UpdateInfoNextYearOfEmploymentOutput = {
  __typename?: 'UpdateInfoNextYearOfEmploymentOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재정보 알림 계획 수정 요청 결과 */
export type UpdateListOfApprovalNotificationScheduleByTemplateOutput = {
  __typename?: 'UpdateListOfApprovalNotificationScheduleByTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 메인 토큰 활성화 값 수정 요청 값 */
export type UpdateMainTokenAllowedFlagOutput = {
  __typename?: 'UpdateMainTokenAllowedFlagOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdatePlaceFreeManagementOutput = {
  __typename?: 'UpdatePlaceFreeManagementOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 결재관리 처리 및 조회 결재 요청 결과 */
export type UpdateProcessingInquiryResultOutput = {
  __typename?: 'UpdateProcessingInquiryResultOutput';
  error?: Maybe<Scalars['String']>;
  /** 결재 요청 결과 ID */
  id?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약 목록 수정하는 요청 결과 */
export type UpdateReservationCategoryOutput = {
  __typename?: 'UpdateReservationCategoryOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약 등록을 위한 요청 */
export type UpdateReservationOutput = {
  __typename?: 'UpdateReservationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 예약종류 수정 요청 결과 */
export type UpdateReservationTypeOutput = {
  __typename?: 'UpdateReservationTypeOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무제[근무시간] ( 선택근무시간제  > 근무시간 > 근무시간 템플릿) - 수정 요청 결과 */
export type UpdateSelfDeterminedWorkHourTemplateOutput = {
  __typename?: 'UpdateSelfDeterminedWorkHourTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 서브 토큰 공용 활성화 및 비활성화 수정 요청 */
export type UpdateSubTokenAllowedFlagOutput = {
  __typename?: 'UpdateSubTokenAllowedFlagOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 템플릿 근무 계획 수정 요청 결과 */
export type UpdateTemplateScheduleInfoOutput = {
  __typename?: 'UpdateTemplateScheduleInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무 템플릿 수정 요청 결과 */
export type UpdateTimeTemplateOutput = {
  __typename?: 'UpdateTimeTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 연차 정보 갱신 요청 결과 */
export type UpdateUserAnnualInfoOutput = {
  __typename?: 'UpdateUserAnnualInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 운행정보 수정을 위한 요청 결과 */
export type UpdateVehicleDrivingInformationOutput = {
  __typename?: 'UpdateVehicleDrivingInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

export type UpdateVehicleInformationOutput = {
  __typename?: 'UpdateVehicleInformationOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 근무제[기본정보] 수정 요청 결과 */
export type UpdateWtBasicInfoOutput = {
  __typename?: 'UpdateWTBasicInfoOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 근무제 할당 기능 수정 요청 결과 */
export type UpdateWorkingTemplateOutput = {
  __typename?: 'UpdateWorkingTemplateOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
};

/** 사용자 연차 정보 */
export type UserAnnualInfoEntity = {
  __typename?: 'UserAnnualInfoEntity';
  /** 가산일수 */
  addDay: Scalars['Int'];
  /** 귀속년도 */
  applyYear: Scalars['String'];
  /** 기본일수 */
  basicDay: Scalars['Int'];
  /** 기본조정 */
  basicModifyDay: Scalars['Int'];
  /** 생성자 */
  createSubject?: Maybe<Scalars['String']>;
  /** 사용자 사번 */
  employeeId: Scalars['String'];
  /** 생성일자 */
  insertDatetime: Scalars['String'];
  /** 이월연차 */
  leaveCarriedForwardDay: Scalars['Int'];
  /** 잔여일 */
  leaveDay: Scalars['Float'];
  /** 변경일자 */
  modifyDatetime?: Maybe<Scalars['String']>;
  /** 변경사유 */
  modifyReason?: Maybe<Scalars['String']>;
  /** 연차종료일 */
  periodateEnd?: Maybe<Scalars['String']>;
  /** 연차시작일 */
  periodateStart?: Maybe<Scalars['String']>;
  /** 상태 */
  statusFlag?: Maybe<Scalars['String']>;
  /** 사용일수 */
  useDay: Scalars['Float'];
  /** 사용조정 */
  useModifyDay: Scalars['Int'];
};

export type UserDailyCheckInfo = {
  /** 날짜 */
  date: Scalars['String'];
  /** 0:미체크, 1:체크 */
  isCheckStatus: Is_Active;
};

export type UserDailyWorkInfo = {
  /** 날짜 */
  date: Scalars['String'];
  /** 사용자 ID */
  employeeId: Scalars['String'];
};

/** 직원 일별 근무 계획 조회 Entity */
export type UserDailyWorkPlanEntity = {
  __typename?: 'UserDailyWorkPlanEntity';
  /** 휴게시간(추가인정)-4시간당 30분 */
  breakTimeAdjustmentMin?: Maybe<Scalars['String']>;
  /** 부서 이름 */
  departmentName: Scalars['String'];
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 사용자 이름 */
  employeeName: Scalars['String'];
  /** 퇴근(계획) */
  endTime: Scalars['String'];
  /** 출근(인정) */
  inCheckDatetime?: Maybe<Scalars['String']>;
  /** 확인유무 */
  isCheckStatus: Is_Active;
  /** 퇴근(인정) */
  outCheckDatetime?: Maybe<Scalars['String']>;
  /** 급여시간 */
  payTime?: Maybe<Scalars['String']>;
  /** 근무시간(인정) */
  recognitionAdmitTime?: Maybe<Scalars['String']>;
  /** 근무시간(계획) */
  recognitionPlanTime: Scalars['String'];
  /** 휴게시간(인정)-4시간당 30분 */
  restAdmitMin?: Maybe<Scalars['String']>;
  /** 휴게시간(계획) */
  restPlanMin: Scalars['String'];
  /** 날짜 */
  startDate: Scalars['String'];
  /** 출근(계획) */
  startTime: Scalars['String'];
  /** 요일 */
  weekDayText: Scalars['String'];
};

/** 사용자 정보 */
export type UserEntity = {
  __typename?: 'UserEntity';
  /** 전화번호 */
  appPhoneNum?: Maybe<Scalars['String']>;
  /** approvalDefine */
  approvalDefine: Scalars['Int'];
  /** approvalEndDate */
  approvalEndDate?: Maybe<Scalars['String']>;
  /** approvalStartDate */
  approvalStartDate?: Maybe<Scalars['String']>;
  /** asonicWidgetUse */
  asonicWidgetUse?: Maybe<Scalars['Int']>;
  /** col1 */
  col1?: Maybe<Scalars['String']>;
  /** col2 */
  col2?: Maybe<Scalars['String']>;
  /** col3 */
  col3?: Maybe<Scalars['String']>;
  /** col4 */
  col4?: Maybe<Scalars['String']>;
  /** col5 */
  col5?: Maybe<Scalars['String']>;
  /** col6 */
  col6?: Maybe<Scalars['String']>;
  /** col7 */
  col7?: Maybe<Scalars['String']>;
  /** col8 */
  col8?: Maybe<Scalars['String']>;
  /** col9 */
  col9?: Maybe<Scalars['String']>;
  /** col10 */
  col10?: Maybe<Scalars['String']>;
  /** col11 */
  col11?: Maybe<Scalars['String']>;
  /** col12 */
  col12?: Maybe<Scalars['String']>;
  /** col13 */
  col13?: Maybe<Scalars['String']>;
  /** col14 */
  col14?: Maybe<Scalars['String']>;
  /** col15 */
  col15?: Maybe<Scalars['String']>;
  /** delegator ID */
  delegatorId?: Maybe<Scalars['String']>;
  /** 부서 ID */
  departmentId?: Maybe<Scalars['Int']>;
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** extWorkingMin */
  extWorkingMin?: Maybe<Scalars['Int']>;
  /** fcmToken */
  fcmToken?: Maybe<Scalars['String']>;
  /** fp Process */
  fpProcess: Scalars['Int'];
  /** fp Process Two */
  fpProcessTwo: Scalars['Int'];
  /** 부서명 */
  fullDepartmentName?: Maybe<Scalars['String']>;
  /** 입력구분(0:수동, 1:자동) */
  insertType?: Maybe<Scalars['Int']>;
  /** leftSeatUse */
  leftSeatUse: Scalars['Int'];
  /** 관리상태(0:관리, 1:삭제) */
  manageable?: Maybe<Scalars['Int']>;
  /** memo */
  memo?: Maybe<Scalars['String']>;
  /** myPicture */
  myPicture?: Maybe<Scalars['String']>;
  /** 사용자 이름 */
  name?: Maybe<Scalars['String']>;
  /** newData */
  newData?: Maybe<Scalars['Int']>;
  /** newDataTime */
  newDataTime?: Maybe<Scalars['String']>;
  /** 사용자 패스워드 */
  password: Scalars['String'];
  /** policy Update Status */
  policyUpdateStatus: Scalars['Int'];
  /** policy Update Status Two */
  policyUpdateStatusTwo: Scalars['Int'];
  /** policyWorkingMin */
  policyWorkingMin?: Maybe<Scalars['Int']>;
  /** policy_ext_working_min */
  policy_ext_working_min?: Maybe<Scalars['Int']>;
  /** 근무 시간 계획 정책 템플릿 Idx */
  timeTemplateIdx?: Maybe<Scalars['Int']>;
  /** tokenField */
  tokenField?: Maybe<Scalars['Int']>;
  /** 사용자 email */
  userEmail?: Maybe<Scalars['String']>;
  /** 직급 */
  userPosition?: Maybe<Scalars['String']>;
  /** web, app 권한 */
  webAppAuth?: Maybe<Scalars['Int']>;
  /** workingMin */
  workingMin?: Maybe<Scalars['Int']>;
  /** 근무 정책 템플릿 Idx */
  workingTemplateIdx?: Maybe<Scalars['Int']>;
};

/** 직원 월별 근무 계획 조회 Entity */
export type UserMonthlyWorkPlanEntity = {
  __typename?: 'UserMonthlyWorkPlanEntity';
  /** 부서 이름 */
  departmentName: Scalars['String'];
  /** 사용자 ID */
  employeeId: Scalars['String'];
  /** 사용자 이름 */
  employeeName: Scalars['String'];
  /** 퇴근(계획) */
  endTime?: Maybe<Scalars['String']>;
  /** 출근(인정) */
  inCheckDatetime?: Maybe<Scalars['String']>;
  /** 확인유무(0:미확인, 1:확인) */
  isCheckStatus: Is_Active;
  /** 마감여부(0:미마감, 1:마감)  */
  isClosingStatus: Is_Active;
  /** 전송유무(0:미전송, 1:전송) */
  isSendStatus: Is_Active;
  /** 종료 날짜 */
  monthLastDay: Scalars['String'];
  /** 시작 날짜 */
  monthStartDay: Scalars['String'];
  /** 퇴근(인정) */
  outCheckDatetime?: Maybe<Scalars['String']>;
  /** 급여시간 */
  payTime?: Maybe<Scalars['String']>;
  /** 근무시간(인정) */
  recognitionAdmitTime?: Maybe<Scalars['String']>;
  /** 근무시간(계획) */
  recognitionPlanTime: Scalars['String'];
  /** 휴게시간(인정)-4시간당 30분 */
  restAdmitMin?: Maybe<Scalars['String']>;
  /** 휴게시간(계획) */
  restPlanMin: Scalars['String'];
  /** 출근(계획) */
  startTime?: Maybe<Scalars['String']>;
};

export type UserOfSearchedApprovalLineEntity = {
  __typename?: 'UserOfSearchedApprovalLineEntity';
  /** 결재 정보 */
  approvalInfo?: Maybe<Scalars['String']>;
  /** 순서 */
  approvalOrder: Scalars['Int'];
  /** 사용자 결재선 타입 */
  approvalType: Approval_User_Type;
  /** 사용자 id */
  employeeId: Scalars['String'];
  /** 결재선 idx */
  lineIdx: Scalars['Int'];
};

/** 사용자 기본 정책정보 Entity */
export type UserPolicyBasicInfoEntity = {
  __typename?: 'UserPolicyBasicInfoEntity';
  /** 사용자 근무 부서 이름 */
  departName: Scalars['String'];
  /** 사용자 이름과 ID */
  nameId: Scalars['String'];
  /** 근무가능시간 */
  possibleTime?: Maybe<Scalars['String']>;
  /** 근무관리정책 이름 */
  templateName?: Maybe<Scalars['String']>;
  /** 근무관리정책정보 */
  wType?: Maybe<Scalars['String']>;
  /** 근무자 선택정보 UI 표현 값이 2일때 UI 표현 */
  workType?: Maybe<Scalars['Float']>;
  /** 근무제 Idx */
  workingTemplateIdx?: Maybe<Scalars['Float']>;
};

/** 근무자 선택정보 Entity */
export type UserPolicySelectedInfoEntity = {
  __typename?: 'UserPolicySelectedInfoEntity';
  /** 근무요일 */
  asDayOfWeek?: Maybe<Array<Day_Type>>;
  /** 의무근무종료시간 */
  dutyEndTime?: Maybe<Scalars['String']>;
  /** 의무근무시작시간 */
  dutyStartTime?: Maybe<Scalars['String']>;
  /** 의무근무시작시간 사용유무 disable(0)/enable(1) */
  dutyWorkHoursType?: Maybe<Is_Use>;
  /** 휴게종료시간 */
  endBreakTime?: Maybe<Scalars['String']>;
  /** 근무종료시간 */
  endTime?: Maybe<Scalars['String']>;
  /** 휴게시간 disable(0)/enable(1) */
  isBreakTimeTypeEnable: Is_Use;
  /** 근무요일 disable(0)/enable(1) */
  isDayOfWeekEnable: Is_Use;
  /** 근무시간 disable(0)/enable(1) */
  isWorkHoursEnable: Is_Use;
  /** 휴게시작시간 */
  startBreakTime?: Maybe<Scalars['String']>;
  /** 근무시작시간 */
  startTime?: Maybe<Scalars['String']>;
  /** 근무시간 템플릿 운영기간 */
  workingPeriod: Scalars['String'];
  /** 근무시간 템플릿 이름 */
  workingTimeName: Scalars['String'];
};

/** 차량 주행 정보 */
export type VehicleDrivingInformationEntity = {
  __typename?: 'VehicleDrivingInformationEntity';
  /** 사용 목적 카테고리 ID */
  categoryId: Scalars['Int'];
  /** 사용 목적 카테고리 이름 */
  categoryName: Scalars['String'];
  /** 도착 날짜 및 시간 */
  endDateTime?: Maybe<Scalars['String']>;
  /** 도착 운전자 사번 */
  endEmployeeId?: Maybe<Scalars['String']>;
  /** 도착 운전자 이름 */
  endEmployeeName?: Maybe<Scalars['String']>;
  /** 도착지 */
  endGpsAddress?: Maybe<Scalars['String']>;
  /** 도착 후 거리 */
  endKm?: Maybe<Scalars['Int']>;
  /** logIdx */
  logIdx: Scalars['Int'];
  /** 출발 날짜 및 시간 */
  startDateTime?: Maybe<Scalars['String']>;
  /** 출발 운전자 사번 */
  startEmployeeId: Scalars['String'];
  /** 출발 운전자 이름 */
  startEmployeeName?: Maybe<Scalars['String']>;
  /** 출발지 */
  startGpsAddress?: Maybe<Scalars['String']>;
  /** 출발 전 거리 */
  startKm?: Maybe<Scalars['Int']>;
  /** 차량 이름 */
  vehicleName?: Maybe<Scalars['String']>;
  /** 차량 번호 */
  vehicleNumber: Scalars['String'];
};

/** 차량 정보 */
export type VehicleInformationEntity = {
  __typename?: 'VehicleInformationEntity';
  /** 제조사 */
  vehicleCompany?: Maybe<Scalars['String']>;
  /** 차량 이름 */
  vehicleName?: Maybe<Scalars['String']>;
  /** 차량 번호 */
  vehicleNumber: Scalars['String'];
};

/** 위젯 사용 설정 타입 */
export enum Widget_Setting_Type {
  CalendarStyle = 'CALENDAR_STYLE',
  GraphStyle = 'GRAPH_STYLE',
  UnUse = 'UN_USE'
}

/** 근무요일설정 [ 0 : 지정(월~금), 1 : 지정(월~토), 2 : 지정(직접선택), 3 : 근무자 자율펼성] */
export enum Work_Day_Of_Week {
  InputMethod = 'INPUT_METHOD',
  MonToFri = 'MON_TO_FRI',
  MonToSat = 'MON_TO_SAT',
  SelfForWorker = 'SELF_FOR_WORKER'
}

/** 근무시간 설정 [ 0 : 지정시간, 1 : 근무자 자율편성] */
export enum Work_Hours_Setting_Type {
  DesignatedTime = 'DESIGNATED_TIME',
  SelfForWorker = 'SELF_FOR_WORKER'
}

/** 근무시간 설정방식 타입 */
export enum Work_Hours_Type {
  EachDayTotalAndQuittingTime = 'EACH_DAY_TOTAL_AND_QUITTING_TIME',
  EachDayWorkTime = 'EACH_DAY_WORK_TIME',
  EachWeekTotalAndQuittingTime = 'EACH_WEEK_TOTAL_AND_QUITTING_TIME',
  EachWeekWorkTime = 'EACH_WEEK_WORK_TIME'
}

/** 근무 유형 타입 */
export enum Work_Type {
  AutonomousCommutingPolicy = 'AUTONOMOUS_COMMUTING_POLICY',
  FlexibleSchedulePolicy = 'FLEXIBLE_SCHEDULE_POLICY',
  FlexibleWorkingHoursPolicy = 'FLEXIBLE_WORKING_HOURS_POLICY',
  SFCommutingPolicy = 'S_F_COMMUTING_POLICY'
}

/** 근무주차 설정 타입 */
export enum Work_Week_Type {
  All = 'ALL',
  InputMethod = 'INPUT_METHOD'
}

/** 근무제 기본정보 Entity */
export type WtBasicInfoEntity = {
  __typename?: 'WTBasicInfoEntity';
  /** 일(기본근무) */
  dayMaxWorkHour: Scalars['Int'];
  /** 주시작요일 */
  dayOfWeekStart: Day_Type;
  /** 의무휴게시간(분) */
  mandatoryBreakMin: Scalars['Int'];
  /** 의무휴게시간(시) */
  mandatoryBreakTermHour: Scalars['Int'];
  /** 관리 이름 */
  name?: Maybe<Scalars['String']>;
  /** 신규기타운영시간자동적용[미체크(0) / 체크(1)] */
  otHolidayTemplateNewAutoUse: Is_Use;
  /** 근무시간유형 */
  otHoursTypeCategoryIdx?: Maybe<Scalars['Float']>;
  /** 근무 단위 설정 값 */
  periodUnitType?: Maybe<Period_Unit_Type>;
  /** 국가간 시차(분) */
  timeDifferenceMin: Scalars['Float'];
  /** 주(시간외 근무시간) */
  weekMaxExtWorkHour: Scalars['Int'];
  /** 주(기본근무) */
  weekMaxWorkHour: Scalars['Int'];
  /** 근무 유형 */
  workType: Work_Type;
  /** 근무제 idx */
  workingTemplateIdx: Scalars['Int'];
};

export type WtGraphBasicEntity = {
  __typename?: 'WTGraphBasicEntity';
  /** 일(기본근무) */
  dayMaxWorkHour: Scalars['Int'];
  /** 주시작요일 */
  dayOfWeekStart: Day_Type;
  /** 근무제 이름 */
  name: Scalars['String'];
  /** 주(기본근무) */
  weekMaxWorkHour: Scalars['Int'];
  /** 근무 유형 */
  workType: Scalars['String'];
};

export type WtGraphWeekEntity = {
  __typename?: 'WTGraphWeekEntity';
  /** 근무 요일 유형 */
  dayOfWeek: Day_Type;
  /** 총 근무 시간 */
  totHour?: Maybe<Scalars['Float']>;
  /** 근무주차 선택 */
  workWeekSelect?: Maybe<Scalars['Int']>;
};

/** 근무제 목록 검색을 위한 타입 */
export enum Wt_Search_Type {
  DayMaxWorkHour = 'DAY_MAX_WORK_HOUR',
  IsLeftSeat = 'IS_LEFT_SEAT',
  LeftSeatBaseMin = 'LEFT_SEAT_BASE_MIN',
  LeftSeatBlackScreenUse = 'LEFT_SEAT_BLACK_SCREEN_USE',
  LeftSeatPlaceInput = 'LEFT_SEAT_PLACE_INPUT',
  LeftSeatReasonUse = 'LEFT_SEAT_REASON_USE',
  Name = 'NAME',
  TimeArray = 'TIME_ARRAY',
  WeekMaxWorkHour = 'WEEK_MAX_WORK_HOUR',
  WType = 'W_TYPE'
}

/** 위젯설정 정보 Entity */
export type WidgetSettingInfoEntity = {
  __typename?: 'WidgetSettingInfoEntity';
  /** 부팅시자동실행 */
  asonicWidgetAutoStart: Is_Use;
  /** 위젯사용설정 */
  asonicWidgetUse: Widget_Setting_Type;
  /** 근무제 idx */
  workingTemplateIdx: Scalars['Int'];
};

/** 근무관리 정보 */
export type WorkManagement = {
  __typename?: 'WorkManagement';
  /** 근무타입 ID */
  categoryId?: Maybe<Scalars['Float']>;
  /** 근무타입 */
  categoryName?: Maybe<Scalars['String']>;
  /** 부서명 */
  departName: Scalars['String'];
  /** 직원 ID */
  employeeId: Scalars['String'];
  /** 종료주소 */
  endAddress?: Maybe<Scalars['String']>;
  /** 종료날짜 */
  endDate?: Maybe<Scalars['String']>;
  /** 종료 gps lat */
  endGpsLat?: Maybe<Scalars['String']>;
  /** 종료 gps long */
  endGpsLong?: Maybe<Scalars['String']>;
  /** 근무종료사진 */
  endImage?: Maybe<Scalars['String']>;
  /** 종료시간 */
  endTime?: Maybe<Scalars['String']>;
  /** 이름 */
  name: Scalars['String'];
  /** 시작주소 */
  startAddress?: Maybe<Scalars['String']>;
  /** 시작날짜 */
  startDate?: Maybe<Scalars['String']>;
  /** 시작 gps lat */
  startGpsLat?: Maybe<Scalars['String']>;
  /** 시작 gps long */
  startGpsLong?: Maybe<Scalars['String']>;
  /** 근무시작사진 */
  startImage?: Maybe<Scalars['String']>;
  /** 시작시간 */
  startTime?: Maybe<Scalars['String']>;
};

/** 근무제 Entity */
export type WorkingTemplateEntity = {
  __typename?: 'WorkingTemplateEntity';
  /** 일(기본근무) */
  dayMaxWorkHour: Scalars['String'];
  /** 이석 사용여부 */
  isLeftSeat: Scalars['String'];
  /** 이석 기준시간 */
  leftSeatBaseMin: Scalars['Int'];
  /** 이석 화면잠금 사용여부 */
  leftSeatBlackScreenUse: Is_Use;
  /** 이석 장소입력 사용여부 */
  leftSeatPlaceInput: Is_Use;
  /** 이석 사유입력 사용여부 */
  leftSeatReasonUse: Is_Use;
  /** 근무제 이름 */
  name?: Maybe<Scalars['String']>;
  /** 근무시간 배열 정보(JSON) */
  timeArray?: Maybe<Scalars['String']>;
  /** 근무 유형 */
  wType: Scalars['String'];
  /** 주(기본근무) */
  weekMaxWorkHour: Scalars['String'];
  /** 근무제 idx */
  workingTemplateIdx: Scalars['Int'];
};

/** 근무시간 유형 정보 Entity */
export type WorkingTimeInfoEntity = {
  __typename?: 'WorkingTimeInfoEntity';
  /** 일(기본근무) */
  dayMaxWorkHour: Scalars['Int'];
  /** 근무시간유형 */
  otHoursTypeCategoryIdx: Scalars['Float'];
  /** 근무시간 유형 이름 */
  typeName: Scalars['String'];
  /** 주(시간외 근무시간) */
  weekMaxExtWorkHour: Scalars['Int'];
  /** 주(기본근무) */
  weekMaxWorkHour: Scalars['Int'];
};

/** 기본 그래프 정보(선택근무시간제) 요청 결과 */
export type GetSelfDeterminedWhGraphOutput = {
  __typename?: 'getSelfDeterminedWHGraphOutput';
  error?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  /** 선택근무시간제 주 그래프 정보 */
  weekDayOfWeekInfo?: Maybe<Array<SelfDeterminedWeekGraphEntity>>;
};

export type GetCompanyListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyListQuery = { __typename?: 'Query', getCompanyList: { __typename?: 'GetCompanyListOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'CompanyEntity', companyCode: string, companyName: string }> | null } };

export type GetAnnualEnvInfoQueryVariables = Exact<{
  companyCode: Scalars['String'];
}>;


export type GetAnnualEnvInfoQuery = { __typename?: 'Query', getAnnualEnvInfo: { __typename?: 'GetAnnualEnvInfoOutput', ok: boolean, error?: string | null, info?: { __typename?: 'AnnualEnvInfoEntity', annualAccrualType: Annual_Accrual_Type, accountingMonth: number, annualMaxCount: number, annualDeductionPriorityType: number, annualFixType: Annual_Fix_Type } | null } };

export type UpdateAnnualEnvInfoMutationVariables = Exact<{
  companyCode: Scalars['String'];
  annualAccrualType: Annual_Accrual_Type;
  accountingMonth: Scalars['Int'];
  annualMaxCount: Scalars['Int'];
  annualDeductionPriorityType: Scalars['Int'];
  annualFixType: Annual_Fix_Type;
}>;


export type UpdateAnnualEnvInfoMutation = { __typename?: 'Mutation', updateAnnualEnvInfo: { __typename?: 'UpdateAnnualEnvInfoOutput', ok: boolean, error?: string | null } };

export type GetInfoMoreThanAYearQueryVariables = Exact<{
  companyCode: Scalars['String'];
}>;


export type GetInfoMoreThanAYearQuery = { __typename?: 'Query', getInfoMoreThanAYear: { __typename?: 'GetInfoMoreThanAYearOutput', ok: boolean, error?: string | null, info?: { __typename?: 'InfoMoreThanAYearEntity', defaultDayCount: number, addedYearCount: number, addedDayCount: number, addedMaxDayCount: number, minusAnnualType: Minus_Annual_Type, minusAnnualDayCount: number, carriedforwardType: Carriedforward_Type, carriedforwardRangeType: Carriedforward_Range_Type } | null } };

export type UpdateInfoMoreThanAYearMutationVariables = Exact<{
  companyCode: Scalars['String'];
  defaultDayCount: Scalars['Int'];
  addedYearCount: Scalars['Int'];
  addedDayCount: Scalars['Int'];
  addedMaxDayCount: Scalars['Int'];
  minusAnnualType: Minus_Annual_Type;
  minusAnnualDayCount: Scalars['Int'];
  carriedforwardType: Carriedforward_Type;
  carriedforwardRangeType: Carriedforward_Range_Type;
}>;


export type UpdateInfoMoreThanAYearMutation = { __typename?: 'Mutation', updateInfoMoreThanAYear: { __typename?: 'UpdateInfoMoreThanAYearOutput', ok: boolean, error?: string | null } };

export type GetInfoLessThanAYearQueryVariables = Exact<{
  companyCode: Scalars['String'];
}>;


export type GetInfoLessThanAYearQuery = { __typename?: 'Query', getInfoLessThanAYear: { __typename?: 'GetInfoLessThanAYearOutput', ok: boolean, error?: string | null, info?: { __typename?: 'InfoLessThanAYearEntity', annualAccrualType: Annual_Accrual_Type_For_Less_Than_A_Year, monthAccrualType: Month_Accrual_Type, fixCount: number, minusAnnualType: Minus_Annual_Type_For_Less_Than_A_Year, minusAnnualDayCount: number } | null } };

export type UpdateInfoLessThanYearMutationVariables = Exact<{
  companyCode: Scalars['String'];
  annualAccrualType: Annual_Accrual_Type_For_Less_Than_A_Year;
  monthAccrualType: Month_Accrual_Type;
  fixCount: Scalars['Float'];
  minusAnnualType: Minus_Annual_Type_For_Less_Than_A_Year;
  minusAnnualDayCount: Scalars['Float'];
}>;


export type UpdateInfoLessThanYearMutation = { __typename?: 'Mutation', updateInfoLessThanYear: { __typename?: 'UpdateInfoLessThanAYearOutput', ok: boolean, error?: string | null } };

export type GetInfoNextYearOfEmploymentQueryVariables = Exact<{
  companyCode: Scalars['String'];
}>;


export type GetInfoNextYearOfEmploymentQuery = { __typename?: 'Query', getInfoNextYearOfEmployment: { __typename?: 'GetInfoNextYearOfEmploymentOutput', ok: boolean, error?: string | null, info?: { __typename?: 'InfoNextYearOfEmploymentEntity', annualAccrualType: Annual_Accrual_Type_For_Next_Year_Of_Employment, monthAccrualType: Month_Accrual_Type_For_Next_Year_Of_Employment, fixCount: number, minusAnnualType: Minus_Annual_Type, minusAnnualDayCount: number, carriedforwardType: Carriedforward_Type, carriedforwardRangeType: Carriedforward_Range_Type } | null } };

export type UpdateInfoNextYearOfEmploymentMutationVariables = Exact<{
  companyCode: Scalars['String'];
  annualAccrualType: Annual_Accrual_Type_For_Next_Year_Of_Employment;
  monthAccrualType: Month_Accrual_Type_For_Next_Year_Of_Employment;
  fixCount: Scalars['Float'];
  minusAnnualType: Minus_Annual_Type;
  minusAnnualDayCount: Scalars['Float'];
  carriedforwardType: Carriedforward_Type;
  carriedforwardRangeType: Carriedforward_Range_Type;
}>;


export type UpdateInfoNextYearOfEmploymentMutation = { __typename?: 'Mutation', updateInfoNextYearOfEmployment: { __typename?: 'UpdateInfoNextYearOfEmploymentOutput', ok: boolean, error?: string | null } };

export type ApplicationApprovalMutationVariables = Exact<{
  employeeId: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  formTemplateIdx: Scalars['Int'];
  title: Scalars['String'];
  startTime?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  replacementRequestStartDate?: InputMaybe<Scalars['String']>;
  replacementRequestEndDate?: InputMaybe<Scalars['String']>;
  replacementRequestStartTime?: InputMaybe<Scalars['String']>;
  replacementRequestEndTime?: InputMaybe<Scalars['String']>;
  listOfApprovalLineUser?: InputMaybe<Array<IListOfApprovalLineUser> | IListOfApprovalLineUser>;
  listOfReceiver?: InputMaybe<Array<IReceiveAndReferenceForUser> | IReceiveAndReferenceForUser>;
  listOfReferencedPerson?: InputMaybe<Array<IReceiveAndReferenceForUser> | IReceiveAndReferenceForUser>;
  listOfReceiveDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment> | IReceiveAndReferenceForDepartment>;
  listOfReferencedPersonDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment> | IReceiveAndReferenceForDepartment>;
}>;


export type ApplicationApprovalMutation = { __typename?: 'Mutation', applicationApproval: { __typename?: 'ApplicationApprovalOutput', ok: boolean, error?: string | null } };

export type AddApprovalTemporaryStorageMutationVariables = Exact<{
  employeeId: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  formTemplateIdx: Scalars['Int'];
  title: Scalars['String'];
  startTime?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  replacementRequestStartDate?: InputMaybe<Scalars['String']>;
  replacementRequestEndDate?: InputMaybe<Scalars['String']>;
  replacementRequestStartTime?: InputMaybe<Scalars['String']>;
  replacementRequestEndTime?: InputMaybe<Scalars['String']>;
  listOfApprovalLineUser?: InputMaybe<Array<IListOfApprovalLineUser> | IListOfApprovalLineUser>;
  listOfReceiver?: InputMaybe<Array<IReceiveAndReferenceForUser> | IReceiveAndReferenceForUser>;
  listOfReferencedPerson?: InputMaybe<Array<IReceiveAndReferenceForUser> | IReceiveAndReferenceForUser>;
  listOfReceiveDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment> | IReceiveAndReferenceForDepartment>;
  listOfReferencedPersonDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment> | IReceiveAndReferenceForDepartment>;
}>;


export type AddApprovalTemporaryStorageMutation = { __typename?: 'Mutation', addApprovalTemporaryStorage: { __typename?: 'ApplicationApprovalOutput', ok: boolean, error?: string | null } };

export type IsActiveApprovalPasswordQueryVariables = Exact<{ [key: string]: never; }>;


export type IsActiveApprovalPasswordQuery = { __typename?: 'Query', isActiveApprovalPassword: { __typename?: 'IsActiveApprovalPasswordOutput', ok: boolean, error?: string | null } };

export type ConfirmIsApprovalPasswordQueryVariables = Exact<{
  password: Scalars['String'];
}>;


export type ConfirmIsApprovalPasswordQuery = { __typename?: 'Query', confirmIsApprovalPassword: { __typename?: 'ConfirmIsApprovalPasswordOutput', ok: boolean, error?: string | null } };

export type SignApprovalMutationVariables = Exact<{
  approvalRequestIdx: Scalars['Int'];
  description: Scalars['String'];
  customEmployeeId?: InputMaybe<Scalars['String']>;
}>;


export type SignApprovalMutation = { __typename?: 'Mutation', signApproval: { __typename?: 'SignApprovalOutput', ok: boolean, error?: string | null } };

export type RejectApprovalMutationVariables = Exact<{
  approvalRequestIdx: Scalars['Int'];
  description: Scalars['String'];
  customEmployeeId?: InputMaybe<Scalars['String']>;
}>;


export type RejectApprovalMutation = { __typename?: 'Mutation', rejectApproval: { __typename?: 'RejectApprovalOutput', ok: boolean, error?: string | null } };

export type DeleteApprovalRequestMutationVariables = Exact<{
  employeeId: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  formTemplateIdx: Scalars['Int'];
  title: Scalars['String'];
  approvalRequestIdx: Scalars['Int'];
  startTime?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  replacementRequestStartDate?: InputMaybe<Scalars['String']>;
  replacementRequestEndDate?: InputMaybe<Scalars['String']>;
  replacementRequestStartTime?: InputMaybe<Scalars['String']>;
  replacementRequestEndTime?: InputMaybe<Scalars['String']>;
  listOfApprovalLineUser?: InputMaybe<Array<IListOfApprovalLineUser> | IListOfApprovalLineUser>;
  listOfReceiver?: InputMaybe<Array<IReceiveAndReferenceForUser> | IReceiveAndReferenceForUser>;
  listOfReferencedPerson?: InputMaybe<Array<IReceiveAndReferenceForUser> | IReceiveAndReferenceForUser>;
  listOfReceiveDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment> | IReceiveAndReferenceForDepartment>;
  listOfReferencedPersonDepartment?: InputMaybe<Array<IReceiveAndReferenceForDepartment> | IReceiveAndReferenceForDepartment>;
}>;


export type DeleteApprovalRequestMutation = { __typename?: 'Mutation', deleteApprovalRequest: { __typename?: 'DeleteApprovalRequestOutput', ok: boolean, error?: string | null } };

export type GetListOfApprovalQueryVariables = Exact<{
  approvalType: Approval_For_List_Type;
  searchType: ApprovalSearchType;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  fieldSort?: InputMaybe<FieldSort>;
  searchValue?: InputMaybe<Scalars['String']>;
  customEmployeeId?: InputMaybe<Scalars['String']>;
}>;


export type GetListOfApprovalQuery = { __typename?: 'Query', getListOfApproval: { __typename?: 'ListOfApprovalOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'ApprovalEntity', approvalRequestIdx: number, formTemplateIdx: number, approvalType: Approval_Sort, approvalTitle?: string | null, categoryId: number, formIdx?: number | null, docNumber?: string | null, approvalStatus: ApprovalStatus, extendStartDate: string, extendEndDate: string, extendStartDateTime: string, extendEndDateTime: string, signUpDateTimeTemp?: string | null, name: string, departName: string, attachedFileName?: string | null, attachedFileLink?: string | null, signUpDateTime?: string | null, extendDescr?: string | null, resultDescr?: string | null, allocateDay?: string | null, annualInfo?: string | null }> | null } };

export type GetGroupsQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type GetGroupsQuery = { __typename?: 'Query', getGroups: { __typename?: 'GetGroupOutput', ok: boolean, error?: string | null, listOfGroup?: Array<{ __typename?: 'GroupEntity', departmentId: number, parentDepartmentId: number, name: string, level: string, fullGroupName: string, insertType: number, countEmployee?: number | null, countEndpoint?: number | null, memo1?: string | null, memo2?: string | null, workingName?: string | null, deptSort: number }> | null } };

export type GetAllEmployeeInformationInDepartmentQueryVariables = Exact<{
  selectedDepartmentId: Scalars['String'];
}>;


export type GetAllEmployeeInformationInDepartmentQuery = { __typename?: 'Query', getAllEmployeeInformationInDepartment: { __typename?: 'GetEmployeeInformationOutput', ok: boolean, error?: string | null, listOfEmployee?: Array<{ __typename?: 'EmployeeInformation', employeeId: string, name: string, departmentId: string, fullDepartmentName: string, departName: string, endpointCount: number, insertType: number, workingName: string, workingTemplateIdx?: number | null, asTimeTemplateName: string, approvalDefine: number, col1: string, col2: string, col3: string, col4: string, col5: string, col6: string, col7: string, col8: string, col9: string, col10: string, col11: string, col12: string, col13: string, col14: string, col15: string, todayWorkingTime?: string | null, weekMaxWorkHour?: string | null, wtTot?: string | null, weekMaxExtWorkHour?: string | null, wtAsextto?: string | null, userPosition?: string | null }> | null } };

export type GetAllEmployeeInformationInDepartmentForAllEmployeesQueryVariables = Exact<{
  selectedDepartmentId: Scalars['String'];
}>;


export type GetAllEmployeeInformationInDepartmentForAllEmployeesQuery = { __typename?: 'Query', getAllEmployeeInformationInDepartmentForAllEmployees: { __typename?: 'GetEmployeeInformationOutput', ok: boolean, error?: string | null, listOfEmployee?: Array<{ __typename?: 'EmployeeInformation', employeeId: string, name: string, departmentId: string, fullDepartmentName: string, departName: string, endpointCount: number, insertType: number, workingName: string, workingTemplateIdx?: number | null, asTimeTemplateName: string, approvalDefine: number, col1: string, col2: string, col3: string, col4: string, col5: string, col6: string, col7: string, col8: string, col9: string, col10: string, col11: string, col12: string, col13: string, col14: string, col15: string, todayWorkingTime?: string | null, weekMaxWorkHour?: string | null, wtTot?: string | null, weekMaxExtWorkHour?: string | null, wtAsextto?: string | null, userPosition?: string | null }> | null } };

export type CountAllListOfApprovalQueryVariables = Exact<{
  searchValue?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
}>;


export type CountAllListOfApprovalQuery = { __typename?: 'Query', countAllListOfApproval: { __typename?: 'CountAllListOfApprovalOutput', ok: boolean, error?: string | null, waitingCount?: number | null, completedCount?: number | null, receiveCount?: number | null, referenceCount?: number | null, myCount?: number | null } };

export type CountListOfApprovalQueryVariables = Exact<{
  approvalType: Approval_For_List_Type;
  searchType: ApprovalSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
  customEmployeeId?: InputMaybe<Scalars['String']>;
}>;


export type CountListOfApprovalQuery = { __typename?: 'Query', countListOfApproval: { __typename?: 'CountListOfApprovalOutput', ok: boolean, error?: string | null, count?: number | null } };

export type GetApprovalFormDetailQueryVariables = Exact<{
  employeeId: Scalars['String'];
  approvalRequestIdx: Scalars['Int'];
}>;


export type GetApprovalFormDetailQuery = { __typename?: 'Query', getApprovalFormDetail: { __typename?: 'GetApprovalFormDetailOutput', ok: boolean, error?: string | null, listOfApprovalUser?: Array<{ __typename?: 'ListOfApprovalUser', approvalRequestIdx: number, approverId: string, name: string, approvalOrder: number, approverType: Approval_User_Type, approvalResult?: Approval_Status | null, approvalTime?: string | null, signType?: SignType | null, signText?: string | null, sign_image?: Array<string> | null, signImage?: string | null, viewTime?: string | null }> | null, information?: { __typename?: 'ApprovalDetailInformationEntity', approvalRequestIdx: number, formTemplateTitle: string, employeeIdRequest: string, departmentName: string, employeeName: string, userPosition?: string | null, docNumber?: string | null, signUpDateTimeTemp?: string | null, signUpDateTime?: string | null, categoryName: string, storePeriod?: string | null, formName?: string | null, extendSDate?: string | null, extendSDateTime?: string | null, extendEDate?: string | null, extendEDateTime?: string | null, approvalTitle?: string | null, extendDescription?: string | null, attachedFileName?: string | null, attachedFileLink?: string | null } | null, listOfReceiveDepartment?: Array<{ __typename?: 'ListOfReceiveAndReferenceDepartmentInformation', departmentId: number, departmentName: string }> | null, listOfReceiveUser?: Array<{ __typename?: 'ListOfReceiveAndReferenceUserInformation', employeeId: string, employeeName: string }> | null, listOfReferenceDepartment?: Array<{ __typename?: 'ListOfReceiveAndReferenceDepartmentInformation', departmentId: number, departmentName: string }> | null, listOfReferenceUser?: Array<{ __typename?: 'ListOfReceiveAndReferenceUserInformation', employeeId: string, employeeName: string }> | null } };

export type GetListOfApprovalHistoryQueryVariables = Exact<{
  approvalRequestIdx: Scalars['Int'];
}>;


export type GetListOfApprovalHistoryQuery = { __typename?: 'Query', getListOfApprovalHistory: { __typename?: 'ApprovalHistoryOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'ApprovalHistoryEntity', numRank: number, empName: string, userPosition?: string | null, departmentName: string, approvalTime?: string | null, approvalResult?: Approval_Status | null, descriptionComment?: string | null, viewTime?: string | null }> | null } };

export type GetListOfApprovalTemporaryStorageQueryVariables = Exact<{
  searchType: ApprovalSearchType;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  fieldSort?: InputMaybe<FieldSort>;
  searchValue?: InputMaybe<Scalars['String']>;
}>;


export type GetListOfApprovalTemporaryStorageQuery = { __typename?: 'Query', getListOfApprovalTemporaryStorage: { __typename?: 'GetListOfApprovalTemporaryStorageOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'ListOfApprovalTemporaryStorageEntity', approvalRequestIdx: number, formTemplateIdx: number, approvalType: Approval_Sort, signUpDatetime?: string | null, approvalTitle?: string | null, categoryId: number, formIdx?: number | null, extendStartDate: string, extendEndDate: string, extendStartDateTime: string, extendEndDateTime: string, name: string, attachedFileName?: string | null, attachedFileLink?: string | null }> | null } };

export type CountListOfApprovalTemporaryStorageQueryVariables = Exact<{
  searchType: ApprovalSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
}>;


export type CountListOfApprovalTemporaryStorageQuery = { __typename?: 'Query', countListOfApprovalTemporaryStorage: { __typename?: 'CountListOfApprovalTemporaryStorageOutput', ok: boolean, error?: string | null, count?: number | null } };

export type GetApprovalTemporaryStorageDetailInformationQueryVariables = Exact<{
  employeeId: Scalars['String'];
  approvalRequestIdx: Scalars['Int'];
}>;


export type GetApprovalTemporaryStorageDetailInformationQuery = { __typename?: 'Query', getApprovalTemporaryStorageDetailInformation: { __typename?: 'GetApprovalTemporaryStorageDetailInformationOutput', ok: boolean, error?: string | null, listOfApprovalUser?: Array<{ __typename?: 'ListOfApprovalUser', approvalRequestIdx: number, approverId: string, name: string, approvalOrder: number, approverType: Approval_User_Type, approvalResult?: Approval_Status | null, approvalTime?: string | null, signType?: SignType | null, signText?: string | null, sign_image?: Array<string> | null, signImage?: string | null }> | null, information?: { __typename?: 'ApprovalDetailInformationEntity', approvalRequestIdx: number, formTemplateTitle: string, employeeIdRequest: string, departmentName: string, employeeName: string, userPosition?: string | null, docNumber?: string | null, signUpDateTime?: string | null, categoryName: string, storePeriod?: string | null, formName?: string | null, extendSDate?: string | null, extendSDateTime?: string | null, extendEDate?: string | null, extendEDateTime?: string | null, approvalTitle?: string | null, temporaryExtendDescription?: string | null, attachedFileName?: string | null, attachedFileLink?: string | null, allocateDay?: string | null, annualInfo?: string | null } | null, listOfReceiveDepartment?: Array<{ __typename?: 'ListOfReceiveAndReferenceDepartmentInformation', departmentId: number, departmentName: string }> | null, listOfReceiveUser?: Array<{ __typename?: 'ListOfReceiveAndReferenceUserInformation', employeeId: string, employeeName: string }> | null, listOfReferenceDepartment?: Array<{ __typename?: 'ListOfReceiveAndReferenceDepartmentInformation', departmentId: number, departmentName: string }> | null, listOfReferenceUser?: Array<{ __typename?: 'ListOfReceiveAndReferenceUserInformation', employeeId: string, employeeName: string }> | null } };

export type DeleteApprovalTemporaryStorageMutationVariables = Exact<{
  approvalRequestIdx: Scalars['Int'];
}>;


export type DeleteApprovalTemporaryStorageMutation = { __typename?: 'Mutation', deleteApprovalTemporaryStorage: { __typename?: 'DeleteApprovalTemporaryStorageOutput', ok: boolean, error?: string | null } };

export type GetListOfApprovalBasicLineUserQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type GetListOfApprovalBasicLineUserQuery = { __typename?: 'Query', getListOfApprovalBasicLineUser: { __typename?: 'GetListOfApprovalBasicLineUserOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'UserOfSearchedApprovalLineEntity', approvalOrder: number, approvalType: Approval_User_Type, approvalInfo?: string | null, lineIdx: number, employeeId: string }> | null } };

export type GetAdditionalFieldInApprovalFormQueryVariables = Exact<{
  employeeId: Scalars['String'];
  year: Scalars['String'];
  categoryId: Scalars['Float'];
}>;


export type GetAdditionalFieldInApprovalFormQuery = { __typename?: 'Query', getAdditionalFieldInApprovalForm: { __typename?: 'GetAdditionalFieldApprovalFormOutput', ok: boolean, error?: string | null, info?: { __typename?: 'ApprovalAdditionalFieldEntity', totDay: number, useDay: number, leaveDay: number } | null } };

export type GetListOfApprovalTemplateQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type GetListOfApprovalTemplateQuery = { __typename?: 'Query', getListOfApprovalTemplate: { __typename?: 'GetListOfApprovalTemplateOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'ApprovalTemplateEntity', numRank: number, formTemplateIdx: number, templateTitle: string, name: string, formIdx: number, formName: string, approvalType: Approval_Sort, description?: string | null, categoryId: number }> | null } };

export type GetListOfApprovalFormQueryVariables = Exact<{ [key: string]: never; }>;


export type GetListOfApprovalFormQuery = { __typename?: 'Query', getListOfApprovalForm: { __typename?: 'GetListOTApprovalFormOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'OtApprovalFormEntity', formIdx: number, formName: string }> | null } };

export type GetListOfOtTimeTypeCategoryQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type GetListOfOtTimeTypeCategoryQuery = { __typename?: 'Query', getListOfOtTimeTypeCategory: { __typename?: 'GetListOfOtTImeTypeCategoryOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'OtTimeTypeCategoryEntity', categoryId: number, name: string }> | null } };

export type GetListOfApprovalLineQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type GetListOfApprovalLineQuery = { __typename?: 'Query', getListOfApprovalLine: { __typename?: 'GetListOfApprovalLineOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'ApprovalLineEntity', lineIdx: number, newTitle: string, basicLine: Is_Default_Approval_Line }> | null } };

export type ControlApprovalFormMutationVariables = Exact<{
  controlApprovalFormType: Type_Control_Approval_Form;
  formIdx: Scalars['Int'];
  templateName: Scalars['String'];
  categoryId: Scalars['Int'];
  approvalSort: Approval_Sort;
  formTemplateIdx?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type ControlApprovalFormMutation = { __typename?: 'Mutation', controlApprovalForm: { __typename?: 'ControlApprovalFormOutput', ok: boolean, error?: string | null, formTemplateIdx?: number | null } };

export type DeleteApprovalTemplateFormMutationVariables = Exact<{
  listOfFormTemplateIdx: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteApprovalTemplateFormMutation = { __typename?: 'Mutation', deleteApprovalTemplateForm: { __typename?: 'DeleteApprovalTemplateFormOutput', ok: boolean, error?: string | null } };

export type GetListOfSearchedApprovalLineUserQueryVariables = Exact<{
  employeeId: Scalars['String'];
  lineIdx: Scalars['Int'];
}>;


export type GetListOfSearchedApprovalLineUserQuery = { __typename?: 'Query', getListOfSearchedApprovalLineUser: { __typename?: 'GetListOfSearchedApprovalLineUserOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'UserOfSearchedApprovalLineEntity', approvalOrder: number, approvalType: Approval_User_Type, approvalInfo?: string | null, lineIdx: number, employeeId: string }> | null } };

export type AddApprovalLineMutationVariables = Exact<{
  approvalLineControlType: ApprovalLineControlType;
  user: Scalars['String'];
  approvalLineName: Scalars['String'];
  isDefaultApprovalLine: Is_Default_Approval_Line;
  count: Scalars['Int'];
  list: Array<ApprovalLineUserEntity> | ApprovalLineUserEntity;
}>;


export type AddApprovalLineMutation = { __typename?: 'Mutation', addApprovalLine: { __typename?: 'AddApprovalLineOutput', ok: boolean, error?: string | null, lineIdx?: number | null } };

export type EditApprovalLineMutationVariables = Exact<{
  approvalLineControlType: ApprovalLineControlType;
  user: Scalars['String'];
  approvalLineName: Scalars['String'];
  isDefaultApprovalLine: Is_Default_Approval_Line;
  count: Scalars['Int'];
  list: Array<ApprovalLineUserEntity> | ApprovalLineUserEntity;
  lineIdx: Scalars['Int'];
}>;


export type EditApprovalLineMutation = { __typename?: 'Mutation', editApprovalLine: { __typename?: 'EditApprovalLineOutput', ok: boolean, error?: string | null, lineIdx?: number | null } };

export type DeleteApprovalLineMutationVariables = Exact<{
  lineIdx: Scalars['Int'];
  user: Scalars['String'];
}>;


export type DeleteApprovalLineMutation = { __typename?: 'Mutation', deleteApprovalLine: { __typename?: 'DeleteApprovalLineOutput', ok: boolean, error?: string | null } };

export type GetApprovalAdminSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApprovalAdminSettingQuery = { __typename?: 'Query', getApprovalAdminSetting: { __typename?: 'GetApprovalAdminSettingOutput', ok: boolean, error?: string | null, preview?: Approval_Admin_Setting_Preview_Type | null, dashboardListCount?: number | null } };

export type EditApprovalAdminSettingMutationVariables = Exact<{
  preview: Approval_Admin_Setting_Preview_Type;
  count: Scalars['Int'];
}>;


export type EditApprovalAdminSettingMutation = { __typename?: 'Mutation', editApprovalAdminSetting: { __typename?: 'EditApprovalAdminSettingOutput', ok: boolean, error?: string | null } };

export type GetApprovalPasswordAndSignSettingInfoQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type GetApprovalPasswordAndSignSettingInfoQuery = { __typename?: 'Query', getApprovalPasswordAndSignSettingInfo: { __typename?: 'GetApprovalPasswordAndSignSettingInfoOutput', ok: boolean, error?: string | null, info?: { __typename?: 'ApprovalPasswordAndSignSettingInfoEntity', employeeId: string, signType: SignType, signText?: string | null, signImage?: string | null, signPassword: string, isSignPassword: Is_Sign_Password, isSignEmailAlarm: Is_Sign_Alarm_Type, isSignMobileAlarm: Is_Sign_Alarm_Type } | null } };

export type SaveApprovalPasswordAndSignSettingMutationVariables = Exact<{
  employeeId: Scalars['String'];
  signType: SignType;
  isSignPassword: Is_Sign_Password;
  isSignEmailAlarm: Is_Sign_Alarm_Type;
  isSignMobileAlarm: Is_Sign_Alarm_Type;
  signText?: InputMaybe<Scalars['String']>;
  signImage?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  signPassword?: InputMaybe<Scalars['String']>;
}>;


export type SaveApprovalPasswordAndSignSettingMutation = { __typename?: 'Mutation', saveApprovalPasswordAndSignSetting: { __typename?: 'SaveApprovalPasswordAndSignSettingOutput', ok: boolean, error?: string | null } };

export type DeleteApprovalSignImageOrTextMutationVariables = Exact<{
  employeeId: Scalars['String'];
  deleteSignType: SignType;
}>;


export type DeleteApprovalSignImageOrTextMutation = { __typename?: 'Mutation', deleteApprovalSignImageOrText: { __typename?: 'DeleteApprovalSignImageOrTextOutput', ok: boolean, error?: string | null } };

export type GetListOfApprovalLineFormQueryVariables = Exact<{
  employeeId: Scalars['String'];
  formTemplateIdx: Scalars['Int'];
}>;


export type GetListOfApprovalLineFormQuery = { __typename?: 'Query', getListOfApprovalLineForm: { __typename?: 'GetListOfApprovalLineFormOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'ApprovalLineFormEntity', numRank: number, templateTitle: string, approvalType: Approval_Sort, formName: string, name: string, descr?: string | null, formTemplateIdx: number, formIdx: number }> | null } };

export type AddCommuteDateTimeMutationVariables = Exact<{
  selectedEmployeeId: Array<Scalars['String']> | Scalars['String'];
  checkDateTime: Scalars['String'];
  commuteType: CommuteType;
  deviceType: Device_Type;
}>;


export type AddCommuteDateTimeMutation = { __typename?: 'Mutation', addCommuteDateTime: { __typename?: 'AddCommuteDateTimeOutput', ok: boolean, error?: string | null } };

export type ListOfEmployeeCommuteInformationQueryVariables = Exact<{
  listOfEmployeeId: Array<Scalars['String']> | Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  fieldSort?: InputMaybe<FieldSort>;
}>;


export type ListOfEmployeeCommuteInformationQuery = { __typename?: 'Query', listOfEmployeeCommuteInformation: { __typename?: 'ListOfEmployeeCommuteManagementOutput', ok: boolean, error?: string | null, cacheId?: string | null, list?: Array<{ __typename?: 'CommuteManagement', name: string, employeeId: string, checkDateTime?: string | null, date?: string | null, time?: string | null, commuteType: CommuteType, deviceType: string, gpsLat?: string | null, gpsLong?: string | null, commuteAddress?: string | null, approvalFlag: ApprovalFlag, checkDateTimeModify?: string | null }> | null } };

export type GetBasicUserInfoQueryVariables = Exact<{
  getBasicUserInfoId?: InputMaybe<Scalars['String']>;
}>;


export type GetBasicUserInfoQuery = { __typename?: 'Query', getBasicUserInfo: { __typename?: 'GetBasicUserInfoOutput', ok: boolean, error?: string | null, user?: { __typename?: 'BasicUserInfoEntity', empName: string, my_picture?: Array<string> | null, myPicture?: string | null, departmentId: number, nameChinese?: string | null, nameEnglish?: string | null, departmentName: string, userJob?: string | null, userPosition?: string | null, userRank?: string | null, joiningDate?: string | null, leavingDate?: string | null, phoneNum?: string | null, userEmail?: string | null } | null } };

export type UpdateBasicUserinfoMutationVariables = Exact<{
  employeeId: Scalars['String'];
  employeeName: Scalars['String'];
  myPicture: Scalars['String'];
  nameChinese?: InputMaybe<Scalars['String']>;
  nameEnglish?: InputMaybe<Scalars['String']>;
  userJob?: InputMaybe<Scalars['String']>;
  userPosition?: InputMaybe<Scalars['String']>;
  userRank?: InputMaybe<Scalars['String']>;
  joiningDate?: InputMaybe<Scalars['String']>;
  leavingDate?: InputMaybe<Scalars['String']>;
  phoneNum?: InputMaybe<Scalars['String']>;
  userEmail?: InputMaybe<Scalars['String']>;
}>;


export type UpdateBasicUserinfoMutation = { __typename?: 'Mutation', updateBasicUserinfo: { __typename?: 'UpdateBasicUserInfoOutput', ok: boolean, error?: string | null } };

export type GetUserAnnualInfoQueryVariables = Exact<{
  employeeId: Scalars['String'];
  annualYear: Scalars['String'];
}>;


export type GetUserAnnualInfoQuery = { __typename?: 'Query', getUserAnnualInfo: { __typename?: 'GetUserAnnualInfoOutput', ok: boolean, error?: string | null, userAnnualInfo?: { __typename?: 'UserAnnualInfoEntity', employeeId: string, applyYear: string, statusFlag?: string | null, insertDatetime: string, createSubject?: string | null, periodateStart?: string | null, periodateEnd?: string | null, basicDay: number, basicModifyDay: number, addDay: number, leaveCarriedForwardDay: number, useModifyDay: number, modifyReason?: string | null, modifyDatetime?: string | null, useDay: number, leaveDay: number } | null } };

export type UpdateUserAnnualInfoMutationVariables = Exact<{
  employeeId: Scalars['String'];
  applyYear: Scalars['String'];
  createSubject: Scalars['String'];
  createSubjectEmployeeId: Scalars['String'];
  periodateStart: Scalars['String'];
  periodateEnd: Scalars['String'];
  basicDay: Scalars['Int'];
  basicModifyDay: Scalars['Int'];
  addDay: Scalars['Int'];
  leaveCarriedForwardDay: Scalars['Int'];
  useModifyDay: Scalars['Int'];
  statusFlag?: InputMaybe<Scalars['String']>;
  modifyReason?: InputMaybe<Scalars['String']>;
}>;


export type UpdateUserAnnualInfoMutation = { __typename?: 'Mutation', updateUserAnnualInfo: { __typename?: 'UpdateUserAnnualInfoOutput', ok: boolean, error?: string | null } };

export type GetAnnualHistoryQueryVariables = Exact<{
  employeeId: Scalars['String'];
  applyYear: Scalars['String'];
}>;


export type GetAnnualHistoryQuery = { __typename?: 'Query', getAnnualHistory: { __typename?: 'GetAnnualHistoryOutput', ok: boolean, error?: string | null, history?: Array<{ __typename?: 'AnnualHistoryEntity', logIdx: number, employeeId: string, applyYear: string, statusFlag: string, createSubject: string, periodateStart: string, periodateEnd: string, basicDay: number, basicModifyDay: number, addDay: number, leaveCarriedForwardDay: number, useModifyDay: number, modifyReason?: string | null, modifyDatetime: string }> | null } };

export type DownloadAnnualHistoryAsExcelQueryVariables = Exact<{
  employeeId: Scalars['String'];
  applyYear: Scalars['String'];
}>;


export type DownloadAnnualHistoryAsExcelQuery = { __typename?: 'Query', downloadAnnualHistoryAsExcel: { __typename?: 'DownloadAnnualHistoryAsExcelOutput', ok: boolean, error?: string | null, excel?: string | null } };

export type GetAnnualStatusListCountQueryVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  searchYear: Scalars['String'];
}>;


export type GetAnnualStatusListCountQuery = { __typename?: 'Query', getAnnualStatusListCount: { __typename?: 'GetAnnualStatusListCountOutput', ok: boolean, error?: string | null, count?: number | null } };

export type GetAnnualStatusListQueryVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  searchYear: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  fieldSort?: InputMaybe<FieldSort>;
}>;


export type GetAnnualStatusListQuery = { __typename?: 'Query', getAnnualStatusList: { __typename?: 'GetAnnualStatusListOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'AnnualStatusEntity', deptName?: string | null, employeeId: string, empName?: string | null, joiningDate?: string | null, applyYear?: string | null, annualDay?: number | null, annualUseDay?: number | null, annualRemainingDay?: number | null, descr?: string | null }> | null } };

export type GetAnnualStatusListExcelQueryVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  searchYear: Scalars['String'];
}>;


export type GetAnnualStatusListExcelQuery = { __typename?: 'Query', getAnnualStatusListExcel: { __typename?: 'GetAnnualStatusListExcelOutput', ok: boolean, error?: string | null, excel?: string | null } };

export type EditAnnualStatusMutationVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  searchYear: Scalars['String'];
  annualDay?: InputMaybe<Scalars['Float']>;
  descr?: InputMaybe<Scalars['String']>;
}>;


export type EditAnnualStatusMutation = { __typename?: 'Mutation', editAnnualStatus: { __typename?: 'EditAnnualStatusOutput', ok: boolean, error?: string | null } };

export type AddAnnualStatusFormExcelMutationVariables = Exact<{
  info: Array<EditAnnualStatusInput> | EditAnnualStatusInput;
}>;


export type AddAnnualStatusFormExcelMutation = { __typename?: 'Mutation', addAnnualStatusFormExcel: { __typename?: 'AddAnnualStatusFormExcelOutput', ok: boolean, error?: string | null } };

export type GetApprovalStatusListCountQueryVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  searchStartDate: Scalars['String'];
  searchEndDate: Scalars['String'];
  searchType: IApprovalStatusSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
}>;


export type GetApprovalStatusListCountQuery = { __typename?: 'Query', getApprovalStatusListCount: { __typename?: 'GetApprovalStatusListCountOutput', ok: boolean, error?: string | null, count?: number | null } };

export type GetApprovalStatusListQueryVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  searchStartDate: Scalars['String'];
  searchEndDate: Scalars['String'];
  searchType: IApprovalStatusSearchType;
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  fieldSort?: InputMaybe<FieldSort>;
  searchValue?: InputMaybe<Scalars['String']>;
}>;


export type GetApprovalStatusListQuery = { __typename?: 'Query', getApprovalStatusList: { __typename?: 'GetApprovalStatusListOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'ApprovalStatusEntity', deptName?: string | null, employeeId: string, empName?: string | null, requestDateTime?: string | null, categoryName?: string | null, startDateTime?: string | null, endDateTime?: string | null, requestDescr?: string | null, processStatus?: string | null, processDateTime?: string | null, processDescr?: string | null }> | null } };

export type GetApprovalStatusListExcelQueryVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  searchStartDate: Scalars['String'];
  searchEndDate: Scalars['String'];
  searchType: IApprovalStatusSearchType;
  searchValue?: InputMaybe<Scalars['String']>;
}>;


export type GetApprovalStatusListExcelQuery = { __typename?: 'Query', getApprovalStatusListExcel: { __typename?: 'GetApprovalStatusListExcelOutput', ok: boolean, error?: string | null, excel?: string | null } };

export type GetUserDailyWorkPlanQueryVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  fieldSort?: InputMaybe<FieldSort>;
}>;


export type GetUserDailyWorkPlanQuery = { __typename?: 'Query', getUserDailyWorkPlan: { __typename?: 'GetUserDailyWorkPlanOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'UserDailyWorkPlanEntity', departmentName: string, employeeId: string, employeeName: string, startDate: string, weekDayText: string, startTime: string, endTime: string, restPlanMin: string, recognitionPlanTime: string, inCheckDatetime?: string | null, outCheckDatetime?: string | null, restAdmitMin?: string | null, breakTimeAdjustmentMin?: string | null, recognitionAdmitTime?: string | null, payTime?: string | null, isCheckStatus: Is_Active }> | null } };

export type GetUserDailyWorkPlanExcelQueryVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetUserDailyWorkPlanExcelQuery = { __typename?: 'Query', getUserDailyWorkPlanExcel: { __typename?: 'GetUserDailyWorkPlanExcelOutput', ok: boolean, error?: string | null, excel?: string | null } };

export type GetUserDailyWorkPlanCountQueryVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetUserDailyWorkPlanCountQuery = { __typename?: 'Query', getUserDailyWorkPlanCount: { __typename?: 'GetUserDailyWorkPlanCountOutput', ok: boolean, error?: string | null, count?: number | null } };

export type AddUserDailyWorkInfoMutationVariables = Exact<{
  info: Array<AddUserDailyWorkInfoType> | AddUserDailyWorkInfoType;
}>;


export type AddUserDailyWorkInfoMutation = { __typename?: 'Mutation', addUserDailyWorkInfo: { __typename?: 'AddUserDailyWorkInfoOutput', ok: boolean, error?: string | null } };

export type DeleteUserDailyWorkInfoMutationVariables = Exact<{
  info: Array<UserDailyWorkInfo> | UserDailyWorkInfo;
}>;


export type DeleteUserDailyWorkInfoMutation = { __typename?: 'Mutation', deleteUserDailyWorkInfo: { __typename?: 'DeleteUserDailyWorkInfoOutput', ok: boolean, error?: string | null } };

export type EditUserDailyWorkInfoMutationVariables = Exact<{
  employeeId: Scalars['String'];
  date: Scalars['String'];
  beforeDate: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  breakTimeAdjustmentMin?: InputMaybe<Scalars['String']>;
}>;


export type EditUserDailyWorkInfoMutation = { __typename?: 'Mutation', editUserDailyWorkInfo: { __typename?: 'EditUserDailyWorkInfoOutput', ok: boolean, error?: string | null } };

export type GetUserMonthlyWorkPlanQueryVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
}>;


export type GetUserMonthlyWorkPlanQuery = { __typename?: 'Query', getUserMonthlyWorkPlan: { __typename?: 'GetUserMonthlyWorkPlanOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'UserMonthlyWorkPlanEntity', departmentName: string, employeeId: string, employeeName: string, monthStartDay: string, monthLastDay: string, startTime?: string | null, endTime?: string | null, restPlanMin: string, recognitionPlanTime: string, inCheckDatetime?: string | null, outCheckDatetime?: string | null, restAdmitMin?: string | null, recognitionAdmitTime?: string | null, payTime?: string | null, isSendStatus: Is_Active, isCheckStatus: Is_Active, isClosingStatus: Is_Active }> | null } };

export type GetUserMonthlyWorkExcelPlanQueryVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type GetUserMonthlyWorkExcelPlanQuery = { __typename?: 'Query', getUserMonthlyWorkExcelPlan: { __typename?: 'GetUserMonthlyWorkExcelPlanOutput', ok: boolean, error?: string | null, excel?: string | null } };

export type CountUserMonthlyWorkPlanQueryVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type CountUserMonthlyWorkPlanQuery = { __typename?: 'Query', countUserMonthlyWorkPlan: { __typename?: 'CountUserMonthlyWorkPlanOutput', ok: boolean, error?: string | null, count?: number | null } };

export type SendUserMonthlyWorkInfoMutationVariables = Exact<{
  info: Array<SendUserMonthlyWorkInfo> | SendUserMonthlyWorkInfo;
}>;


export type SendUserMonthlyWorkInfoMutation = { __typename?: 'Mutation', sendUserMonthlyWorkInfo: { __typename?: 'SendUserMonthlyWorkInfoOutput', ok: boolean, error?: string | null } };

export type EditUserMonthlyWorkInfoMutationVariables = Exact<{
  info: Array<EditUserMonthlyWorkInfo> | EditUserMonthlyWorkInfo;
}>;


export type EditUserMonthlyWorkInfoMutation = { __typename?: 'Mutation', editUserMonthlyWorkInfo: { __typename?: 'EditUserMonthlyWorkInfoOutput', ok: boolean, error?: string | null } };

export type CountListOfEmployeeInformationQueryVariables = Exact<{
  selectedDepartmentId: Scalars['String'];
  searchType?: InputMaybe<Type_Of_Employee_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
}>;


export type CountListOfEmployeeInformationQuery = { __typename?: 'Query', countListOfEmployeeInformation: { __typename?: 'CountListOfEmployeeInformationOutput', ok: boolean, error?: string | null, total?: number | null } };

export type GetEmployeeInformationQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  selectedDepartmentId: Scalars['String'];
  searchType?: InputMaybe<Type_Of_Employee_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
  fieldSort?: InputMaybe<FieldSort>;
}>;


export type GetEmployeeInformationQuery = { __typename?: 'Query', getEmployeeInformation: { __typename?: 'GetEmployeeInformationOutput', ok: boolean, error?: string | null, listOfEmployee?: Array<{ __typename?: 'EmployeeInformation', employeeId: string, name: string, departmentId: string, fullDepartmentName: string, departName: string, endpointCount: number, insertType: number, workingName: string, workingTemplateIdx?: number | null, asTimeTemplateName: string, approvalDefine: number, col3: string, col2: string, col1: string, col4: string, col5: string, col6: string, col7: string, col8: string, col9: string, col10: string, col11: string, col12: string, col13: string, col14: string, col15: string, wtAsextto?: string | null, weekMaxExtWorkHour?: string | null, wtTot?: string | null, weekMaxWorkHour?: string | null, todayWorkingTime?: string | null }> | null } };

export type DownloadListOfEmployeeInformationExcelQueryVariables = Exact<{
  selectedDepartmentId: Scalars['String'];
  searchType?: InputMaybe<Type_Of_Employee_Information>;
  searchValue?: InputMaybe<Scalars['String']>;
}>;


export type DownloadListOfEmployeeInformationExcelQuery = { __typename?: 'Query', downloadListOfEmployeeInformationExcel: { __typename?: 'DownloadListOfEmployeeInformationExcelOutput', ok: boolean, error?: string | null, excel?: string | null } };

export type GetMainTokenInfoQueryVariables = Exact<{
  employeeId?: InputMaybe<Scalars['String']>;
  tokenField?: InputMaybe<Scalars['String']>;
}>;


export type GetMainTokenInfoQuery = { __typename?: 'Query', getMainTokenInfo: { __typename?: 'GetMainTokenInfoOutput', ok: boolean, error?: string | null, info?: Array<{ __typename?: 'MainTokenInfoEntity', tokenField: string, allowFlag: Allowed_Flag_Type }> | null } };

export type UpdateMainTokenAllowFlagMutationVariables = Exact<{
  tokenField: Scalars['String'];
  allowedFlag: Allowed_Flag_Type;
}>;


export type UpdateMainTokenAllowFlagMutation = { __typename?: 'Mutation', updateMainTokenAllowFlag: { __typename?: 'UpdateMainTokenAllowedFlagOutput', ok: boolean, error?: string | null } };

export type DeleteMainTokenInfoMutationVariables = Exact<{
  tokenField: Scalars['String'];
}>;


export type DeleteMainTokenInfoMutation = { __typename?: 'Mutation', deleteMainTokenInfo: { __typename?: 'DeleteMainTokenInfoOutput', ok: boolean, error?: string | null } };

export type GetSubTokenInfoQueryVariables = Exact<{
  tokenFiled: Scalars['String'];
}>;


export type GetSubTokenInfoQuery = { __typename?: 'Query', getSubTokenInfo: { __typename?: 'GetSubTokenInfoOutput', ok: boolean, error?: string | null, info?: Array<{ __typename?: 'SubTokenInfoEntity', tokenField: string, employeeId: string, name: string, departmentName: string, allowFlag: Allowed_Flag_Type }> | null } };

export type UpdateSubTokenAllowedFlagMutationVariables = Exact<{
  employeeId: Scalars['String'];
  tokenField: Scalars['String'];
  allowedFlag: Allowed_Flag_Type;
}>;


export type UpdateSubTokenAllowedFlagMutation = { __typename?: 'Mutation', updateSubTokenAllowedFlag: { __typename?: 'UpdateSubTokenAllowedFlagOutput', ok: boolean, error?: string | null } };

export type DeleteSubTokenInfoMutationVariables = Exact<{
  tokenField: Scalars['String'];
  employeeId: Scalars['String'];
}>;


export type DeleteSubTokenInfoMutation = { __typename?: 'Mutation', deleteSubTokenInfo: { __typename?: 'DeleteSubTokenInfoOutput', ok: boolean, error?: string | null } };

export type GetCountStatisticsConditionQueryVariables = Exact<{
  startDate: Scalars['String'];
  listOfDepartmentId: Array<Scalars['String']> | Scalars['String'];
  employeeId?: InputMaybe<Scalars['String']>;
}>;


export type GetCountStatisticsConditionQuery = { __typename?: 'Query', getCountStatisticsCondition: { __typename?: 'GetCountStatisticsConditionOutput', ok: boolean, error?: string | null, total?: number | null } };

export type GetStatisticsConditionListQueryVariables = Exact<{
  startDate: Scalars['String'];
  listOfDepartmentId: Array<Scalars['String']> | Scalars['String'];
  page?: InputMaybe<Scalars['Float']>;
  take?: InputMaybe<Scalars['Float']>;
  fieldSort?: InputMaybe<FieldSort>;
  employeeId?: InputMaybe<Scalars['String']>;
}>;


export type GetStatisticsConditionListQuery = { __typename?: 'Query', getStatisticsConditionList: { __typename?: 'GetStatisticsConditionListOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'StatisticsConditionEntity', periodInformation: string, departName: string, employeeId: string, empName: string, workingTemplateName?: string | null, totPossibleMin?: string | null, totWorkPossibleMin?: string | null, useWorkMin?: string | null, totWorkPossibleLeaveMin?: string | null, totExtPossibleMin?: string | null, useExtMin?: string | null, totExtPossibleLeaveMin?: string | null, totMonWorkDay?: string | null, totMonHoliDay?: string | null, totMonNormalWorkDay?: string | null, totMonVacationDay?: string | null, totMonEducationDay?: string | null, totMonBusinessTripDay?: string | null, totMonAbsentDay?: string | null, totMonLateDay?: string | null, totMonEarlyDay?: string | null, totMonSickDay?: string | null, totMonFurloughDay?: string | null }> | null } };

export type GetStatisticsConditionExcelQueryVariables = Exact<{
  startDate: Scalars['String'];
  listOfDepartmentId: Array<Scalars['String']> | Scalars['String'];
  employeeId?: InputMaybe<Scalars['String']>;
}>;


export type GetStatisticsConditionExcelQuery = { __typename?: 'Query', getStatisticsConditionExcel: { __typename?: 'GetStatisticsConditionExcelOutput', ok: boolean, error?: string | null, excel?: string | null } };

export type GetAttendanceDetailsListQueryVariables = Exact<{
  startDate: Scalars['String'];
  listOfDepartmentId: Array<Scalars['String']> | Scalars['String'];
  fieldSort?: InputMaybe<FieldSort>;
  employeeId?: InputMaybe<Scalars['String']>;
}>;


export type GetAttendanceDetailsListQuery = { __typename?: 'Query', getAttendanceDetailsList: { __typename?: 'GetAttendanceDetailsListOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'AttendanceDetailsEntity', periodInformation: string, dateValue: string, dayOfWeek: string, departName: string, employeeId: string, empName: string, workingTemplateName?: string | null, totPossibleMin?: string | null, totWorkPossibleMin?: string | null, useWorkMin?: string | null, totWorkPossibleLeaveMin?: string | null, totExtPossibleMin?: string | null, useExtMin?: string | null, totExtPossibleLeaveMin?: string | null, workStartTime?: string | null, workEndTime?: string | null, restMin?: string | null }> | null } };

export type GetAttendanceDetailsListExcelQueryVariables = Exact<{
  startDate: Scalars['String'];
  listOfDepartmentId: Array<Scalars['String']> | Scalars['String'];
  employeeId?: InputMaybe<Scalars['String']>;
  fieldSort?: InputMaybe<FieldSort>;
}>;


export type GetAttendanceDetailsListExcelQuery = { __typename?: 'Query', getAttendanceDetailsListExcel: { __typename?: 'GetAttendanceDetailsListExcelOutput', ok: boolean, error?: string | null, excel?: string | null } };

export type GetMobilWebViewInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMobilWebViewInfoQuery = { __typename?: 'Query', getMobilWebViewInfo: { __typename?: 'GetMobilWebViewInfoOutput', ok: boolean, error?: string | null, webViewInfo?: { __typename?: 'MobileWebViewEntity', appWebviewButtonStatus: Is_Show, appWebViewButtonName: string, appWebViewButtonLink: string, appWebViewMonthWorkInfoStatus: Is_Show, webApprovalButtonName: string } | null } };

export type GetAbsenceManagementInfoQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
}>;


export type GetAbsenceManagementInfoQuery = { __typename?: 'Query', getAbsenceManagementInfo: { __typename?: 'GetAbsenceManagementInfoOutput', ok: boolean, error?: string | null, info?: { __typename?: 'AbsenceManagementEntity', workingTemplateIdx: number, leftSeatBlackScreenUse: Is_Use, leftSeatPlaceInput: Is_Use, leftSeatAttendeeInput: Is_Use, leftSeatAutoUse: Is_Use, leftSeatBaseMin: number, leftSeatReasonUse: Is_Use, extendLeftSeatAutoCommuteOutUse: Is_Use, leftSeatApplyTimeUse: Is_Use } | null } };

export type AddAbsenceManagementInfoMutationVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  leftSeatBlackScreenUse: Is_Use;
  leftSeatPlaceInput: Is_Use;
  leftSeatAttendeeInput: Is_Use;
  leftSeatAutoUse: Is_Use;
  leftSeatBaseMin: Scalars['Int'];
  leftSeatReasonUse: Is_Use;
  extendLeftSeatAutoCommuteOutUse: Is_Use;
  leftSeatApplyTimeUse: Is_Use;
}>;


export type AddAbsenceManagementInfoMutation = { __typename?: 'Mutation', addAbsenceManagementInfo: { __typename?: 'AddAbsenceManagementInfoOutput', ok: boolean, error?: string | null } };

export type GetAutonomyWorkingHourInfoQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
}>;


export type GetAutonomyWorkingHourInfoQuery = { __typename?: 'Query', getAutonomyWorkingHourInfo: { __typename?: 'GetAutonomyWorkingHourInfoOutput', ok: boolean, error?: string | null, basicInfo?: { __typename?: 'OtWorkingFreeStartBasicEntity', workingTemplateIdx: number, startTime: string, endTime: string, dayOfWeekType: Day_Of_Week_Type, dayOfWeek: Array<Day_Type>, breakTimeType: Break_Time_Type, startBreakTime: string, endBreakTime: string } | null } };

export type AddAutonomyWorkingHourInfoMutationVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  dayOfWeekType: Day_Of_Week_Type;
  dayOfWeek: Array<Day_Type> | Day_Type;
  breakTimeType: Break_Time_Type;
  startBreakTime: Scalars['String'];
  endBreakTime: Scalars['String'];
}>;


export type AddAutonomyWorkingHourInfoMutation = { __typename?: 'Mutation', addAutonomyWorkingHourInfo: { __typename?: 'AddAutonomyWorkingHourInfoOutput', ok: boolean, error?: string | null } };

export type GetSelfDeterminedWorkHourInfoQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
}>;


export type GetSelfDeterminedWorkHourInfoQuery = { __typename?: 'Query', getSelfDeterminedWorkHourInfo: { __typename?: 'GetSelfDeterminedWorkInfoOutput', ok: boolean, error?: string | null, otWorkingStandardBasic?: { __typename?: 'SelfDeterminedWorkHourBasicBasicEntity', workingTemplateIdx: number, startBreakTime: string, endBreakTime: string } | null, basicInfo?: { __typename?: 'WTGraphBasicEntity', name: string, workType: string, dayMaxWorkHour: number, weekMaxWorkHour: number, dayOfWeekStart: Day_Type } | null, otWSHoursList?: Array<{ __typename?: 'OtSelfWorkingStandardHoursEntity', workingTemplateIdx?: number | null, workingTimeName: string, useFlag?: Is_Use | null, operatingPeriodType: Operating_Period_Type, operatingPeriodStartDate: string, operatingPeriodEndDate: string, workHoursType: Work_Hours_Setting_Type, startTime: string, endTime: string, dutyWorkHoursType: Is_Use, dutyStartTime: string, dutyEndTime: string, dayOfWeekType: Work_Day_Of_Week, dayOfWeek: Array<Day_Type>, breakTimeType: Break_Time_Type, startBreakTime: string, endBreakTime: string }> | null } };

export type GetSelfDeterminedWorkTemplateHoursQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  workingTimeName: Scalars['String'];
}>;


export type GetSelfDeterminedWorkTemplateHoursQuery = { __typename?: 'Query', getSelfDeterminedWorkTemplateHours: { __typename?: 'GetSelfDeterminedWorkTemplateHoursOutput', ok: boolean, error?: string | null, template?: { __typename?: 'OtSelfWorkingStandardHoursEntity', workingTemplateIdx?: number | null, workingTimeName: string, useFlag?: Is_Use | null, operatingPeriodType: Operating_Period_Type, operatingPeriodStartDate: string, operatingPeriodEndDate: string, workHoursType: Work_Hours_Setting_Type, startTime: string, endTime: string, dutyWorkHoursType: Is_Use, dutyStartTime: string, dutyEndTime: string, dayOfWeekType: Work_Day_Of_Week, dayOfWeek: Array<Day_Type>, breakTimeType: Break_Time_Type, startBreakTime: string, endBreakTime: string } | null } };

export type AddSelfDeterminedWorkHoursMutationVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  startBreakTime: Scalars['String'];
  endBreakTime: Scalars['String'];
  workingTimeName: Array<Scalars['String']> | Scalars['String'];
}>;


export type AddSelfDeterminedWorkHoursMutation = { __typename?: 'Mutation', addSelfDeterminedWorkHours: { __typename?: 'AddSelfDeterminedWorkHoursOutput', ok: boolean, error?: string | null } };

export type AddSelfDeterminedWorkHourTemplateMutationVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  workingTimeName: Scalars['String'];
  operatingPeriodType: Operating_Period_Type;
  operatingPeriodStartDate: Scalars['String'];
  operatingPeriodEndDate: Scalars['String'];
  dayOfWeekType: Work_Day_Of_Week;
  dayOfWeek: Array<Day_Type> | Day_Type;
  workHoursType: Work_Hours_Setting_Type;
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  dutyWorkHoursType: Is_Use;
  dutyStartTime: Scalars['String'];
  dutyEndTime: Scalars['String'];
  breakTimeType: Break_Time_Type;
  startBreakTime: Scalars['String'];
  endBreakTime: Scalars['String'];
}>;


export type AddSelfDeterminedWorkHourTemplateMutation = { __typename?: 'Mutation', addSelfDeterminedWorkHourTemplate: { __typename?: 'AddSelfDeterminedWorkHourTemplateOutput', ok: boolean, error?: string | null } };

export type UpdateSelfDeterminedWorkHourTemplateMutationVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  workingTimeName: Scalars['String'];
  operatingPeriodType: Operating_Period_Type;
  operatingPeriodStartDate: Scalars['String'];
  operatingPeriodEndDate: Scalars['String'];
  dayOfWeekType: Work_Day_Of_Week;
  dayOfWeek: Array<Day_Type> | Day_Type;
  workHoursType: Work_Hours_Setting_Type;
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  dutyWorkHoursType: Is_Use;
  dutyStartTime: Scalars['String'];
  dutyEndTime: Scalars['String'];
  breakTimeType: Break_Time_Type;
  startBreakTime: Scalars['String'];
  endBreakTime: Scalars['String'];
  oldWorkingTimeName: Scalars['String'];
}>;


export type UpdateSelfDeterminedWorkHourTemplateMutation = { __typename?: 'Mutation', updateSelfDeterminedWorkHourTemplate: { __typename?: 'UpdateSelfDeterminedWorkHourTemplateOutput', ok: boolean, error?: string | null } };

export type DeleteSelfDeterminedWorkHourTemplateMutationVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  workingTimeNameList: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteSelfDeterminedWorkHourTemplateMutation = { __typename?: 'Mutation', deleteSelfDeterminedWorkHourTemplate: { __typename?: 'DeleteSelfDeterminedWorkHourTemplateOutput', ok: boolean, error?: string | null } };

export type GetControlAlertQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
}>;


export type GetControlAlertQuery = { __typename?: 'Query', getControlAlert: { __typename?: 'GetControlAlertOutput', ok: boolean, error?: string | null, info?: { __typename?: 'ControlAlertEntity', workingTemplateIdx: number, beforeStartWorkingMin: number, beforeStartWorkingCtrlMsg?: number | null, beforeEndWorkingMin?: number | null, beforeEndWorkingCtrlMsg?: number | null, afterEndWorkingCtrlMsg?: number | null, breakTimeCtrlMsg?: number | null, exceedCtrlMsgBeforeMinOne?: number | null, exceedCtrlMsgBeforeOne?: number | null, exceedCtrlMsgBeforeMinTwo?: number | null, exceedCtrlMsgBeforeTwo?: number | null, exceedCtrlMsgAfter?: number | null } | null } };

export type GetControlNotificationCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetControlNotificationCategoryQuery = { __typename?: 'Query', getControlNotificationCategory: { __typename?: 'GetControlNotificationCategoryOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'CtrlMsgTemplateEntity', ctrlMsgTemplateIdx: number, name: string }> | null } };

export type AddControlNotificationMutationVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  beforeStartWorkingMin?: InputMaybe<Scalars['Int']>;
  beforeStartWorkingCtrlMsg?: InputMaybe<Scalars['Int']>;
  beforeEndWorkingMin?: InputMaybe<Scalars['Int']>;
  beforeEndWorkingCtrlMsg?: InputMaybe<Scalars['Int']>;
  afterEndWorkingCtrlMsg?: InputMaybe<Scalars['Int']>;
  breakTimeCtrlMsg?: InputMaybe<Scalars['Int']>;
  exceedCtrlMsgBeforeMinOne?: InputMaybe<Scalars['Int']>;
  exceedCtrlMsgBeforeOne?: InputMaybe<Scalars['Int']>;
  exceedCtrlMsgBeforeMinTwo?: InputMaybe<Scalars['Int']>;
  exceedCtrlMsgBeforeTwo?: InputMaybe<Scalars['Int']>;
  exceedCtrlMsgAfter?: InputMaybe<Scalars['Int']>;
}>;


export type AddControlNotificationMutation = { __typename?: 'Mutation', addControlNotification: { __typename?: 'AddControlNotificationOutput', ok: boolean, error?: string | null } };

export type GetEtcOperatingHoursQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
}>;


export type GetEtcOperatingHoursQuery = { __typename?: 'Query', getEtcOperatingHours: { __typename?: 'GetEtcOperatingHoursOutput', ok: boolean, error?: string | null, info?: Array<{ __typename?: 'GetEtcOperatingHoursEntity', workingTemplateIdx: number, holidayTemplateIdx: number }> | null } };

export type GetEtcOperatingHourListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEtcOperatingHourListQuery = { __typename?: 'Query', getEtcOperatingHourList: { __typename?: 'GetEtcOperatingHourListOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'EtcOperatingHourEntity', holidayTemplateIdx: number, name: string, weekSequence: string, daySetType: string, dayDateOrWeek: string, startDateTime: string, endDateTime: string, ctrlName: string }> | null } };

export type AddEtcOperatingHoursMutationVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  otHolidayTemplateNewAutoUse: Is_Use;
  holidayTemplateIdx: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddEtcOperatingHoursMutation = { __typename?: 'Mutation', addEtcOperatingHours: { __typename?: 'AddEtcOperatingHoursOutput', ok: boolean, error?: string | null } };

export type GetFlexibleWorkingHourInfoQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
}>;


export type GetFlexibleWorkingHourInfoQuery = { __typename?: 'Query', getFlexibleWorkingHourInfo: { __typename?: 'GetFlexibleWorkingHourInfoOutput', ok: boolean, error?: string | null, basicInfo?: { __typename?: 'OtWorkingFlexibleBasicEntity', workingTemplateIdx?: number | null, startTime: string, endTime: string, settlementSectionHour: number, workHoursType: Work_Hours_Type, dayOfWeekType: Day_Of_Week_Type, dayOfWeek: Array<Day_Type>, startDate: string, startBreakTime: string, endBreakTime: string } | null, extraList?: Array<{ __typename?: 'OtWorkingFlexibleHoursEntity', workingTemplateIdx?: number | null, weekSequence: number, dayOfWeek: Day_Type, workHour: number, startTime: string, endTime: string, startBreakTime: string, endBreakTime: string }> | null } };

export type AddFlexibleWorkingHourInfoMutationVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  settlementSectionHour: Scalars['Int'];
  workHoursType: Work_Hours_Type;
  dayOfWeekType: Day_Of_Week_Type;
  dayOfWeek: Array<Day_Type> | Day_Type;
  startDate: Scalars['String'];
  startBreakTime: Scalars['String'];
  endBreakTime: Scalars['String'];
  otWorkingFlexibleHours: Array<OtWorkingFlexibleHours> | OtWorkingFlexibleHours;
}>;


export type AddFlexibleWorkingHourInfoMutation = { __typename?: 'Mutation', addFlexibleWorkingHourInfo: { __typename?: 'AddFlexibleWorkingHourInfoOutput', ok: boolean, error?: string | null } };

export type EditUserDailyWorkCheckInfoMutationVariables = Exact<{
  employeeId: Scalars['String'];
  dailyCheckInfo: Array<UserDailyCheckInfo> | UserDailyCheckInfo;
}>;


export type EditUserDailyWorkCheckInfoMutation = { __typename?: 'Mutation', editUserDailyWorkCheckInfo: { __typename?: 'EditUserDailyCheckInfoOutput', ok: boolean, error?: string | null } };

export type GetCommuteRecordInfoQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
}>;


export type GetCommuteRecordInfoQuery = { __typename?: 'Query', getCommuteRecordInfo: { __typename?: 'GetCommuteRecordInfoOutput', ok: boolean, error?: string | null, info?: { __typename?: 'CommuteRecordInfoEntity', commuteTemplateIdx?: number | null, pcUse?: Is_Use | null, appUse?: Is_Use | null, startWorkingMin?: number | null, endWorkingMin?: number | null, approvalDataType?: Is_Use | null, dayOfWeek?: Array<Day_Type> | null, unCheckoutAutoCheckoutFlag: Is_Check_Auto_Time, unCheckoutAutoCheckoutTime: string, checkInStartWorkTimeSetFlag: Is_Use, checkoutEndWorkTimeSetFlag: Is_Use } | null } };

export type AddCommuteRecordInfoMutationVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  name: Scalars['String'];
  pcUse: Is_Use;
  appUse: Is_Use;
  startWorkingMin: Scalars['Int'];
  endWorkingMin: Scalars['Int'];
  approvalDataType: Is_Use;
  dayOfWeek: Array<Day_Type> | Day_Type;
  unCheckoutAutoCheckoutFlag: Is_Check_Auto_Time;
  unCheckoutAutoCheckoutTime: Scalars['String'];
  checkInStartWorkTimeSetFlag: Is_Use;
  checkoutEndWorkTimeSetFlag: Is_Use;
}>;


export type AddCommuteRecordInfoMutation = { __typename?: 'Mutation', addCommuteRecordInfo: { __typename?: 'AddCommuteRecordInfoOutput', ok: boolean, error?: string | null } };

export type GetUserQueryVariables = Exact<{
  getUserId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  departmentId?: InputMaybe<Scalars['Int']>;
  like?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'GetUserOutput', ok: boolean, error?: string | null, user?: Array<{ __typename?: 'UserEntity', employeeId: string, departmentId?: number | null, fullDepartmentName?: string | null, workingTemplateIdx?: number | null, timeTemplateIdx?: number | null, leftSeatUse: number, asonicWidgetUse?: number | null, approvalDefine: number, name?: string | null, password: string, newData?: number | null, newDataTime?: string | null, manageable?: number | null, insertType?: number | null, policyWorkingMin?: number | null, workingMin?: number | null, policy_ext_working_min?: number | null, extWorkingMin?: number | null, tokenField?: number | null, myPicture?: string | null, fcmToken?: string | null, delegatorId?: string | null, approvalStartDate?: string | null, approvalEndDate?: string | null, userEmail?: string | null, webAppAuth?: number | null, appPhoneNum?: string | null, col1?: string | null, col2?: string | null, col3?: string | null, col4?: string | null, col5?: string | null, col6?: string | null, col7?: string | null, col8?: string | null, col9?: string | null, col10?: string | null, col11?: string | null, col12?: string | null, col13?: string | null, col14?: string | null, col15?: string | null, fpProcess: number, policyUpdateStatus: number, fpProcessTwo: number, policyUpdateStatusTwo: number, memo?: string | null, userPosition?: string | null }> | null } };

export type GetGroupsForAllQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupsForAllQuery = { __typename?: 'Query', getGroupsForAll: { __typename?: 'GetGroupOutput', ok: boolean, error?: string | null, listOfGroup?: Array<{ __typename?: 'GroupEntity', departmentId: number, parentDepartmentId: number, name: string, level: string, fullGroupName: string, insertType: number, countEmployee?: number | null, countEndpoint?: number | null, memo1?: string | null, memo2?: string | null, workingName?: string | null, deptSort: number }> | null } };

export type UpdateWorkingTemplateMutationVariables = Exact<{
  employeeIdList: Array<Scalars['String']> | Scalars['String'];
  workingTemplateIdx?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateWorkingTemplateMutation = { __typename?: 'Mutation', updateWorkingTemplate: { __typename?: 'UpdateWorkingTemplateOutput', ok: boolean, error?: string | null } };

export type GetUserPolicyBasicInfoQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type GetUserPolicyBasicInfoQuery = { __typename?: 'Query', getUserPolicyBasicInfo: { __typename?: 'GetUserPolicyBasicInfoOutput', ok: boolean, error?: string | null, basicInfo?: { __typename?: 'UserPolicyBasicInfoEntity', workingTemplateIdx?: number | null, nameId: string, departName: string, templateName?: string | null, wType?: string | null, possibleTime?: string | null, workType?: number | null } | null } };

export type GetUserPolicySelectedInfoQueryVariables = Exact<{
  employeeId: Scalars['String'];
  workingTimeName?: InputMaybe<Scalars['String']>;
}>;


export type GetUserPolicySelectedInfoQuery = { __typename?: 'Query', getUserPolicySelectedInfo: { __typename?: 'GetUserPolicySelectedInfoOutput', ok: boolean, error?: string | null, info?: { __typename?: 'UserPolicySelectedInfoEntity', workingTimeName: string, workingPeriod: string, isDayOfWeekEnable: Is_Use, asDayOfWeek?: Array<Day_Type> | null, isWorkHoursEnable: Is_Use, startTime?: string | null, endTime?: string | null, isBreakTimeTypeEnable: Is_Use, startBreakTime?: string | null, endBreakTime?: string | null, dutyWorkHoursType?: Is_Use | null, dutyStartTime?: string | null, dutyEndTime?: string | null } | null } };

export type GetWtiFromEmployeeQueryVariables = Exact<{
  employeeId: Scalars['String'];
}>;


export type GetWtiFromEmployeeQuery = { __typename?: 'Query', getWTIFromEmployee: { __typename?: 'GetWTFFromEmployeeOutput', ok: boolean, error?: string | null, workingTemplateIdx?: string | null } };

export type SaveUserPolicySelectedInfoMutationVariables = Exact<{
  employeeId: Scalars['String'];
  workingTemplateIdx: Scalars['Int'];
  workingTimeName: Scalars['String'];
  dayOfWeek: Array<Day_Type> | Day_Type;
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  startBreakTime: Scalars['String'];
  endBreakTime: Scalars['String'];
}>;


export type SaveUserPolicySelectedInfoMutation = { __typename?: 'Mutation', saveUserPolicySelectedInfo: { __typename?: 'SaveUserPolicySelectedInfOutput', ok: boolean, error?: string | null } };

export type GetWidgetSettingInfoQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
}>;


export type GetWidgetSettingInfoQuery = { __typename?: 'Query', getWidgetSettingInfo: { __typename?: 'GetWidgetSettingInfoOutput', ok: boolean, error?: string | null, info?: { __typename?: 'WidgetSettingInfoEntity', workingTemplateIdx: number, asonicWidgetUse: Widget_Setting_Type, asonicWidgetAutoStart: Is_Use } | null } };

export type AddWidgetSettingInfoMutationVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  asonicWidgetUse: Widget_Setting_Type;
  asonicWidgetAutoStart: Is_Use;
}>;


export type AddWidgetSettingInfoMutation = { __typename?: 'Mutation', addWidgetSettingInfo: { __typename?: 'AddWidgetSettingInfoOutput', ok: boolean, error?: string | null } };

export type GetListAllWorkingTemplateQueryVariables = Exact<{
  searchValue: Scalars['String'];
  searchType?: InputMaybe<Wt_Search_Type>;
  fieldSort?: InputMaybe<FieldSort>;
}>;


export type GetListAllWorkingTemplateQuery = { __typename?: 'Query', getListAllWorkingTemplate: { __typename?: 'GetListAllWorkingTemplateOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'WorkingTemplateEntity', workingTemplateIdx: number, wType: string, name?: string | null, timeArray?: string | null, dayMaxWorkHour: string, weekMaxWorkHour: string, isLeftSeat: string, leftSeatBaseMin: number, leftSeatReasonUse: Is_Use, leftSeatPlaceInput: Is_Use, leftSeatBlackScreenUse: Is_Use }> | null } };

export type GetWtBasicInfoQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
}>;


export type GetWtBasicInfoQuery = { __typename?: 'Query', getWTBasicInfo: { __typename?: 'GetWTBasicInfoOutput', ok: boolean, error?: string | null, wTBasicInfo?: { __typename?: 'WTBasicInfoEntity', workingTemplateIdx: number, name?: string | null, workType: Work_Type, otHoursTypeCategoryIdx?: number | null, dayMaxWorkHour: number, weekMaxWorkHour: number, weekMaxExtWorkHour: number, mandatoryBreakTermHour: number, mandatoryBreakMin: number, dayOfWeekStart: Day_Type, otHolidayTemplateNewAutoUse: Is_Use, timeDifferenceMin: number, periodUnitType?: Period_Unit_Type | null } | null } };

export type GetWorkingTimeListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWorkingTimeListQuery = { __typename?: 'Query', getWorkingTimeList: { __typename?: 'GetWorkingTimeListOutput', ok: boolean, error?: string | null, list?: Array<{ __typename?: 'WorkingTimeInfoEntity', otHoursTypeCategoryIdx: number, typeName: string, dayMaxWorkHour: number, weekMaxWorkHour: number, weekMaxExtWorkHour: number }> | null } };

export type DeleteWtBasicInfoMutationVariables = Exact<{
  workingTemplateIdxList: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteWtBasicInfoMutation = { __typename?: 'Mutation', deleteWTBasicInfo: { __typename?: 'DeleteWTBasicInfoOutput', ok: boolean, error?: string | null } };

export type GetStandardWtBasicInfoQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
}>;


export type GetStandardWtBasicInfoQuery = { __typename?: 'Query', getStandardWTBasicInfo: { __typename?: 'GetStandardWTBasicInfoOutput', ok: boolean, error?: string | null, basicInfo?: { __typename?: 'StandardBasicInfo', workingTemplateIdx?: number | null, startTime: string, endTime: string, workWeekType: Work_Week_Type, workWeekSelect: Array<number>, dayOfWeekType: Day_Of_Week_Type, dayOfWeek: Array<Day_Type>, startBreakTime: string, endBreakTime: string } | null, extraDaySettingList?: Array<{ __typename?: 'OtWorkingStandardHoursEntity', workingTemplateIdx?: number | null, timeSequence: number, startTime: string, endTime: string, workWeekType: Work_Week_Type, workWeekSelect: Array<number>, dayOfWeekType: Day_Of_Week_Type, dayOfWeek: Array<Day_Type>, breakTimeType: Break_Time_Type, startBreakTime: string, endBreakTime: string }> | null } };

export type AddWtBasicInfoMutationVariables = Exact<{
  name: Scalars['String'];
  workType: Work_Type;
  dayMaxWorkHour: Scalars['Int'];
  weekMaxWorkHour: Scalars['Int'];
  weekMaxExtWorkHour: Scalars['Int'];
  mandatoryBreakTermHour: Scalars['Int'];
  mandatoryBreakMin: Scalars['Int'];
  dayOfWeekStart: Day_Type;
  otHoursTypeCategoryIdx?: InputMaybe<Scalars['Float']>;
  timeDifferenceMin?: InputMaybe<Scalars['Float']>;
  periodUnitType?: InputMaybe<Period_Unit_Type>;
}>;


export type AddWtBasicInfoMutation = { __typename?: 'Mutation', addWTBasicInfo: { __typename?: 'AddWTBasicInfoOutput', ok: boolean, error?: string | null, workingTemplateIdx?: number | null } };

export type UpdateWtBasicInfoMutationVariables = Exact<{
  name: Scalars['String'];
  workType: Work_Type;
  dayMaxWorkHour: Scalars['Int'];
  weekMaxWorkHour: Scalars['Int'];
  weekMaxExtWorkHour: Scalars['Int'];
  mandatoryBreakTermHour: Scalars['Int'];
  mandatoryBreakMin: Scalars['Int'];
  dayOfWeekStart: Day_Type;
  otHolidayTemplateNewAutoUse: Is_Use;
  otHoursTypeCategoryIdx?: InputMaybe<Scalars['Float']>;
  workingTemplateIdx?: InputMaybe<Scalars['Int']>;
  timeDifferenceMin?: InputMaybe<Scalars['Float']>;
  periodUnitType?: InputMaybe<Period_Unit_Type>;
}>;


export type UpdateWtBasicInfoMutation = { __typename?: 'Mutation', updateWTBasicInfo: { __typename?: 'UpdateWTBasicInfoOutput', ok: boolean, error?: string | null } };

export type AddStandardWtBasicInfoMutationVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  workWeekType: Work_Week_Type;
  workWeekSelect: Array<Scalars['Int']> | Scalars['Int'];
  dayOfWeekType: Day_Of_Week_Type;
  dayOfWeek: Array<Day_Type> | Day_Type;
  startBreakTime: Scalars['String'];
  endBreakTime: Scalars['String'];
  otWorkingStandardHourList?: InputMaybe<Array<Ot_Working_Standard_Hours> | Ot_Working_Standard_Hours>;
}>;


export type AddStandardWtBasicInfoMutation = { __typename?: 'Mutation', addStandardWTBasicInfo: { __typename?: 'AddStandardWTBasicInfoOutput', error?: string | null, ok: boolean } };

export type GetWtGraphQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
}>;


export type GetWtGraphQuery = { __typename?: 'Query', getWTGraph: { __typename?: 'GetWTGraphOutput', ok: boolean, error?: string | null, basicInfo?: { __typename?: 'WTGraphBasicEntity', name: string, workType: string, dayMaxWorkHour: number, weekMaxWorkHour: number, dayOfWeekStart: Day_Type } | null, weekDayOfWeekInfo?: Array<{ __typename?: 'WTGraphWeekEntity', workWeekSelect?: number | null, dayOfWeek: Day_Type, totHour?: number | null }> | null } };

export type GetWtDayGraphQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  dayOfWeek: Day_Type;
  workWeekSelect?: InputMaybe<Scalars['Int']>;
}>;


export type GetWtDayGraphQuery = { __typename?: 'Query', getWTDayGraph: { __typename?: 'GetDayGraphOutput', ok: boolean, error?: string | null, dayGraph?: { __typename?: 'DayGraphEntity', startTime?: string | null, endTime?: string | null, startBreakTime: string, endBreakTime: string } | null } };

export type GetSelfDeterminedWhGraphQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  workingTimeName: Scalars['String'];
}>;


export type GetSelfDeterminedWhGraphQuery = { __typename?: 'Query', getSelfDeterminedWHGraph: { __typename?: 'getSelfDeterminedWHGraphOutput', ok: boolean, error?: string | null, weekDayOfWeekInfo?: Array<{ __typename?: 'SelfDeterminedWeekGraphEntity', dayOfWeek?: Day_Type | null, totHour?: number | null }> | null } };

export type GetSelfDeterminedDayGraphQueryVariables = Exact<{
  workingTemplateIdx: Scalars['Int'];
  workingTimeName: Scalars['String'];
  dayOfWeek: Day_Type;
}>;


export type GetSelfDeterminedDayGraphQuery = { __typename?: 'Query', getSelfDeterminedDayGraph: { __typename?: 'GetSelfDeterminedDayGraphOutput', ok: boolean, error?: string | null, dayGraph?: { __typename?: 'DayGraphEntity', startTime?: string | null, endTime?: string | null, startBreakTime: string, endBreakTime: string } | null } };


export const GetCompanyListDocument = gql`
    query GetCompanyList {
  getCompanyList {
    ok
    error
    list {
      companyCode
      companyName
    }
  }
}
    `;

/**
 * __useGetCompanyListQuery__
 *
 * To run a query within a React component, call `useGetCompanyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyListQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyListQuery, GetCompanyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyListQuery, GetCompanyListQueryVariables>(GetCompanyListDocument, options);
      }
export function useGetCompanyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyListQuery, GetCompanyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyListQuery, GetCompanyListQueryVariables>(GetCompanyListDocument, options);
        }
export type GetCompanyListQueryHookResult = ReturnType<typeof useGetCompanyListQuery>;
export type GetCompanyListLazyQueryHookResult = ReturnType<typeof useGetCompanyListLazyQuery>;
export type GetCompanyListQueryResult = Apollo.QueryResult<GetCompanyListQuery, GetCompanyListQueryVariables>;
export const GetAnnualEnvInfoDocument = gql`
    query GetAnnualEnvInfo($companyCode: String!) {
  getAnnualEnvInfo(companyCode: $companyCode) {
    ok
    error
    info {
      annualAccrualType
      accountingMonth
      annualMaxCount
      annualDeductionPriorityType
      annualFixType
    }
  }
}
    `;

/**
 * __useGetAnnualEnvInfoQuery__
 *
 * To run a query within a React component, call `useGetAnnualEnvInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnualEnvInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnualEnvInfoQuery({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useGetAnnualEnvInfoQuery(baseOptions: Apollo.QueryHookOptions<GetAnnualEnvInfoQuery, GetAnnualEnvInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnualEnvInfoQuery, GetAnnualEnvInfoQueryVariables>(GetAnnualEnvInfoDocument, options);
      }
export function useGetAnnualEnvInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnualEnvInfoQuery, GetAnnualEnvInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnualEnvInfoQuery, GetAnnualEnvInfoQueryVariables>(GetAnnualEnvInfoDocument, options);
        }
export type GetAnnualEnvInfoQueryHookResult = ReturnType<typeof useGetAnnualEnvInfoQuery>;
export type GetAnnualEnvInfoLazyQueryHookResult = ReturnType<typeof useGetAnnualEnvInfoLazyQuery>;
export type GetAnnualEnvInfoQueryResult = Apollo.QueryResult<GetAnnualEnvInfoQuery, GetAnnualEnvInfoQueryVariables>;
export const UpdateAnnualEnvInfoDocument = gql`
    mutation UpdateAnnualEnvInfo($companyCode: String!, $annualAccrualType: ANNUAL_ACCRUAL_TYPE!, $accountingMonth: Int!, $annualMaxCount: Int!, $annualDeductionPriorityType: Int!, $annualFixType: ANNUAL_FIX_TYPE!) {
  updateAnnualEnvInfo(
    companyCode: $companyCode
    annualAccrualType: $annualAccrualType
    accountingMonth: $accountingMonth
    annualMaxCount: $annualMaxCount
    annualDeductionPriorityType: $annualDeductionPriorityType
    annualFixType: $annualFixType
  ) {
    ok
    error
  }
}
    `;
export type UpdateAnnualEnvInfoMutationFn = Apollo.MutationFunction<UpdateAnnualEnvInfoMutation, UpdateAnnualEnvInfoMutationVariables>;

/**
 * __useUpdateAnnualEnvInfoMutation__
 *
 * To run a mutation, you first call `useUpdateAnnualEnvInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnualEnvInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnualEnvInfoMutation, { data, loading, error }] = useUpdateAnnualEnvInfoMutation({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *      annualAccrualType: // value for 'annualAccrualType'
 *      accountingMonth: // value for 'accountingMonth'
 *      annualMaxCount: // value for 'annualMaxCount'
 *      annualDeductionPriorityType: // value for 'annualDeductionPriorityType'
 *      annualFixType: // value for 'annualFixType'
 *   },
 * });
 */
export function useUpdateAnnualEnvInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnnualEnvInfoMutation, UpdateAnnualEnvInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnnualEnvInfoMutation, UpdateAnnualEnvInfoMutationVariables>(UpdateAnnualEnvInfoDocument, options);
      }
export type UpdateAnnualEnvInfoMutationHookResult = ReturnType<typeof useUpdateAnnualEnvInfoMutation>;
export type UpdateAnnualEnvInfoMutationResult = Apollo.MutationResult<UpdateAnnualEnvInfoMutation>;
export type UpdateAnnualEnvInfoMutationOptions = Apollo.BaseMutationOptions<UpdateAnnualEnvInfoMutation, UpdateAnnualEnvInfoMutationVariables>;
export const GetInfoMoreThanAYearDocument = gql`
    query GetInfoMoreThanAYear($companyCode: String!) {
  getInfoMoreThanAYear(companyCode: $companyCode) {
    ok
    error
    info {
      defaultDayCount
      addedYearCount
      addedDayCount
      addedMaxDayCount
      minusAnnualType
      minusAnnualDayCount
      carriedforwardType
      carriedforwardRangeType
    }
  }
}
    `;

/**
 * __useGetInfoMoreThanAYearQuery__
 *
 * To run a query within a React component, call `useGetInfoMoreThanAYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoMoreThanAYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoMoreThanAYearQuery({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useGetInfoMoreThanAYearQuery(baseOptions: Apollo.QueryHookOptions<GetInfoMoreThanAYearQuery, GetInfoMoreThanAYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInfoMoreThanAYearQuery, GetInfoMoreThanAYearQueryVariables>(GetInfoMoreThanAYearDocument, options);
      }
export function useGetInfoMoreThanAYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfoMoreThanAYearQuery, GetInfoMoreThanAYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInfoMoreThanAYearQuery, GetInfoMoreThanAYearQueryVariables>(GetInfoMoreThanAYearDocument, options);
        }
export type GetInfoMoreThanAYearQueryHookResult = ReturnType<typeof useGetInfoMoreThanAYearQuery>;
export type GetInfoMoreThanAYearLazyQueryHookResult = ReturnType<typeof useGetInfoMoreThanAYearLazyQuery>;
export type GetInfoMoreThanAYearQueryResult = Apollo.QueryResult<GetInfoMoreThanAYearQuery, GetInfoMoreThanAYearQueryVariables>;
export const UpdateInfoMoreThanAYearDocument = gql`
    mutation UpdateInfoMoreThanAYear($companyCode: String!, $defaultDayCount: Int!, $addedYearCount: Int!, $addedDayCount: Int!, $addedMaxDayCount: Int!, $minusAnnualType: MINUS_ANNUAL_TYPE!, $minusAnnualDayCount: Int!, $carriedforwardType: CARRIEDFORWARD_TYPE!, $carriedforwardRangeType: CARRIEDFORWARD_RANGE_TYPE!) {
  updateInfoMoreThanAYear(
    companyCode: $companyCode
    defaultDayCount: $defaultDayCount
    addedYearCount: $addedYearCount
    addedDayCount: $addedDayCount
    addedMaxDayCount: $addedMaxDayCount
    minusAnnualType: $minusAnnualType
    minusAnnualDayCount: $minusAnnualDayCount
    carriedforwardType: $carriedforwardType
    carriedforwardRangeType: $carriedforwardRangeType
  ) {
    ok
    error
  }
}
    `;
export type UpdateInfoMoreThanAYearMutationFn = Apollo.MutationFunction<UpdateInfoMoreThanAYearMutation, UpdateInfoMoreThanAYearMutationVariables>;

/**
 * __useUpdateInfoMoreThanAYearMutation__
 *
 * To run a mutation, you first call `useUpdateInfoMoreThanAYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfoMoreThanAYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfoMoreThanAYearMutation, { data, loading, error }] = useUpdateInfoMoreThanAYearMutation({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *      defaultDayCount: // value for 'defaultDayCount'
 *      addedYearCount: // value for 'addedYearCount'
 *      addedDayCount: // value for 'addedDayCount'
 *      addedMaxDayCount: // value for 'addedMaxDayCount'
 *      minusAnnualType: // value for 'minusAnnualType'
 *      minusAnnualDayCount: // value for 'minusAnnualDayCount'
 *      carriedforwardType: // value for 'carriedforwardType'
 *      carriedforwardRangeType: // value for 'carriedforwardRangeType'
 *   },
 * });
 */
export function useUpdateInfoMoreThanAYearMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInfoMoreThanAYearMutation, UpdateInfoMoreThanAYearMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInfoMoreThanAYearMutation, UpdateInfoMoreThanAYearMutationVariables>(UpdateInfoMoreThanAYearDocument, options);
      }
export type UpdateInfoMoreThanAYearMutationHookResult = ReturnType<typeof useUpdateInfoMoreThanAYearMutation>;
export type UpdateInfoMoreThanAYearMutationResult = Apollo.MutationResult<UpdateInfoMoreThanAYearMutation>;
export type UpdateInfoMoreThanAYearMutationOptions = Apollo.BaseMutationOptions<UpdateInfoMoreThanAYearMutation, UpdateInfoMoreThanAYearMutationVariables>;
export const GetInfoLessThanAYearDocument = gql`
    query GetInfoLessThanAYear($companyCode: String!) {
  getInfoLessThanAYear(companyCode: $companyCode) {
    ok
    error
    info {
      annualAccrualType
      monthAccrualType
      fixCount
      minusAnnualType
      minusAnnualDayCount
    }
  }
}
    `;

/**
 * __useGetInfoLessThanAYearQuery__
 *
 * To run a query within a React component, call `useGetInfoLessThanAYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoLessThanAYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoLessThanAYearQuery({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useGetInfoLessThanAYearQuery(baseOptions: Apollo.QueryHookOptions<GetInfoLessThanAYearQuery, GetInfoLessThanAYearQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInfoLessThanAYearQuery, GetInfoLessThanAYearQueryVariables>(GetInfoLessThanAYearDocument, options);
      }
export function useGetInfoLessThanAYearLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfoLessThanAYearQuery, GetInfoLessThanAYearQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInfoLessThanAYearQuery, GetInfoLessThanAYearQueryVariables>(GetInfoLessThanAYearDocument, options);
        }
export type GetInfoLessThanAYearQueryHookResult = ReturnType<typeof useGetInfoLessThanAYearQuery>;
export type GetInfoLessThanAYearLazyQueryHookResult = ReturnType<typeof useGetInfoLessThanAYearLazyQuery>;
export type GetInfoLessThanAYearQueryResult = Apollo.QueryResult<GetInfoLessThanAYearQuery, GetInfoLessThanAYearQueryVariables>;
export const UpdateInfoLessThanYearDocument = gql`
    mutation UpdateInfoLessThanYear($companyCode: String!, $annualAccrualType: ANNUAL_ACCRUAL_TYPE_FOR_LESS_THAN_A_YEAR!, $monthAccrualType: MONTH_ACCRUAL_TYPE!, $fixCount: Float!, $minusAnnualType: MINUS_ANNUAL_TYPE_FOR_LESS_THAN_A_YEAR!, $minusAnnualDayCount: Float!) {
  updateInfoLessThanYear(
    companyCode: $companyCode
    annualAccrualType: $annualAccrualType
    monthAccrualType: $monthAccrualType
    fixCount: $fixCount
    minusAnnualType: $minusAnnualType
    minusAnnualDayCount: $minusAnnualDayCount
  ) {
    ok
    error
  }
}
    `;
export type UpdateInfoLessThanYearMutationFn = Apollo.MutationFunction<UpdateInfoLessThanYearMutation, UpdateInfoLessThanYearMutationVariables>;

/**
 * __useUpdateInfoLessThanYearMutation__
 *
 * To run a mutation, you first call `useUpdateInfoLessThanYearMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfoLessThanYearMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfoLessThanYearMutation, { data, loading, error }] = useUpdateInfoLessThanYearMutation({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *      annualAccrualType: // value for 'annualAccrualType'
 *      monthAccrualType: // value for 'monthAccrualType'
 *      fixCount: // value for 'fixCount'
 *      minusAnnualType: // value for 'minusAnnualType'
 *      minusAnnualDayCount: // value for 'minusAnnualDayCount'
 *   },
 * });
 */
export function useUpdateInfoLessThanYearMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInfoLessThanYearMutation, UpdateInfoLessThanYearMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInfoLessThanYearMutation, UpdateInfoLessThanYearMutationVariables>(UpdateInfoLessThanYearDocument, options);
      }
export type UpdateInfoLessThanYearMutationHookResult = ReturnType<typeof useUpdateInfoLessThanYearMutation>;
export type UpdateInfoLessThanYearMutationResult = Apollo.MutationResult<UpdateInfoLessThanYearMutation>;
export type UpdateInfoLessThanYearMutationOptions = Apollo.BaseMutationOptions<UpdateInfoLessThanYearMutation, UpdateInfoLessThanYearMutationVariables>;
export const GetInfoNextYearOfEmploymentDocument = gql`
    query GetInfoNextYearOfEmployment($companyCode: String!) {
  getInfoNextYearOfEmployment(companyCode: $companyCode) {
    ok
    error
    info {
      annualAccrualType
      monthAccrualType
      fixCount
      minusAnnualType
      minusAnnualDayCount
      carriedforwardType
      carriedforwardRangeType
    }
  }
}
    `;

/**
 * __useGetInfoNextYearOfEmploymentQuery__
 *
 * To run a query within a React component, call `useGetInfoNextYearOfEmploymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfoNextYearOfEmploymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfoNextYearOfEmploymentQuery({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *   },
 * });
 */
export function useGetInfoNextYearOfEmploymentQuery(baseOptions: Apollo.QueryHookOptions<GetInfoNextYearOfEmploymentQuery, GetInfoNextYearOfEmploymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInfoNextYearOfEmploymentQuery, GetInfoNextYearOfEmploymentQueryVariables>(GetInfoNextYearOfEmploymentDocument, options);
      }
export function useGetInfoNextYearOfEmploymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfoNextYearOfEmploymentQuery, GetInfoNextYearOfEmploymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInfoNextYearOfEmploymentQuery, GetInfoNextYearOfEmploymentQueryVariables>(GetInfoNextYearOfEmploymentDocument, options);
        }
export type GetInfoNextYearOfEmploymentQueryHookResult = ReturnType<typeof useGetInfoNextYearOfEmploymentQuery>;
export type GetInfoNextYearOfEmploymentLazyQueryHookResult = ReturnType<typeof useGetInfoNextYearOfEmploymentLazyQuery>;
export type GetInfoNextYearOfEmploymentQueryResult = Apollo.QueryResult<GetInfoNextYearOfEmploymentQuery, GetInfoNextYearOfEmploymentQueryVariables>;
export const UpdateInfoNextYearOfEmploymentDocument = gql`
    mutation UpdateInfoNextYearOfEmployment($companyCode: String!, $annualAccrualType: ANNUAL_ACCRUAL_TYPE_FOR_NEXT_YEAR_OF_EMPLOYMENT!, $monthAccrualType: MONTH_ACCRUAL_TYPE_FOR_NEXT_YEAR_OF_EMPLOYMENT!, $fixCount: Float!, $minusAnnualType: MINUS_ANNUAL_TYPE!, $minusAnnualDayCount: Float!, $carriedforwardType: CARRIEDFORWARD_TYPE!, $carriedforwardRangeType: CARRIEDFORWARD_RANGE_TYPE!) {
  updateInfoNextYearOfEmployment(
    companyCode: $companyCode
    annualAccrualType: $annualAccrualType
    monthAccrualType: $monthAccrualType
    fixCount: $fixCount
    minusAnnualType: $minusAnnualType
    minusAnnualDayCount: $minusAnnualDayCount
    carriedforwardType: $carriedforwardType
    carriedforwardRangeType: $carriedforwardRangeType
  ) {
    ok
    error
  }
}
    `;
export type UpdateInfoNextYearOfEmploymentMutationFn = Apollo.MutationFunction<UpdateInfoNextYearOfEmploymentMutation, UpdateInfoNextYearOfEmploymentMutationVariables>;

/**
 * __useUpdateInfoNextYearOfEmploymentMutation__
 *
 * To run a mutation, you first call `useUpdateInfoNextYearOfEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInfoNextYearOfEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInfoNextYearOfEmploymentMutation, { data, loading, error }] = useUpdateInfoNextYearOfEmploymentMutation({
 *   variables: {
 *      companyCode: // value for 'companyCode'
 *      annualAccrualType: // value for 'annualAccrualType'
 *      monthAccrualType: // value for 'monthAccrualType'
 *      fixCount: // value for 'fixCount'
 *      minusAnnualType: // value for 'minusAnnualType'
 *      minusAnnualDayCount: // value for 'minusAnnualDayCount'
 *      carriedforwardType: // value for 'carriedforwardType'
 *      carriedforwardRangeType: // value for 'carriedforwardRangeType'
 *   },
 * });
 */
export function useUpdateInfoNextYearOfEmploymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInfoNextYearOfEmploymentMutation, UpdateInfoNextYearOfEmploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInfoNextYearOfEmploymentMutation, UpdateInfoNextYearOfEmploymentMutationVariables>(UpdateInfoNextYearOfEmploymentDocument, options);
      }
export type UpdateInfoNextYearOfEmploymentMutationHookResult = ReturnType<typeof useUpdateInfoNextYearOfEmploymentMutation>;
export type UpdateInfoNextYearOfEmploymentMutationResult = Apollo.MutationResult<UpdateInfoNextYearOfEmploymentMutation>;
export type UpdateInfoNextYearOfEmploymentMutationOptions = Apollo.BaseMutationOptions<UpdateInfoNextYearOfEmploymentMutation, UpdateInfoNextYearOfEmploymentMutationVariables>;
export const ApplicationApprovalDocument = gql`
    mutation ApplicationApproval($employeeId: String!, $startDate: String!, $endDate: String!, $formTemplateIdx: Int!, $title: String!, $startTime: String, $endTime: String, $description: String, $replacementRequestStartDate: String, $replacementRequestEndDate: String, $replacementRequestStartTime: String, $replacementRequestEndTime: String, $listOfApprovalLineUser: [IListOfApprovalLineUser!], $listOfReceiver: [IReceiveAndReferenceForUser!], $listOfReferencedPerson: [IReceiveAndReferenceForUser!], $listOfReceiveDepartment: [IReceiveAndReferenceForDepartment!], $listOfReferencedPersonDepartment: [IReceiveAndReferenceForDepartment!]) {
  applicationApproval(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
    formTemplateIdx: $formTemplateIdx
    title: $title
    startTime: $startTime
    endTime: $endTime
    description: $description
    replacementRequestStartDate: $replacementRequestStartDate
    replacementRequestEndDate: $replacementRequestEndDate
    replacementRequestStartTime: $replacementRequestStartTime
    replacementRequestEndTime: $replacementRequestEndTime
    listOfApprovalLineUser: $listOfApprovalLineUser
    listOfReceiver: $listOfReceiver
    listOfReferencedPerson: $listOfReferencedPerson
    listOfReceiveDepartment: $listOfReceiveDepartment
    listOfReferencedPersonDepartment: $listOfReferencedPersonDepartment
  ) {
    ok
    error
  }
}
    `;
export type ApplicationApprovalMutationFn = Apollo.MutationFunction<ApplicationApprovalMutation, ApplicationApprovalMutationVariables>;

/**
 * __useApplicationApprovalMutation__
 *
 * To run a mutation, you first call `useApplicationApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplicationApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applicationApprovalMutation, { data, loading, error }] = useApplicationApprovalMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      formTemplateIdx: // value for 'formTemplateIdx'
 *      title: // value for 'title'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      description: // value for 'description'
 *      replacementRequestStartDate: // value for 'replacementRequestStartDate'
 *      replacementRequestEndDate: // value for 'replacementRequestEndDate'
 *      replacementRequestStartTime: // value for 'replacementRequestStartTime'
 *      replacementRequestEndTime: // value for 'replacementRequestEndTime'
 *      listOfApprovalLineUser: // value for 'listOfApprovalLineUser'
 *      listOfReceiver: // value for 'listOfReceiver'
 *      listOfReferencedPerson: // value for 'listOfReferencedPerson'
 *      listOfReceiveDepartment: // value for 'listOfReceiveDepartment'
 *      listOfReferencedPersonDepartment: // value for 'listOfReferencedPersonDepartment'
 *   },
 * });
 */
export function useApplicationApprovalMutation(baseOptions?: Apollo.MutationHookOptions<ApplicationApprovalMutation, ApplicationApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplicationApprovalMutation, ApplicationApprovalMutationVariables>(ApplicationApprovalDocument, options);
      }
export type ApplicationApprovalMutationHookResult = ReturnType<typeof useApplicationApprovalMutation>;
export type ApplicationApprovalMutationResult = Apollo.MutationResult<ApplicationApprovalMutation>;
export type ApplicationApprovalMutationOptions = Apollo.BaseMutationOptions<ApplicationApprovalMutation, ApplicationApprovalMutationVariables>;
export const AddApprovalTemporaryStorageDocument = gql`
    mutation AddApprovalTemporaryStorage($employeeId: String!, $startDate: String!, $endDate: String!, $formTemplateIdx: Int!, $title: String!, $startTime: String, $endTime: String, $description: String, $replacementRequestStartDate: String, $replacementRequestEndDate: String, $replacementRequestStartTime: String, $replacementRequestEndTime: String, $listOfApprovalLineUser: [IListOfApprovalLineUser!], $listOfReceiver: [IReceiveAndReferenceForUser!], $listOfReferencedPerson: [IReceiveAndReferenceForUser!], $listOfReceiveDepartment: [IReceiveAndReferenceForDepartment!], $listOfReferencedPersonDepartment: [IReceiveAndReferenceForDepartment!]) {
  addApprovalTemporaryStorage(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
    formTemplateIdx: $formTemplateIdx
    title: $title
    startTime: $startTime
    endTime: $endTime
    description: $description
    replacementRequestStartDate: $replacementRequestStartDate
    replacementRequestEndDate: $replacementRequestEndDate
    replacementRequestStartTime: $replacementRequestStartTime
    replacementRequestEndTime: $replacementRequestEndTime
    listOfApprovalLineUser: $listOfApprovalLineUser
    listOfReceiver: $listOfReceiver
    listOfReferencedPerson: $listOfReferencedPerson
    listOfReceiveDepartment: $listOfReceiveDepartment
    listOfReferencedPersonDepartment: $listOfReferencedPersonDepartment
  ) {
    ok
    error
  }
}
    `;
export type AddApprovalTemporaryStorageMutationFn = Apollo.MutationFunction<AddApprovalTemporaryStorageMutation, AddApprovalTemporaryStorageMutationVariables>;

/**
 * __useAddApprovalTemporaryStorageMutation__
 *
 * To run a mutation, you first call `useAddApprovalTemporaryStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApprovalTemporaryStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApprovalTemporaryStorageMutation, { data, loading, error }] = useAddApprovalTemporaryStorageMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      formTemplateIdx: // value for 'formTemplateIdx'
 *      title: // value for 'title'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      description: // value for 'description'
 *      replacementRequestStartDate: // value for 'replacementRequestStartDate'
 *      replacementRequestEndDate: // value for 'replacementRequestEndDate'
 *      replacementRequestStartTime: // value for 'replacementRequestStartTime'
 *      replacementRequestEndTime: // value for 'replacementRequestEndTime'
 *      listOfApprovalLineUser: // value for 'listOfApprovalLineUser'
 *      listOfReceiver: // value for 'listOfReceiver'
 *      listOfReferencedPerson: // value for 'listOfReferencedPerson'
 *      listOfReceiveDepartment: // value for 'listOfReceiveDepartment'
 *      listOfReferencedPersonDepartment: // value for 'listOfReferencedPersonDepartment'
 *   },
 * });
 */
export function useAddApprovalTemporaryStorageMutation(baseOptions?: Apollo.MutationHookOptions<AddApprovalTemporaryStorageMutation, AddApprovalTemporaryStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddApprovalTemporaryStorageMutation, AddApprovalTemporaryStorageMutationVariables>(AddApprovalTemporaryStorageDocument, options);
      }
export type AddApprovalTemporaryStorageMutationHookResult = ReturnType<typeof useAddApprovalTemporaryStorageMutation>;
export type AddApprovalTemporaryStorageMutationResult = Apollo.MutationResult<AddApprovalTemporaryStorageMutation>;
export type AddApprovalTemporaryStorageMutationOptions = Apollo.BaseMutationOptions<AddApprovalTemporaryStorageMutation, AddApprovalTemporaryStorageMutationVariables>;
export const IsActiveApprovalPasswordDocument = gql`
    query IsActiveApprovalPassword {
  isActiveApprovalPassword {
    ok
    error
  }
}
    `;

/**
 * __useIsActiveApprovalPasswordQuery__
 *
 * To run a query within a React component, call `useIsActiveApprovalPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsActiveApprovalPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsActiveApprovalPasswordQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsActiveApprovalPasswordQuery(baseOptions?: Apollo.QueryHookOptions<IsActiveApprovalPasswordQuery, IsActiveApprovalPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsActiveApprovalPasswordQuery, IsActiveApprovalPasswordQueryVariables>(IsActiveApprovalPasswordDocument, options);
      }
export function useIsActiveApprovalPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsActiveApprovalPasswordQuery, IsActiveApprovalPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsActiveApprovalPasswordQuery, IsActiveApprovalPasswordQueryVariables>(IsActiveApprovalPasswordDocument, options);
        }
export type IsActiveApprovalPasswordQueryHookResult = ReturnType<typeof useIsActiveApprovalPasswordQuery>;
export type IsActiveApprovalPasswordLazyQueryHookResult = ReturnType<typeof useIsActiveApprovalPasswordLazyQuery>;
export type IsActiveApprovalPasswordQueryResult = Apollo.QueryResult<IsActiveApprovalPasswordQuery, IsActiveApprovalPasswordQueryVariables>;
export const ConfirmIsApprovalPasswordDocument = gql`
    query ConfirmIsApprovalPassword($password: String!) {
  confirmIsApprovalPassword(password: $password) {
    ok
    error
  }
}
    `;

/**
 * __useConfirmIsApprovalPasswordQuery__
 *
 * To run a query within a React component, call `useConfirmIsApprovalPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmIsApprovalPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmIsApprovalPasswordQuery({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useConfirmIsApprovalPasswordQuery(baseOptions: Apollo.QueryHookOptions<ConfirmIsApprovalPasswordQuery, ConfirmIsApprovalPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfirmIsApprovalPasswordQuery, ConfirmIsApprovalPasswordQueryVariables>(ConfirmIsApprovalPasswordDocument, options);
      }
export function useConfirmIsApprovalPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfirmIsApprovalPasswordQuery, ConfirmIsApprovalPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfirmIsApprovalPasswordQuery, ConfirmIsApprovalPasswordQueryVariables>(ConfirmIsApprovalPasswordDocument, options);
        }
export type ConfirmIsApprovalPasswordQueryHookResult = ReturnType<typeof useConfirmIsApprovalPasswordQuery>;
export type ConfirmIsApprovalPasswordLazyQueryHookResult = ReturnType<typeof useConfirmIsApprovalPasswordLazyQuery>;
export type ConfirmIsApprovalPasswordQueryResult = Apollo.QueryResult<ConfirmIsApprovalPasswordQuery, ConfirmIsApprovalPasswordQueryVariables>;
export const SignApprovalDocument = gql`
    mutation SignApproval($approvalRequestIdx: Int!, $description: String!, $customEmployeeId: String) {
  signApproval(
    approvalRequestIdx: $approvalRequestIdx
    description: $description
    customEmployeeId: $customEmployeeId
  ) {
    ok
    error
  }
}
    `;
export type SignApprovalMutationFn = Apollo.MutationFunction<SignApprovalMutation, SignApprovalMutationVariables>;

/**
 * __useSignApprovalMutation__
 *
 * To run a mutation, you first call `useSignApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signApprovalMutation, { data, loading, error }] = useSignApprovalMutation({
 *   variables: {
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *      description: // value for 'description'
 *      customEmployeeId: // value for 'customEmployeeId'
 *   },
 * });
 */
export function useSignApprovalMutation(baseOptions?: Apollo.MutationHookOptions<SignApprovalMutation, SignApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignApprovalMutation, SignApprovalMutationVariables>(SignApprovalDocument, options);
      }
export type SignApprovalMutationHookResult = ReturnType<typeof useSignApprovalMutation>;
export type SignApprovalMutationResult = Apollo.MutationResult<SignApprovalMutation>;
export type SignApprovalMutationOptions = Apollo.BaseMutationOptions<SignApprovalMutation, SignApprovalMutationVariables>;
export const RejectApprovalDocument = gql`
    mutation RejectApproval($approvalRequestIdx: Int!, $description: String!, $customEmployeeId: String) {
  rejectApproval(
    approvalRequestIdx: $approvalRequestIdx
    description: $description
    customEmployeeId: $customEmployeeId
  ) {
    ok
    error
  }
}
    `;
export type RejectApprovalMutationFn = Apollo.MutationFunction<RejectApprovalMutation, RejectApprovalMutationVariables>;

/**
 * __useRejectApprovalMutation__
 *
 * To run a mutation, you first call `useRejectApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectApprovalMutation, { data, loading, error }] = useRejectApprovalMutation({
 *   variables: {
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *      description: // value for 'description'
 *      customEmployeeId: // value for 'customEmployeeId'
 *   },
 * });
 */
export function useRejectApprovalMutation(baseOptions?: Apollo.MutationHookOptions<RejectApprovalMutation, RejectApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectApprovalMutation, RejectApprovalMutationVariables>(RejectApprovalDocument, options);
      }
export type RejectApprovalMutationHookResult = ReturnType<typeof useRejectApprovalMutation>;
export type RejectApprovalMutationResult = Apollo.MutationResult<RejectApprovalMutation>;
export type RejectApprovalMutationOptions = Apollo.BaseMutationOptions<RejectApprovalMutation, RejectApprovalMutationVariables>;
export const DeleteApprovalRequestDocument = gql`
    mutation DeleteApprovalRequest($employeeId: String!, $startDate: String!, $endDate: String!, $formTemplateIdx: Int!, $title: String!, $approvalRequestIdx: Int!, $startTime: String, $endTime: String, $description: String, $replacementRequestStartDate: String, $replacementRequestEndDate: String, $replacementRequestStartTime: String, $replacementRequestEndTime: String, $listOfApprovalLineUser: [IListOfApprovalLineUser!], $listOfReceiver: [IReceiveAndReferenceForUser!], $listOfReferencedPerson: [IReceiveAndReferenceForUser!], $listOfReceiveDepartment: [IReceiveAndReferenceForDepartment!], $listOfReferencedPersonDepartment: [IReceiveAndReferenceForDepartment!]) {
  deleteApprovalRequest(
    employeeId: $employeeId
    startDate: $startDate
    endDate: $endDate
    formTemplateIdx: $formTemplateIdx
    title: $title
    approvalRequestIdx: $approvalRequestIdx
    startTime: $startTime
    endTime: $endTime
    description: $description
    replacementRequestStartDate: $replacementRequestStartDate
    replacementRequestEndDate: $replacementRequestEndDate
    replacementRequestStartTime: $replacementRequestStartTime
    replacementRequestEndTime: $replacementRequestEndTime
    listOfApprovalLineUser: $listOfApprovalLineUser
    listOfReceiver: $listOfReceiver
    listOfReferencedPerson: $listOfReferencedPerson
    listOfReceiveDepartment: $listOfReceiveDepartment
    listOfReferencedPersonDepartment: $listOfReferencedPersonDepartment
  ) {
    ok
    error
  }
}
    `;
export type DeleteApprovalRequestMutationFn = Apollo.MutationFunction<DeleteApprovalRequestMutation, DeleteApprovalRequestMutationVariables>;

/**
 * __useDeleteApprovalRequestMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalRequestMutation, { data, loading, error }] = useDeleteApprovalRequestMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      formTemplateIdx: // value for 'formTemplateIdx'
 *      title: // value for 'title'
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      description: // value for 'description'
 *      replacementRequestStartDate: // value for 'replacementRequestStartDate'
 *      replacementRequestEndDate: // value for 'replacementRequestEndDate'
 *      replacementRequestStartTime: // value for 'replacementRequestStartTime'
 *      replacementRequestEndTime: // value for 'replacementRequestEndTime'
 *      listOfApprovalLineUser: // value for 'listOfApprovalLineUser'
 *      listOfReceiver: // value for 'listOfReceiver'
 *      listOfReferencedPerson: // value for 'listOfReferencedPerson'
 *      listOfReceiveDepartment: // value for 'listOfReceiveDepartment'
 *      listOfReferencedPersonDepartment: // value for 'listOfReferencedPersonDepartment'
 *   },
 * });
 */
export function useDeleteApprovalRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApprovalRequestMutation, DeleteApprovalRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApprovalRequestMutation, DeleteApprovalRequestMutationVariables>(DeleteApprovalRequestDocument, options);
      }
export type DeleteApprovalRequestMutationHookResult = ReturnType<typeof useDeleteApprovalRequestMutation>;
export type DeleteApprovalRequestMutationResult = Apollo.MutationResult<DeleteApprovalRequestMutation>;
export type DeleteApprovalRequestMutationOptions = Apollo.BaseMutationOptions<DeleteApprovalRequestMutation, DeleteApprovalRequestMutationVariables>;
export const GetListOfApprovalDocument = gql`
    query GetListOfApproval($approvalType: APPROVAL_FOR_LIST_TYPE!, $searchType: ApprovalSearchType!, $page: Float, $take: Float, $fieldSort: FieldSort, $searchValue: String, $customEmployeeId: String) {
  getListOfApproval(
    approvalType: $approvalType
    searchType: $searchType
    page: $page
    take: $take
    fieldSort: $fieldSort
    searchValue: $searchValue
    customEmployeeId: $customEmployeeId
  ) {
    ok
    error
    list {
      approvalRequestIdx
      formTemplateIdx
      approvalType
      approvalTitle
      categoryId
      formIdx
      docNumber
      approvalStatus
      extendStartDate
      extendEndDate
      extendStartDateTime
      extendEndDateTime
      signUpDateTimeTemp
      name
      departName
      attachedFileName
      attachedFileLink
      signUpDateTime
      extendDescr
      resultDescr
      allocateDay
      annualInfo
    }
  }
}
    `;

/**
 * __useGetListOfApprovalQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalQuery({
 *   variables: {
 *      approvalType: // value for 'approvalType'
 *      searchType: // value for 'searchType'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      fieldSort: // value for 'fieldSort'
 *      searchValue: // value for 'searchValue'
 *      customEmployeeId: // value for 'customEmployeeId'
 *   },
 * });
 */
export function useGetListOfApprovalQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalQuery, GetListOfApprovalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalQuery, GetListOfApprovalQueryVariables>(GetListOfApprovalDocument, options);
      }
export function useGetListOfApprovalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalQuery, GetListOfApprovalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalQuery, GetListOfApprovalQueryVariables>(GetListOfApprovalDocument, options);
        }
export type GetListOfApprovalQueryHookResult = ReturnType<typeof useGetListOfApprovalQuery>;
export type GetListOfApprovalLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalLazyQuery>;
export type GetListOfApprovalQueryResult = Apollo.QueryResult<GetListOfApprovalQuery, GetListOfApprovalQueryVariables>;
export const GetGroupsDocument = gql`
    query GetGroups($employeeId: String!) {
  getGroups(employeeId: $employeeId) {
    ok
    error
    listOfGroup {
      departmentId
      parentDepartmentId
      name
      level
      fullGroupName
      insertType
      countEmployee
      countEndpoint
      memo1
      memo2
      workingName
      deptSort
    }
  }
}
    `;

/**
 * __useGetGroupsQuery__
 *
 * To run a query within a React component, call `useGetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
      }
export function useGetGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
        }
export type GetGroupsQueryHookResult = ReturnType<typeof useGetGroupsQuery>;
export type GetGroupsLazyQueryHookResult = ReturnType<typeof useGetGroupsLazyQuery>;
export type GetGroupsQueryResult = Apollo.QueryResult<GetGroupsQuery, GetGroupsQueryVariables>;
export const GetAllEmployeeInformationInDepartmentDocument = gql`
    query GetAllEmployeeInformationInDepartment($selectedDepartmentId: String!) {
  getAllEmployeeInformationInDepartment(
    selectedDepartmentId: $selectedDepartmentId
  ) {
    ok
    error
    listOfEmployee {
      employeeId
      name
      departmentId
      fullDepartmentName
      departName
      endpointCount
      insertType
      workingName
      workingTemplateIdx
      asTimeTemplateName
      approvalDefine
      col1
      col2
      col3
      col4
      col5
      col6
      col7
      col8
      col9
      col10
      col11
      col12
      col13
      col14
      col15
      todayWorkingTime
      weekMaxWorkHour
      wtTot
      weekMaxExtWorkHour
      wtAsextto
      userPosition
    }
  }
}
    `;

/**
 * __useGetAllEmployeeInformationInDepartmentQuery__
 *
 * To run a query within a React component, call `useGetAllEmployeeInformationInDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEmployeeInformationInDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEmployeeInformationInDepartmentQuery({
 *   variables: {
 *      selectedDepartmentId: // value for 'selectedDepartmentId'
 *   },
 * });
 */
export function useGetAllEmployeeInformationInDepartmentQuery(baseOptions: Apollo.QueryHookOptions<GetAllEmployeeInformationInDepartmentQuery, GetAllEmployeeInformationInDepartmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEmployeeInformationInDepartmentQuery, GetAllEmployeeInformationInDepartmentQueryVariables>(GetAllEmployeeInformationInDepartmentDocument, options);
      }
export function useGetAllEmployeeInformationInDepartmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEmployeeInformationInDepartmentQuery, GetAllEmployeeInformationInDepartmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEmployeeInformationInDepartmentQuery, GetAllEmployeeInformationInDepartmentQueryVariables>(GetAllEmployeeInformationInDepartmentDocument, options);
        }
export type GetAllEmployeeInformationInDepartmentQueryHookResult = ReturnType<typeof useGetAllEmployeeInformationInDepartmentQuery>;
export type GetAllEmployeeInformationInDepartmentLazyQueryHookResult = ReturnType<typeof useGetAllEmployeeInformationInDepartmentLazyQuery>;
export type GetAllEmployeeInformationInDepartmentQueryResult = Apollo.QueryResult<GetAllEmployeeInformationInDepartmentQuery, GetAllEmployeeInformationInDepartmentQueryVariables>;
export const GetAllEmployeeInformationInDepartmentForAllEmployeesDocument = gql`
    query GetAllEmployeeInformationInDepartmentForAllEmployees($selectedDepartmentId: String!) {
  getAllEmployeeInformationInDepartmentForAllEmployees(
    selectedDepartmentId: $selectedDepartmentId
  ) {
    ok
    error
    listOfEmployee {
      employeeId
      name
      departmentId
      fullDepartmentName
      departName
      endpointCount
      insertType
      workingName
      workingTemplateIdx
      asTimeTemplateName
      approvalDefine
      col1
      col2
      col3
      col4
      col5
      col6
      col7
      col8
      col9
      col10
      col11
      col12
      col13
      col14
      col15
      todayWorkingTime
      weekMaxWorkHour
      wtTot
      weekMaxExtWorkHour
      wtAsextto
      userPosition
    }
  }
}
    `;

/**
 * __useGetAllEmployeeInformationInDepartmentForAllEmployeesQuery__
 *
 * To run a query within a React component, call `useGetAllEmployeeInformationInDepartmentForAllEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEmployeeInformationInDepartmentForAllEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEmployeeInformationInDepartmentForAllEmployeesQuery({
 *   variables: {
 *      selectedDepartmentId: // value for 'selectedDepartmentId'
 *   },
 * });
 */
export function useGetAllEmployeeInformationInDepartmentForAllEmployeesQuery(baseOptions: Apollo.QueryHookOptions<GetAllEmployeeInformationInDepartmentForAllEmployeesQuery, GetAllEmployeeInformationInDepartmentForAllEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEmployeeInformationInDepartmentForAllEmployeesQuery, GetAllEmployeeInformationInDepartmentForAllEmployeesQueryVariables>(GetAllEmployeeInformationInDepartmentForAllEmployeesDocument, options);
      }
export function useGetAllEmployeeInformationInDepartmentForAllEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEmployeeInformationInDepartmentForAllEmployeesQuery, GetAllEmployeeInformationInDepartmentForAllEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEmployeeInformationInDepartmentForAllEmployeesQuery, GetAllEmployeeInformationInDepartmentForAllEmployeesQueryVariables>(GetAllEmployeeInformationInDepartmentForAllEmployeesDocument, options);
        }
export type GetAllEmployeeInformationInDepartmentForAllEmployeesQueryHookResult = ReturnType<typeof useGetAllEmployeeInformationInDepartmentForAllEmployeesQuery>;
export type GetAllEmployeeInformationInDepartmentForAllEmployeesLazyQueryHookResult = ReturnType<typeof useGetAllEmployeeInformationInDepartmentForAllEmployeesLazyQuery>;
export type GetAllEmployeeInformationInDepartmentForAllEmployeesQueryResult = Apollo.QueryResult<GetAllEmployeeInformationInDepartmentForAllEmployeesQuery, GetAllEmployeeInformationInDepartmentForAllEmployeesQueryVariables>;
export const CountAllListOfApprovalDocument = gql`
    query CountAllListOfApproval($searchValue: String, $employeeId: String) {
  countAllListOfApproval(searchValue: $searchValue, employeeId: $employeeId) {
    ok
    error
    waitingCount
    completedCount
    receiveCount
    referenceCount
    myCount
  }
}
    `;

/**
 * __useCountAllListOfApprovalQuery__
 *
 * To run a query within a React component, call `useCountAllListOfApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAllListOfApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountAllListOfApprovalQuery({
 *   variables: {
 *      searchValue: // value for 'searchValue'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useCountAllListOfApprovalQuery(baseOptions?: Apollo.QueryHookOptions<CountAllListOfApprovalQuery, CountAllListOfApprovalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountAllListOfApprovalQuery, CountAllListOfApprovalQueryVariables>(CountAllListOfApprovalDocument, options);
      }
export function useCountAllListOfApprovalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountAllListOfApprovalQuery, CountAllListOfApprovalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountAllListOfApprovalQuery, CountAllListOfApprovalQueryVariables>(CountAllListOfApprovalDocument, options);
        }
export type CountAllListOfApprovalQueryHookResult = ReturnType<typeof useCountAllListOfApprovalQuery>;
export type CountAllListOfApprovalLazyQueryHookResult = ReturnType<typeof useCountAllListOfApprovalLazyQuery>;
export type CountAllListOfApprovalQueryResult = Apollo.QueryResult<CountAllListOfApprovalQuery, CountAllListOfApprovalQueryVariables>;
export const CountListOfApprovalDocument = gql`
    query CountListOfApproval($approvalType: APPROVAL_FOR_LIST_TYPE!, $searchType: ApprovalSearchType!, $searchValue: String, $customEmployeeId: String) {
  countListOfApproval(
    approvalType: $approvalType
    searchType: $searchType
    searchValue: $searchValue
    customEmployeeId: $customEmployeeId
  ) {
    ok
    error
    count
  }
}
    `;

/**
 * __useCountListOfApprovalQuery__
 *
 * To run a query within a React component, call `useCountListOfApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountListOfApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountListOfApprovalQuery({
 *   variables: {
 *      approvalType: // value for 'approvalType'
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *      customEmployeeId: // value for 'customEmployeeId'
 *   },
 * });
 */
export function useCountListOfApprovalQuery(baseOptions: Apollo.QueryHookOptions<CountListOfApprovalQuery, CountListOfApprovalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountListOfApprovalQuery, CountListOfApprovalQueryVariables>(CountListOfApprovalDocument, options);
      }
export function useCountListOfApprovalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountListOfApprovalQuery, CountListOfApprovalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountListOfApprovalQuery, CountListOfApprovalQueryVariables>(CountListOfApprovalDocument, options);
        }
export type CountListOfApprovalQueryHookResult = ReturnType<typeof useCountListOfApprovalQuery>;
export type CountListOfApprovalLazyQueryHookResult = ReturnType<typeof useCountListOfApprovalLazyQuery>;
export type CountListOfApprovalQueryResult = Apollo.QueryResult<CountListOfApprovalQuery, CountListOfApprovalQueryVariables>;
export const GetApprovalFormDetailDocument = gql`
    query GetApprovalFormDetail($employeeId: String!, $approvalRequestIdx: Int!) {
  getApprovalFormDetail(
    employeeId: $employeeId
    approvalRequestIdx: $approvalRequestIdx
  ) {
    ok
    error
    listOfApprovalUser {
      approvalRequestIdx
      approverId
      name
      approvalOrder
      approverType
      approvalResult
      approvalTime
      signType
      signText
      sign_image
      signImage
      viewTime
    }
    information {
      approvalRequestIdx
      formTemplateTitle
      employeeIdRequest
      departmentName
      employeeName
      userPosition
      docNumber
      signUpDateTimeTemp
      signUpDateTime
      categoryName
      storePeriod
      formName
      extendSDate
      extendSDateTime
      extendEDate
      extendEDateTime
      approvalTitle
      extendDescription
      attachedFileName
      attachedFileLink
    }
    listOfReceiveDepartment {
      departmentId
      departmentName
    }
    listOfReceiveUser {
      employeeId
      employeeName
    }
    listOfReferenceDepartment {
      departmentId
      departmentName
    }
    listOfReferenceUser {
      employeeId
      employeeName
    }
  }
}
    `;

/**
 * __useGetApprovalFormDetailQuery__
 *
 * To run a query within a React component, call `useGetApprovalFormDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalFormDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalFormDetailQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *   },
 * });
 */
export function useGetApprovalFormDetailQuery(baseOptions: Apollo.QueryHookOptions<GetApprovalFormDetailQuery, GetApprovalFormDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalFormDetailQuery, GetApprovalFormDetailQueryVariables>(GetApprovalFormDetailDocument, options);
      }
export function useGetApprovalFormDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalFormDetailQuery, GetApprovalFormDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalFormDetailQuery, GetApprovalFormDetailQueryVariables>(GetApprovalFormDetailDocument, options);
        }
export type GetApprovalFormDetailQueryHookResult = ReturnType<typeof useGetApprovalFormDetailQuery>;
export type GetApprovalFormDetailLazyQueryHookResult = ReturnType<typeof useGetApprovalFormDetailLazyQuery>;
export type GetApprovalFormDetailQueryResult = Apollo.QueryResult<GetApprovalFormDetailQuery, GetApprovalFormDetailQueryVariables>;
export const GetListOfApprovalHistoryDocument = gql`
    query GetListOfApprovalHistory($approvalRequestIdx: Int!) {
  getListOfApprovalHistory(approvalRequestIdx: $approvalRequestIdx) {
    ok
    error
    list {
      numRank
      empName
      userPosition
      departmentName
      approvalTime
      approvalResult
      descriptionComment
      viewTime
    }
  }
}
    `;

/**
 * __useGetListOfApprovalHistoryQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalHistoryQuery({
 *   variables: {
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *   },
 * });
 */
export function useGetListOfApprovalHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalHistoryQuery, GetListOfApprovalHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalHistoryQuery, GetListOfApprovalHistoryQueryVariables>(GetListOfApprovalHistoryDocument, options);
      }
export function useGetListOfApprovalHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalHistoryQuery, GetListOfApprovalHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalHistoryQuery, GetListOfApprovalHistoryQueryVariables>(GetListOfApprovalHistoryDocument, options);
        }
export type GetListOfApprovalHistoryQueryHookResult = ReturnType<typeof useGetListOfApprovalHistoryQuery>;
export type GetListOfApprovalHistoryLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalHistoryLazyQuery>;
export type GetListOfApprovalHistoryQueryResult = Apollo.QueryResult<GetListOfApprovalHistoryQuery, GetListOfApprovalHistoryQueryVariables>;
export const GetListOfApprovalTemporaryStorageDocument = gql`
    query GetListOfApprovalTemporaryStorage($searchType: ApprovalSearchType!, $page: Float, $take: Float, $fieldSort: FieldSort, $searchValue: String) {
  getListOfApprovalTemporaryStorage(
    searchType: $searchType
    page: $page
    take: $take
    fieldSort: $fieldSort
    searchValue: $searchValue
  ) {
    ok
    error
    list {
      approvalRequestIdx
      formTemplateIdx
      approvalType
      signUpDatetime
      approvalTitle
      categoryId
      formIdx
      extendStartDate
      extendEndDate
      extendStartDateTime
      extendEndDateTime
      name
      attachedFileName
      attachedFileLink
    }
  }
}
    `;

/**
 * __useGetListOfApprovalTemporaryStorageQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalTemporaryStorageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalTemporaryStorageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalTemporaryStorageQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      fieldSort: // value for 'fieldSort'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetListOfApprovalTemporaryStorageQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalTemporaryStorageQuery, GetListOfApprovalTemporaryStorageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalTemporaryStorageQuery, GetListOfApprovalTemporaryStorageQueryVariables>(GetListOfApprovalTemporaryStorageDocument, options);
      }
export function useGetListOfApprovalTemporaryStorageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalTemporaryStorageQuery, GetListOfApprovalTemporaryStorageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalTemporaryStorageQuery, GetListOfApprovalTemporaryStorageQueryVariables>(GetListOfApprovalTemporaryStorageDocument, options);
        }
export type GetListOfApprovalTemporaryStorageQueryHookResult = ReturnType<typeof useGetListOfApprovalTemporaryStorageQuery>;
export type GetListOfApprovalTemporaryStorageLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalTemporaryStorageLazyQuery>;
export type GetListOfApprovalTemporaryStorageQueryResult = Apollo.QueryResult<GetListOfApprovalTemporaryStorageQuery, GetListOfApprovalTemporaryStorageQueryVariables>;
export const CountListOfApprovalTemporaryStorageDocument = gql`
    query CountListOfApprovalTemporaryStorage($searchType: ApprovalSearchType!, $searchValue: String) {
  countListOfApprovalTemporaryStorage(
    searchType: $searchType
    searchValue: $searchValue
  ) {
    ok
    error
    count
  }
}
    `;

/**
 * __useCountListOfApprovalTemporaryStorageQuery__
 *
 * To run a query within a React component, call `useCountListOfApprovalTemporaryStorageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountListOfApprovalTemporaryStorageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountListOfApprovalTemporaryStorageQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useCountListOfApprovalTemporaryStorageQuery(baseOptions: Apollo.QueryHookOptions<CountListOfApprovalTemporaryStorageQuery, CountListOfApprovalTemporaryStorageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountListOfApprovalTemporaryStorageQuery, CountListOfApprovalTemporaryStorageQueryVariables>(CountListOfApprovalTemporaryStorageDocument, options);
      }
export function useCountListOfApprovalTemporaryStorageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountListOfApprovalTemporaryStorageQuery, CountListOfApprovalTemporaryStorageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountListOfApprovalTemporaryStorageQuery, CountListOfApprovalTemporaryStorageQueryVariables>(CountListOfApprovalTemporaryStorageDocument, options);
        }
export type CountListOfApprovalTemporaryStorageQueryHookResult = ReturnType<typeof useCountListOfApprovalTemporaryStorageQuery>;
export type CountListOfApprovalTemporaryStorageLazyQueryHookResult = ReturnType<typeof useCountListOfApprovalTemporaryStorageLazyQuery>;
export type CountListOfApprovalTemporaryStorageQueryResult = Apollo.QueryResult<CountListOfApprovalTemporaryStorageQuery, CountListOfApprovalTemporaryStorageQueryVariables>;
export const GetApprovalTemporaryStorageDetailInformationDocument = gql`
    query GetApprovalTemporaryStorageDetailInformation($employeeId: String!, $approvalRequestIdx: Int!) {
  getApprovalTemporaryStorageDetailInformation(
    employeeId: $employeeId
    approvalRequestIdx: $approvalRequestIdx
  ) {
    ok
    error
    listOfApprovalUser {
      approvalRequestIdx
      approverId
      name
      approvalOrder
      approverType
      approvalResult
      approvalTime
      signType
      signText
      sign_image
      signImage
    }
    information {
      approvalRequestIdx
      formTemplateTitle
      employeeIdRequest
      departmentName
      employeeName
      userPosition
      docNumber
      signUpDateTime
      categoryName
      storePeriod
      formName
      extendSDate
      extendSDateTime
      extendEDate
      extendEDateTime
      approvalTitle
      temporaryExtendDescription
      attachedFileName
      attachedFileLink
      allocateDay
      annualInfo
    }
    listOfReceiveDepartment {
      departmentId
      departmentName
    }
    listOfReceiveUser {
      employeeId
      employeeName
    }
    listOfReferenceDepartment {
      departmentId
      departmentName
    }
    listOfReferenceUser {
      employeeId
      employeeName
    }
  }
}
    `;

/**
 * __useGetApprovalTemporaryStorageDetailInformationQuery__
 *
 * To run a query within a React component, call `useGetApprovalTemporaryStorageDetailInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalTemporaryStorageDetailInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalTemporaryStorageDetailInformationQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *   },
 * });
 */
export function useGetApprovalTemporaryStorageDetailInformationQuery(baseOptions: Apollo.QueryHookOptions<GetApprovalTemporaryStorageDetailInformationQuery, GetApprovalTemporaryStorageDetailInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalTemporaryStorageDetailInformationQuery, GetApprovalTemporaryStorageDetailInformationQueryVariables>(GetApprovalTemporaryStorageDetailInformationDocument, options);
      }
export function useGetApprovalTemporaryStorageDetailInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalTemporaryStorageDetailInformationQuery, GetApprovalTemporaryStorageDetailInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalTemporaryStorageDetailInformationQuery, GetApprovalTemporaryStorageDetailInformationQueryVariables>(GetApprovalTemporaryStorageDetailInformationDocument, options);
        }
export type GetApprovalTemporaryStorageDetailInformationQueryHookResult = ReturnType<typeof useGetApprovalTemporaryStorageDetailInformationQuery>;
export type GetApprovalTemporaryStorageDetailInformationLazyQueryHookResult = ReturnType<typeof useGetApprovalTemporaryStorageDetailInformationLazyQuery>;
export type GetApprovalTemporaryStorageDetailInformationQueryResult = Apollo.QueryResult<GetApprovalTemporaryStorageDetailInformationQuery, GetApprovalTemporaryStorageDetailInformationQueryVariables>;
export const DeleteApprovalTemporaryStorageDocument = gql`
    mutation DeleteApprovalTemporaryStorage($approvalRequestIdx: Int!) {
  deleteApprovalTemporaryStorage(approvalRequestIdx: $approvalRequestIdx) {
    ok
    error
  }
}
    `;
export type DeleteApprovalTemporaryStorageMutationFn = Apollo.MutationFunction<DeleteApprovalTemporaryStorageMutation, DeleteApprovalTemporaryStorageMutationVariables>;

/**
 * __useDeleteApprovalTemporaryStorageMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalTemporaryStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalTemporaryStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalTemporaryStorageMutation, { data, loading, error }] = useDeleteApprovalTemporaryStorageMutation({
 *   variables: {
 *      approvalRequestIdx: // value for 'approvalRequestIdx'
 *   },
 * });
 */
export function useDeleteApprovalTemporaryStorageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApprovalTemporaryStorageMutation, DeleteApprovalTemporaryStorageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApprovalTemporaryStorageMutation, DeleteApprovalTemporaryStorageMutationVariables>(DeleteApprovalTemporaryStorageDocument, options);
      }
export type DeleteApprovalTemporaryStorageMutationHookResult = ReturnType<typeof useDeleteApprovalTemporaryStorageMutation>;
export type DeleteApprovalTemporaryStorageMutationResult = Apollo.MutationResult<DeleteApprovalTemporaryStorageMutation>;
export type DeleteApprovalTemporaryStorageMutationOptions = Apollo.BaseMutationOptions<DeleteApprovalTemporaryStorageMutation, DeleteApprovalTemporaryStorageMutationVariables>;
export const GetListOfApprovalBasicLineUserDocument = gql`
    query GetListOfApprovalBasicLineUser($employeeId: String!) {
  getListOfApprovalBasicLineUser(employeeId: $employeeId) {
    ok
    error
    list {
      approvalOrder
      approvalType
      approvalInfo
      lineIdx
      employeeId
    }
  }
}
    `;

/**
 * __useGetListOfApprovalBasicLineUserQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalBasicLineUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalBasicLineUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalBasicLineUserQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetListOfApprovalBasicLineUserQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalBasicLineUserQuery, GetListOfApprovalBasicLineUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalBasicLineUserQuery, GetListOfApprovalBasicLineUserQueryVariables>(GetListOfApprovalBasicLineUserDocument, options);
      }
export function useGetListOfApprovalBasicLineUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalBasicLineUserQuery, GetListOfApprovalBasicLineUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalBasicLineUserQuery, GetListOfApprovalBasicLineUserQueryVariables>(GetListOfApprovalBasicLineUserDocument, options);
        }
export type GetListOfApprovalBasicLineUserQueryHookResult = ReturnType<typeof useGetListOfApprovalBasicLineUserQuery>;
export type GetListOfApprovalBasicLineUserLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalBasicLineUserLazyQuery>;
export type GetListOfApprovalBasicLineUserQueryResult = Apollo.QueryResult<GetListOfApprovalBasicLineUserQuery, GetListOfApprovalBasicLineUserQueryVariables>;
export const GetAdditionalFieldInApprovalFormDocument = gql`
    query GetAdditionalFieldInApprovalForm($employeeId: String!, $year: String!, $categoryId: Float!) {
  getAdditionalFieldInApprovalForm(
    employeeId: $employeeId
    year: $year
    categoryId: $categoryId
  ) {
    ok
    error
    info {
      totDay
      useDay
      leaveDay
    }
  }
}
    `;

/**
 * __useGetAdditionalFieldInApprovalFormQuery__
 *
 * To run a query within a React component, call `useGetAdditionalFieldInApprovalFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdditionalFieldInApprovalFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdditionalFieldInApprovalFormQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      year: // value for 'year'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetAdditionalFieldInApprovalFormQuery(baseOptions: Apollo.QueryHookOptions<GetAdditionalFieldInApprovalFormQuery, GetAdditionalFieldInApprovalFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdditionalFieldInApprovalFormQuery, GetAdditionalFieldInApprovalFormQueryVariables>(GetAdditionalFieldInApprovalFormDocument, options);
      }
export function useGetAdditionalFieldInApprovalFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdditionalFieldInApprovalFormQuery, GetAdditionalFieldInApprovalFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdditionalFieldInApprovalFormQuery, GetAdditionalFieldInApprovalFormQueryVariables>(GetAdditionalFieldInApprovalFormDocument, options);
        }
export type GetAdditionalFieldInApprovalFormQueryHookResult = ReturnType<typeof useGetAdditionalFieldInApprovalFormQuery>;
export type GetAdditionalFieldInApprovalFormLazyQueryHookResult = ReturnType<typeof useGetAdditionalFieldInApprovalFormLazyQuery>;
export type GetAdditionalFieldInApprovalFormQueryResult = Apollo.QueryResult<GetAdditionalFieldInApprovalFormQuery, GetAdditionalFieldInApprovalFormQueryVariables>;
export const GetListOfApprovalTemplateDocument = gql`
    query GetListOfApprovalTemplate($employeeId: String!) {
  getListOfApprovalTemplate(employeeId: $employeeId) {
    ok
    error
    list {
      numRank
      formTemplateIdx
      templateTitle
      name
      formIdx
      formName
      approvalType
      description
      categoryId
    }
  }
}
    `;

/**
 * __useGetListOfApprovalTemplateQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalTemplateQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetListOfApprovalTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalTemplateQuery, GetListOfApprovalTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalTemplateQuery, GetListOfApprovalTemplateQueryVariables>(GetListOfApprovalTemplateDocument, options);
      }
export function useGetListOfApprovalTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalTemplateQuery, GetListOfApprovalTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalTemplateQuery, GetListOfApprovalTemplateQueryVariables>(GetListOfApprovalTemplateDocument, options);
        }
export type GetListOfApprovalTemplateQueryHookResult = ReturnType<typeof useGetListOfApprovalTemplateQuery>;
export type GetListOfApprovalTemplateLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalTemplateLazyQuery>;
export type GetListOfApprovalTemplateQueryResult = Apollo.QueryResult<GetListOfApprovalTemplateQuery, GetListOfApprovalTemplateQueryVariables>;
export const GetListOfApprovalFormDocument = gql`
    query GetListOfApprovalForm {
  getListOfApprovalForm {
    ok
    error
    list {
      formIdx
      formName
    }
  }
}
    `;

/**
 * __useGetListOfApprovalFormQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetListOfApprovalFormQuery(baseOptions?: Apollo.QueryHookOptions<GetListOfApprovalFormQuery, GetListOfApprovalFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalFormQuery, GetListOfApprovalFormQueryVariables>(GetListOfApprovalFormDocument, options);
      }
export function useGetListOfApprovalFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalFormQuery, GetListOfApprovalFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalFormQuery, GetListOfApprovalFormQueryVariables>(GetListOfApprovalFormDocument, options);
        }
export type GetListOfApprovalFormQueryHookResult = ReturnType<typeof useGetListOfApprovalFormQuery>;
export type GetListOfApprovalFormLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalFormLazyQuery>;
export type GetListOfApprovalFormQueryResult = Apollo.QueryResult<GetListOfApprovalFormQuery, GetListOfApprovalFormQueryVariables>;
export const GetListOfOtTimeTypeCategoryDocument = gql`
    query GetListOfOtTimeTypeCategory($name: String) {
  getListOfOtTimeTypeCategory(name: $name) {
    ok
    error
    list {
      categoryId
      name
    }
  }
}
    `;

/**
 * __useGetListOfOtTimeTypeCategoryQuery__
 *
 * To run a query within a React component, call `useGetListOfOtTimeTypeCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfOtTimeTypeCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfOtTimeTypeCategoryQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetListOfOtTimeTypeCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetListOfOtTimeTypeCategoryQuery, GetListOfOtTimeTypeCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfOtTimeTypeCategoryQuery, GetListOfOtTimeTypeCategoryQueryVariables>(GetListOfOtTimeTypeCategoryDocument, options);
      }
export function useGetListOfOtTimeTypeCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfOtTimeTypeCategoryQuery, GetListOfOtTimeTypeCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfOtTimeTypeCategoryQuery, GetListOfOtTimeTypeCategoryQueryVariables>(GetListOfOtTimeTypeCategoryDocument, options);
        }
export type GetListOfOtTimeTypeCategoryQueryHookResult = ReturnType<typeof useGetListOfOtTimeTypeCategoryQuery>;
export type GetListOfOtTimeTypeCategoryLazyQueryHookResult = ReturnType<typeof useGetListOfOtTimeTypeCategoryLazyQuery>;
export type GetListOfOtTimeTypeCategoryQueryResult = Apollo.QueryResult<GetListOfOtTimeTypeCategoryQuery, GetListOfOtTimeTypeCategoryQueryVariables>;
export const GetListOfApprovalLineDocument = gql`
    query GetListOfApprovalLine($employeeId: String!) {
  getListOfApprovalLine(employeeId: $employeeId) {
    ok
    error
    list {
      lineIdx
      newTitle
      basicLine
    }
  }
}
    `;

/**
 * __useGetListOfApprovalLineQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalLineQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetListOfApprovalLineQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalLineQuery, GetListOfApprovalLineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalLineQuery, GetListOfApprovalLineQueryVariables>(GetListOfApprovalLineDocument, options);
      }
export function useGetListOfApprovalLineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalLineQuery, GetListOfApprovalLineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalLineQuery, GetListOfApprovalLineQueryVariables>(GetListOfApprovalLineDocument, options);
        }
export type GetListOfApprovalLineQueryHookResult = ReturnType<typeof useGetListOfApprovalLineQuery>;
export type GetListOfApprovalLineLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalLineLazyQuery>;
export type GetListOfApprovalLineQueryResult = Apollo.QueryResult<GetListOfApprovalLineQuery, GetListOfApprovalLineQueryVariables>;
export const ControlApprovalFormDocument = gql`
    mutation ControlApprovalForm($controlApprovalFormType: TYPE_CONTROL_APPROVAL_FORM!, $formIdx: Int!, $templateName: String!, $categoryId: Int!, $approvalSort: APPROVAL_SORT!, $formTemplateIdx: Int, $description: String) {
  controlApprovalForm(
    controlApprovalFormType: $controlApprovalFormType
    formIdx: $formIdx
    templateName: $templateName
    categoryId: $categoryId
    approvalSort: $approvalSort
    formTemplateIdx: $formTemplateIdx
    description: $description
  ) {
    ok
    error
    formTemplateIdx
  }
}
    `;
export type ControlApprovalFormMutationFn = Apollo.MutationFunction<ControlApprovalFormMutation, ControlApprovalFormMutationVariables>;

/**
 * __useControlApprovalFormMutation__
 *
 * To run a mutation, you first call `useControlApprovalFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useControlApprovalFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [controlApprovalFormMutation, { data, loading, error }] = useControlApprovalFormMutation({
 *   variables: {
 *      controlApprovalFormType: // value for 'controlApprovalFormType'
 *      formIdx: // value for 'formIdx'
 *      templateName: // value for 'templateName'
 *      categoryId: // value for 'categoryId'
 *      approvalSort: // value for 'approvalSort'
 *      formTemplateIdx: // value for 'formTemplateIdx'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useControlApprovalFormMutation(baseOptions?: Apollo.MutationHookOptions<ControlApprovalFormMutation, ControlApprovalFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ControlApprovalFormMutation, ControlApprovalFormMutationVariables>(ControlApprovalFormDocument, options);
      }
export type ControlApprovalFormMutationHookResult = ReturnType<typeof useControlApprovalFormMutation>;
export type ControlApprovalFormMutationResult = Apollo.MutationResult<ControlApprovalFormMutation>;
export type ControlApprovalFormMutationOptions = Apollo.BaseMutationOptions<ControlApprovalFormMutation, ControlApprovalFormMutationVariables>;
export const DeleteApprovalTemplateFormDocument = gql`
    mutation DeleteApprovalTemplateForm($listOfFormTemplateIdx: [Int!]!) {
  deleteApprovalTemplateForm(listOfFormTemplateIdx: $listOfFormTemplateIdx) {
    ok
    error
  }
}
    `;
export type DeleteApprovalTemplateFormMutationFn = Apollo.MutationFunction<DeleteApprovalTemplateFormMutation, DeleteApprovalTemplateFormMutationVariables>;

/**
 * __useDeleteApprovalTemplateFormMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalTemplateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalTemplateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalTemplateFormMutation, { data, loading, error }] = useDeleteApprovalTemplateFormMutation({
 *   variables: {
 *      listOfFormTemplateIdx: // value for 'listOfFormTemplateIdx'
 *   },
 * });
 */
export function useDeleteApprovalTemplateFormMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApprovalTemplateFormMutation, DeleteApprovalTemplateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApprovalTemplateFormMutation, DeleteApprovalTemplateFormMutationVariables>(DeleteApprovalTemplateFormDocument, options);
      }
export type DeleteApprovalTemplateFormMutationHookResult = ReturnType<typeof useDeleteApprovalTemplateFormMutation>;
export type DeleteApprovalTemplateFormMutationResult = Apollo.MutationResult<DeleteApprovalTemplateFormMutation>;
export type DeleteApprovalTemplateFormMutationOptions = Apollo.BaseMutationOptions<DeleteApprovalTemplateFormMutation, DeleteApprovalTemplateFormMutationVariables>;
export const GetListOfSearchedApprovalLineUserDocument = gql`
    query GetListOfSearchedApprovalLineUser($employeeId: String!, $lineIdx: Int!) {
  getListOfSearchedApprovalLineUser(employeeId: $employeeId, lineIdx: $lineIdx) {
    ok
    error
    list {
      approvalOrder
      approvalType
      approvalInfo
      lineIdx
      employeeId
    }
  }
}
    `;

/**
 * __useGetListOfSearchedApprovalLineUserQuery__
 *
 * To run a query within a React component, call `useGetListOfSearchedApprovalLineUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfSearchedApprovalLineUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfSearchedApprovalLineUserQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      lineIdx: // value for 'lineIdx'
 *   },
 * });
 */
export function useGetListOfSearchedApprovalLineUserQuery(baseOptions: Apollo.QueryHookOptions<GetListOfSearchedApprovalLineUserQuery, GetListOfSearchedApprovalLineUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfSearchedApprovalLineUserQuery, GetListOfSearchedApprovalLineUserQueryVariables>(GetListOfSearchedApprovalLineUserDocument, options);
      }
export function useGetListOfSearchedApprovalLineUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfSearchedApprovalLineUserQuery, GetListOfSearchedApprovalLineUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfSearchedApprovalLineUserQuery, GetListOfSearchedApprovalLineUserQueryVariables>(GetListOfSearchedApprovalLineUserDocument, options);
        }
export type GetListOfSearchedApprovalLineUserQueryHookResult = ReturnType<typeof useGetListOfSearchedApprovalLineUserQuery>;
export type GetListOfSearchedApprovalLineUserLazyQueryHookResult = ReturnType<typeof useGetListOfSearchedApprovalLineUserLazyQuery>;
export type GetListOfSearchedApprovalLineUserQueryResult = Apollo.QueryResult<GetListOfSearchedApprovalLineUserQuery, GetListOfSearchedApprovalLineUserQueryVariables>;
export const AddApprovalLineDocument = gql`
    mutation AddApprovalLine($approvalLineControlType: ApprovalLineControlType!, $user: String!, $approvalLineName: String!, $isDefaultApprovalLine: IS_DEFAULT_APPROVAL_LINE!, $count: Int!, $list: [ApprovalLineUserEntity!]!) {
  addApprovalLine(
    approvalLineControlType: $approvalLineControlType
    user: $user
    approvalLineName: $approvalLineName
    isDefaultApprovalLine: $isDefaultApprovalLine
    count: $count
    list: $list
  ) {
    ok
    error
    lineIdx
  }
}
    `;
export type AddApprovalLineMutationFn = Apollo.MutationFunction<AddApprovalLineMutation, AddApprovalLineMutationVariables>;

/**
 * __useAddApprovalLineMutation__
 *
 * To run a mutation, you first call `useAddApprovalLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApprovalLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApprovalLineMutation, { data, loading, error }] = useAddApprovalLineMutation({
 *   variables: {
 *      approvalLineControlType: // value for 'approvalLineControlType'
 *      user: // value for 'user'
 *      approvalLineName: // value for 'approvalLineName'
 *      isDefaultApprovalLine: // value for 'isDefaultApprovalLine'
 *      count: // value for 'count'
 *      list: // value for 'list'
 *   },
 * });
 */
export function useAddApprovalLineMutation(baseOptions?: Apollo.MutationHookOptions<AddApprovalLineMutation, AddApprovalLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddApprovalLineMutation, AddApprovalLineMutationVariables>(AddApprovalLineDocument, options);
      }
export type AddApprovalLineMutationHookResult = ReturnType<typeof useAddApprovalLineMutation>;
export type AddApprovalLineMutationResult = Apollo.MutationResult<AddApprovalLineMutation>;
export type AddApprovalLineMutationOptions = Apollo.BaseMutationOptions<AddApprovalLineMutation, AddApprovalLineMutationVariables>;
export const EditApprovalLineDocument = gql`
    mutation EditApprovalLine($approvalLineControlType: ApprovalLineControlType!, $user: String!, $approvalLineName: String!, $isDefaultApprovalLine: IS_DEFAULT_APPROVAL_LINE!, $count: Int!, $list: [ApprovalLineUserEntity!]!, $lineIdx: Int!) {
  editApprovalLine(
    approvalLineControlType: $approvalLineControlType
    user: $user
    approvalLineName: $approvalLineName
    isDefaultApprovalLine: $isDefaultApprovalLine
    count: $count
    list: $list
    lineIdx: $lineIdx
  ) {
    ok
    error
    lineIdx
  }
}
    `;
export type EditApprovalLineMutationFn = Apollo.MutationFunction<EditApprovalLineMutation, EditApprovalLineMutationVariables>;

/**
 * __useEditApprovalLineMutation__
 *
 * To run a mutation, you first call `useEditApprovalLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditApprovalLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editApprovalLineMutation, { data, loading, error }] = useEditApprovalLineMutation({
 *   variables: {
 *      approvalLineControlType: // value for 'approvalLineControlType'
 *      user: // value for 'user'
 *      approvalLineName: // value for 'approvalLineName'
 *      isDefaultApprovalLine: // value for 'isDefaultApprovalLine'
 *      count: // value for 'count'
 *      list: // value for 'list'
 *      lineIdx: // value for 'lineIdx'
 *   },
 * });
 */
export function useEditApprovalLineMutation(baseOptions?: Apollo.MutationHookOptions<EditApprovalLineMutation, EditApprovalLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditApprovalLineMutation, EditApprovalLineMutationVariables>(EditApprovalLineDocument, options);
      }
export type EditApprovalLineMutationHookResult = ReturnType<typeof useEditApprovalLineMutation>;
export type EditApprovalLineMutationResult = Apollo.MutationResult<EditApprovalLineMutation>;
export type EditApprovalLineMutationOptions = Apollo.BaseMutationOptions<EditApprovalLineMutation, EditApprovalLineMutationVariables>;
export const DeleteApprovalLineDocument = gql`
    mutation DeleteApprovalLine($lineIdx: Int!, $user: String!) {
  deleteApprovalLine(lineIdx: $lineIdx, user: $user) {
    ok
    error
  }
}
    `;
export type DeleteApprovalLineMutationFn = Apollo.MutationFunction<DeleteApprovalLineMutation, DeleteApprovalLineMutationVariables>;

/**
 * __useDeleteApprovalLineMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalLineMutation, { data, loading, error }] = useDeleteApprovalLineMutation({
 *   variables: {
 *      lineIdx: // value for 'lineIdx'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useDeleteApprovalLineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApprovalLineMutation, DeleteApprovalLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApprovalLineMutation, DeleteApprovalLineMutationVariables>(DeleteApprovalLineDocument, options);
      }
export type DeleteApprovalLineMutationHookResult = ReturnType<typeof useDeleteApprovalLineMutation>;
export type DeleteApprovalLineMutationResult = Apollo.MutationResult<DeleteApprovalLineMutation>;
export type DeleteApprovalLineMutationOptions = Apollo.BaseMutationOptions<DeleteApprovalLineMutation, DeleteApprovalLineMutationVariables>;
export const GetApprovalAdminSettingDocument = gql`
    query GetApprovalAdminSetting {
  getApprovalAdminSetting {
    ok
    error
    preview
    dashboardListCount
  }
}
    `;

/**
 * __useGetApprovalAdminSettingQuery__
 *
 * To run a query within a React component, call `useGetApprovalAdminSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalAdminSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalAdminSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApprovalAdminSettingQuery(baseOptions?: Apollo.QueryHookOptions<GetApprovalAdminSettingQuery, GetApprovalAdminSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalAdminSettingQuery, GetApprovalAdminSettingQueryVariables>(GetApprovalAdminSettingDocument, options);
      }
export function useGetApprovalAdminSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalAdminSettingQuery, GetApprovalAdminSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalAdminSettingQuery, GetApprovalAdminSettingQueryVariables>(GetApprovalAdminSettingDocument, options);
        }
export type GetApprovalAdminSettingQueryHookResult = ReturnType<typeof useGetApprovalAdminSettingQuery>;
export type GetApprovalAdminSettingLazyQueryHookResult = ReturnType<typeof useGetApprovalAdminSettingLazyQuery>;
export type GetApprovalAdminSettingQueryResult = Apollo.QueryResult<GetApprovalAdminSettingQuery, GetApprovalAdminSettingQueryVariables>;
export const EditApprovalAdminSettingDocument = gql`
    mutation EditApprovalAdminSetting($preview: APPROVAL_ADMIN_SETTING_PREVIEW_TYPE!, $count: Int!) {
  editApprovalAdminSetting(preview: $preview, count: $count) {
    ok
    error
  }
}
    `;
export type EditApprovalAdminSettingMutationFn = Apollo.MutationFunction<EditApprovalAdminSettingMutation, EditApprovalAdminSettingMutationVariables>;

/**
 * __useEditApprovalAdminSettingMutation__
 *
 * To run a mutation, you first call `useEditApprovalAdminSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditApprovalAdminSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editApprovalAdminSettingMutation, { data, loading, error }] = useEditApprovalAdminSettingMutation({
 *   variables: {
 *      preview: // value for 'preview'
 *      count: // value for 'count'
 *   },
 * });
 */
export function useEditApprovalAdminSettingMutation(baseOptions?: Apollo.MutationHookOptions<EditApprovalAdminSettingMutation, EditApprovalAdminSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditApprovalAdminSettingMutation, EditApprovalAdminSettingMutationVariables>(EditApprovalAdminSettingDocument, options);
      }
export type EditApprovalAdminSettingMutationHookResult = ReturnType<typeof useEditApprovalAdminSettingMutation>;
export type EditApprovalAdminSettingMutationResult = Apollo.MutationResult<EditApprovalAdminSettingMutation>;
export type EditApprovalAdminSettingMutationOptions = Apollo.BaseMutationOptions<EditApprovalAdminSettingMutation, EditApprovalAdminSettingMutationVariables>;
export const GetApprovalPasswordAndSignSettingInfoDocument = gql`
    query GetApprovalPasswordAndSignSettingInfo($employeeId: String!) {
  getApprovalPasswordAndSignSettingInfo(employeeId: $employeeId) {
    ok
    error
    info {
      employeeId
      signType
      signText
      signImage
      signPassword
      isSignPassword
      isSignEmailAlarm
      isSignMobileAlarm
    }
  }
}
    `;

/**
 * __useGetApprovalPasswordAndSignSettingInfoQuery__
 *
 * To run a query within a React component, call `useGetApprovalPasswordAndSignSettingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPasswordAndSignSettingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPasswordAndSignSettingInfoQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetApprovalPasswordAndSignSettingInfoQuery(baseOptions: Apollo.QueryHookOptions<GetApprovalPasswordAndSignSettingInfoQuery, GetApprovalPasswordAndSignSettingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalPasswordAndSignSettingInfoQuery, GetApprovalPasswordAndSignSettingInfoQueryVariables>(GetApprovalPasswordAndSignSettingInfoDocument, options);
      }
export function useGetApprovalPasswordAndSignSettingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalPasswordAndSignSettingInfoQuery, GetApprovalPasswordAndSignSettingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalPasswordAndSignSettingInfoQuery, GetApprovalPasswordAndSignSettingInfoQueryVariables>(GetApprovalPasswordAndSignSettingInfoDocument, options);
        }
export type GetApprovalPasswordAndSignSettingInfoQueryHookResult = ReturnType<typeof useGetApprovalPasswordAndSignSettingInfoQuery>;
export type GetApprovalPasswordAndSignSettingInfoLazyQueryHookResult = ReturnType<typeof useGetApprovalPasswordAndSignSettingInfoLazyQuery>;
export type GetApprovalPasswordAndSignSettingInfoQueryResult = Apollo.QueryResult<GetApprovalPasswordAndSignSettingInfoQuery, GetApprovalPasswordAndSignSettingInfoQueryVariables>;
export const SaveApprovalPasswordAndSignSettingDocument = gql`
    mutation SaveApprovalPasswordAndSignSetting($employeeId: String!, $signType: SignType!, $isSignPassword: IS_SIGN_PASSWORD!, $isSignEmailAlarm: IS_SIGN_ALARM_TYPE!, $isSignMobileAlarm: IS_SIGN_ALARM_TYPE!, $signText: String, $signImage: [String!], $signPassword: String) {
  saveApprovalPasswordAndSignSetting(
    employeeId: $employeeId
    signType: $signType
    isSignPassword: $isSignPassword
    isSignEmailAlarm: $isSignEmailAlarm
    isSignMobileAlarm: $isSignMobileAlarm
    signText: $signText
    signImage: $signImage
    signPassword: $signPassword
  ) {
    ok
    error
  }
}
    `;
export type SaveApprovalPasswordAndSignSettingMutationFn = Apollo.MutationFunction<SaveApprovalPasswordAndSignSettingMutation, SaveApprovalPasswordAndSignSettingMutationVariables>;

/**
 * __useSaveApprovalPasswordAndSignSettingMutation__
 *
 * To run a mutation, you first call `useSaveApprovalPasswordAndSignSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveApprovalPasswordAndSignSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveApprovalPasswordAndSignSettingMutation, { data, loading, error }] = useSaveApprovalPasswordAndSignSettingMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      signType: // value for 'signType'
 *      isSignPassword: // value for 'isSignPassword'
 *      isSignEmailAlarm: // value for 'isSignEmailAlarm'
 *      isSignMobileAlarm: // value for 'isSignMobileAlarm'
 *      signText: // value for 'signText'
 *      signImage: // value for 'signImage'
 *      signPassword: // value for 'signPassword'
 *   },
 * });
 */
export function useSaveApprovalPasswordAndSignSettingMutation(baseOptions?: Apollo.MutationHookOptions<SaveApprovalPasswordAndSignSettingMutation, SaveApprovalPasswordAndSignSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveApprovalPasswordAndSignSettingMutation, SaveApprovalPasswordAndSignSettingMutationVariables>(SaveApprovalPasswordAndSignSettingDocument, options);
      }
export type SaveApprovalPasswordAndSignSettingMutationHookResult = ReturnType<typeof useSaveApprovalPasswordAndSignSettingMutation>;
export type SaveApprovalPasswordAndSignSettingMutationResult = Apollo.MutationResult<SaveApprovalPasswordAndSignSettingMutation>;
export type SaveApprovalPasswordAndSignSettingMutationOptions = Apollo.BaseMutationOptions<SaveApprovalPasswordAndSignSettingMutation, SaveApprovalPasswordAndSignSettingMutationVariables>;
export const DeleteApprovalSignImageOrTextDocument = gql`
    mutation DeleteApprovalSignImageOrText($employeeId: String!, $deleteSignType: SignType!) {
  deleteApprovalSignImageOrText(
    employeeId: $employeeId
    deleteSignType: $deleteSignType
  ) {
    ok
    error
  }
}
    `;
export type DeleteApprovalSignImageOrTextMutationFn = Apollo.MutationFunction<DeleteApprovalSignImageOrTextMutation, DeleteApprovalSignImageOrTextMutationVariables>;

/**
 * __useDeleteApprovalSignImageOrTextMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalSignImageOrTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalSignImageOrTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalSignImageOrTextMutation, { data, loading, error }] = useDeleteApprovalSignImageOrTextMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      deleteSignType: // value for 'deleteSignType'
 *   },
 * });
 */
export function useDeleteApprovalSignImageOrTextMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApprovalSignImageOrTextMutation, DeleteApprovalSignImageOrTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApprovalSignImageOrTextMutation, DeleteApprovalSignImageOrTextMutationVariables>(DeleteApprovalSignImageOrTextDocument, options);
      }
export type DeleteApprovalSignImageOrTextMutationHookResult = ReturnType<typeof useDeleteApprovalSignImageOrTextMutation>;
export type DeleteApprovalSignImageOrTextMutationResult = Apollo.MutationResult<DeleteApprovalSignImageOrTextMutation>;
export type DeleteApprovalSignImageOrTextMutationOptions = Apollo.BaseMutationOptions<DeleteApprovalSignImageOrTextMutation, DeleteApprovalSignImageOrTextMutationVariables>;
export const GetListOfApprovalLineFormDocument = gql`
    query GetListOfApprovalLineForm($employeeId: String!, $formTemplateIdx: Int!) {
  getListOfApprovalLineForm(
    employeeId: $employeeId
    formTemplateIdx: $formTemplateIdx
  ) {
    ok
    error
    list {
      numRank
      templateTitle
      approvalType
      formName
      name
      descr
      formTemplateIdx
      formIdx
    }
  }
}
    `;

/**
 * __useGetListOfApprovalLineFormQuery__
 *
 * To run a query within a React component, call `useGetListOfApprovalLineFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfApprovalLineFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfApprovalLineFormQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      formTemplateIdx: // value for 'formTemplateIdx'
 *   },
 * });
 */
export function useGetListOfApprovalLineFormQuery(baseOptions: Apollo.QueryHookOptions<GetListOfApprovalLineFormQuery, GetListOfApprovalLineFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfApprovalLineFormQuery, GetListOfApprovalLineFormQueryVariables>(GetListOfApprovalLineFormDocument, options);
      }
export function useGetListOfApprovalLineFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfApprovalLineFormQuery, GetListOfApprovalLineFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfApprovalLineFormQuery, GetListOfApprovalLineFormQueryVariables>(GetListOfApprovalLineFormDocument, options);
        }
export type GetListOfApprovalLineFormQueryHookResult = ReturnType<typeof useGetListOfApprovalLineFormQuery>;
export type GetListOfApprovalLineFormLazyQueryHookResult = ReturnType<typeof useGetListOfApprovalLineFormLazyQuery>;
export type GetListOfApprovalLineFormQueryResult = Apollo.QueryResult<GetListOfApprovalLineFormQuery, GetListOfApprovalLineFormQueryVariables>;
export const AddCommuteDateTimeDocument = gql`
    mutation AddCommuteDateTime($selectedEmployeeId: [String!]!, $checkDateTime: String!, $commuteType: CommuteType!, $deviceType: DEVICE_TYPE!) {
  addCommuteDateTime(
    selectedEmployeeId: $selectedEmployeeId
    checkDateTime: $checkDateTime
    commuteType: $commuteType
    deviceType: $deviceType
  ) {
    ok
    error
  }
}
    `;
export type AddCommuteDateTimeMutationFn = Apollo.MutationFunction<AddCommuteDateTimeMutation, AddCommuteDateTimeMutationVariables>;

/**
 * __useAddCommuteDateTimeMutation__
 *
 * To run a mutation, you first call `useAddCommuteDateTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommuteDateTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommuteDateTimeMutation, { data, loading, error }] = useAddCommuteDateTimeMutation({
 *   variables: {
 *      selectedEmployeeId: // value for 'selectedEmployeeId'
 *      checkDateTime: // value for 'checkDateTime'
 *      commuteType: // value for 'commuteType'
 *      deviceType: // value for 'deviceType'
 *   },
 * });
 */
export function useAddCommuteDateTimeMutation(baseOptions?: Apollo.MutationHookOptions<AddCommuteDateTimeMutation, AddCommuteDateTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommuteDateTimeMutation, AddCommuteDateTimeMutationVariables>(AddCommuteDateTimeDocument, options);
      }
export type AddCommuteDateTimeMutationHookResult = ReturnType<typeof useAddCommuteDateTimeMutation>;
export type AddCommuteDateTimeMutationResult = Apollo.MutationResult<AddCommuteDateTimeMutation>;
export type AddCommuteDateTimeMutationOptions = Apollo.BaseMutationOptions<AddCommuteDateTimeMutation, AddCommuteDateTimeMutationVariables>;
export const ListOfEmployeeCommuteInformationDocument = gql`
    query ListOfEmployeeCommuteInformation($listOfEmployeeId: [String!]!, $startDate: String!, $endDate: String!, $page: Float, $take: Float, $fieldSort: FieldSort) {
  listOfEmployeeCommuteInformation(
    listOfEmployeeId: $listOfEmployeeId
    startDate: $startDate
    endDate: $endDate
    page: $page
    take: $take
    fieldSort: $fieldSort
  ) {
    ok
    error
    list {
      name
      employeeId
      checkDateTime
      date
      time
      commuteType
      deviceType
      gpsLat
      gpsLong
      commuteAddress
      approvalFlag
      checkDateTimeModify
    }
    cacheId
  }
}
    `;

/**
 * __useListOfEmployeeCommuteInformationQuery__
 *
 * To run a query within a React component, call `useListOfEmployeeCommuteInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOfEmployeeCommuteInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOfEmployeeCommuteInformationQuery({
 *   variables: {
 *      listOfEmployeeId: // value for 'listOfEmployeeId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      fieldSort: // value for 'fieldSort'
 *   },
 * });
 */
export function useListOfEmployeeCommuteInformationQuery(baseOptions: Apollo.QueryHookOptions<ListOfEmployeeCommuteInformationQuery, ListOfEmployeeCommuteInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOfEmployeeCommuteInformationQuery, ListOfEmployeeCommuteInformationQueryVariables>(ListOfEmployeeCommuteInformationDocument, options);
      }
export function useListOfEmployeeCommuteInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOfEmployeeCommuteInformationQuery, ListOfEmployeeCommuteInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOfEmployeeCommuteInformationQuery, ListOfEmployeeCommuteInformationQueryVariables>(ListOfEmployeeCommuteInformationDocument, options);
        }
export type ListOfEmployeeCommuteInformationQueryHookResult = ReturnType<typeof useListOfEmployeeCommuteInformationQuery>;
export type ListOfEmployeeCommuteInformationLazyQueryHookResult = ReturnType<typeof useListOfEmployeeCommuteInformationLazyQuery>;
export type ListOfEmployeeCommuteInformationQueryResult = Apollo.QueryResult<ListOfEmployeeCommuteInformationQuery, ListOfEmployeeCommuteInformationQueryVariables>;
export const GetBasicUserInfoDocument = gql`
    query GetBasicUserInfo($getBasicUserInfoId: String) {
  getBasicUserInfo(id: $getBasicUserInfoId) {
    ok
    error
    user {
      empName
      my_picture
      myPicture
      departmentId
      nameChinese
      nameEnglish
      departmentName
      userJob
      userPosition
      userRank
      joiningDate
      leavingDate
      phoneNum
      userEmail
    }
  }
}
    `;

/**
 * __useGetBasicUserInfoQuery__
 *
 * To run a query within a React component, call `useGetBasicUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicUserInfoQuery({
 *   variables: {
 *      getBasicUserInfoId: // value for 'getBasicUserInfoId'
 *   },
 * });
 */
export function useGetBasicUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetBasicUserInfoQuery, GetBasicUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBasicUserInfoQuery, GetBasicUserInfoQueryVariables>(GetBasicUserInfoDocument, options);
      }
export function useGetBasicUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBasicUserInfoQuery, GetBasicUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBasicUserInfoQuery, GetBasicUserInfoQueryVariables>(GetBasicUserInfoDocument, options);
        }
export type GetBasicUserInfoQueryHookResult = ReturnType<typeof useGetBasicUserInfoQuery>;
export type GetBasicUserInfoLazyQueryHookResult = ReturnType<typeof useGetBasicUserInfoLazyQuery>;
export type GetBasicUserInfoQueryResult = Apollo.QueryResult<GetBasicUserInfoQuery, GetBasicUserInfoQueryVariables>;
export const UpdateBasicUserinfoDocument = gql`
    mutation UpdateBasicUserinfo($employeeId: String!, $employeeName: String!, $myPicture: String!, $nameChinese: String, $nameEnglish: String, $userJob: String, $userPosition: String, $userRank: String, $joiningDate: String, $leavingDate: String, $phoneNum: String, $userEmail: String) {
  updateBasicUserinfo(
    employeeId: $employeeId
    employeeName: $employeeName
    myPicture: $myPicture
    nameChinese: $nameChinese
    nameEnglish: $nameEnglish
    userJob: $userJob
    userPosition: $userPosition
    userRank: $userRank
    joiningDate: $joiningDate
    leavingDate: $leavingDate
    phoneNum: $phoneNum
    userEmail: $userEmail
  ) {
    ok
    error
  }
}
    `;
export type UpdateBasicUserinfoMutationFn = Apollo.MutationFunction<UpdateBasicUserinfoMutation, UpdateBasicUserinfoMutationVariables>;

/**
 * __useUpdateBasicUserinfoMutation__
 *
 * To run a mutation, you first call `useUpdateBasicUserinfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBasicUserinfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBasicUserinfoMutation, { data, loading, error }] = useUpdateBasicUserinfoMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      employeeName: // value for 'employeeName'
 *      myPicture: // value for 'myPicture'
 *      nameChinese: // value for 'nameChinese'
 *      nameEnglish: // value for 'nameEnglish'
 *      userJob: // value for 'userJob'
 *      userPosition: // value for 'userPosition'
 *      userRank: // value for 'userRank'
 *      joiningDate: // value for 'joiningDate'
 *      leavingDate: // value for 'leavingDate'
 *      phoneNum: // value for 'phoneNum'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useUpdateBasicUserinfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBasicUserinfoMutation, UpdateBasicUserinfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBasicUserinfoMutation, UpdateBasicUserinfoMutationVariables>(UpdateBasicUserinfoDocument, options);
      }
export type UpdateBasicUserinfoMutationHookResult = ReturnType<typeof useUpdateBasicUserinfoMutation>;
export type UpdateBasicUserinfoMutationResult = Apollo.MutationResult<UpdateBasicUserinfoMutation>;
export type UpdateBasicUserinfoMutationOptions = Apollo.BaseMutationOptions<UpdateBasicUserinfoMutation, UpdateBasicUserinfoMutationVariables>;
export const GetUserAnnualInfoDocument = gql`
    query GetUserAnnualInfo($employeeId: String!, $annualYear: String!) {
  getUserAnnualInfo(employeeId: $employeeId, annualYear: $annualYear) {
    ok
    error
    userAnnualInfo {
      employeeId
      applyYear
      statusFlag
      insertDatetime
      createSubject
      periodateStart
      periodateEnd
      basicDay
      basicModifyDay
      addDay
      leaveCarriedForwardDay
      useModifyDay
      modifyReason
      modifyDatetime
      useDay
      leaveDay
    }
  }
}
    `;

/**
 * __useGetUserAnnualInfoQuery__
 *
 * To run a query within a React component, call `useGetUserAnnualInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAnnualInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAnnualInfoQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      annualYear: // value for 'annualYear'
 *   },
 * });
 */
export function useGetUserAnnualInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUserAnnualInfoQuery, GetUserAnnualInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAnnualInfoQuery, GetUserAnnualInfoQueryVariables>(GetUserAnnualInfoDocument, options);
      }
export function useGetUserAnnualInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAnnualInfoQuery, GetUserAnnualInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAnnualInfoQuery, GetUserAnnualInfoQueryVariables>(GetUserAnnualInfoDocument, options);
        }
export type GetUserAnnualInfoQueryHookResult = ReturnType<typeof useGetUserAnnualInfoQuery>;
export type GetUserAnnualInfoLazyQueryHookResult = ReturnType<typeof useGetUserAnnualInfoLazyQuery>;
export type GetUserAnnualInfoQueryResult = Apollo.QueryResult<GetUserAnnualInfoQuery, GetUserAnnualInfoQueryVariables>;
export const UpdateUserAnnualInfoDocument = gql`
    mutation UpdateUserAnnualInfo($employeeId: String!, $applyYear: String!, $createSubject: String!, $createSubjectEmployeeId: String!, $periodateStart: String!, $periodateEnd: String!, $basicDay: Int!, $basicModifyDay: Int!, $addDay: Int!, $leaveCarriedForwardDay: Int!, $useModifyDay: Int!, $statusFlag: String, $modifyReason: String) {
  updateUserAnnualInfo(
    employeeId: $employeeId
    applyYear: $applyYear
    createSubject: $createSubject
    createSubjectEmployeeId: $createSubjectEmployeeId
    periodateStart: $periodateStart
    periodateEnd: $periodateEnd
    basicDay: $basicDay
    basicModifyDay: $basicModifyDay
    addDay: $addDay
    leaveCarriedForwardDay: $leaveCarriedForwardDay
    useModifyDay: $useModifyDay
    statusFlag: $statusFlag
    modifyReason: $modifyReason
  ) {
    ok
    error
  }
}
    `;
export type UpdateUserAnnualInfoMutationFn = Apollo.MutationFunction<UpdateUserAnnualInfoMutation, UpdateUserAnnualInfoMutationVariables>;

/**
 * __useUpdateUserAnnualInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserAnnualInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAnnualInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAnnualInfoMutation, { data, loading, error }] = useUpdateUserAnnualInfoMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      applyYear: // value for 'applyYear'
 *      createSubject: // value for 'createSubject'
 *      createSubjectEmployeeId: // value for 'createSubjectEmployeeId'
 *      periodateStart: // value for 'periodateStart'
 *      periodateEnd: // value for 'periodateEnd'
 *      basicDay: // value for 'basicDay'
 *      basicModifyDay: // value for 'basicModifyDay'
 *      addDay: // value for 'addDay'
 *      leaveCarriedForwardDay: // value for 'leaveCarriedForwardDay'
 *      useModifyDay: // value for 'useModifyDay'
 *      statusFlag: // value for 'statusFlag'
 *      modifyReason: // value for 'modifyReason'
 *   },
 * });
 */
export function useUpdateUserAnnualInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAnnualInfoMutation, UpdateUserAnnualInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAnnualInfoMutation, UpdateUserAnnualInfoMutationVariables>(UpdateUserAnnualInfoDocument, options);
      }
export type UpdateUserAnnualInfoMutationHookResult = ReturnType<typeof useUpdateUserAnnualInfoMutation>;
export type UpdateUserAnnualInfoMutationResult = Apollo.MutationResult<UpdateUserAnnualInfoMutation>;
export type UpdateUserAnnualInfoMutationOptions = Apollo.BaseMutationOptions<UpdateUserAnnualInfoMutation, UpdateUserAnnualInfoMutationVariables>;
export const GetAnnualHistoryDocument = gql`
    query GetAnnualHistory($employeeId: String!, $applyYear: String!) {
  getAnnualHistory(employeeId: $employeeId, applyYear: $applyYear) {
    ok
    error
    history {
      logIdx
      employeeId
      applyYear
      statusFlag
      createSubject
      periodateStart
      periodateEnd
      basicDay
      basicModifyDay
      addDay
      leaveCarriedForwardDay
      useModifyDay
      modifyReason
      modifyDatetime
    }
  }
}
    `;

/**
 * __useGetAnnualHistoryQuery__
 *
 * To run a query within a React component, call `useGetAnnualHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnualHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnualHistoryQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      applyYear: // value for 'applyYear'
 *   },
 * });
 */
export function useGetAnnualHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetAnnualHistoryQuery, GetAnnualHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnualHistoryQuery, GetAnnualHistoryQueryVariables>(GetAnnualHistoryDocument, options);
      }
export function useGetAnnualHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnualHistoryQuery, GetAnnualHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnualHistoryQuery, GetAnnualHistoryQueryVariables>(GetAnnualHistoryDocument, options);
        }
export type GetAnnualHistoryQueryHookResult = ReturnType<typeof useGetAnnualHistoryQuery>;
export type GetAnnualHistoryLazyQueryHookResult = ReturnType<typeof useGetAnnualHistoryLazyQuery>;
export type GetAnnualHistoryQueryResult = Apollo.QueryResult<GetAnnualHistoryQuery, GetAnnualHistoryQueryVariables>;
export const DownloadAnnualHistoryAsExcelDocument = gql`
    query DownloadAnnualHistoryAsExcel($employeeId: String!, $applyYear: String!) {
  downloadAnnualHistoryAsExcel(employeeId: $employeeId, applyYear: $applyYear) {
    ok
    error
    excel
  }
}
    `;

/**
 * __useDownloadAnnualHistoryAsExcelQuery__
 *
 * To run a query within a React component, call `useDownloadAnnualHistoryAsExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadAnnualHistoryAsExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadAnnualHistoryAsExcelQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      applyYear: // value for 'applyYear'
 *   },
 * });
 */
export function useDownloadAnnualHistoryAsExcelQuery(baseOptions: Apollo.QueryHookOptions<DownloadAnnualHistoryAsExcelQuery, DownloadAnnualHistoryAsExcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadAnnualHistoryAsExcelQuery, DownloadAnnualHistoryAsExcelQueryVariables>(DownloadAnnualHistoryAsExcelDocument, options);
      }
export function useDownloadAnnualHistoryAsExcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadAnnualHistoryAsExcelQuery, DownloadAnnualHistoryAsExcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadAnnualHistoryAsExcelQuery, DownloadAnnualHistoryAsExcelQueryVariables>(DownloadAnnualHistoryAsExcelDocument, options);
        }
export type DownloadAnnualHistoryAsExcelQueryHookResult = ReturnType<typeof useDownloadAnnualHistoryAsExcelQuery>;
export type DownloadAnnualHistoryAsExcelLazyQueryHookResult = ReturnType<typeof useDownloadAnnualHistoryAsExcelLazyQuery>;
export type DownloadAnnualHistoryAsExcelQueryResult = Apollo.QueryResult<DownloadAnnualHistoryAsExcelQuery, DownloadAnnualHistoryAsExcelQueryVariables>;
export const GetAnnualStatusListCountDocument = gql`
    query GetAnnualStatusListCount($employeeIdList: [String!]!, $searchYear: String!) {
  getAnnualStatusListCount(
    employeeIdList: $employeeIdList
    searchYear: $searchYear
  ) {
    ok
    error
    count
  }
}
    `;

/**
 * __useGetAnnualStatusListCountQuery__
 *
 * To run a query within a React component, call `useGetAnnualStatusListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnualStatusListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnualStatusListCountQuery({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      searchYear: // value for 'searchYear'
 *   },
 * });
 */
export function useGetAnnualStatusListCountQuery(baseOptions: Apollo.QueryHookOptions<GetAnnualStatusListCountQuery, GetAnnualStatusListCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnualStatusListCountQuery, GetAnnualStatusListCountQueryVariables>(GetAnnualStatusListCountDocument, options);
      }
export function useGetAnnualStatusListCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnualStatusListCountQuery, GetAnnualStatusListCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnualStatusListCountQuery, GetAnnualStatusListCountQueryVariables>(GetAnnualStatusListCountDocument, options);
        }
export type GetAnnualStatusListCountQueryHookResult = ReturnType<typeof useGetAnnualStatusListCountQuery>;
export type GetAnnualStatusListCountLazyQueryHookResult = ReturnType<typeof useGetAnnualStatusListCountLazyQuery>;
export type GetAnnualStatusListCountQueryResult = Apollo.QueryResult<GetAnnualStatusListCountQuery, GetAnnualStatusListCountQueryVariables>;
export const GetAnnualStatusListDocument = gql`
    query GetAnnualStatusList($employeeIdList: [String!]!, $searchYear: String!, $page: Float, $take: Float, $fieldSort: FieldSort) {
  getAnnualStatusList(
    employeeIdList: $employeeIdList
    searchYear: $searchYear
    page: $page
    take: $take
    fieldSort: $fieldSort
  ) {
    ok
    error
    list {
      deptName
      employeeId
      empName
      joiningDate
      applyYear
      annualDay
      annualUseDay
      annualRemainingDay
      descr
    }
  }
}
    `;

/**
 * __useGetAnnualStatusListQuery__
 *
 * To run a query within a React component, call `useGetAnnualStatusListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnualStatusListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnualStatusListQuery({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      searchYear: // value for 'searchYear'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      fieldSort: // value for 'fieldSort'
 *   },
 * });
 */
export function useGetAnnualStatusListQuery(baseOptions: Apollo.QueryHookOptions<GetAnnualStatusListQuery, GetAnnualStatusListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnualStatusListQuery, GetAnnualStatusListQueryVariables>(GetAnnualStatusListDocument, options);
      }
export function useGetAnnualStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnualStatusListQuery, GetAnnualStatusListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnualStatusListQuery, GetAnnualStatusListQueryVariables>(GetAnnualStatusListDocument, options);
        }
export type GetAnnualStatusListQueryHookResult = ReturnType<typeof useGetAnnualStatusListQuery>;
export type GetAnnualStatusListLazyQueryHookResult = ReturnType<typeof useGetAnnualStatusListLazyQuery>;
export type GetAnnualStatusListQueryResult = Apollo.QueryResult<GetAnnualStatusListQuery, GetAnnualStatusListQueryVariables>;
export const GetAnnualStatusListExcelDocument = gql`
    query GetAnnualStatusListExcel($employeeIdList: [String!]!, $searchYear: String!) {
  getAnnualStatusListExcel(
    employeeIdList: $employeeIdList
    searchYear: $searchYear
  ) {
    ok
    error
    excel
  }
}
    `;

/**
 * __useGetAnnualStatusListExcelQuery__
 *
 * To run a query within a React component, call `useGetAnnualStatusListExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnualStatusListExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnualStatusListExcelQuery({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      searchYear: // value for 'searchYear'
 *   },
 * });
 */
export function useGetAnnualStatusListExcelQuery(baseOptions: Apollo.QueryHookOptions<GetAnnualStatusListExcelQuery, GetAnnualStatusListExcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnualStatusListExcelQuery, GetAnnualStatusListExcelQueryVariables>(GetAnnualStatusListExcelDocument, options);
      }
export function useGetAnnualStatusListExcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnualStatusListExcelQuery, GetAnnualStatusListExcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnualStatusListExcelQuery, GetAnnualStatusListExcelQueryVariables>(GetAnnualStatusListExcelDocument, options);
        }
export type GetAnnualStatusListExcelQueryHookResult = ReturnType<typeof useGetAnnualStatusListExcelQuery>;
export type GetAnnualStatusListExcelLazyQueryHookResult = ReturnType<typeof useGetAnnualStatusListExcelLazyQuery>;
export type GetAnnualStatusListExcelQueryResult = Apollo.QueryResult<GetAnnualStatusListExcelQuery, GetAnnualStatusListExcelQueryVariables>;
export const EditAnnualStatusDocument = gql`
    mutation EditAnnualStatus($employeeIdList: [String!]!, $searchYear: String!, $annualDay: Float, $descr: String) {
  editAnnualStatus(
    employeeIdList: $employeeIdList
    searchYear: $searchYear
    annualDay: $annualDay
    descr: $descr
  ) {
    ok
    error
  }
}
    `;
export type EditAnnualStatusMutationFn = Apollo.MutationFunction<EditAnnualStatusMutation, EditAnnualStatusMutationVariables>;

/**
 * __useEditAnnualStatusMutation__
 *
 * To run a mutation, you first call `useEditAnnualStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAnnualStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAnnualStatusMutation, { data, loading, error }] = useEditAnnualStatusMutation({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      searchYear: // value for 'searchYear'
 *      annualDay: // value for 'annualDay'
 *      descr: // value for 'descr'
 *   },
 * });
 */
export function useEditAnnualStatusMutation(baseOptions?: Apollo.MutationHookOptions<EditAnnualStatusMutation, EditAnnualStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAnnualStatusMutation, EditAnnualStatusMutationVariables>(EditAnnualStatusDocument, options);
      }
export type EditAnnualStatusMutationHookResult = ReturnType<typeof useEditAnnualStatusMutation>;
export type EditAnnualStatusMutationResult = Apollo.MutationResult<EditAnnualStatusMutation>;
export type EditAnnualStatusMutationOptions = Apollo.BaseMutationOptions<EditAnnualStatusMutation, EditAnnualStatusMutationVariables>;
export const AddAnnualStatusFormExcelDocument = gql`
    mutation AddAnnualStatusFormExcel($info: [EditAnnualStatusInput!]!) {
  addAnnualStatusFormExcel(info: $info) {
    ok
    error
  }
}
    `;
export type AddAnnualStatusFormExcelMutationFn = Apollo.MutationFunction<AddAnnualStatusFormExcelMutation, AddAnnualStatusFormExcelMutationVariables>;

/**
 * __useAddAnnualStatusFormExcelMutation__
 *
 * To run a mutation, you first call `useAddAnnualStatusFormExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnnualStatusFormExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnnualStatusFormExcelMutation, { data, loading, error }] = useAddAnnualStatusFormExcelMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useAddAnnualStatusFormExcelMutation(baseOptions?: Apollo.MutationHookOptions<AddAnnualStatusFormExcelMutation, AddAnnualStatusFormExcelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAnnualStatusFormExcelMutation, AddAnnualStatusFormExcelMutationVariables>(AddAnnualStatusFormExcelDocument, options);
      }
export type AddAnnualStatusFormExcelMutationHookResult = ReturnType<typeof useAddAnnualStatusFormExcelMutation>;
export type AddAnnualStatusFormExcelMutationResult = Apollo.MutationResult<AddAnnualStatusFormExcelMutation>;
export type AddAnnualStatusFormExcelMutationOptions = Apollo.BaseMutationOptions<AddAnnualStatusFormExcelMutation, AddAnnualStatusFormExcelMutationVariables>;
export const GetApprovalStatusListCountDocument = gql`
    query GetApprovalStatusListCount($employeeIdList: [String!]!, $searchStartDate: String!, $searchEndDate: String!, $searchType: IApprovalStatusSearchType!, $searchValue: String) {
  getApprovalStatusListCount(
    employeeIdList: $employeeIdList
    searchStartDate: $searchStartDate
    searchEndDate: $searchEndDate
    searchType: $searchType
    searchValue: $searchValue
  ) {
    ok
    error
    count
  }
}
    `;

/**
 * __useGetApprovalStatusListCountQuery__
 *
 * To run a query within a React component, call `useGetApprovalStatusListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalStatusListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalStatusListCountQuery({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      searchStartDate: // value for 'searchStartDate'
 *      searchEndDate: // value for 'searchEndDate'
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApprovalStatusListCountQuery(baseOptions: Apollo.QueryHookOptions<GetApprovalStatusListCountQuery, GetApprovalStatusListCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalStatusListCountQuery, GetApprovalStatusListCountQueryVariables>(GetApprovalStatusListCountDocument, options);
      }
export function useGetApprovalStatusListCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalStatusListCountQuery, GetApprovalStatusListCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalStatusListCountQuery, GetApprovalStatusListCountQueryVariables>(GetApprovalStatusListCountDocument, options);
        }
export type GetApprovalStatusListCountQueryHookResult = ReturnType<typeof useGetApprovalStatusListCountQuery>;
export type GetApprovalStatusListCountLazyQueryHookResult = ReturnType<typeof useGetApprovalStatusListCountLazyQuery>;
export type GetApprovalStatusListCountQueryResult = Apollo.QueryResult<GetApprovalStatusListCountQuery, GetApprovalStatusListCountQueryVariables>;
export const GetApprovalStatusListDocument = gql`
    query GetApprovalStatusList($employeeIdList: [String!]!, $searchStartDate: String!, $searchEndDate: String!, $searchType: IApprovalStatusSearchType!, $page: Float, $take: Float, $fieldSort: FieldSort, $searchValue: String) {
  getApprovalStatusList(
    employeeIdList: $employeeIdList
    searchStartDate: $searchStartDate
    searchEndDate: $searchEndDate
    searchType: $searchType
    page: $page
    take: $take
    fieldSort: $fieldSort
    searchValue: $searchValue
  ) {
    ok
    error
    list {
      deptName
      employeeId
      empName
      requestDateTime
      categoryName
      startDateTime
      endDateTime
      requestDescr
      processStatus
      processDateTime
      processDescr
    }
  }
}
    `;

/**
 * __useGetApprovalStatusListQuery__
 *
 * To run a query within a React component, call `useGetApprovalStatusListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalStatusListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalStatusListQuery({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      searchStartDate: // value for 'searchStartDate'
 *      searchEndDate: // value for 'searchEndDate'
 *      searchType: // value for 'searchType'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      fieldSort: // value for 'fieldSort'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApprovalStatusListQuery(baseOptions: Apollo.QueryHookOptions<GetApprovalStatusListQuery, GetApprovalStatusListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalStatusListQuery, GetApprovalStatusListQueryVariables>(GetApprovalStatusListDocument, options);
      }
export function useGetApprovalStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalStatusListQuery, GetApprovalStatusListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalStatusListQuery, GetApprovalStatusListQueryVariables>(GetApprovalStatusListDocument, options);
        }
export type GetApprovalStatusListQueryHookResult = ReturnType<typeof useGetApprovalStatusListQuery>;
export type GetApprovalStatusListLazyQueryHookResult = ReturnType<typeof useGetApprovalStatusListLazyQuery>;
export type GetApprovalStatusListQueryResult = Apollo.QueryResult<GetApprovalStatusListQuery, GetApprovalStatusListQueryVariables>;
export const GetApprovalStatusListExcelDocument = gql`
    query GetApprovalStatusListExcel($employeeIdList: [String!]!, $searchStartDate: String!, $searchEndDate: String!, $searchType: IApprovalStatusSearchType!, $searchValue: String) {
  getApprovalStatusListExcel(
    employeeIdList: $employeeIdList
    searchStartDate: $searchStartDate
    searchEndDate: $searchEndDate
    searchType: $searchType
    searchValue: $searchValue
  ) {
    ok
    error
    excel
  }
}
    `;

/**
 * __useGetApprovalStatusListExcelQuery__
 *
 * To run a query within a React component, call `useGetApprovalStatusListExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalStatusListExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalStatusListExcelQuery({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      searchStartDate: // value for 'searchStartDate'
 *      searchEndDate: // value for 'searchEndDate'
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useGetApprovalStatusListExcelQuery(baseOptions: Apollo.QueryHookOptions<GetApprovalStatusListExcelQuery, GetApprovalStatusListExcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApprovalStatusListExcelQuery, GetApprovalStatusListExcelQueryVariables>(GetApprovalStatusListExcelDocument, options);
      }
export function useGetApprovalStatusListExcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalStatusListExcelQuery, GetApprovalStatusListExcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApprovalStatusListExcelQuery, GetApprovalStatusListExcelQueryVariables>(GetApprovalStatusListExcelDocument, options);
        }
export type GetApprovalStatusListExcelQueryHookResult = ReturnType<typeof useGetApprovalStatusListExcelQuery>;
export type GetApprovalStatusListExcelLazyQueryHookResult = ReturnType<typeof useGetApprovalStatusListExcelLazyQuery>;
export type GetApprovalStatusListExcelQueryResult = Apollo.QueryResult<GetApprovalStatusListExcelQuery, GetApprovalStatusListExcelQueryVariables>;
export const GetUserDailyWorkPlanDocument = gql`
    query GetUserDailyWorkPlan($employeeIdList: [String!]!, $startDate: String!, $endDate: String!, $page: Float, $take: Float, $fieldSort: FieldSort) {
  getUserDailyWorkPlan(
    employeeIdList: $employeeIdList
    startDate: $startDate
    endDate: $endDate
    page: $page
    take: $take
    fieldSort: $fieldSort
  ) {
    ok
    error
    list {
      departmentName
      employeeId
      employeeName
      startDate
      weekDayText
      startTime
      endTime
      restPlanMin
      recognitionPlanTime
      inCheckDatetime
      outCheckDatetime
      restAdmitMin
      breakTimeAdjustmentMin
      recognitionAdmitTime
      payTime
      isCheckStatus
    }
  }
}
    `;

/**
 * __useGetUserDailyWorkPlanQuery__
 *
 * To run a query within a React component, call `useGetUserDailyWorkPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDailyWorkPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDailyWorkPlanQuery({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      fieldSort: // value for 'fieldSort'
 *   },
 * });
 */
export function useGetUserDailyWorkPlanQuery(baseOptions: Apollo.QueryHookOptions<GetUserDailyWorkPlanQuery, GetUserDailyWorkPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDailyWorkPlanQuery, GetUserDailyWorkPlanQueryVariables>(GetUserDailyWorkPlanDocument, options);
      }
export function useGetUserDailyWorkPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDailyWorkPlanQuery, GetUserDailyWorkPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDailyWorkPlanQuery, GetUserDailyWorkPlanQueryVariables>(GetUserDailyWorkPlanDocument, options);
        }
export type GetUserDailyWorkPlanQueryHookResult = ReturnType<typeof useGetUserDailyWorkPlanQuery>;
export type GetUserDailyWorkPlanLazyQueryHookResult = ReturnType<typeof useGetUserDailyWorkPlanLazyQuery>;
export type GetUserDailyWorkPlanQueryResult = Apollo.QueryResult<GetUserDailyWorkPlanQuery, GetUserDailyWorkPlanQueryVariables>;
export const GetUserDailyWorkPlanExcelDocument = gql`
    query GetUserDailyWorkPlanExcel($employeeIdList: [String!]!, $startDate: String!, $endDate: String!) {
  getUserDailyWorkPlanExcel(
    employeeIdList: $employeeIdList
    startDate: $startDate
    endDate: $endDate
  ) {
    ok
    error
    excel
  }
}
    `;

/**
 * __useGetUserDailyWorkPlanExcelQuery__
 *
 * To run a query within a React component, call `useGetUserDailyWorkPlanExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDailyWorkPlanExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDailyWorkPlanExcelQuery({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetUserDailyWorkPlanExcelQuery(baseOptions: Apollo.QueryHookOptions<GetUserDailyWorkPlanExcelQuery, GetUserDailyWorkPlanExcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDailyWorkPlanExcelQuery, GetUserDailyWorkPlanExcelQueryVariables>(GetUserDailyWorkPlanExcelDocument, options);
      }
export function useGetUserDailyWorkPlanExcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDailyWorkPlanExcelQuery, GetUserDailyWorkPlanExcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDailyWorkPlanExcelQuery, GetUserDailyWorkPlanExcelQueryVariables>(GetUserDailyWorkPlanExcelDocument, options);
        }
export type GetUserDailyWorkPlanExcelQueryHookResult = ReturnType<typeof useGetUserDailyWorkPlanExcelQuery>;
export type GetUserDailyWorkPlanExcelLazyQueryHookResult = ReturnType<typeof useGetUserDailyWorkPlanExcelLazyQuery>;
export type GetUserDailyWorkPlanExcelQueryResult = Apollo.QueryResult<GetUserDailyWorkPlanExcelQuery, GetUserDailyWorkPlanExcelQueryVariables>;
export const GetUserDailyWorkPlanCountDocument = gql`
    query GetUserDailyWorkPlanCount($employeeIdList: [String!]!, $startDate: String!, $endDate: String!) {
  getUserDailyWorkPlanCount(
    employeeIdList: $employeeIdList
    startDate: $startDate
    endDate: $endDate
  ) {
    ok
    error
    count
  }
}
    `;

/**
 * __useGetUserDailyWorkPlanCountQuery__
 *
 * To run a query within a React component, call `useGetUserDailyWorkPlanCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDailyWorkPlanCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDailyWorkPlanCountQuery({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetUserDailyWorkPlanCountQuery(baseOptions: Apollo.QueryHookOptions<GetUserDailyWorkPlanCountQuery, GetUserDailyWorkPlanCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDailyWorkPlanCountQuery, GetUserDailyWorkPlanCountQueryVariables>(GetUserDailyWorkPlanCountDocument, options);
      }
export function useGetUserDailyWorkPlanCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDailyWorkPlanCountQuery, GetUserDailyWorkPlanCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDailyWorkPlanCountQuery, GetUserDailyWorkPlanCountQueryVariables>(GetUserDailyWorkPlanCountDocument, options);
        }
export type GetUserDailyWorkPlanCountQueryHookResult = ReturnType<typeof useGetUserDailyWorkPlanCountQuery>;
export type GetUserDailyWorkPlanCountLazyQueryHookResult = ReturnType<typeof useGetUserDailyWorkPlanCountLazyQuery>;
export type GetUserDailyWorkPlanCountQueryResult = Apollo.QueryResult<GetUserDailyWorkPlanCountQuery, GetUserDailyWorkPlanCountQueryVariables>;
export const AddUserDailyWorkInfoDocument = gql`
    mutation AddUserDailyWorkInfo($info: [AddUserDailyWorkInfoType!]!) {
  addUserDailyWorkInfo(info: $info) {
    ok
    error
  }
}
    `;
export type AddUserDailyWorkInfoMutationFn = Apollo.MutationFunction<AddUserDailyWorkInfoMutation, AddUserDailyWorkInfoMutationVariables>;

/**
 * __useAddUserDailyWorkInfoMutation__
 *
 * To run a mutation, you first call `useAddUserDailyWorkInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserDailyWorkInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserDailyWorkInfoMutation, { data, loading, error }] = useAddUserDailyWorkInfoMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useAddUserDailyWorkInfoMutation(baseOptions?: Apollo.MutationHookOptions<AddUserDailyWorkInfoMutation, AddUserDailyWorkInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserDailyWorkInfoMutation, AddUserDailyWorkInfoMutationVariables>(AddUserDailyWorkInfoDocument, options);
      }
export type AddUserDailyWorkInfoMutationHookResult = ReturnType<typeof useAddUserDailyWorkInfoMutation>;
export type AddUserDailyWorkInfoMutationResult = Apollo.MutationResult<AddUserDailyWorkInfoMutation>;
export type AddUserDailyWorkInfoMutationOptions = Apollo.BaseMutationOptions<AddUserDailyWorkInfoMutation, AddUserDailyWorkInfoMutationVariables>;
export const DeleteUserDailyWorkInfoDocument = gql`
    mutation DeleteUserDailyWorkInfo($info: [UserDailyWorkInfo!]!) {
  deleteUserDailyWorkInfo(info: $info) {
    ok
    error
  }
}
    `;
export type DeleteUserDailyWorkInfoMutationFn = Apollo.MutationFunction<DeleteUserDailyWorkInfoMutation, DeleteUserDailyWorkInfoMutationVariables>;

/**
 * __useDeleteUserDailyWorkInfoMutation__
 *
 * To run a mutation, you first call `useDeleteUserDailyWorkInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserDailyWorkInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserDailyWorkInfoMutation, { data, loading, error }] = useDeleteUserDailyWorkInfoMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useDeleteUserDailyWorkInfoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserDailyWorkInfoMutation, DeleteUserDailyWorkInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserDailyWorkInfoMutation, DeleteUserDailyWorkInfoMutationVariables>(DeleteUserDailyWorkInfoDocument, options);
      }
export type DeleteUserDailyWorkInfoMutationHookResult = ReturnType<typeof useDeleteUserDailyWorkInfoMutation>;
export type DeleteUserDailyWorkInfoMutationResult = Apollo.MutationResult<DeleteUserDailyWorkInfoMutation>;
export type DeleteUserDailyWorkInfoMutationOptions = Apollo.BaseMutationOptions<DeleteUserDailyWorkInfoMutation, DeleteUserDailyWorkInfoMutationVariables>;
export const EditUserDailyWorkInfoDocument = gql`
    mutation EditUserDailyWorkInfo($employeeId: String!, $date: String!, $beforeDate: String!, $startTime: String!, $endTime: String!, $breakTimeAdjustmentMin: String) {
  editUserDailyWorkInfo(
    employeeId: $employeeId
    date: $date
    beforeDate: $beforeDate
    startTime: $startTime
    endTime: $endTime
    breakTimeAdjustmentMin: $breakTimeAdjustmentMin
  ) {
    ok
    error
  }
}
    `;
export type EditUserDailyWorkInfoMutationFn = Apollo.MutationFunction<EditUserDailyWorkInfoMutation, EditUserDailyWorkInfoMutationVariables>;

/**
 * __useEditUserDailyWorkInfoMutation__
 *
 * To run a mutation, you first call `useEditUserDailyWorkInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserDailyWorkInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserDailyWorkInfoMutation, { data, loading, error }] = useEditUserDailyWorkInfoMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      date: // value for 'date'
 *      beforeDate: // value for 'beforeDate'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      breakTimeAdjustmentMin: // value for 'breakTimeAdjustmentMin'
 *   },
 * });
 */
export function useEditUserDailyWorkInfoMutation(baseOptions?: Apollo.MutationHookOptions<EditUserDailyWorkInfoMutation, EditUserDailyWorkInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserDailyWorkInfoMutation, EditUserDailyWorkInfoMutationVariables>(EditUserDailyWorkInfoDocument, options);
      }
export type EditUserDailyWorkInfoMutationHookResult = ReturnType<typeof useEditUserDailyWorkInfoMutation>;
export type EditUserDailyWorkInfoMutationResult = Apollo.MutationResult<EditUserDailyWorkInfoMutation>;
export type EditUserDailyWorkInfoMutationOptions = Apollo.BaseMutationOptions<EditUserDailyWorkInfoMutation, EditUserDailyWorkInfoMutationVariables>;
export const GetUserMonthlyWorkPlanDocument = gql`
    query GetUserMonthlyWorkPlan($employeeIdList: [String!]!, $startDate: String!, $endDate: String!, $page: Float, $take: Float) {
  getUserMonthlyWorkPlan(
    employeeIdList: $employeeIdList
    startDate: $startDate
    endDate: $endDate
    page: $page
    take: $take
  ) {
    ok
    error
    list {
      departmentName
      employeeId
      employeeName
      monthStartDay
      monthLastDay
      startTime
      endTime
      restPlanMin
      recognitionPlanTime
      inCheckDatetime
      outCheckDatetime
      restAdmitMin
      recognitionAdmitTime
      payTime
      isSendStatus
      isCheckStatus
      isClosingStatus
    }
  }
}
    `;

/**
 * __useGetUserMonthlyWorkPlanQuery__
 *
 * To run a query within a React component, call `useGetUserMonthlyWorkPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMonthlyWorkPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMonthlyWorkPlanQuery({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetUserMonthlyWorkPlanQuery(baseOptions: Apollo.QueryHookOptions<GetUserMonthlyWorkPlanQuery, GetUserMonthlyWorkPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserMonthlyWorkPlanQuery, GetUserMonthlyWorkPlanQueryVariables>(GetUserMonthlyWorkPlanDocument, options);
      }
export function useGetUserMonthlyWorkPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserMonthlyWorkPlanQuery, GetUserMonthlyWorkPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserMonthlyWorkPlanQuery, GetUserMonthlyWorkPlanQueryVariables>(GetUserMonthlyWorkPlanDocument, options);
        }
export type GetUserMonthlyWorkPlanQueryHookResult = ReturnType<typeof useGetUserMonthlyWorkPlanQuery>;
export type GetUserMonthlyWorkPlanLazyQueryHookResult = ReturnType<typeof useGetUserMonthlyWorkPlanLazyQuery>;
export type GetUserMonthlyWorkPlanQueryResult = Apollo.QueryResult<GetUserMonthlyWorkPlanQuery, GetUserMonthlyWorkPlanQueryVariables>;
export const GetUserMonthlyWorkExcelPlanDocument = gql`
    query GetUserMonthlyWorkExcelPlan($employeeIdList: [String!]!, $startDate: String!, $endDate: String!) {
  getUserMonthlyWorkExcelPlan(
    employeeIdList: $employeeIdList
    startDate: $startDate
    endDate: $endDate
  ) {
    ok
    error
    excel
  }
}
    `;

/**
 * __useGetUserMonthlyWorkExcelPlanQuery__
 *
 * To run a query within a React component, call `useGetUserMonthlyWorkExcelPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMonthlyWorkExcelPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMonthlyWorkExcelPlanQuery({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetUserMonthlyWorkExcelPlanQuery(baseOptions: Apollo.QueryHookOptions<GetUserMonthlyWorkExcelPlanQuery, GetUserMonthlyWorkExcelPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserMonthlyWorkExcelPlanQuery, GetUserMonthlyWorkExcelPlanQueryVariables>(GetUserMonthlyWorkExcelPlanDocument, options);
      }
export function useGetUserMonthlyWorkExcelPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserMonthlyWorkExcelPlanQuery, GetUserMonthlyWorkExcelPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserMonthlyWorkExcelPlanQuery, GetUserMonthlyWorkExcelPlanQueryVariables>(GetUserMonthlyWorkExcelPlanDocument, options);
        }
export type GetUserMonthlyWorkExcelPlanQueryHookResult = ReturnType<typeof useGetUserMonthlyWorkExcelPlanQuery>;
export type GetUserMonthlyWorkExcelPlanLazyQueryHookResult = ReturnType<typeof useGetUserMonthlyWorkExcelPlanLazyQuery>;
export type GetUserMonthlyWorkExcelPlanQueryResult = Apollo.QueryResult<GetUserMonthlyWorkExcelPlanQuery, GetUserMonthlyWorkExcelPlanQueryVariables>;
export const CountUserMonthlyWorkPlanDocument = gql`
    query CountUserMonthlyWorkPlan($employeeIdList: [String!]!, $startDate: String!, $endDate: String!) {
  countUserMonthlyWorkPlan(
    employeeIdList: $employeeIdList
    startDate: $startDate
    endDate: $endDate
  ) {
    ok
    error
    count
  }
}
    `;

/**
 * __useCountUserMonthlyWorkPlanQuery__
 *
 * To run a query within a React component, call `useCountUserMonthlyWorkPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountUserMonthlyWorkPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountUserMonthlyWorkPlanQuery({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCountUserMonthlyWorkPlanQuery(baseOptions: Apollo.QueryHookOptions<CountUserMonthlyWorkPlanQuery, CountUserMonthlyWorkPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountUserMonthlyWorkPlanQuery, CountUserMonthlyWorkPlanQueryVariables>(CountUserMonthlyWorkPlanDocument, options);
      }
export function useCountUserMonthlyWorkPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountUserMonthlyWorkPlanQuery, CountUserMonthlyWorkPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountUserMonthlyWorkPlanQuery, CountUserMonthlyWorkPlanQueryVariables>(CountUserMonthlyWorkPlanDocument, options);
        }
export type CountUserMonthlyWorkPlanQueryHookResult = ReturnType<typeof useCountUserMonthlyWorkPlanQuery>;
export type CountUserMonthlyWorkPlanLazyQueryHookResult = ReturnType<typeof useCountUserMonthlyWorkPlanLazyQuery>;
export type CountUserMonthlyWorkPlanQueryResult = Apollo.QueryResult<CountUserMonthlyWorkPlanQuery, CountUserMonthlyWorkPlanQueryVariables>;
export const SendUserMonthlyWorkInfoDocument = gql`
    mutation SendUserMonthlyWorkInfo($info: [SendUserMonthlyWorkInfo!]!) {
  sendUserMonthlyWorkInfo(info: $info) {
    ok
    error
  }
}
    `;
export type SendUserMonthlyWorkInfoMutationFn = Apollo.MutationFunction<SendUserMonthlyWorkInfoMutation, SendUserMonthlyWorkInfoMutationVariables>;

/**
 * __useSendUserMonthlyWorkInfoMutation__
 *
 * To run a mutation, you first call `useSendUserMonthlyWorkInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserMonthlyWorkInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserMonthlyWorkInfoMutation, { data, loading, error }] = useSendUserMonthlyWorkInfoMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useSendUserMonthlyWorkInfoMutation(baseOptions?: Apollo.MutationHookOptions<SendUserMonthlyWorkInfoMutation, SendUserMonthlyWorkInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendUserMonthlyWorkInfoMutation, SendUserMonthlyWorkInfoMutationVariables>(SendUserMonthlyWorkInfoDocument, options);
      }
export type SendUserMonthlyWorkInfoMutationHookResult = ReturnType<typeof useSendUserMonthlyWorkInfoMutation>;
export type SendUserMonthlyWorkInfoMutationResult = Apollo.MutationResult<SendUserMonthlyWorkInfoMutation>;
export type SendUserMonthlyWorkInfoMutationOptions = Apollo.BaseMutationOptions<SendUserMonthlyWorkInfoMutation, SendUserMonthlyWorkInfoMutationVariables>;
export const EditUserMonthlyWorkInfoDocument = gql`
    mutation EditUserMonthlyWorkInfo($info: [EditUserMonthlyWorkInfo!]!) {
  editUserMonthlyWorkInfo(info: $info) {
    ok
    error
  }
}
    `;
export type EditUserMonthlyWorkInfoMutationFn = Apollo.MutationFunction<EditUserMonthlyWorkInfoMutation, EditUserMonthlyWorkInfoMutationVariables>;

/**
 * __useEditUserMonthlyWorkInfoMutation__
 *
 * To run a mutation, you first call `useEditUserMonthlyWorkInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMonthlyWorkInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMonthlyWorkInfoMutation, { data, loading, error }] = useEditUserMonthlyWorkInfoMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useEditUserMonthlyWorkInfoMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMonthlyWorkInfoMutation, EditUserMonthlyWorkInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserMonthlyWorkInfoMutation, EditUserMonthlyWorkInfoMutationVariables>(EditUserMonthlyWorkInfoDocument, options);
      }
export type EditUserMonthlyWorkInfoMutationHookResult = ReturnType<typeof useEditUserMonthlyWorkInfoMutation>;
export type EditUserMonthlyWorkInfoMutationResult = Apollo.MutationResult<EditUserMonthlyWorkInfoMutation>;
export type EditUserMonthlyWorkInfoMutationOptions = Apollo.BaseMutationOptions<EditUserMonthlyWorkInfoMutation, EditUserMonthlyWorkInfoMutationVariables>;
export const CountListOfEmployeeInformationDocument = gql`
    query CountListOfEmployeeInformation($selectedDepartmentId: String!, $searchType: TYPE_OF_EMPLOYEE_INFORMATION, $searchValue: String) {
  countListOfEmployeeInformation(
    selectedDepartmentId: $selectedDepartmentId
    searchType: $searchType
    searchValue: $searchValue
  ) {
    ok
    error
    total
  }
}
    `;

/**
 * __useCountListOfEmployeeInformationQuery__
 *
 * To run a query within a React component, call `useCountListOfEmployeeInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountListOfEmployeeInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountListOfEmployeeInformationQuery({
 *   variables: {
 *      selectedDepartmentId: // value for 'selectedDepartmentId'
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useCountListOfEmployeeInformationQuery(baseOptions: Apollo.QueryHookOptions<CountListOfEmployeeInformationQuery, CountListOfEmployeeInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountListOfEmployeeInformationQuery, CountListOfEmployeeInformationQueryVariables>(CountListOfEmployeeInformationDocument, options);
      }
export function useCountListOfEmployeeInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountListOfEmployeeInformationQuery, CountListOfEmployeeInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountListOfEmployeeInformationQuery, CountListOfEmployeeInformationQueryVariables>(CountListOfEmployeeInformationDocument, options);
        }
export type CountListOfEmployeeInformationQueryHookResult = ReturnType<typeof useCountListOfEmployeeInformationQuery>;
export type CountListOfEmployeeInformationLazyQueryHookResult = ReturnType<typeof useCountListOfEmployeeInformationLazyQuery>;
export type CountListOfEmployeeInformationQueryResult = Apollo.QueryResult<CountListOfEmployeeInformationQuery, CountListOfEmployeeInformationQueryVariables>;
export const GetEmployeeInformationDocument = gql`
    query GetEmployeeInformation($page: Float = 1, $take: Float = 10, $selectedDepartmentId: String!, $searchType: TYPE_OF_EMPLOYEE_INFORMATION, $searchValue: String, $fieldSort: FieldSort) {
  getEmployeeInformation(
    page: $page
    take: $take
    selectedDepartmentId: $selectedDepartmentId
    searchType: $searchType
    searchValue: $searchValue
    fieldSort: $fieldSort
  ) {
    ok
    error
    listOfEmployee {
      employeeId
      name
      departmentId
      fullDepartmentName
      departName
      endpointCount
      insertType
      workingName
      workingTemplateIdx
      asTimeTemplateName
      approvalDefine
      col3
      col2
      col1
      col4
      col5
      col6
      col7
      col8
      col9
      col10
      col11
      col12
      col13
      col14
      col15
      wtAsextto
      weekMaxExtWorkHour
      wtTot
      weekMaxWorkHour
      todayWorkingTime
    }
  }
}
    `;

/**
 * __useGetEmployeeInformationQuery__
 *
 * To run a query within a React component, call `useGetEmployeeInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeInformationQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      selectedDepartmentId: // value for 'selectedDepartmentId'
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *      fieldSort: // value for 'fieldSort'
 *   },
 * });
 */
export function useGetEmployeeInformationQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeInformationQuery, GetEmployeeInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeInformationQuery, GetEmployeeInformationQueryVariables>(GetEmployeeInformationDocument, options);
      }
export function useGetEmployeeInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeInformationQuery, GetEmployeeInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeInformationQuery, GetEmployeeInformationQueryVariables>(GetEmployeeInformationDocument, options);
        }
export type GetEmployeeInformationQueryHookResult = ReturnType<typeof useGetEmployeeInformationQuery>;
export type GetEmployeeInformationLazyQueryHookResult = ReturnType<typeof useGetEmployeeInformationLazyQuery>;
export type GetEmployeeInformationQueryResult = Apollo.QueryResult<GetEmployeeInformationQuery, GetEmployeeInformationQueryVariables>;
export const DownloadListOfEmployeeInformationExcelDocument = gql`
    query DownloadListOfEmployeeInformationExcel($selectedDepartmentId: String!, $searchType: TYPE_OF_EMPLOYEE_INFORMATION, $searchValue: String) {
  downloadListOfEmployeeInformationExcel(
    selectedDepartmentId: $selectedDepartmentId
    searchType: $searchType
    searchValue: $searchValue
  ) {
    ok
    error
    excel
  }
}
    `;

/**
 * __useDownloadListOfEmployeeInformationExcelQuery__
 *
 * To run a query within a React component, call `useDownloadListOfEmployeeInformationExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadListOfEmployeeInformationExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadListOfEmployeeInformationExcelQuery({
 *   variables: {
 *      selectedDepartmentId: // value for 'selectedDepartmentId'
 *      searchType: // value for 'searchType'
 *      searchValue: // value for 'searchValue'
 *   },
 * });
 */
export function useDownloadListOfEmployeeInformationExcelQuery(baseOptions: Apollo.QueryHookOptions<DownloadListOfEmployeeInformationExcelQuery, DownloadListOfEmployeeInformationExcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadListOfEmployeeInformationExcelQuery, DownloadListOfEmployeeInformationExcelQueryVariables>(DownloadListOfEmployeeInformationExcelDocument, options);
      }
export function useDownloadListOfEmployeeInformationExcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadListOfEmployeeInformationExcelQuery, DownloadListOfEmployeeInformationExcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadListOfEmployeeInformationExcelQuery, DownloadListOfEmployeeInformationExcelQueryVariables>(DownloadListOfEmployeeInformationExcelDocument, options);
        }
export type DownloadListOfEmployeeInformationExcelQueryHookResult = ReturnType<typeof useDownloadListOfEmployeeInformationExcelQuery>;
export type DownloadListOfEmployeeInformationExcelLazyQueryHookResult = ReturnType<typeof useDownloadListOfEmployeeInformationExcelLazyQuery>;
export type DownloadListOfEmployeeInformationExcelQueryResult = Apollo.QueryResult<DownloadListOfEmployeeInformationExcelQuery, DownloadListOfEmployeeInformationExcelQueryVariables>;
export const GetMainTokenInfoDocument = gql`
    query GetMainTokenInfo($employeeId: String, $tokenField: String) {
  getMainTokenInfo(employeeId: $employeeId, tokenField: $tokenField) {
    ok
    error
    info {
      tokenField
      allowFlag
    }
  }
}
    `;

/**
 * __useGetMainTokenInfoQuery__
 *
 * To run a query within a React component, call `useGetMainTokenInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMainTokenInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMainTokenInfoQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      tokenField: // value for 'tokenField'
 *   },
 * });
 */
export function useGetMainTokenInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetMainTokenInfoQuery, GetMainTokenInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMainTokenInfoQuery, GetMainTokenInfoQueryVariables>(GetMainTokenInfoDocument, options);
      }
export function useGetMainTokenInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMainTokenInfoQuery, GetMainTokenInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMainTokenInfoQuery, GetMainTokenInfoQueryVariables>(GetMainTokenInfoDocument, options);
        }
export type GetMainTokenInfoQueryHookResult = ReturnType<typeof useGetMainTokenInfoQuery>;
export type GetMainTokenInfoLazyQueryHookResult = ReturnType<typeof useGetMainTokenInfoLazyQuery>;
export type GetMainTokenInfoQueryResult = Apollo.QueryResult<GetMainTokenInfoQuery, GetMainTokenInfoQueryVariables>;
export const UpdateMainTokenAllowFlagDocument = gql`
    mutation UpdateMainTokenAllowFlag($tokenField: String!, $allowedFlag: ALLOWED_FLAG_TYPE!) {
  updateMainTokenAllowFlag(tokenField: $tokenField, allowedFlag: $allowedFlag) {
    ok
    error
  }
}
    `;
export type UpdateMainTokenAllowFlagMutationFn = Apollo.MutationFunction<UpdateMainTokenAllowFlagMutation, UpdateMainTokenAllowFlagMutationVariables>;

/**
 * __useUpdateMainTokenAllowFlagMutation__
 *
 * To run a mutation, you first call `useUpdateMainTokenAllowFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMainTokenAllowFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMainTokenAllowFlagMutation, { data, loading, error }] = useUpdateMainTokenAllowFlagMutation({
 *   variables: {
 *      tokenField: // value for 'tokenField'
 *      allowedFlag: // value for 'allowedFlag'
 *   },
 * });
 */
export function useUpdateMainTokenAllowFlagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMainTokenAllowFlagMutation, UpdateMainTokenAllowFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMainTokenAllowFlagMutation, UpdateMainTokenAllowFlagMutationVariables>(UpdateMainTokenAllowFlagDocument, options);
      }
export type UpdateMainTokenAllowFlagMutationHookResult = ReturnType<typeof useUpdateMainTokenAllowFlagMutation>;
export type UpdateMainTokenAllowFlagMutationResult = Apollo.MutationResult<UpdateMainTokenAllowFlagMutation>;
export type UpdateMainTokenAllowFlagMutationOptions = Apollo.BaseMutationOptions<UpdateMainTokenAllowFlagMutation, UpdateMainTokenAllowFlagMutationVariables>;
export const DeleteMainTokenInfoDocument = gql`
    mutation DeleteMainTokenInfo($tokenField: String!) {
  deleteMainTokenInfo(tokenField: $tokenField) {
    ok
    error
  }
}
    `;
export type DeleteMainTokenInfoMutationFn = Apollo.MutationFunction<DeleteMainTokenInfoMutation, DeleteMainTokenInfoMutationVariables>;

/**
 * __useDeleteMainTokenInfoMutation__
 *
 * To run a mutation, you first call `useDeleteMainTokenInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMainTokenInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMainTokenInfoMutation, { data, loading, error }] = useDeleteMainTokenInfoMutation({
 *   variables: {
 *      tokenField: // value for 'tokenField'
 *   },
 * });
 */
export function useDeleteMainTokenInfoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMainTokenInfoMutation, DeleteMainTokenInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMainTokenInfoMutation, DeleteMainTokenInfoMutationVariables>(DeleteMainTokenInfoDocument, options);
      }
export type DeleteMainTokenInfoMutationHookResult = ReturnType<typeof useDeleteMainTokenInfoMutation>;
export type DeleteMainTokenInfoMutationResult = Apollo.MutationResult<DeleteMainTokenInfoMutation>;
export type DeleteMainTokenInfoMutationOptions = Apollo.BaseMutationOptions<DeleteMainTokenInfoMutation, DeleteMainTokenInfoMutationVariables>;
export const GetSubTokenInfoDocument = gql`
    query GetSubTokenInfo($tokenFiled: String!) {
  getSubTokenInfo(tokenFiled: $tokenFiled) {
    ok
    error
    info {
      tokenField
      employeeId
      name
      departmentName
      allowFlag
    }
  }
}
    `;

/**
 * __useGetSubTokenInfoQuery__
 *
 * To run a query within a React component, call `useGetSubTokenInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubTokenInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubTokenInfoQuery({
 *   variables: {
 *      tokenFiled: // value for 'tokenFiled'
 *   },
 * });
 */
export function useGetSubTokenInfoQuery(baseOptions: Apollo.QueryHookOptions<GetSubTokenInfoQuery, GetSubTokenInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubTokenInfoQuery, GetSubTokenInfoQueryVariables>(GetSubTokenInfoDocument, options);
      }
export function useGetSubTokenInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubTokenInfoQuery, GetSubTokenInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubTokenInfoQuery, GetSubTokenInfoQueryVariables>(GetSubTokenInfoDocument, options);
        }
export type GetSubTokenInfoQueryHookResult = ReturnType<typeof useGetSubTokenInfoQuery>;
export type GetSubTokenInfoLazyQueryHookResult = ReturnType<typeof useGetSubTokenInfoLazyQuery>;
export type GetSubTokenInfoQueryResult = Apollo.QueryResult<GetSubTokenInfoQuery, GetSubTokenInfoQueryVariables>;
export const UpdateSubTokenAllowedFlagDocument = gql`
    mutation UpdateSubTokenAllowedFlag($employeeId: String!, $tokenField: String!, $allowedFlag: ALLOWED_FLAG_TYPE!) {
  updateSubTokenAllowedFlag(
    employeeId: $employeeId
    tokenField: $tokenField
    allowedFlag: $allowedFlag
  ) {
    ok
    error
  }
}
    `;
export type UpdateSubTokenAllowedFlagMutationFn = Apollo.MutationFunction<UpdateSubTokenAllowedFlagMutation, UpdateSubTokenAllowedFlagMutationVariables>;

/**
 * __useUpdateSubTokenAllowedFlagMutation__
 *
 * To run a mutation, you first call `useUpdateSubTokenAllowedFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubTokenAllowedFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubTokenAllowedFlagMutation, { data, loading, error }] = useUpdateSubTokenAllowedFlagMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      tokenField: // value for 'tokenField'
 *      allowedFlag: // value for 'allowedFlag'
 *   },
 * });
 */
export function useUpdateSubTokenAllowedFlagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubTokenAllowedFlagMutation, UpdateSubTokenAllowedFlagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubTokenAllowedFlagMutation, UpdateSubTokenAllowedFlagMutationVariables>(UpdateSubTokenAllowedFlagDocument, options);
      }
export type UpdateSubTokenAllowedFlagMutationHookResult = ReturnType<typeof useUpdateSubTokenAllowedFlagMutation>;
export type UpdateSubTokenAllowedFlagMutationResult = Apollo.MutationResult<UpdateSubTokenAllowedFlagMutation>;
export type UpdateSubTokenAllowedFlagMutationOptions = Apollo.BaseMutationOptions<UpdateSubTokenAllowedFlagMutation, UpdateSubTokenAllowedFlagMutationVariables>;
export const DeleteSubTokenInfoDocument = gql`
    mutation DeleteSubTokenInfo($tokenField: String!, $employeeId: String!) {
  deleteSubTokenInfo(tokenField: $tokenField, employeeId: $employeeId) {
    ok
    error
  }
}
    `;
export type DeleteSubTokenInfoMutationFn = Apollo.MutationFunction<DeleteSubTokenInfoMutation, DeleteSubTokenInfoMutationVariables>;

/**
 * __useDeleteSubTokenInfoMutation__
 *
 * To run a mutation, you first call `useDeleteSubTokenInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubTokenInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubTokenInfoMutation, { data, loading, error }] = useDeleteSubTokenInfoMutation({
 *   variables: {
 *      tokenField: // value for 'tokenField'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useDeleteSubTokenInfoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubTokenInfoMutation, DeleteSubTokenInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubTokenInfoMutation, DeleteSubTokenInfoMutationVariables>(DeleteSubTokenInfoDocument, options);
      }
export type DeleteSubTokenInfoMutationHookResult = ReturnType<typeof useDeleteSubTokenInfoMutation>;
export type DeleteSubTokenInfoMutationResult = Apollo.MutationResult<DeleteSubTokenInfoMutation>;
export type DeleteSubTokenInfoMutationOptions = Apollo.BaseMutationOptions<DeleteSubTokenInfoMutation, DeleteSubTokenInfoMutationVariables>;
export const GetCountStatisticsConditionDocument = gql`
    query GetCountStatisticsCondition($startDate: String!, $listOfDepartmentId: [String!]!, $employeeId: String) {
  getCountStatisticsCondition(
    startDate: $startDate
    listOfDepartmentId: $listOfDepartmentId
    employeeId: $employeeId
  ) {
    ok
    error
    total
  }
}
    `;

/**
 * __useGetCountStatisticsConditionQuery__
 *
 * To run a query within a React component, call `useGetCountStatisticsConditionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountStatisticsConditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountStatisticsConditionQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      listOfDepartmentId: // value for 'listOfDepartmentId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetCountStatisticsConditionQuery(baseOptions: Apollo.QueryHookOptions<GetCountStatisticsConditionQuery, GetCountStatisticsConditionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountStatisticsConditionQuery, GetCountStatisticsConditionQueryVariables>(GetCountStatisticsConditionDocument, options);
      }
export function useGetCountStatisticsConditionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountStatisticsConditionQuery, GetCountStatisticsConditionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountStatisticsConditionQuery, GetCountStatisticsConditionQueryVariables>(GetCountStatisticsConditionDocument, options);
        }
export type GetCountStatisticsConditionQueryHookResult = ReturnType<typeof useGetCountStatisticsConditionQuery>;
export type GetCountStatisticsConditionLazyQueryHookResult = ReturnType<typeof useGetCountStatisticsConditionLazyQuery>;
export type GetCountStatisticsConditionQueryResult = Apollo.QueryResult<GetCountStatisticsConditionQuery, GetCountStatisticsConditionQueryVariables>;
export const GetStatisticsConditionListDocument = gql`
    query GetStatisticsConditionList($startDate: String!, $listOfDepartmentId: [String!]!, $page: Float, $take: Float, $fieldSort: FieldSort, $employeeId: String) {
  getStatisticsConditionList(
    startDate: $startDate
    listOfDepartmentId: $listOfDepartmentId
    page: $page
    take: $take
    fieldSort: $fieldSort
    employeeId: $employeeId
  ) {
    ok
    error
    list {
      periodInformation
      departName
      employeeId
      empName
      workingTemplateName
      totPossibleMin
      totWorkPossibleMin
      useWorkMin
      totWorkPossibleLeaveMin
      totExtPossibleMin
      useExtMin
      totExtPossibleLeaveMin
      totMonWorkDay
      totMonHoliDay
      totMonNormalWorkDay
      totMonVacationDay
      totMonEducationDay
      totMonBusinessTripDay
      totMonAbsentDay
      totMonLateDay
      totMonEarlyDay
      totMonSickDay
      totMonFurloughDay
    }
  }
}
    `;

/**
 * __useGetStatisticsConditionListQuery__
 *
 * To run a query within a React component, call `useGetStatisticsConditionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticsConditionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticsConditionListQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      listOfDepartmentId: // value for 'listOfDepartmentId'
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      fieldSort: // value for 'fieldSort'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetStatisticsConditionListQuery(baseOptions: Apollo.QueryHookOptions<GetStatisticsConditionListQuery, GetStatisticsConditionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatisticsConditionListQuery, GetStatisticsConditionListQueryVariables>(GetStatisticsConditionListDocument, options);
      }
export function useGetStatisticsConditionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatisticsConditionListQuery, GetStatisticsConditionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatisticsConditionListQuery, GetStatisticsConditionListQueryVariables>(GetStatisticsConditionListDocument, options);
        }
export type GetStatisticsConditionListQueryHookResult = ReturnType<typeof useGetStatisticsConditionListQuery>;
export type GetStatisticsConditionListLazyQueryHookResult = ReturnType<typeof useGetStatisticsConditionListLazyQuery>;
export type GetStatisticsConditionListQueryResult = Apollo.QueryResult<GetStatisticsConditionListQuery, GetStatisticsConditionListQueryVariables>;
export const GetStatisticsConditionExcelDocument = gql`
    query GetStatisticsConditionExcel($startDate: String!, $listOfDepartmentId: [String!]!, $employeeId: String) {
  getStatisticsConditionExcel(
    startDate: $startDate
    listOfDepartmentId: $listOfDepartmentId
    employeeId: $employeeId
  ) {
    ok
    error
    excel
  }
}
    `;

/**
 * __useGetStatisticsConditionExcelQuery__
 *
 * To run a query within a React component, call `useGetStatisticsConditionExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticsConditionExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticsConditionExcelQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      listOfDepartmentId: // value for 'listOfDepartmentId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetStatisticsConditionExcelQuery(baseOptions: Apollo.QueryHookOptions<GetStatisticsConditionExcelQuery, GetStatisticsConditionExcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatisticsConditionExcelQuery, GetStatisticsConditionExcelQueryVariables>(GetStatisticsConditionExcelDocument, options);
      }
export function useGetStatisticsConditionExcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatisticsConditionExcelQuery, GetStatisticsConditionExcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatisticsConditionExcelQuery, GetStatisticsConditionExcelQueryVariables>(GetStatisticsConditionExcelDocument, options);
        }
export type GetStatisticsConditionExcelQueryHookResult = ReturnType<typeof useGetStatisticsConditionExcelQuery>;
export type GetStatisticsConditionExcelLazyQueryHookResult = ReturnType<typeof useGetStatisticsConditionExcelLazyQuery>;
export type GetStatisticsConditionExcelQueryResult = Apollo.QueryResult<GetStatisticsConditionExcelQuery, GetStatisticsConditionExcelQueryVariables>;
export const GetAttendanceDetailsListDocument = gql`
    query GetAttendanceDetailsList($startDate: String!, $listOfDepartmentId: [String!]!, $fieldSort: FieldSort, $employeeId: String) {
  getAttendanceDetailsList(
    startDate: $startDate
    listOfDepartmentId: $listOfDepartmentId
    fieldSort: $fieldSort
    employeeId: $employeeId
  ) {
    ok
    error
    list {
      periodInformation
      dateValue
      dayOfWeek
      departName
      employeeId
      empName
      workingTemplateName
      totPossibleMin
      totWorkPossibleMin
      useWorkMin
      totWorkPossibleLeaveMin
      totExtPossibleMin
      useExtMin
      totExtPossibleLeaveMin
      workStartTime
      workEndTime
      restMin
    }
  }
}
    `;

/**
 * __useGetAttendanceDetailsListQuery__
 *
 * To run a query within a React component, call `useGetAttendanceDetailsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceDetailsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceDetailsListQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      listOfDepartmentId: // value for 'listOfDepartmentId'
 *      fieldSort: // value for 'fieldSort'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetAttendanceDetailsListQuery(baseOptions: Apollo.QueryHookOptions<GetAttendanceDetailsListQuery, GetAttendanceDetailsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttendanceDetailsListQuery, GetAttendanceDetailsListQueryVariables>(GetAttendanceDetailsListDocument, options);
      }
export function useGetAttendanceDetailsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttendanceDetailsListQuery, GetAttendanceDetailsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttendanceDetailsListQuery, GetAttendanceDetailsListQueryVariables>(GetAttendanceDetailsListDocument, options);
        }
export type GetAttendanceDetailsListQueryHookResult = ReturnType<typeof useGetAttendanceDetailsListQuery>;
export type GetAttendanceDetailsListLazyQueryHookResult = ReturnType<typeof useGetAttendanceDetailsListLazyQuery>;
export type GetAttendanceDetailsListQueryResult = Apollo.QueryResult<GetAttendanceDetailsListQuery, GetAttendanceDetailsListQueryVariables>;
export const GetAttendanceDetailsListExcelDocument = gql`
    query GetAttendanceDetailsListExcel($startDate: String!, $listOfDepartmentId: [String!]!, $employeeId: String, $fieldSort: FieldSort) {
  getAttendanceDetailsListExcel(
    startDate: $startDate
    listOfDepartmentId: $listOfDepartmentId
    employeeId: $employeeId
    fieldSort: $fieldSort
  ) {
    ok
    error
    excel
  }
}
    `;

/**
 * __useGetAttendanceDetailsListExcelQuery__
 *
 * To run a query within a React component, call `useGetAttendanceDetailsListExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceDetailsListExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceDetailsListExcelQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      listOfDepartmentId: // value for 'listOfDepartmentId'
 *      employeeId: // value for 'employeeId'
 *      fieldSort: // value for 'fieldSort'
 *   },
 * });
 */
export function useGetAttendanceDetailsListExcelQuery(baseOptions: Apollo.QueryHookOptions<GetAttendanceDetailsListExcelQuery, GetAttendanceDetailsListExcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttendanceDetailsListExcelQuery, GetAttendanceDetailsListExcelQueryVariables>(GetAttendanceDetailsListExcelDocument, options);
      }
export function useGetAttendanceDetailsListExcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttendanceDetailsListExcelQuery, GetAttendanceDetailsListExcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttendanceDetailsListExcelQuery, GetAttendanceDetailsListExcelQueryVariables>(GetAttendanceDetailsListExcelDocument, options);
        }
export type GetAttendanceDetailsListExcelQueryHookResult = ReturnType<typeof useGetAttendanceDetailsListExcelQuery>;
export type GetAttendanceDetailsListExcelLazyQueryHookResult = ReturnType<typeof useGetAttendanceDetailsListExcelLazyQuery>;
export type GetAttendanceDetailsListExcelQueryResult = Apollo.QueryResult<GetAttendanceDetailsListExcelQuery, GetAttendanceDetailsListExcelQueryVariables>;
export const GetMobilWebViewInfoDocument = gql`
    query GetMobilWebViewInfo {
  getMobilWebViewInfo {
    ok
    error
    webViewInfo {
      appWebviewButtonStatus
      appWebViewButtonName
      appWebViewButtonLink
      appWebViewMonthWorkInfoStatus
      webApprovalButtonName
    }
  }
}
    `;

/**
 * __useGetMobilWebViewInfoQuery__
 *
 * To run a query within a React component, call `useGetMobilWebViewInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobilWebViewInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobilWebViewInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMobilWebViewInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetMobilWebViewInfoQuery, GetMobilWebViewInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMobilWebViewInfoQuery, GetMobilWebViewInfoQueryVariables>(GetMobilWebViewInfoDocument, options);
      }
export function useGetMobilWebViewInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMobilWebViewInfoQuery, GetMobilWebViewInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMobilWebViewInfoQuery, GetMobilWebViewInfoQueryVariables>(GetMobilWebViewInfoDocument, options);
        }
export type GetMobilWebViewInfoQueryHookResult = ReturnType<typeof useGetMobilWebViewInfoQuery>;
export type GetMobilWebViewInfoLazyQueryHookResult = ReturnType<typeof useGetMobilWebViewInfoLazyQuery>;
export type GetMobilWebViewInfoQueryResult = Apollo.QueryResult<GetMobilWebViewInfoQuery, GetMobilWebViewInfoQueryVariables>;
export const GetAbsenceManagementInfoDocument = gql`
    query GetAbsenceManagementInfo($workingTemplateIdx: Int!) {
  getAbsenceManagementInfo(workingTemplateIdx: $workingTemplateIdx) {
    ok
    error
    info {
      workingTemplateIdx
      leftSeatBlackScreenUse
      leftSeatPlaceInput
      leftSeatAttendeeInput
      leftSeatAutoUse
      leftSeatBaseMin
      leftSeatReasonUse
      extendLeftSeatAutoCommuteOutUse
      leftSeatApplyTimeUse
    }
  }
}
    `;

/**
 * __useGetAbsenceManagementInfoQuery__
 *
 * To run a query within a React component, call `useGetAbsenceManagementInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAbsenceManagementInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAbsenceManagementInfoQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *   },
 * });
 */
export function useGetAbsenceManagementInfoQuery(baseOptions: Apollo.QueryHookOptions<GetAbsenceManagementInfoQuery, GetAbsenceManagementInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAbsenceManagementInfoQuery, GetAbsenceManagementInfoQueryVariables>(GetAbsenceManagementInfoDocument, options);
      }
export function useGetAbsenceManagementInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAbsenceManagementInfoQuery, GetAbsenceManagementInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAbsenceManagementInfoQuery, GetAbsenceManagementInfoQueryVariables>(GetAbsenceManagementInfoDocument, options);
        }
export type GetAbsenceManagementInfoQueryHookResult = ReturnType<typeof useGetAbsenceManagementInfoQuery>;
export type GetAbsenceManagementInfoLazyQueryHookResult = ReturnType<typeof useGetAbsenceManagementInfoLazyQuery>;
export type GetAbsenceManagementInfoQueryResult = Apollo.QueryResult<GetAbsenceManagementInfoQuery, GetAbsenceManagementInfoQueryVariables>;
export const AddAbsenceManagementInfoDocument = gql`
    mutation AddAbsenceManagementInfo($workingTemplateIdx: Int!, $leftSeatBlackScreenUse: IS_USE!, $leftSeatPlaceInput: IS_USE!, $leftSeatAttendeeInput: IS_USE!, $leftSeatAutoUse: IS_USE!, $leftSeatBaseMin: Int!, $leftSeatReasonUse: IS_USE!, $extendLeftSeatAutoCommuteOutUse: IS_USE!, $leftSeatApplyTimeUse: IS_USE!) {
  addAbsenceManagementInfo(
    workingTemplateIdx: $workingTemplateIdx
    leftSeatBlackScreenUse: $leftSeatBlackScreenUse
    leftSeatPlaceInput: $leftSeatPlaceInput
    leftSeatAttendeeInput: $leftSeatAttendeeInput
    leftSeatAutoUse: $leftSeatAutoUse
    leftSeatBaseMin: $leftSeatBaseMin
    leftSeatReasonUse: $leftSeatReasonUse
    extendLeftSeatAutoCommuteOutUse: $extendLeftSeatAutoCommuteOutUse
    leftSeatApplyTimeUse: $leftSeatApplyTimeUse
  ) {
    ok
    error
  }
}
    `;
export type AddAbsenceManagementInfoMutationFn = Apollo.MutationFunction<AddAbsenceManagementInfoMutation, AddAbsenceManagementInfoMutationVariables>;

/**
 * __useAddAbsenceManagementInfoMutation__
 *
 * To run a mutation, you first call `useAddAbsenceManagementInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAbsenceManagementInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAbsenceManagementInfoMutation, { data, loading, error }] = useAddAbsenceManagementInfoMutation({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      leftSeatBlackScreenUse: // value for 'leftSeatBlackScreenUse'
 *      leftSeatPlaceInput: // value for 'leftSeatPlaceInput'
 *      leftSeatAttendeeInput: // value for 'leftSeatAttendeeInput'
 *      leftSeatAutoUse: // value for 'leftSeatAutoUse'
 *      leftSeatBaseMin: // value for 'leftSeatBaseMin'
 *      leftSeatReasonUse: // value for 'leftSeatReasonUse'
 *      extendLeftSeatAutoCommuteOutUse: // value for 'extendLeftSeatAutoCommuteOutUse'
 *      leftSeatApplyTimeUse: // value for 'leftSeatApplyTimeUse'
 *   },
 * });
 */
export function useAddAbsenceManagementInfoMutation(baseOptions?: Apollo.MutationHookOptions<AddAbsenceManagementInfoMutation, AddAbsenceManagementInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAbsenceManagementInfoMutation, AddAbsenceManagementInfoMutationVariables>(AddAbsenceManagementInfoDocument, options);
      }
export type AddAbsenceManagementInfoMutationHookResult = ReturnType<typeof useAddAbsenceManagementInfoMutation>;
export type AddAbsenceManagementInfoMutationResult = Apollo.MutationResult<AddAbsenceManagementInfoMutation>;
export type AddAbsenceManagementInfoMutationOptions = Apollo.BaseMutationOptions<AddAbsenceManagementInfoMutation, AddAbsenceManagementInfoMutationVariables>;
export const GetAutonomyWorkingHourInfoDocument = gql`
    query GetAutonomyWorkingHourInfo($workingTemplateIdx: Int!) {
  getAutonomyWorkingHourInfo(workingTemplateIdx: $workingTemplateIdx) {
    ok
    error
    basicInfo {
      workingTemplateIdx
      startTime
      endTime
      dayOfWeekType
      dayOfWeek
      breakTimeType
      startBreakTime
      endBreakTime
    }
  }
}
    `;

/**
 * __useGetAutonomyWorkingHourInfoQuery__
 *
 * To run a query within a React component, call `useGetAutonomyWorkingHourInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutonomyWorkingHourInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutonomyWorkingHourInfoQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *   },
 * });
 */
export function useGetAutonomyWorkingHourInfoQuery(baseOptions: Apollo.QueryHookOptions<GetAutonomyWorkingHourInfoQuery, GetAutonomyWorkingHourInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutonomyWorkingHourInfoQuery, GetAutonomyWorkingHourInfoQueryVariables>(GetAutonomyWorkingHourInfoDocument, options);
      }
export function useGetAutonomyWorkingHourInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutonomyWorkingHourInfoQuery, GetAutonomyWorkingHourInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutonomyWorkingHourInfoQuery, GetAutonomyWorkingHourInfoQueryVariables>(GetAutonomyWorkingHourInfoDocument, options);
        }
export type GetAutonomyWorkingHourInfoQueryHookResult = ReturnType<typeof useGetAutonomyWorkingHourInfoQuery>;
export type GetAutonomyWorkingHourInfoLazyQueryHookResult = ReturnType<typeof useGetAutonomyWorkingHourInfoLazyQuery>;
export type GetAutonomyWorkingHourInfoQueryResult = Apollo.QueryResult<GetAutonomyWorkingHourInfoQuery, GetAutonomyWorkingHourInfoQueryVariables>;
export const AddAutonomyWorkingHourInfoDocument = gql`
    mutation AddAutonomyWorkingHourInfo($workingTemplateIdx: Int!, $startTime: String!, $endTime: String!, $dayOfWeekType: DAY_OF_WEEK_TYPE!, $dayOfWeek: [DAY_TYPE!]!, $breakTimeType: BREAK_TIME_TYPE!, $startBreakTime: String!, $endBreakTime: String!) {
  addAutonomyWorkingHourInfo(
    workingTemplateIdx: $workingTemplateIdx
    startTime: $startTime
    endTime: $endTime
    dayOfWeekType: $dayOfWeekType
    dayOfWeek: $dayOfWeek
    breakTimeType: $breakTimeType
    startBreakTime: $startBreakTime
    endBreakTime: $endBreakTime
  ) {
    ok
    error
  }
}
    `;
export type AddAutonomyWorkingHourInfoMutationFn = Apollo.MutationFunction<AddAutonomyWorkingHourInfoMutation, AddAutonomyWorkingHourInfoMutationVariables>;

/**
 * __useAddAutonomyWorkingHourInfoMutation__
 *
 * To run a mutation, you first call `useAddAutonomyWorkingHourInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAutonomyWorkingHourInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAutonomyWorkingHourInfoMutation, { data, loading, error }] = useAddAutonomyWorkingHourInfoMutation({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      dayOfWeekType: // value for 'dayOfWeekType'
 *      dayOfWeek: // value for 'dayOfWeek'
 *      breakTimeType: // value for 'breakTimeType'
 *      startBreakTime: // value for 'startBreakTime'
 *      endBreakTime: // value for 'endBreakTime'
 *   },
 * });
 */
export function useAddAutonomyWorkingHourInfoMutation(baseOptions?: Apollo.MutationHookOptions<AddAutonomyWorkingHourInfoMutation, AddAutonomyWorkingHourInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAutonomyWorkingHourInfoMutation, AddAutonomyWorkingHourInfoMutationVariables>(AddAutonomyWorkingHourInfoDocument, options);
      }
export type AddAutonomyWorkingHourInfoMutationHookResult = ReturnType<typeof useAddAutonomyWorkingHourInfoMutation>;
export type AddAutonomyWorkingHourInfoMutationResult = Apollo.MutationResult<AddAutonomyWorkingHourInfoMutation>;
export type AddAutonomyWorkingHourInfoMutationOptions = Apollo.BaseMutationOptions<AddAutonomyWorkingHourInfoMutation, AddAutonomyWorkingHourInfoMutationVariables>;
export const GetSelfDeterminedWorkHourInfoDocument = gql`
    query GetSelfDeterminedWorkHourInfo($workingTemplateIdx: Int!) {
  getSelfDeterminedWorkHourInfo(workingTemplateIdx: $workingTemplateIdx) {
    ok
    error
    otWorkingStandardBasic {
      workingTemplateIdx
      startBreakTime
      endBreakTime
    }
    basicInfo {
      name
      workType
      dayMaxWorkHour
      weekMaxWorkHour
      dayOfWeekStart
    }
    otWSHoursList {
      workingTemplateIdx
      workingTimeName
      useFlag
      operatingPeriodType
      operatingPeriodStartDate
      operatingPeriodEndDate
      workHoursType
      startTime
      endTime
      dutyWorkHoursType
      dutyStartTime
      dutyEndTime
      dayOfWeekType
      dayOfWeek
      breakTimeType
      startBreakTime
      endBreakTime
    }
  }
}
    `;

/**
 * __useGetSelfDeterminedWorkHourInfoQuery__
 *
 * To run a query within a React component, call `useGetSelfDeterminedWorkHourInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfDeterminedWorkHourInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfDeterminedWorkHourInfoQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *   },
 * });
 */
export function useGetSelfDeterminedWorkHourInfoQuery(baseOptions: Apollo.QueryHookOptions<GetSelfDeterminedWorkHourInfoQuery, GetSelfDeterminedWorkHourInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfDeterminedWorkHourInfoQuery, GetSelfDeterminedWorkHourInfoQueryVariables>(GetSelfDeterminedWorkHourInfoDocument, options);
      }
export function useGetSelfDeterminedWorkHourInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfDeterminedWorkHourInfoQuery, GetSelfDeterminedWorkHourInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfDeterminedWorkHourInfoQuery, GetSelfDeterminedWorkHourInfoQueryVariables>(GetSelfDeterminedWorkHourInfoDocument, options);
        }
export type GetSelfDeterminedWorkHourInfoQueryHookResult = ReturnType<typeof useGetSelfDeterminedWorkHourInfoQuery>;
export type GetSelfDeterminedWorkHourInfoLazyQueryHookResult = ReturnType<typeof useGetSelfDeterminedWorkHourInfoLazyQuery>;
export type GetSelfDeterminedWorkHourInfoQueryResult = Apollo.QueryResult<GetSelfDeterminedWorkHourInfoQuery, GetSelfDeterminedWorkHourInfoQueryVariables>;
export const GetSelfDeterminedWorkTemplateHoursDocument = gql`
    query GetSelfDeterminedWorkTemplateHours($workingTemplateIdx: Int!, $workingTimeName: String!) {
  getSelfDeterminedWorkTemplateHours(
    workingTemplateIdx: $workingTemplateIdx
    workingTimeName: $workingTimeName
  ) {
    ok
    error
    template {
      workingTemplateIdx
      workingTimeName
      useFlag
      operatingPeriodType
      operatingPeriodStartDate
      operatingPeriodEndDate
      workHoursType
      startTime
      endTime
      dutyWorkHoursType
      dutyStartTime
      dutyEndTime
      dayOfWeekType
      dayOfWeek
      breakTimeType
      startBreakTime
      endBreakTime
    }
  }
}
    `;

/**
 * __useGetSelfDeterminedWorkTemplateHoursQuery__
 *
 * To run a query within a React component, call `useGetSelfDeterminedWorkTemplateHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfDeterminedWorkTemplateHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfDeterminedWorkTemplateHoursQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      workingTimeName: // value for 'workingTimeName'
 *   },
 * });
 */
export function useGetSelfDeterminedWorkTemplateHoursQuery(baseOptions: Apollo.QueryHookOptions<GetSelfDeterminedWorkTemplateHoursQuery, GetSelfDeterminedWorkTemplateHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfDeterminedWorkTemplateHoursQuery, GetSelfDeterminedWorkTemplateHoursQueryVariables>(GetSelfDeterminedWorkTemplateHoursDocument, options);
      }
export function useGetSelfDeterminedWorkTemplateHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfDeterminedWorkTemplateHoursQuery, GetSelfDeterminedWorkTemplateHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfDeterminedWorkTemplateHoursQuery, GetSelfDeterminedWorkTemplateHoursQueryVariables>(GetSelfDeterminedWorkTemplateHoursDocument, options);
        }
export type GetSelfDeterminedWorkTemplateHoursQueryHookResult = ReturnType<typeof useGetSelfDeterminedWorkTemplateHoursQuery>;
export type GetSelfDeterminedWorkTemplateHoursLazyQueryHookResult = ReturnType<typeof useGetSelfDeterminedWorkTemplateHoursLazyQuery>;
export type GetSelfDeterminedWorkTemplateHoursQueryResult = Apollo.QueryResult<GetSelfDeterminedWorkTemplateHoursQuery, GetSelfDeterminedWorkTemplateHoursQueryVariables>;
export const AddSelfDeterminedWorkHoursDocument = gql`
    mutation AddSelfDeterminedWorkHours($workingTemplateIdx: Int!, $startBreakTime: String!, $endBreakTime: String!, $workingTimeName: [String!]!) {
  addSelfDeterminedWorkHours(
    workingTemplateIdx: $workingTemplateIdx
    startBreakTime: $startBreakTime
    endBreakTime: $endBreakTime
    workingTimeName: $workingTimeName
  ) {
    ok
    error
  }
}
    `;
export type AddSelfDeterminedWorkHoursMutationFn = Apollo.MutationFunction<AddSelfDeterminedWorkHoursMutation, AddSelfDeterminedWorkHoursMutationVariables>;

/**
 * __useAddSelfDeterminedWorkHoursMutation__
 *
 * To run a mutation, you first call `useAddSelfDeterminedWorkHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSelfDeterminedWorkHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSelfDeterminedWorkHoursMutation, { data, loading, error }] = useAddSelfDeterminedWorkHoursMutation({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      startBreakTime: // value for 'startBreakTime'
 *      endBreakTime: // value for 'endBreakTime'
 *      workingTimeName: // value for 'workingTimeName'
 *   },
 * });
 */
export function useAddSelfDeterminedWorkHoursMutation(baseOptions?: Apollo.MutationHookOptions<AddSelfDeterminedWorkHoursMutation, AddSelfDeterminedWorkHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSelfDeterminedWorkHoursMutation, AddSelfDeterminedWorkHoursMutationVariables>(AddSelfDeterminedWorkHoursDocument, options);
      }
export type AddSelfDeterminedWorkHoursMutationHookResult = ReturnType<typeof useAddSelfDeterminedWorkHoursMutation>;
export type AddSelfDeterminedWorkHoursMutationResult = Apollo.MutationResult<AddSelfDeterminedWorkHoursMutation>;
export type AddSelfDeterminedWorkHoursMutationOptions = Apollo.BaseMutationOptions<AddSelfDeterminedWorkHoursMutation, AddSelfDeterminedWorkHoursMutationVariables>;
export const AddSelfDeterminedWorkHourTemplateDocument = gql`
    mutation AddSelfDeterminedWorkHourTemplate($workingTemplateIdx: Int!, $workingTimeName: String!, $operatingPeriodType: OPERATING_PERIOD_TYPE!, $operatingPeriodStartDate: String!, $operatingPeriodEndDate: String!, $dayOfWeekType: WORK_DAY_OF_WEEK!, $dayOfWeek: [DAY_TYPE!]!, $workHoursType: WORK_HOURS_SETTING_TYPE!, $startTime: String!, $endTime: String!, $dutyWorkHoursType: IS_USE!, $dutyStartTime: String!, $dutyEndTime: String!, $breakTimeType: BREAK_TIME_TYPE!, $startBreakTime: String!, $endBreakTime: String!) {
  addSelfDeterminedWorkHourTemplate(
    workingTemplateIdx: $workingTemplateIdx
    workingTimeName: $workingTimeName
    operatingPeriodType: $operatingPeriodType
    operatingPeriodStartDate: $operatingPeriodStartDate
    operatingPeriodEndDate: $operatingPeriodEndDate
    dayOfWeekType: $dayOfWeekType
    dayOfWeek: $dayOfWeek
    workHoursType: $workHoursType
    startTime: $startTime
    endTime: $endTime
    dutyWorkHoursType: $dutyWorkHoursType
    dutyStartTime: $dutyStartTime
    dutyEndTime: $dutyEndTime
    breakTimeType: $breakTimeType
    startBreakTime: $startBreakTime
    endBreakTime: $endBreakTime
  ) {
    ok
    error
  }
}
    `;
export type AddSelfDeterminedWorkHourTemplateMutationFn = Apollo.MutationFunction<AddSelfDeterminedWorkHourTemplateMutation, AddSelfDeterminedWorkHourTemplateMutationVariables>;

/**
 * __useAddSelfDeterminedWorkHourTemplateMutation__
 *
 * To run a mutation, you first call `useAddSelfDeterminedWorkHourTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSelfDeterminedWorkHourTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSelfDeterminedWorkHourTemplateMutation, { data, loading, error }] = useAddSelfDeterminedWorkHourTemplateMutation({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      workingTimeName: // value for 'workingTimeName'
 *      operatingPeriodType: // value for 'operatingPeriodType'
 *      operatingPeriodStartDate: // value for 'operatingPeriodStartDate'
 *      operatingPeriodEndDate: // value for 'operatingPeriodEndDate'
 *      dayOfWeekType: // value for 'dayOfWeekType'
 *      dayOfWeek: // value for 'dayOfWeek'
 *      workHoursType: // value for 'workHoursType'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      dutyWorkHoursType: // value for 'dutyWorkHoursType'
 *      dutyStartTime: // value for 'dutyStartTime'
 *      dutyEndTime: // value for 'dutyEndTime'
 *      breakTimeType: // value for 'breakTimeType'
 *      startBreakTime: // value for 'startBreakTime'
 *      endBreakTime: // value for 'endBreakTime'
 *   },
 * });
 */
export function useAddSelfDeterminedWorkHourTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AddSelfDeterminedWorkHourTemplateMutation, AddSelfDeterminedWorkHourTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSelfDeterminedWorkHourTemplateMutation, AddSelfDeterminedWorkHourTemplateMutationVariables>(AddSelfDeterminedWorkHourTemplateDocument, options);
      }
export type AddSelfDeterminedWorkHourTemplateMutationHookResult = ReturnType<typeof useAddSelfDeterminedWorkHourTemplateMutation>;
export type AddSelfDeterminedWorkHourTemplateMutationResult = Apollo.MutationResult<AddSelfDeterminedWorkHourTemplateMutation>;
export type AddSelfDeterminedWorkHourTemplateMutationOptions = Apollo.BaseMutationOptions<AddSelfDeterminedWorkHourTemplateMutation, AddSelfDeterminedWorkHourTemplateMutationVariables>;
export const UpdateSelfDeterminedWorkHourTemplateDocument = gql`
    mutation UpdateSelfDeterminedWorkHourTemplate($workingTemplateIdx: Int!, $workingTimeName: String!, $operatingPeriodType: OPERATING_PERIOD_TYPE!, $operatingPeriodStartDate: String!, $operatingPeriodEndDate: String!, $dayOfWeekType: WORK_DAY_OF_WEEK!, $dayOfWeek: [DAY_TYPE!]!, $workHoursType: WORK_HOURS_SETTING_TYPE!, $startTime: String!, $endTime: String!, $dutyWorkHoursType: IS_USE!, $dutyStartTime: String!, $dutyEndTime: String!, $breakTimeType: BREAK_TIME_TYPE!, $startBreakTime: String!, $endBreakTime: String!, $oldWorkingTimeName: String!) {
  updateSelfDeterminedWorkHourTemplate(
    workingTemplateIdx: $workingTemplateIdx
    workingTimeName: $workingTimeName
    operatingPeriodType: $operatingPeriodType
    operatingPeriodStartDate: $operatingPeriodStartDate
    operatingPeriodEndDate: $operatingPeriodEndDate
    dayOfWeekType: $dayOfWeekType
    dayOfWeek: $dayOfWeek
    workHoursType: $workHoursType
    startTime: $startTime
    endTime: $endTime
    dutyWorkHoursType: $dutyWorkHoursType
    dutyStartTime: $dutyStartTime
    dutyEndTime: $dutyEndTime
    breakTimeType: $breakTimeType
    startBreakTime: $startBreakTime
    endBreakTime: $endBreakTime
    oldWorkingTimeName: $oldWorkingTimeName
  ) {
    ok
    error
  }
}
    `;
export type UpdateSelfDeterminedWorkHourTemplateMutationFn = Apollo.MutationFunction<UpdateSelfDeterminedWorkHourTemplateMutation, UpdateSelfDeterminedWorkHourTemplateMutationVariables>;

/**
 * __useUpdateSelfDeterminedWorkHourTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateSelfDeterminedWorkHourTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelfDeterminedWorkHourTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelfDeterminedWorkHourTemplateMutation, { data, loading, error }] = useUpdateSelfDeterminedWorkHourTemplateMutation({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      workingTimeName: // value for 'workingTimeName'
 *      operatingPeriodType: // value for 'operatingPeriodType'
 *      operatingPeriodStartDate: // value for 'operatingPeriodStartDate'
 *      operatingPeriodEndDate: // value for 'operatingPeriodEndDate'
 *      dayOfWeekType: // value for 'dayOfWeekType'
 *      dayOfWeek: // value for 'dayOfWeek'
 *      workHoursType: // value for 'workHoursType'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      dutyWorkHoursType: // value for 'dutyWorkHoursType'
 *      dutyStartTime: // value for 'dutyStartTime'
 *      dutyEndTime: // value for 'dutyEndTime'
 *      breakTimeType: // value for 'breakTimeType'
 *      startBreakTime: // value for 'startBreakTime'
 *      endBreakTime: // value for 'endBreakTime'
 *      oldWorkingTimeName: // value for 'oldWorkingTimeName'
 *   },
 * });
 */
export function useUpdateSelfDeterminedWorkHourTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelfDeterminedWorkHourTemplateMutation, UpdateSelfDeterminedWorkHourTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelfDeterminedWorkHourTemplateMutation, UpdateSelfDeterminedWorkHourTemplateMutationVariables>(UpdateSelfDeterminedWorkHourTemplateDocument, options);
      }
export type UpdateSelfDeterminedWorkHourTemplateMutationHookResult = ReturnType<typeof useUpdateSelfDeterminedWorkHourTemplateMutation>;
export type UpdateSelfDeterminedWorkHourTemplateMutationResult = Apollo.MutationResult<UpdateSelfDeterminedWorkHourTemplateMutation>;
export type UpdateSelfDeterminedWorkHourTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateSelfDeterminedWorkHourTemplateMutation, UpdateSelfDeterminedWorkHourTemplateMutationVariables>;
export const DeleteSelfDeterminedWorkHourTemplateDocument = gql`
    mutation DeleteSelfDeterminedWorkHourTemplate($workingTemplateIdx: Int!, $workingTimeNameList: [String!]!) {
  deleteSelfDeterminedWorkHourTemplate(
    workingTemplateIdx: $workingTemplateIdx
    workingTimeNameList: $workingTimeNameList
  ) {
    ok
    error
  }
}
    `;
export type DeleteSelfDeterminedWorkHourTemplateMutationFn = Apollo.MutationFunction<DeleteSelfDeterminedWorkHourTemplateMutation, DeleteSelfDeterminedWorkHourTemplateMutationVariables>;

/**
 * __useDeleteSelfDeterminedWorkHourTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteSelfDeterminedWorkHourTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSelfDeterminedWorkHourTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSelfDeterminedWorkHourTemplateMutation, { data, loading, error }] = useDeleteSelfDeterminedWorkHourTemplateMutation({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      workingTimeNameList: // value for 'workingTimeNameList'
 *   },
 * });
 */
export function useDeleteSelfDeterminedWorkHourTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSelfDeterminedWorkHourTemplateMutation, DeleteSelfDeterminedWorkHourTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSelfDeterminedWorkHourTemplateMutation, DeleteSelfDeterminedWorkHourTemplateMutationVariables>(DeleteSelfDeterminedWorkHourTemplateDocument, options);
      }
export type DeleteSelfDeterminedWorkHourTemplateMutationHookResult = ReturnType<typeof useDeleteSelfDeterminedWorkHourTemplateMutation>;
export type DeleteSelfDeterminedWorkHourTemplateMutationResult = Apollo.MutationResult<DeleteSelfDeterminedWorkHourTemplateMutation>;
export type DeleteSelfDeterminedWorkHourTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteSelfDeterminedWorkHourTemplateMutation, DeleteSelfDeterminedWorkHourTemplateMutationVariables>;
export const GetControlAlertDocument = gql`
    query GetControlAlert($workingTemplateIdx: Int!) {
  getControlAlert(workingTemplateIdx: $workingTemplateIdx) {
    ok
    error
    info {
      workingTemplateIdx
      beforeStartWorkingMin
      beforeStartWorkingCtrlMsg
      beforeEndWorkingMin
      beforeEndWorkingCtrlMsg
      afterEndWorkingCtrlMsg
      breakTimeCtrlMsg
      exceedCtrlMsgBeforeMinOne
      exceedCtrlMsgBeforeOne
      exceedCtrlMsgBeforeMinTwo
      exceedCtrlMsgBeforeTwo
      exceedCtrlMsgAfter
    }
  }
}
    `;

/**
 * __useGetControlAlertQuery__
 *
 * To run a query within a React component, call `useGetControlAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetControlAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetControlAlertQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *   },
 * });
 */
export function useGetControlAlertQuery(baseOptions: Apollo.QueryHookOptions<GetControlAlertQuery, GetControlAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetControlAlertQuery, GetControlAlertQueryVariables>(GetControlAlertDocument, options);
      }
export function useGetControlAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetControlAlertQuery, GetControlAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetControlAlertQuery, GetControlAlertQueryVariables>(GetControlAlertDocument, options);
        }
export type GetControlAlertQueryHookResult = ReturnType<typeof useGetControlAlertQuery>;
export type GetControlAlertLazyQueryHookResult = ReturnType<typeof useGetControlAlertLazyQuery>;
export type GetControlAlertQueryResult = Apollo.QueryResult<GetControlAlertQuery, GetControlAlertQueryVariables>;
export const GetControlNotificationCategoryDocument = gql`
    query GetControlNotificationCategory {
  getControlNotificationCategory {
    ok
    error
    list {
      ctrlMsgTemplateIdx
      name
    }
  }
}
    `;

/**
 * __useGetControlNotificationCategoryQuery__
 *
 * To run a query within a React component, call `useGetControlNotificationCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetControlNotificationCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetControlNotificationCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetControlNotificationCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetControlNotificationCategoryQuery, GetControlNotificationCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetControlNotificationCategoryQuery, GetControlNotificationCategoryQueryVariables>(GetControlNotificationCategoryDocument, options);
      }
export function useGetControlNotificationCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetControlNotificationCategoryQuery, GetControlNotificationCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetControlNotificationCategoryQuery, GetControlNotificationCategoryQueryVariables>(GetControlNotificationCategoryDocument, options);
        }
export type GetControlNotificationCategoryQueryHookResult = ReturnType<typeof useGetControlNotificationCategoryQuery>;
export type GetControlNotificationCategoryLazyQueryHookResult = ReturnType<typeof useGetControlNotificationCategoryLazyQuery>;
export type GetControlNotificationCategoryQueryResult = Apollo.QueryResult<GetControlNotificationCategoryQuery, GetControlNotificationCategoryQueryVariables>;
export const AddControlNotificationDocument = gql`
    mutation AddControlNotification($workingTemplateIdx: Int!, $beforeStartWorkingMin: Int, $beforeStartWorkingCtrlMsg: Int, $beforeEndWorkingMin: Int, $beforeEndWorkingCtrlMsg: Int, $afterEndWorkingCtrlMsg: Int, $breakTimeCtrlMsg: Int, $exceedCtrlMsgBeforeMinOne: Int, $exceedCtrlMsgBeforeOne: Int, $exceedCtrlMsgBeforeMinTwo: Int, $exceedCtrlMsgBeforeTwo: Int, $exceedCtrlMsgAfter: Int) {
  addControlNotification(
    workingTemplateIdx: $workingTemplateIdx
    beforeStartWorkingMin: $beforeStartWorkingMin
    beforeStartWorkingCtrlMsg: $beforeStartWorkingCtrlMsg
    beforeEndWorkingMin: $beforeEndWorkingMin
    beforeEndWorkingCtrlMsg: $beforeEndWorkingCtrlMsg
    afterEndWorkingCtrlMsg: $afterEndWorkingCtrlMsg
    breakTimeCtrlMsg: $breakTimeCtrlMsg
    exceedCtrlMsgBeforeMinOne: $exceedCtrlMsgBeforeMinOne
    exceedCtrlMsgBeforeOne: $exceedCtrlMsgBeforeOne
    exceedCtrlMsgBeforeMinTwo: $exceedCtrlMsgBeforeMinTwo
    exceedCtrlMsgBeforeTwo: $exceedCtrlMsgBeforeTwo
    exceedCtrlMsgAfter: $exceedCtrlMsgAfter
  ) {
    ok
    error
  }
}
    `;
export type AddControlNotificationMutationFn = Apollo.MutationFunction<AddControlNotificationMutation, AddControlNotificationMutationVariables>;

/**
 * __useAddControlNotificationMutation__
 *
 * To run a mutation, you first call `useAddControlNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddControlNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addControlNotificationMutation, { data, loading, error }] = useAddControlNotificationMutation({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      beforeStartWorkingMin: // value for 'beforeStartWorkingMin'
 *      beforeStartWorkingCtrlMsg: // value for 'beforeStartWorkingCtrlMsg'
 *      beforeEndWorkingMin: // value for 'beforeEndWorkingMin'
 *      beforeEndWorkingCtrlMsg: // value for 'beforeEndWorkingCtrlMsg'
 *      afterEndWorkingCtrlMsg: // value for 'afterEndWorkingCtrlMsg'
 *      breakTimeCtrlMsg: // value for 'breakTimeCtrlMsg'
 *      exceedCtrlMsgBeforeMinOne: // value for 'exceedCtrlMsgBeforeMinOne'
 *      exceedCtrlMsgBeforeOne: // value for 'exceedCtrlMsgBeforeOne'
 *      exceedCtrlMsgBeforeMinTwo: // value for 'exceedCtrlMsgBeforeMinTwo'
 *      exceedCtrlMsgBeforeTwo: // value for 'exceedCtrlMsgBeforeTwo'
 *      exceedCtrlMsgAfter: // value for 'exceedCtrlMsgAfter'
 *   },
 * });
 */
export function useAddControlNotificationMutation(baseOptions?: Apollo.MutationHookOptions<AddControlNotificationMutation, AddControlNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddControlNotificationMutation, AddControlNotificationMutationVariables>(AddControlNotificationDocument, options);
      }
export type AddControlNotificationMutationHookResult = ReturnType<typeof useAddControlNotificationMutation>;
export type AddControlNotificationMutationResult = Apollo.MutationResult<AddControlNotificationMutation>;
export type AddControlNotificationMutationOptions = Apollo.BaseMutationOptions<AddControlNotificationMutation, AddControlNotificationMutationVariables>;
export const GetEtcOperatingHoursDocument = gql`
    query GetEtcOperatingHours($workingTemplateIdx: Int!) {
  getEtcOperatingHours(workingTemplateIdx: $workingTemplateIdx) {
    ok
    error
    info {
      workingTemplateIdx
      holidayTemplateIdx
    }
  }
}
    `;

/**
 * __useGetEtcOperatingHoursQuery__
 *
 * To run a query within a React component, call `useGetEtcOperatingHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEtcOperatingHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEtcOperatingHoursQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *   },
 * });
 */
export function useGetEtcOperatingHoursQuery(baseOptions: Apollo.QueryHookOptions<GetEtcOperatingHoursQuery, GetEtcOperatingHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEtcOperatingHoursQuery, GetEtcOperatingHoursQueryVariables>(GetEtcOperatingHoursDocument, options);
      }
export function useGetEtcOperatingHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEtcOperatingHoursQuery, GetEtcOperatingHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEtcOperatingHoursQuery, GetEtcOperatingHoursQueryVariables>(GetEtcOperatingHoursDocument, options);
        }
export type GetEtcOperatingHoursQueryHookResult = ReturnType<typeof useGetEtcOperatingHoursQuery>;
export type GetEtcOperatingHoursLazyQueryHookResult = ReturnType<typeof useGetEtcOperatingHoursLazyQuery>;
export type GetEtcOperatingHoursQueryResult = Apollo.QueryResult<GetEtcOperatingHoursQuery, GetEtcOperatingHoursQueryVariables>;
export const GetEtcOperatingHourListDocument = gql`
    query GetEtcOperatingHourList {
  getEtcOperatingHourList {
    ok
    error
    list {
      holidayTemplateIdx
      name
      weekSequence
      daySetType
      dayDateOrWeek
      startDateTime
      endDateTime
      ctrlName
    }
  }
}
    `;

/**
 * __useGetEtcOperatingHourListQuery__
 *
 * To run a query within a React component, call `useGetEtcOperatingHourListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEtcOperatingHourListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEtcOperatingHourListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEtcOperatingHourListQuery(baseOptions?: Apollo.QueryHookOptions<GetEtcOperatingHourListQuery, GetEtcOperatingHourListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEtcOperatingHourListQuery, GetEtcOperatingHourListQueryVariables>(GetEtcOperatingHourListDocument, options);
      }
export function useGetEtcOperatingHourListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEtcOperatingHourListQuery, GetEtcOperatingHourListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEtcOperatingHourListQuery, GetEtcOperatingHourListQueryVariables>(GetEtcOperatingHourListDocument, options);
        }
export type GetEtcOperatingHourListQueryHookResult = ReturnType<typeof useGetEtcOperatingHourListQuery>;
export type GetEtcOperatingHourListLazyQueryHookResult = ReturnType<typeof useGetEtcOperatingHourListLazyQuery>;
export type GetEtcOperatingHourListQueryResult = Apollo.QueryResult<GetEtcOperatingHourListQuery, GetEtcOperatingHourListQueryVariables>;
export const AddEtcOperatingHoursDocument = gql`
    mutation AddEtcOperatingHours($workingTemplateIdx: Int!, $otHolidayTemplateNewAutoUse: IS_USE!, $holidayTemplateIdx: [Int!]!) {
  addEtcOperatingHours(
    workingTemplateIdx: $workingTemplateIdx
    otHolidayTemplateNewAutoUse: $otHolidayTemplateNewAutoUse
    holidayTemplateIdx: $holidayTemplateIdx
  ) {
    ok
    error
  }
}
    `;
export type AddEtcOperatingHoursMutationFn = Apollo.MutationFunction<AddEtcOperatingHoursMutation, AddEtcOperatingHoursMutationVariables>;

/**
 * __useAddEtcOperatingHoursMutation__
 *
 * To run a mutation, you first call `useAddEtcOperatingHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEtcOperatingHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEtcOperatingHoursMutation, { data, loading, error }] = useAddEtcOperatingHoursMutation({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      otHolidayTemplateNewAutoUse: // value for 'otHolidayTemplateNewAutoUse'
 *      holidayTemplateIdx: // value for 'holidayTemplateIdx'
 *   },
 * });
 */
export function useAddEtcOperatingHoursMutation(baseOptions?: Apollo.MutationHookOptions<AddEtcOperatingHoursMutation, AddEtcOperatingHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEtcOperatingHoursMutation, AddEtcOperatingHoursMutationVariables>(AddEtcOperatingHoursDocument, options);
      }
export type AddEtcOperatingHoursMutationHookResult = ReturnType<typeof useAddEtcOperatingHoursMutation>;
export type AddEtcOperatingHoursMutationResult = Apollo.MutationResult<AddEtcOperatingHoursMutation>;
export type AddEtcOperatingHoursMutationOptions = Apollo.BaseMutationOptions<AddEtcOperatingHoursMutation, AddEtcOperatingHoursMutationVariables>;
export const GetFlexibleWorkingHourInfoDocument = gql`
    query GetFlexibleWorkingHourInfo($workingTemplateIdx: Int!) {
  getFlexibleWorkingHourInfo(workingTemplateIdx: $workingTemplateIdx) {
    ok
    error
    basicInfo {
      workingTemplateIdx
      startTime
      endTime
      settlementSectionHour
      workHoursType
      dayOfWeekType
      dayOfWeek
      startDate
      startBreakTime
      endBreakTime
    }
    extraList {
      workingTemplateIdx
      weekSequence
      dayOfWeek
      workHour
      startTime
      endTime
      startBreakTime
      endBreakTime
    }
  }
}
    `;

/**
 * __useGetFlexibleWorkingHourInfoQuery__
 *
 * To run a query within a React component, call `useGetFlexibleWorkingHourInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFlexibleWorkingHourInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFlexibleWorkingHourInfoQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *   },
 * });
 */
export function useGetFlexibleWorkingHourInfoQuery(baseOptions: Apollo.QueryHookOptions<GetFlexibleWorkingHourInfoQuery, GetFlexibleWorkingHourInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFlexibleWorkingHourInfoQuery, GetFlexibleWorkingHourInfoQueryVariables>(GetFlexibleWorkingHourInfoDocument, options);
      }
export function useGetFlexibleWorkingHourInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFlexibleWorkingHourInfoQuery, GetFlexibleWorkingHourInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFlexibleWorkingHourInfoQuery, GetFlexibleWorkingHourInfoQueryVariables>(GetFlexibleWorkingHourInfoDocument, options);
        }
export type GetFlexibleWorkingHourInfoQueryHookResult = ReturnType<typeof useGetFlexibleWorkingHourInfoQuery>;
export type GetFlexibleWorkingHourInfoLazyQueryHookResult = ReturnType<typeof useGetFlexibleWorkingHourInfoLazyQuery>;
export type GetFlexibleWorkingHourInfoQueryResult = Apollo.QueryResult<GetFlexibleWorkingHourInfoQuery, GetFlexibleWorkingHourInfoQueryVariables>;
export const AddFlexibleWorkingHourInfoDocument = gql`
    mutation AddFlexibleWorkingHourInfo($workingTemplateIdx: Int!, $startTime: String!, $endTime: String!, $settlementSectionHour: Int!, $workHoursType: WORK_HOURS_TYPE!, $dayOfWeekType: DAY_OF_WEEK_TYPE!, $dayOfWeek: [DAY_TYPE!]!, $startDate: String!, $startBreakTime: String!, $endBreakTime: String!, $otWorkingFlexibleHours: [OtWorkingFlexibleHours!]!) {
  addFlexibleWorkingHourInfo(
    workingTemplateIdx: $workingTemplateIdx
    startTime: $startTime
    endTime: $endTime
    settlementSectionHour: $settlementSectionHour
    workHoursType: $workHoursType
    dayOfWeekType: $dayOfWeekType
    dayOfWeek: $dayOfWeek
    startDate: $startDate
    startBreakTime: $startBreakTime
    endBreakTime: $endBreakTime
    otWorkingFlexibleHours: $otWorkingFlexibleHours
  ) {
    ok
    error
  }
}
    `;
export type AddFlexibleWorkingHourInfoMutationFn = Apollo.MutationFunction<AddFlexibleWorkingHourInfoMutation, AddFlexibleWorkingHourInfoMutationVariables>;

/**
 * __useAddFlexibleWorkingHourInfoMutation__
 *
 * To run a mutation, you first call `useAddFlexibleWorkingHourInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFlexibleWorkingHourInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFlexibleWorkingHourInfoMutation, { data, loading, error }] = useAddFlexibleWorkingHourInfoMutation({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      settlementSectionHour: // value for 'settlementSectionHour'
 *      workHoursType: // value for 'workHoursType'
 *      dayOfWeekType: // value for 'dayOfWeekType'
 *      dayOfWeek: // value for 'dayOfWeek'
 *      startDate: // value for 'startDate'
 *      startBreakTime: // value for 'startBreakTime'
 *      endBreakTime: // value for 'endBreakTime'
 *      otWorkingFlexibleHours: // value for 'otWorkingFlexibleHours'
 *   },
 * });
 */
export function useAddFlexibleWorkingHourInfoMutation(baseOptions?: Apollo.MutationHookOptions<AddFlexibleWorkingHourInfoMutation, AddFlexibleWorkingHourInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFlexibleWorkingHourInfoMutation, AddFlexibleWorkingHourInfoMutationVariables>(AddFlexibleWorkingHourInfoDocument, options);
      }
export type AddFlexibleWorkingHourInfoMutationHookResult = ReturnType<typeof useAddFlexibleWorkingHourInfoMutation>;
export type AddFlexibleWorkingHourInfoMutationResult = Apollo.MutationResult<AddFlexibleWorkingHourInfoMutation>;
export type AddFlexibleWorkingHourInfoMutationOptions = Apollo.BaseMutationOptions<AddFlexibleWorkingHourInfoMutation, AddFlexibleWorkingHourInfoMutationVariables>;
export const EditUserDailyWorkCheckInfoDocument = gql`
    mutation EditUserDailyWorkCheckInfo($employeeId: String!, $dailyCheckInfo: [UserDailyCheckInfo!]!) {
  editUserDailyWorkCheckInfo(
    employeeId: $employeeId
    dailyCheckInfo: $dailyCheckInfo
  ) {
    ok
    error
  }
}
    `;
export type EditUserDailyWorkCheckInfoMutationFn = Apollo.MutationFunction<EditUserDailyWorkCheckInfoMutation, EditUserDailyWorkCheckInfoMutationVariables>;

/**
 * __useEditUserDailyWorkCheckInfoMutation__
 *
 * To run a mutation, you first call `useEditUserDailyWorkCheckInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserDailyWorkCheckInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserDailyWorkCheckInfoMutation, { data, loading, error }] = useEditUserDailyWorkCheckInfoMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      dailyCheckInfo: // value for 'dailyCheckInfo'
 *   },
 * });
 */
export function useEditUserDailyWorkCheckInfoMutation(baseOptions?: Apollo.MutationHookOptions<EditUserDailyWorkCheckInfoMutation, EditUserDailyWorkCheckInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserDailyWorkCheckInfoMutation, EditUserDailyWorkCheckInfoMutationVariables>(EditUserDailyWorkCheckInfoDocument, options);
      }
export type EditUserDailyWorkCheckInfoMutationHookResult = ReturnType<typeof useEditUserDailyWorkCheckInfoMutation>;
export type EditUserDailyWorkCheckInfoMutationResult = Apollo.MutationResult<EditUserDailyWorkCheckInfoMutation>;
export type EditUserDailyWorkCheckInfoMutationOptions = Apollo.BaseMutationOptions<EditUserDailyWorkCheckInfoMutation, EditUserDailyWorkCheckInfoMutationVariables>;
export const GetCommuteRecordInfoDocument = gql`
    query GetCommuteRecordInfo($workingTemplateIdx: Int!) {
  getCommuteRecordInfo(workingTemplateIdx: $workingTemplateIdx) {
    ok
    error
    info {
      commuteTemplateIdx
      pcUse
      appUse
      startWorkingMin
      endWorkingMin
      approvalDataType
      dayOfWeek
      unCheckoutAutoCheckoutFlag
      unCheckoutAutoCheckoutTime
      checkInStartWorkTimeSetFlag
      checkoutEndWorkTimeSetFlag
    }
  }
}
    `;

/**
 * __useGetCommuteRecordInfoQuery__
 *
 * To run a query within a React component, call `useGetCommuteRecordInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommuteRecordInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommuteRecordInfoQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *   },
 * });
 */
export function useGetCommuteRecordInfoQuery(baseOptions: Apollo.QueryHookOptions<GetCommuteRecordInfoQuery, GetCommuteRecordInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommuteRecordInfoQuery, GetCommuteRecordInfoQueryVariables>(GetCommuteRecordInfoDocument, options);
      }
export function useGetCommuteRecordInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommuteRecordInfoQuery, GetCommuteRecordInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommuteRecordInfoQuery, GetCommuteRecordInfoQueryVariables>(GetCommuteRecordInfoDocument, options);
        }
export type GetCommuteRecordInfoQueryHookResult = ReturnType<typeof useGetCommuteRecordInfoQuery>;
export type GetCommuteRecordInfoLazyQueryHookResult = ReturnType<typeof useGetCommuteRecordInfoLazyQuery>;
export type GetCommuteRecordInfoQueryResult = Apollo.QueryResult<GetCommuteRecordInfoQuery, GetCommuteRecordInfoQueryVariables>;
export const AddCommuteRecordInfoDocument = gql`
    mutation AddCommuteRecordInfo($workingTemplateIdx: Int!, $name: String!, $pcUse: IS_USE!, $appUse: IS_USE!, $startWorkingMin: Int!, $endWorkingMin: Int!, $approvalDataType: IS_USE!, $dayOfWeek: [DAY_TYPE!]!, $unCheckoutAutoCheckoutFlag: IS_CHECK_AUTO_TIME!, $unCheckoutAutoCheckoutTime: String!, $checkInStartWorkTimeSetFlag: IS_USE!, $checkoutEndWorkTimeSetFlag: IS_USE!) {
  addCommuteRecordInfo(
    workingTemplateIdx: $workingTemplateIdx
    name: $name
    pcUse: $pcUse
    appUse: $appUse
    startWorkingMin: $startWorkingMin
    endWorkingMin: $endWorkingMin
    approvalDataType: $approvalDataType
    dayOfWeek: $dayOfWeek
    unCheckoutAutoCheckoutFlag: $unCheckoutAutoCheckoutFlag
    unCheckoutAutoCheckoutTime: $unCheckoutAutoCheckoutTime
    checkInStartWorkTimeSetFlag: $checkInStartWorkTimeSetFlag
    checkoutEndWorkTimeSetFlag: $checkoutEndWorkTimeSetFlag
  ) {
    ok
    error
  }
}
    `;
export type AddCommuteRecordInfoMutationFn = Apollo.MutationFunction<AddCommuteRecordInfoMutation, AddCommuteRecordInfoMutationVariables>;

/**
 * __useAddCommuteRecordInfoMutation__
 *
 * To run a mutation, you first call `useAddCommuteRecordInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommuteRecordInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommuteRecordInfoMutation, { data, loading, error }] = useAddCommuteRecordInfoMutation({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      name: // value for 'name'
 *      pcUse: // value for 'pcUse'
 *      appUse: // value for 'appUse'
 *      startWorkingMin: // value for 'startWorkingMin'
 *      endWorkingMin: // value for 'endWorkingMin'
 *      approvalDataType: // value for 'approvalDataType'
 *      dayOfWeek: // value for 'dayOfWeek'
 *      unCheckoutAutoCheckoutFlag: // value for 'unCheckoutAutoCheckoutFlag'
 *      unCheckoutAutoCheckoutTime: // value for 'unCheckoutAutoCheckoutTime'
 *      checkInStartWorkTimeSetFlag: // value for 'checkInStartWorkTimeSetFlag'
 *      checkoutEndWorkTimeSetFlag: // value for 'checkoutEndWorkTimeSetFlag'
 *   },
 * });
 */
export function useAddCommuteRecordInfoMutation(baseOptions?: Apollo.MutationHookOptions<AddCommuteRecordInfoMutation, AddCommuteRecordInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommuteRecordInfoMutation, AddCommuteRecordInfoMutationVariables>(AddCommuteRecordInfoDocument, options);
      }
export type AddCommuteRecordInfoMutationHookResult = ReturnType<typeof useAddCommuteRecordInfoMutation>;
export type AddCommuteRecordInfoMutationResult = Apollo.MutationResult<AddCommuteRecordInfoMutation>;
export type AddCommuteRecordInfoMutationOptions = Apollo.BaseMutationOptions<AddCommuteRecordInfoMutation, AddCommuteRecordInfoMutationVariables>;
export const GetUserDocument = gql`
    query GetUser($getUserId: String, $name: String, $departmentId: Int, $like: Boolean) {
  getUser(id: $getUserId, name: $name, departmentId: $departmentId, like: $like) {
    ok
    error
    user {
      employeeId
      departmentId
      fullDepartmentName
      workingTemplateIdx
      timeTemplateIdx
      leftSeatUse
      asonicWidgetUse
      approvalDefine
      name
      password
      newData
      newDataTime
      manageable
      insertType
      policyWorkingMin
      workingMin
      policy_ext_working_min
      extWorkingMin
      tokenField
      myPicture
      fcmToken
      delegatorId
      approvalStartDate
      approvalEndDate
      userEmail
      webAppAuth
      appPhoneNum
      col1
      col2
      col3
      col4
      col5
      col6
      col7
      col8
      col9
      col10
      col11
      col12
      col13
      col14
      col15
      fpProcess
      policyUpdateStatus
      fpProcessTwo
      policyUpdateStatusTwo
      memo
      userPosition
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      getUserId: // value for 'getUserId'
 *      name: // value for 'name'
 *      departmentId: // value for 'departmentId'
 *      like: // value for 'like'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetGroupsForAllDocument = gql`
    query GetGroupsForAll {
  getGroupsForAll {
    ok
    error
    listOfGroup {
      departmentId
      parentDepartmentId
      name
      level
      fullGroupName
      insertType
      countEmployee
      countEndpoint
      memo1
      memo2
      workingName
      deptSort
    }
  }
}
    `;

/**
 * __useGetGroupsForAllQuery__
 *
 * To run a query within a React component, call `useGetGroupsForAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsForAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsForAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupsForAllQuery(baseOptions?: Apollo.QueryHookOptions<GetGroupsForAllQuery, GetGroupsForAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupsForAllQuery, GetGroupsForAllQueryVariables>(GetGroupsForAllDocument, options);
      }
export function useGetGroupsForAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupsForAllQuery, GetGroupsForAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupsForAllQuery, GetGroupsForAllQueryVariables>(GetGroupsForAllDocument, options);
        }
export type GetGroupsForAllQueryHookResult = ReturnType<typeof useGetGroupsForAllQuery>;
export type GetGroupsForAllLazyQueryHookResult = ReturnType<typeof useGetGroupsForAllLazyQuery>;
export type GetGroupsForAllQueryResult = Apollo.QueryResult<GetGroupsForAllQuery, GetGroupsForAllQueryVariables>;
export const UpdateWorkingTemplateDocument = gql`
    mutation UpdateWorkingTemplate($employeeIdList: [String!]!, $workingTemplateIdx: Int) {
  updateWorkingTemplate(
    employeeIdList: $employeeIdList
    workingTemplateIdx: $workingTemplateIdx
  ) {
    ok
    error
  }
}
    `;
export type UpdateWorkingTemplateMutationFn = Apollo.MutationFunction<UpdateWorkingTemplateMutation, UpdateWorkingTemplateMutationVariables>;

/**
 * __useUpdateWorkingTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateWorkingTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkingTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkingTemplateMutation, { data, loading, error }] = useUpdateWorkingTemplateMutation({
 *   variables: {
 *      employeeIdList: // value for 'employeeIdList'
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *   },
 * });
 */
export function useUpdateWorkingTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkingTemplateMutation, UpdateWorkingTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkingTemplateMutation, UpdateWorkingTemplateMutationVariables>(UpdateWorkingTemplateDocument, options);
      }
export type UpdateWorkingTemplateMutationHookResult = ReturnType<typeof useUpdateWorkingTemplateMutation>;
export type UpdateWorkingTemplateMutationResult = Apollo.MutationResult<UpdateWorkingTemplateMutation>;
export type UpdateWorkingTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateWorkingTemplateMutation, UpdateWorkingTemplateMutationVariables>;
export const GetUserPolicyBasicInfoDocument = gql`
    query GetUserPolicyBasicInfo($employeeId: String!) {
  getUserPolicyBasicInfo(employeeId: $employeeId) {
    ok
    error
    basicInfo {
      workingTemplateIdx
      nameId
      departName
      templateName
      wType
      possibleTime
      workType
    }
  }
}
    `;

/**
 * __useGetUserPolicyBasicInfoQuery__
 *
 * To run a query within a React component, call `useGetUserPolicyBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPolicyBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPolicyBasicInfoQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetUserPolicyBasicInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUserPolicyBasicInfoQuery, GetUserPolicyBasicInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPolicyBasicInfoQuery, GetUserPolicyBasicInfoQueryVariables>(GetUserPolicyBasicInfoDocument, options);
      }
export function useGetUserPolicyBasicInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPolicyBasicInfoQuery, GetUserPolicyBasicInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPolicyBasicInfoQuery, GetUserPolicyBasicInfoQueryVariables>(GetUserPolicyBasicInfoDocument, options);
        }
export type GetUserPolicyBasicInfoQueryHookResult = ReturnType<typeof useGetUserPolicyBasicInfoQuery>;
export type GetUserPolicyBasicInfoLazyQueryHookResult = ReturnType<typeof useGetUserPolicyBasicInfoLazyQuery>;
export type GetUserPolicyBasicInfoQueryResult = Apollo.QueryResult<GetUserPolicyBasicInfoQuery, GetUserPolicyBasicInfoQueryVariables>;
export const GetUserPolicySelectedInfoDocument = gql`
    query GetUserPolicySelectedInfo($employeeId: String!, $workingTimeName: String) {
  getUserPolicySelectedInfo(
    employeeId: $employeeId
    workingTimeName: $workingTimeName
  ) {
    ok
    error
    info {
      workingTimeName
      workingPeriod
      isDayOfWeekEnable
      asDayOfWeek
      isWorkHoursEnable
      startTime
      endTime
      isBreakTimeTypeEnable
      startBreakTime
      endBreakTime
      dutyWorkHoursType
      dutyStartTime
      dutyEndTime
    }
  }
}
    `;

/**
 * __useGetUserPolicySelectedInfoQuery__
 *
 * To run a query within a React component, call `useGetUserPolicySelectedInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPolicySelectedInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPolicySelectedInfoQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      workingTimeName: // value for 'workingTimeName'
 *   },
 * });
 */
export function useGetUserPolicySelectedInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUserPolicySelectedInfoQuery, GetUserPolicySelectedInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPolicySelectedInfoQuery, GetUserPolicySelectedInfoQueryVariables>(GetUserPolicySelectedInfoDocument, options);
      }
export function useGetUserPolicySelectedInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPolicySelectedInfoQuery, GetUserPolicySelectedInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPolicySelectedInfoQuery, GetUserPolicySelectedInfoQueryVariables>(GetUserPolicySelectedInfoDocument, options);
        }
export type GetUserPolicySelectedInfoQueryHookResult = ReturnType<typeof useGetUserPolicySelectedInfoQuery>;
export type GetUserPolicySelectedInfoLazyQueryHookResult = ReturnType<typeof useGetUserPolicySelectedInfoLazyQuery>;
export type GetUserPolicySelectedInfoQueryResult = Apollo.QueryResult<GetUserPolicySelectedInfoQuery, GetUserPolicySelectedInfoQueryVariables>;
export const GetWtiFromEmployeeDocument = gql`
    query GetWTIFromEmployee($employeeId: String!) {
  getWTIFromEmployee(employeeId: $employeeId) {
    ok
    error
    workingTemplateIdx
  }
}
    `;

/**
 * __useGetWtiFromEmployeeQuery__
 *
 * To run a query within a React component, call `useGetWtiFromEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWtiFromEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWtiFromEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetWtiFromEmployeeQuery(baseOptions: Apollo.QueryHookOptions<GetWtiFromEmployeeQuery, GetWtiFromEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWtiFromEmployeeQuery, GetWtiFromEmployeeQueryVariables>(GetWtiFromEmployeeDocument, options);
      }
export function useGetWtiFromEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWtiFromEmployeeQuery, GetWtiFromEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWtiFromEmployeeQuery, GetWtiFromEmployeeQueryVariables>(GetWtiFromEmployeeDocument, options);
        }
export type GetWtiFromEmployeeQueryHookResult = ReturnType<typeof useGetWtiFromEmployeeQuery>;
export type GetWtiFromEmployeeLazyQueryHookResult = ReturnType<typeof useGetWtiFromEmployeeLazyQuery>;
export type GetWtiFromEmployeeQueryResult = Apollo.QueryResult<GetWtiFromEmployeeQuery, GetWtiFromEmployeeQueryVariables>;
export const SaveUserPolicySelectedInfoDocument = gql`
    mutation SaveUserPolicySelectedInfo($employeeId: String!, $workingTemplateIdx: Int!, $workingTimeName: String!, $dayOfWeek: [DAY_TYPE!]!, $startTime: String!, $endTime: String!, $startBreakTime: String!, $endBreakTime: String!) {
  saveUserPolicySelectedInfo(
    employeeId: $employeeId
    workingTemplateIdx: $workingTemplateIdx
    workingTimeName: $workingTimeName
    dayOfWeek: $dayOfWeek
    startTime: $startTime
    endTime: $endTime
    startBreakTime: $startBreakTime
    endBreakTime: $endBreakTime
  ) {
    ok
    error
  }
}
    `;
export type SaveUserPolicySelectedInfoMutationFn = Apollo.MutationFunction<SaveUserPolicySelectedInfoMutation, SaveUserPolicySelectedInfoMutationVariables>;

/**
 * __useSaveUserPolicySelectedInfoMutation__
 *
 * To run a mutation, you first call `useSaveUserPolicySelectedInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserPolicySelectedInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserPolicySelectedInfoMutation, { data, loading, error }] = useSaveUserPolicySelectedInfoMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      workingTimeName: // value for 'workingTimeName'
 *      dayOfWeek: // value for 'dayOfWeek'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      startBreakTime: // value for 'startBreakTime'
 *      endBreakTime: // value for 'endBreakTime'
 *   },
 * });
 */
export function useSaveUserPolicySelectedInfoMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserPolicySelectedInfoMutation, SaveUserPolicySelectedInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserPolicySelectedInfoMutation, SaveUserPolicySelectedInfoMutationVariables>(SaveUserPolicySelectedInfoDocument, options);
      }
export type SaveUserPolicySelectedInfoMutationHookResult = ReturnType<typeof useSaveUserPolicySelectedInfoMutation>;
export type SaveUserPolicySelectedInfoMutationResult = Apollo.MutationResult<SaveUserPolicySelectedInfoMutation>;
export type SaveUserPolicySelectedInfoMutationOptions = Apollo.BaseMutationOptions<SaveUserPolicySelectedInfoMutation, SaveUserPolicySelectedInfoMutationVariables>;
export const GetWidgetSettingInfoDocument = gql`
    query GetWidgetSettingInfo($workingTemplateIdx: Int!) {
  getWidgetSettingInfo(workingTemplateIdx: $workingTemplateIdx) {
    ok
    error
    info {
      workingTemplateIdx
      asonicWidgetUse
      asonicWidgetAutoStart
    }
  }
}
    `;

/**
 * __useGetWidgetSettingInfoQuery__
 *
 * To run a query within a React component, call `useGetWidgetSettingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWidgetSettingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWidgetSettingInfoQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *   },
 * });
 */
export function useGetWidgetSettingInfoQuery(baseOptions: Apollo.QueryHookOptions<GetWidgetSettingInfoQuery, GetWidgetSettingInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWidgetSettingInfoQuery, GetWidgetSettingInfoQueryVariables>(GetWidgetSettingInfoDocument, options);
      }
export function useGetWidgetSettingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWidgetSettingInfoQuery, GetWidgetSettingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWidgetSettingInfoQuery, GetWidgetSettingInfoQueryVariables>(GetWidgetSettingInfoDocument, options);
        }
export type GetWidgetSettingInfoQueryHookResult = ReturnType<typeof useGetWidgetSettingInfoQuery>;
export type GetWidgetSettingInfoLazyQueryHookResult = ReturnType<typeof useGetWidgetSettingInfoLazyQuery>;
export type GetWidgetSettingInfoQueryResult = Apollo.QueryResult<GetWidgetSettingInfoQuery, GetWidgetSettingInfoQueryVariables>;
export const AddWidgetSettingInfoDocument = gql`
    mutation AddWidgetSettingInfo($workingTemplateIdx: Int!, $asonicWidgetUse: WIDGET_SETTING_TYPE!, $asonicWidgetAutoStart: IS_USE!) {
  addWidgetSettingInfo(
    workingTemplateIdx: $workingTemplateIdx
    asonicWidgetUse: $asonicWidgetUse
    asonicWidgetAutoStart: $asonicWidgetAutoStart
  ) {
    ok
    error
  }
}
    `;
export type AddWidgetSettingInfoMutationFn = Apollo.MutationFunction<AddWidgetSettingInfoMutation, AddWidgetSettingInfoMutationVariables>;

/**
 * __useAddWidgetSettingInfoMutation__
 *
 * To run a mutation, you first call `useAddWidgetSettingInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWidgetSettingInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWidgetSettingInfoMutation, { data, loading, error }] = useAddWidgetSettingInfoMutation({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      asonicWidgetUse: // value for 'asonicWidgetUse'
 *      asonicWidgetAutoStart: // value for 'asonicWidgetAutoStart'
 *   },
 * });
 */
export function useAddWidgetSettingInfoMutation(baseOptions?: Apollo.MutationHookOptions<AddWidgetSettingInfoMutation, AddWidgetSettingInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWidgetSettingInfoMutation, AddWidgetSettingInfoMutationVariables>(AddWidgetSettingInfoDocument, options);
      }
export type AddWidgetSettingInfoMutationHookResult = ReturnType<typeof useAddWidgetSettingInfoMutation>;
export type AddWidgetSettingInfoMutationResult = Apollo.MutationResult<AddWidgetSettingInfoMutation>;
export type AddWidgetSettingInfoMutationOptions = Apollo.BaseMutationOptions<AddWidgetSettingInfoMutation, AddWidgetSettingInfoMutationVariables>;
export const GetListAllWorkingTemplateDocument = gql`
    query GetListAllWorkingTemplate($searchValue: String!, $searchType: WT_SEARCH_TYPE, $fieldSort: FieldSort) {
  getListAllWorkingTemplate(
    searchValue: $searchValue
    searchType: $searchType
    fieldSort: $fieldSort
  ) {
    ok
    error
    list {
      workingTemplateIdx
      wType
      name
      timeArray
      dayMaxWorkHour
      weekMaxWorkHour
      isLeftSeat
      leftSeatBaseMin
      leftSeatReasonUse
      leftSeatPlaceInput
      leftSeatBlackScreenUse
    }
  }
}
    `;

/**
 * __useGetListAllWorkingTemplateQuery__
 *
 * To run a query within a React component, call `useGetListAllWorkingTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListAllWorkingTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListAllWorkingTemplateQuery({
 *   variables: {
 *      searchValue: // value for 'searchValue'
 *      searchType: // value for 'searchType'
 *      fieldSort: // value for 'fieldSort'
 *   },
 * });
 */
export function useGetListAllWorkingTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetListAllWorkingTemplateQuery, GetListAllWorkingTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListAllWorkingTemplateQuery, GetListAllWorkingTemplateQueryVariables>(GetListAllWorkingTemplateDocument, options);
      }
export function useGetListAllWorkingTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListAllWorkingTemplateQuery, GetListAllWorkingTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListAllWorkingTemplateQuery, GetListAllWorkingTemplateQueryVariables>(GetListAllWorkingTemplateDocument, options);
        }
export type GetListAllWorkingTemplateQueryHookResult = ReturnType<typeof useGetListAllWorkingTemplateQuery>;
export type GetListAllWorkingTemplateLazyQueryHookResult = ReturnType<typeof useGetListAllWorkingTemplateLazyQuery>;
export type GetListAllWorkingTemplateQueryResult = Apollo.QueryResult<GetListAllWorkingTemplateQuery, GetListAllWorkingTemplateQueryVariables>;
export const GetWtBasicInfoDocument = gql`
    query GetWTBasicInfo($workingTemplateIdx: Int!) {
  getWTBasicInfo(workingTemplateIdx: $workingTemplateIdx) {
    ok
    error
    wTBasicInfo {
      workingTemplateIdx
      name
      workType
      otHoursTypeCategoryIdx
      dayMaxWorkHour
      weekMaxWorkHour
      weekMaxExtWorkHour
      mandatoryBreakTermHour
      mandatoryBreakMin
      dayOfWeekStart
      otHolidayTemplateNewAutoUse
      timeDifferenceMin
      periodUnitType
    }
  }
}
    `;

/**
 * __useGetWtBasicInfoQuery__
 *
 * To run a query within a React component, call `useGetWtBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWtBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWtBasicInfoQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *   },
 * });
 */
export function useGetWtBasicInfoQuery(baseOptions: Apollo.QueryHookOptions<GetWtBasicInfoQuery, GetWtBasicInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWtBasicInfoQuery, GetWtBasicInfoQueryVariables>(GetWtBasicInfoDocument, options);
      }
export function useGetWtBasicInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWtBasicInfoQuery, GetWtBasicInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWtBasicInfoQuery, GetWtBasicInfoQueryVariables>(GetWtBasicInfoDocument, options);
        }
export type GetWtBasicInfoQueryHookResult = ReturnType<typeof useGetWtBasicInfoQuery>;
export type GetWtBasicInfoLazyQueryHookResult = ReturnType<typeof useGetWtBasicInfoLazyQuery>;
export type GetWtBasicInfoQueryResult = Apollo.QueryResult<GetWtBasicInfoQuery, GetWtBasicInfoQueryVariables>;
export const GetWorkingTimeListDocument = gql`
    query GetWorkingTimeList {
  getWorkingTimeList {
    ok
    error
    list {
      otHoursTypeCategoryIdx
      typeName
      dayMaxWorkHour
      weekMaxWorkHour
      weekMaxExtWorkHour
    }
  }
}
    `;

/**
 * __useGetWorkingTimeListQuery__
 *
 * To run a query within a React component, call `useGetWorkingTimeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkingTimeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkingTimeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkingTimeListQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkingTimeListQuery, GetWorkingTimeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkingTimeListQuery, GetWorkingTimeListQueryVariables>(GetWorkingTimeListDocument, options);
      }
export function useGetWorkingTimeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkingTimeListQuery, GetWorkingTimeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkingTimeListQuery, GetWorkingTimeListQueryVariables>(GetWorkingTimeListDocument, options);
        }
export type GetWorkingTimeListQueryHookResult = ReturnType<typeof useGetWorkingTimeListQuery>;
export type GetWorkingTimeListLazyQueryHookResult = ReturnType<typeof useGetWorkingTimeListLazyQuery>;
export type GetWorkingTimeListQueryResult = Apollo.QueryResult<GetWorkingTimeListQuery, GetWorkingTimeListQueryVariables>;
export const DeleteWtBasicInfoDocument = gql`
    mutation DeleteWTBasicInfo($workingTemplateIdxList: [Int!]!) {
  deleteWTBasicInfo(workingTemplateIdxList: $workingTemplateIdxList) {
    ok
    error
  }
}
    `;
export type DeleteWtBasicInfoMutationFn = Apollo.MutationFunction<DeleteWtBasicInfoMutation, DeleteWtBasicInfoMutationVariables>;

/**
 * __useDeleteWtBasicInfoMutation__
 *
 * To run a mutation, you first call `useDeleteWtBasicInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWtBasicInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWtBasicInfoMutation, { data, loading, error }] = useDeleteWtBasicInfoMutation({
 *   variables: {
 *      workingTemplateIdxList: // value for 'workingTemplateIdxList'
 *   },
 * });
 */
export function useDeleteWtBasicInfoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWtBasicInfoMutation, DeleteWtBasicInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWtBasicInfoMutation, DeleteWtBasicInfoMutationVariables>(DeleteWtBasicInfoDocument, options);
      }
export type DeleteWtBasicInfoMutationHookResult = ReturnType<typeof useDeleteWtBasicInfoMutation>;
export type DeleteWtBasicInfoMutationResult = Apollo.MutationResult<DeleteWtBasicInfoMutation>;
export type DeleteWtBasicInfoMutationOptions = Apollo.BaseMutationOptions<DeleteWtBasicInfoMutation, DeleteWtBasicInfoMutationVariables>;
export const GetStandardWtBasicInfoDocument = gql`
    query GetStandardWTBasicInfo($workingTemplateIdx: Int!) {
  getStandardWTBasicInfo(workingTemplateIdx: $workingTemplateIdx) {
    ok
    error
    basicInfo {
      workingTemplateIdx
      startTime
      endTime
      workWeekType
      workWeekSelect
      dayOfWeekType
      dayOfWeek
      startBreakTime
      endBreakTime
    }
    extraDaySettingList {
      workingTemplateIdx
      timeSequence
      startTime
      endTime
      workWeekType
      workWeekSelect
      dayOfWeekType
      dayOfWeek
      breakTimeType
      startBreakTime
      endBreakTime
    }
  }
}
    `;

/**
 * __useGetStandardWtBasicInfoQuery__
 *
 * To run a query within a React component, call `useGetStandardWtBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStandardWtBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStandardWtBasicInfoQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *   },
 * });
 */
export function useGetStandardWtBasicInfoQuery(baseOptions: Apollo.QueryHookOptions<GetStandardWtBasicInfoQuery, GetStandardWtBasicInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStandardWtBasicInfoQuery, GetStandardWtBasicInfoQueryVariables>(GetStandardWtBasicInfoDocument, options);
      }
export function useGetStandardWtBasicInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStandardWtBasicInfoQuery, GetStandardWtBasicInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStandardWtBasicInfoQuery, GetStandardWtBasicInfoQueryVariables>(GetStandardWtBasicInfoDocument, options);
        }
export type GetStandardWtBasicInfoQueryHookResult = ReturnType<typeof useGetStandardWtBasicInfoQuery>;
export type GetStandardWtBasicInfoLazyQueryHookResult = ReturnType<typeof useGetStandardWtBasicInfoLazyQuery>;
export type GetStandardWtBasicInfoQueryResult = Apollo.QueryResult<GetStandardWtBasicInfoQuery, GetStandardWtBasicInfoQueryVariables>;
export const AddWtBasicInfoDocument = gql`
    mutation AddWTBasicInfo($name: String!, $workType: WORK_TYPE!, $dayMaxWorkHour: Int!, $weekMaxWorkHour: Int!, $weekMaxExtWorkHour: Int!, $mandatoryBreakTermHour: Int!, $mandatoryBreakMin: Int!, $dayOfWeekStart: DAY_TYPE!, $otHoursTypeCategoryIdx: Float, $timeDifferenceMin: Float, $periodUnitType: PERIOD_UNIT_TYPE) {
  addWTBasicInfo(
    name: $name
    workType: $workType
    dayMaxWorkHour: $dayMaxWorkHour
    weekMaxWorkHour: $weekMaxWorkHour
    weekMaxExtWorkHour: $weekMaxExtWorkHour
    mandatoryBreakTermHour: $mandatoryBreakTermHour
    mandatoryBreakMin: $mandatoryBreakMin
    dayOfWeekStart: $dayOfWeekStart
    otHoursTypeCategoryIdx: $otHoursTypeCategoryIdx
    timeDifferenceMin: $timeDifferenceMin
    periodUnitType: $periodUnitType
  ) {
    ok
    error
    workingTemplateIdx
  }
}
    `;
export type AddWtBasicInfoMutationFn = Apollo.MutationFunction<AddWtBasicInfoMutation, AddWtBasicInfoMutationVariables>;

/**
 * __useAddWtBasicInfoMutation__
 *
 * To run a mutation, you first call `useAddWtBasicInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWtBasicInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWtBasicInfoMutation, { data, loading, error }] = useAddWtBasicInfoMutation({
 *   variables: {
 *      name: // value for 'name'
 *      workType: // value for 'workType'
 *      dayMaxWorkHour: // value for 'dayMaxWorkHour'
 *      weekMaxWorkHour: // value for 'weekMaxWorkHour'
 *      weekMaxExtWorkHour: // value for 'weekMaxExtWorkHour'
 *      mandatoryBreakTermHour: // value for 'mandatoryBreakTermHour'
 *      mandatoryBreakMin: // value for 'mandatoryBreakMin'
 *      dayOfWeekStart: // value for 'dayOfWeekStart'
 *      otHoursTypeCategoryIdx: // value for 'otHoursTypeCategoryIdx'
 *      timeDifferenceMin: // value for 'timeDifferenceMin'
 *      periodUnitType: // value for 'periodUnitType'
 *   },
 * });
 */
export function useAddWtBasicInfoMutation(baseOptions?: Apollo.MutationHookOptions<AddWtBasicInfoMutation, AddWtBasicInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWtBasicInfoMutation, AddWtBasicInfoMutationVariables>(AddWtBasicInfoDocument, options);
      }
export type AddWtBasicInfoMutationHookResult = ReturnType<typeof useAddWtBasicInfoMutation>;
export type AddWtBasicInfoMutationResult = Apollo.MutationResult<AddWtBasicInfoMutation>;
export type AddWtBasicInfoMutationOptions = Apollo.BaseMutationOptions<AddWtBasicInfoMutation, AddWtBasicInfoMutationVariables>;
export const UpdateWtBasicInfoDocument = gql`
    mutation UpdateWTBasicInfo($name: String!, $workType: WORK_TYPE!, $dayMaxWorkHour: Int!, $weekMaxWorkHour: Int!, $weekMaxExtWorkHour: Int!, $mandatoryBreakTermHour: Int!, $mandatoryBreakMin: Int!, $dayOfWeekStart: DAY_TYPE!, $otHolidayTemplateNewAutoUse: IS_USE!, $otHoursTypeCategoryIdx: Float, $workingTemplateIdx: Int, $timeDifferenceMin: Float, $periodUnitType: PERIOD_UNIT_TYPE) {
  updateWTBasicInfo(
    name: $name
    workType: $workType
    dayMaxWorkHour: $dayMaxWorkHour
    weekMaxWorkHour: $weekMaxWorkHour
    weekMaxExtWorkHour: $weekMaxExtWorkHour
    mandatoryBreakTermHour: $mandatoryBreakTermHour
    mandatoryBreakMin: $mandatoryBreakMin
    dayOfWeekStart: $dayOfWeekStart
    otHolidayTemplateNewAutoUse: $otHolidayTemplateNewAutoUse
    otHoursTypeCategoryIdx: $otHoursTypeCategoryIdx
    workingTemplateIdx: $workingTemplateIdx
    timeDifferenceMin: $timeDifferenceMin
    periodUnitType: $periodUnitType
  ) {
    ok
    error
  }
}
    `;
export type UpdateWtBasicInfoMutationFn = Apollo.MutationFunction<UpdateWtBasicInfoMutation, UpdateWtBasicInfoMutationVariables>;

/**
 * __useUpdateWtBasicInfoMutation__
 *
 * To run a mutation, you first call `useUpdateWtBasicInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWtBasicInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWtBasicInfoMutation, { data, loading, error }] = useUpdateWtBasicInfoMutation({
 *   variables: {
 *      name: // value for 'name'
 *      workType: // value for 'workType'
 *      dayMaxWorkHour: // value for 'dayMaxWorkHour'
 *      weekMaxWorkHour: // value for 'weekMaxWorkHour'
 *      weekMaxExtWorkHour: // value for 'weekMaxExtWorkHour'
 *      mandatoryBreakTermHour: // value for 'mandatoryBreakTermHour'
 *      mandatoryBreakMin: // value for 'mandatoryBreakMin'
 *      dayOfWeekStart: // value for 'dayOfWeekStart'
 *      otHolidayTemplateNewAutoUse: // value for 'otHolidayTemplateNewAutoUse'
 *      otHoursTypeCategoryIdx: // value for 'otHoursTypeCategoryIdx'
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      timeDifferenceMin: // value for 'timeDifferenceMin'
 *      periodUnitType: // value for 'periodUnitType'
 *   },
 * });
 */
export function useUpdateWtBasicInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWtBasicInfoMutation, UpdateWtBasicInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWtBasicInfoMutation, UpdateWtBasicInfoMutationVariables>(UpdateWtBasicInfoDocument, options);
      }
export type UpdateWtBasicInfoMutationHookResult = ReturnType<typeof useUpdateWtBasicInfoMutation>;
export type UpdateWtBasicInfoMutationResult = Apollo.MutationResult<UpdateWtBasicInfoMutation>;
export type UpdateWtBasicInfoMutationOptions = Apollo.BaseMutationOptions<UpdateWtBasicInfoMutation, UpdateWtBasicInfoMutationVariables>;
export const AddStandardWtBasicInfoDocument = gql`
    mutation AddStandardWTBasicInfo($workingTemplateIdx: Int!, $startTime: String!, $endTime: String!, $workWeekType: WORK_WEEK_TYPE!, $workWeekSelect: [Int!]!, $dayOfWeekType: DAY_OF_WEEK_TYPE!, $dayOfWeek: [DAY_TYPE!]!, $startBreakTime: String!, $endBreakTime: String!, $otWorkingStandardHourList: [OT_WORKING_STANDARD_HOURS!]) {
  addStandardWTBasicInfo(
    workingTemplateIdx: $workingTemplateIdx
    startTime: $startTime
    endTime: $endTime
    workWeekType: $workWeekType
    workWeekSelect: $workWeekSelect
    dayOfWeekType: $dayOfWeekType
    dayOfWeek: $dayOfWeek
    startBreakTime: $startBreakTime
    endBreakTime: $endBreakTime
    otWorkingStandardHourList: $otWorkingStandardHourList
  ) {
    error
    ok
  }
}
    `;
export type AddStandardWtBasicInfoMutationFn = Apollo.MutationFunction<AddStandardWtBasicInfoMutation, AddStandardWtBasicInfoMutationVariables>;

/**
 * __useAddStandardWtBasicInfoMutation__
 *
 * To run a mutation, you first call `useAddStandardWtBasicInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStandardWtBasicInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStandardWtBasicInfoMutation, { data, loading, error }] = useAddStandardWtBasicInfoMutation({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      workWeekType: // value for 'workWeekType'
 *      workWeekSelect: // value for 'workWeekSelect'
 *      dayOfWeekType: // value for 'dayOfWeekType'
 *      dayOfWeek: // value for 'dayOfWeek'
 *      startBreakTime: // value for 'startBreakTime'
 *      endBreakTime: // value for 'endBreakTime'
 *      otWorkingStandardHourList: // value for 'otWorkingStandardHourList'
 *   },
 * });
 */
export function useAddStandardWtBasicInfoMutation(baseOptions?: Apollo.MutationHookOptions<AddStandardWtBasicInfoMutation, AddStandardWtBasicInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStandardWtBasicInfoMutation, AddStandardWtBasicInfoMutationVariables>(AddStandardWtBasicInfoDocument, options);
      }
export type AddStandardWtBasicInfoMutationHookResult = ReturnType<typeof useAddStandardWtBasicInfoMutation>;
export type AddStandardWtBasicInfoMutationResult = Apollo.MutationResult<AddStandardWtBasicInfoMutation>;
export type AddStandardWtBasicInfoMutationOptions = Apollo.BaseMutationOptions<AddStandardWtBasicInfoMutation, AddStandardWtBasicInfoMutationVariables>;
export const GetWtGraphDocument = gql`
    query GetWTGraph($workingTemplateIdx: Int!) {
  getWTGraph(workingTemplateIdx: $workingTemplateIdx) {
    ok
    error
    basicInfo {
      name
      workType
      dayMaxWorkHour
      weekMaxWorkHour
      dayOfWeekStart
    }
    weekDayOfWeekInfo {
      workWeekSelect
      dayOfWeek
      totHour
    }
  }
}
    `;

/**
 * __useGetWtGraphQuery__
 *
 * To run a query within a React component, call `useGetWtGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWtGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWtGraphQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *   },
 * });
 */
export function useGetWtGraphQuery(baseOptions: Apollo.QueryHookOptions<GetWtGraphQuery, GetWtGraphQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWtGraphQuery, GetWtGraphQueryVariables>(GetWtGraphDocument, options);
      }
export function useGetWtGraphLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWtGraphQuery, GetWtGraphQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWtGraphQuery, GetWtGraphQueryVariables>(GetWtGraphDocument, options);
        }
export type GetWtGraphQueryHookResult = ReturnType<typeof useGetWtGraphQuery>;
export type GetWtGraphLazyQueryHookResult = ReturnType<typeof useGetWtGraphLazyQuery>;
export type GetWtGraphQueryResult = Apollo.QueryResult<GetWtGraphQuery, GetWtGraphQueryVariables>;
export const GetWtDayGraphDocument = gql`
    query GetWTDayGraph($workingTemplateIdx: Int!, $dayOfWeek: DAY_TYPE!, $workWeekSelect: Int) {
  getWTDayGraph(
    workingTemplateIdx: $workingTemplateIdx
    dayOfWeek: $dayOfWeek
    workWeekSelect: $workWeekSelect
  ) {
    ok
    error
    dayGraph {
      startTime
      endTime
      startBreakTime
      endBreakTime
    }
  }
}
    `;

/**
 * __useGetWtDayGraphQuery__
 *
 * To run a query within a React component, call `useGetWtDayGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWtDayGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWtDayGraphQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      dayOfWeek: // value for 'dayOfWeek'
 *      workWeekSelect: // value for 'workWeekSelect'
 *   },
 * });
 */
export function useGetWtDayGraphQuery(baseOptions: Apollo.QueryHookOptions<GetWtDayGraphQuery, GetWtDayGraphQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWtDayGraphQuery, GetWtDayGraphQueryVariables>(GetWtDayGraphDocument, options);
      }
export function useGetWtDayGraphLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWtDayGraphQuery, GetWtDayGraphQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWtDayGraphQuery, GetWtDayGraphQueryVariables>(GetWtDayGraphDocument, options);
        }
export type GetWtDayGraphQueryHookResult = ReturnType<typeof useGetWtDayGraphQuery>;
export type GetWtDayGraphLazyQueryHookResult = ReturnType<typeof useGetWtDayGraphLazyQuery>;
export type GetWtDayGraphQueryResult = Apollo.QueryResult<GetWtDayGraphQuery, GetWtDayGraphQueryVariables>;
export const GetSelfDeterminedWhGraphDocument = gql`
    query GetSelfDeterminedWHGraph($workingTemplateIdx: Int!, $workingTimeName: String!) {
  getSelfDeterminedWHGraph(
    workingTemplateIdx: $workingTemplateIdx
    workingTimeName: $workingTimeName
  ) {
    ok
    error
    weekDayOfWeekInfo {
      dayOfWeek
      totHour
    }
  }
}
    `;

/**
 * __useGetSelfDeterminedWhGraphQuery__
 *
 * To run a query within a React component, call `useGetSelfDeterminedWhGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfDeterminedWhGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfDeterminedWhGraphQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      workingTimeName: // value for 'workingTimeName'
 *   },
 * });
 */
export function useGetSelfDeterminedWhGraphQuery(baseOptions: Apollo.QueryHookOptions<GetSelfDeterminedWhGraphQuery, GetSelfDeterminedWhGraphQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfDeterminedWhGraphQuery, GetSelfDeterminedWhGraphQueryVariables>(GetSelfDeterminedWhGraphDocument, options);
      }
export function useGetSelfDeterminedWhGraphLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfDeterminedWhGraphQuery, GetSelfDeterminedWhGraphQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfDeterminedWhGraphQuery, GetSelfDeterminedWhGraphQueryVariables>(GetSelfDeterminedWhGraphDocument, options);
        }
export type GetSelfDeterminedWhGraphQueryHookResult = ReturnType<typeof useGetSelfDeterminedWhGraphQuery>;
export type GetSelfDeterminedWhGraphLazyQueryHookResult = ReturnType<typeof useGetSelfDeterminedWhGraphLazyQuery>;
export type GetSelfDeterminedWhGraphQueryResult = Apollo.QueryResult<GetSelfDeterminedWhGraphQuery, GetSelfDeterminedWhGraphQueryVariables>;
export const GetSelfDeterminedDayGraphDocument = gql`
    query GetSelfDeterminedDayGraph($workingTemplateIdx: Int!, $workingTimeName: String!, $dayOfWeek: DAY_TYPE!) {
  getSelfDeterminedDayGraph(
    workingTemplateIdx: $workingTemplateIdx
    workingTimeName: $workingTimeName
    dayOfWeek: $dayOfWeek
  ) {
    ok
    error
    dayGraph {
      startTime
      endTime
      startBreakTime
      endBreakTime
    }
  }
}
    `;

/**
 * __useGetSelfDeterminedDayGraphQuery__
 *
 * To run a query within a React component, call `useGetSelfDeterminedDayGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfDeterminedDayGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfDeterminedDayGraphQuery({
 *   variables: {
 *      workingTemplateIdx: // value for 'workingTemplateIdx'
 *      workingTimeName: // value for 'workingTimeName'
 *      dayOfWeek: // value for 'dayOfWeek'
 *   },
 * });
 */
export function useGetSelfDeterminedDayGraphQuery(baseOptions: Apollo.QueryHookOptions<GetSelfDeterminedDayGraphQuery, GetSelfDeterminedDayGraphQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfDeterminedDayGraphQuery, GetSelfDeterminedDayGraphQueryVariables>(GetSelfDeterminedDayGraphDocument, options);
      }
export function useGetSelfDeterminedDayGraphLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfDeterminedDayGraphQuery, GetSelfDeterminedDayGraphQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfDeterminedDayGraphQuery, GetSelfDeterminedDayGraphQueryVariables>(GetSelfDeterminedDayGraphDocument, options);
        }
export type GetSelfDeterminedDayGraphQueryHookResult = ReturnType<typeof useGetSelfDeterminedDayGraphQuery>;
export type GetSelfDeterminedDayGraphLazyQueryHookResult = ReturnType<typeof useGetSelfDeterminedDayGraphLazyQuery>;
export type GetSelfDeterminedDayGraphQueryResult = Apollo.QueryResult<GetSelfDeterminedDayGraphQuery, GetSelfDeterminedDayGraphQueryVariables>;