import { useCallback, useState } from "react";

function usePageControl() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [take, setTake] = useState<number>(10);

  const handleCurrentPage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handleTake = useCallback((value: string) => {
    setCurrentPage(1);
    setTake(parseInt(value));
  }, []);

  return { currentPage, handleCurrentPage, take, handleTake };
}

export default usePageControl;
