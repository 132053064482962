import styled from "styled-components";

const RadioContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: 5px;
  label {
    display: flex;
    min-width: max-content;
  }
`;

export default RadioContainer;
