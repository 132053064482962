import CollapsiblePanel from "../../../smallcomponents/CollapsiblePanel";
import { Container, ContentContainer, DvideContentContainer } from "./styled";
import WebMenuSettings from "../web-menu-settings";
import MobileMenuSettings from "../mobile-menu-setting";
import AsoicWidgetSetting from "../asonic-widget-setting";
import { useCallback, useEffect, useState } from "react";
import menuSettingsApi from "../../../../../api/menu-settings-api";

type Props = {
  title: string;
};

enum OT_USE_FUNCTION {
  WidgetBox = 16
}

const MenuSettings = (props: Props) => {
  const { title } = props;
  const [isWidgetBox, setIsWidgetBox] = useState(false);

  const handleWidgetBox = useCallback(() => {
    menuSettingsApi.getIsWidgetBox().then(({ data }) => {
      if (data.ok) {
        setIsWidgetBox(Boolean(data.otUseFunction & OT_USE_FUNCTION.WidgetBox));
      }
    });
  }, []);

  useEffect(() => {
    handleWidgetBox();
  }, [handleWidgetBox]);
  return (
    <CollapsiblePanel title={title}>
      <Container>
        <ContentContainer>
          <DvideContentContainer>
            <WebMenuSettings />
            <MobileMenuSettings />
            {isWidgetBox && <AsoicWidgetSetting />}
          </DvideContentContainer>
        </ContentContainer>
      </Container>
    </CollapsiblePanel>
  );
};

export default MenuSettings;
