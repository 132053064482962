import { createContext } from "react";
import { ApprovalLineEntity } from "../../../generated/graphql";
import { IEmployeeInformation } from "../approval-process/list-of-selected-approval-user";

interface IApprovalLineContext {
  selectedPersonalApprovalLine?: ApprovalLineEntity;
  handlePersonalApprovalLine?: (payload?: ApprovalLineEntity) => void;
  initListOfUser?: () => void;
  handleLoadListOfPersonalApprovalLine: (data: IEmployeeInformation[]) => void;
  selectedApprovalLine?: ApprovalLineEntity;
  handleSelectedApprovalLine: (item: ApprovalLineEntity) => void;
}

const ApprovalLineContext = createContext<IApprovalLineContext | undefined>(
  undefined
);

export default ApprovalLineContext;
