import TColumnComponent, { ITColumnComponentProps } from "./t_column_component";
import TBody from "./t_body";
import THead from "./t_head";
import THeaderRow from "./t_header_row";
import TLayout from "./t_layout";
import TMainContainer from "./t_main_container";
import * as ReactTable from "react-table";
import TRenderRow, { TRenderRowProps } from "./t_render_row";
import ReactLoading from "react-loading";
import { colors } from "../GlobalStyle/GlobalStyle";
import styled from "styled-components";

export interface ITableProps<P extends object>
  extends Partial<ITColumnComponentProps<P>>,
    Partial<TRenderRowProps<P>> {
  title: string;
  table: ReactTable.TableInstance<P>;
  height: number;
  width: number;
  loading?: boolean;
}

const Content = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

function TableV2<P extends object>({
  table,
  title,
  fieldSort,
  handleFieldSort,
  listOfColumnDisabled,
  moveColumn,
  selectedRow,
  height,
  width,
  handleSelectRow,
  handleContextMenu,
  loading,
  isCustomSelect
}: ITableProps<P>) {
  return (
    <TLayout $height={height} $width={width}>
      <TMainContainer {...table.getTableProps()} className="table sticky">
        <THead className="header">
          {table.headerGroups.map(headerGroup => (
            <THeaderRow {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column, index) => (
                <TColumnComponent<P>
                  key={column.id}
                  column={column}
                  index={index}
                  fieldSort={fieldSort}
                  handleFieldSort={handleFieldSort}
                  listOfColumnDisabled={listOfColumnDisabled}
                  moveColumn={moveColumn}
                  title={title}
                />
              ))}
            </THeaderRow>
          ))}
        </THead>
        <TBody {...table.getTableBodyProps()} className="body">
          {loading ? (
            <Content>
              <ReactLoading
                type="spokes"
                color={colors.green}
                height={200}
                width={170}
              />
            </Content>
          ) : (
            table.rows.map(item => {
              return (
                <TRenderRow
                  key={item.id}
                  prepareRow={table.prepareRow}
                  handleSelectRow={handleSelectRow}
                  handleContextMenu={handleContextMenu}
                  selectedRow={selectedRow}
                  row={item}
                  data={table.rows}
                  isCustomSelect={isCustomSelect}
                />
              );
            })
          )}
        </TBody>
      </TMainContainer>
    </TLayout>
  );
}

export default TableV2;
