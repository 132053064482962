import styled from "styled-components";
import useAimMultiTab from "../../../../../hooks/additional-information-management-hooks/use-aim-multi-tab";
import MultiTab from "../../../../multi-tab";
import UserDailyWork from "./user_daily_work";
import UserMonthlyWork from "./user_monthly_work";

interface IProps {}

const Container = styled.div`
  display: flex;
  flex: 10;
  flex-direction: column;
`;

const Contents = styled.div`
  display: flex;
  flex: 10;
`;

const TitleBar = styled.div`
  display: flex;
  border-bottom: 4px solid #bdc3c7;
  margin: 20px 0px;
`;

const WorkTimeInformation = <P extends IProps>(props: P) => {
  const { list, selectedItem, handleSelected } = useAimMultiTab({
    menuList: [
      { name: "일별근무", isSelected: true },
      { name: "월별근무", isSelected: false }
    ]
  });

  return (
    <Container>
      <TitleBar>
        <MultiTab list={list} handleSelected={handleSelected} />
      </TitleBar>
      <Contents>
        <UserDailyWork isVisibility={selectedItem?.name === "일별근무"} />
        <UserMonthlyWork isVisibility={selectedItem?.name === "월별근무"} />
      </Contents>
    </Container>
  );
};

export default WorkTimeInformation;
