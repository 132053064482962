import { useCallback, useState } from "react";
import {
  AddAbsenceManagementInfoMutationVariables,
  AddAutonomyWorkingHourInfoMutationVariables,
  AddCommuteRecordInfoMutationVariables,
  AddControlNotificationMutationVariables,
  AddEtcOperatingHoursMutationVariables,
  AddSelfDeterminedWorkHoursMutationVariables,
  AddWidgetSettingInfoMutationVariables,
  Is_Use,
  OtWorkingFlexibleBasicEntity,
  OtWorkingFlexibleHoursEntity,
  OtWorkingStandardHoursEntity,
  WtBasicInfoEntity
} from "../../generated/graphql";

function useAsonicWorkingSystem() {
  const [weekTotalWorkHour, setWeekTotalWorkHour] = useState(0);
  const [workingTemplateIdx, setWorkingTemplateIdx] = useState<
    number | undefined
  >();
  const handleWorkingTemplateIdx = useCallback((id: number) => {
    setWorkingTemplateIdx(id);
  }, []);

  const [wSBasicInfo, setWSBasicInfo] = useState<WtBasicInfoEntity>();
  const handleWSBasicInfo = useCallback((payload: WtBasicInfoEntity) => {
    setWSBasicInfo(payload);
  }, []);

  const [isSelfBasicWorkType, setIsSelfBasicWorkType] =
    useState<boolean>(false);
  const [otHolidayTemplateNewAutoUse, setOtHolidayTemplateNewAutoUse] =
    useState<Is_Use>(Is_Use.UnUse);

  const handleOtHolidayTemplateNewAutoUse = useCallback((value: Is_Use) => {
    setOtHolidayTemplateNewAutoUse(value);
  }, []);

  const handleIsSelfBasicWorkType = useCallback((type: boolean) => {
    setIsSelfBasicWorkType(type);
  }, []);

  const [otWorkingStandardHours, setOtWorkingStandardHours] =
    useState<OtWorkingStandardHoursEntity>();

  const handleOtWorkingStandardHours = useCallback(
    (payload: OtWorkingStandardHoursEntity) => {
      setOtWorkingStandardHours(payload);
    },
    []
  );

  const [otWorkingStandardHourList, setOtWorkingStandardHourList] = useState<
    OtWorkingStandardHoursEntity[]
  >([]);
  const handleOtWorkingStandardHourList = useCallback(
    (list: OtWorkingStandardHoursEntity[]) => {
      setOtWorkingStandardHourList(list);
    },
    []
  );

  const [otWorkingFlexibleBasic, setOtWorkingFlexibleBasic] =
    useState<OtWorkingFlexibleBasicEntity>();
  const [otWorkingFlexibleHours, setOtWorkingFlexibleHours] = useState<
    OtWorkingFlexibleHoursEntity[]
  >([]);

  const [selfDeterminedWorkHourBasicInfo, setSelfDeterminedWorkHourBasicInfo] =
    useState<AddSelfDeterminedWorkHoursMutationVariables>();

  const [workingTimeName, setWorkingTimeName] = useState<string[]>([]);

  const [autonomyWorkingHourInfo, setAutonomyWorkingHourInfo] =
    useState<AddAutonomyWorkingHourInfoMutationVariables>();

  const [commuteRecordInfo, setCommuteRecordInfo] =
    useState<AddCommuteRecordInfoMutationVariables>();

  const [controlNotiInfo, setControlNotInfo] =
    useState<AddControlNotificationMutationVariables>();

  const [absenceManagementInfo, setAbsenceManagementInfo] =
    useState<AddAbsenceManagementInfoMutationVariables>();

  const [widgetSettingInfo, setWidgetSettingInfo] =
    useState<AddWidgetSettingInfoMutationVariables>();

  const [etcOperatingHours, setEtcOperatingHours] =
    useState<AddEtcOperatingHoursMutationVariables>();

  return {
    workingTemplateIdx,
    handleWorkingTemplateIdx,
    wSBasicInfo,
    handleWSBasicInfo,
    isSelfBasicWorkType,
    otHolidayTemplateNewAutoUse,
    handleOtHolidayTemplateNewAutoUse,
    handleIsSelfBasicWorkType,
    otWorkingStandardHours,
    handleOtWorkingStandardHours,
    otWorkingStandardHourList,
    handleOtWorkingStandardHourList,
    otWorkingFlexibleBasic,
    setOtWorkingFlexibleBasic,
    otWorkingFlexibleHours,
    setOtWorkingFlexibleHours,
    selfDeterminedWorkHourBasicInfo,
    setSelfDeterminedWorkHourBasicInfo,
    workingTimeName,
    setWorkingTimeName,
    autonomyWorkingHourInfo,
    setAutonomyWorkingHourInfo,
    commuteRecordInfo,
    setCommuteRecordInfo,
    controlNotiInfo,
    setControlNotInfo,
    absenceManagementInfo,
    setAbsenceManagementInfo,
    widgetSettingInfo,
    setWidgetSettingInfo,
    etcOperatingHours,
    setEtcOperatingHours,
    weekTotalWorkHour,
    setWeekTotalWorkHour
  };
}

export default useAsonicWorkingSystem;
