import SectionContainer from "../shared/section_container/section_container";
import AsonicTab from "../shared/asonic-tab";
import useAsonicTab, {
  ITabs,
  KTabs
} from "../../hooks/hooks-shared/use-asonic-tab";
import { useContext, useEffect, useMemo } from "react";
import MainContainer from "../shared/main_container/main_container";
import WorkTimeTab from "./work_time_tab/work_time_tab";
import ControlNotiSetting from "./control_noti_setting/control_noti_setting";
import AbsenceManagement from "./absence_management/absence_management";
import WidgetSetting from "./widget_setting/widget_setting";
import RecordingTime from "./recording_time/recording_time";
import EtcOperatingTime from "./etc_operating_time/etc_operating_time";
import { Work_Type } from "../../generated/graphql";
import {
  SELECTED_SUB_TAB,
  WS_STATE
} from "../../screens/asonic-working-system.screen";

interface IProps {
  workType: Work_Type;
}

function WSTabs({ workType }: IProps) {
  const wsState = useContext(WS_STATE);
  const tabs: ITabs = useMemo(() => {
    return {
      WORKING_TIME: "근무시간",
      RECORDING_TIME: "출퇴근기록",
      CONTROL_ALARM: "제어(알림)",
      LEAVE_WORK_MANAGEMENT: "이석관리",
      ETC_OPERATING_TIME: "기타운영시간",
      WIDGET_SETTING: "위젯설정"
    };
  }, []);

  const { list, selectedTab, handleSelectTab } = useAsonicTab<ITabs, KTabs>({
    tabs
  });

  useEffect(() => {
    if (wsState?.setSelectedSubTab) {
      wsState?.setSelectedSubTab(selectedTab as SELECTED_SUB_TAB);
    }
  }, [wsState?.setSelectedSubTab, selectedTab]);

  return (
    <MainContainer flex={10}>
      <AsonicTab<string>
        list={list}
        selectedTab={selectedTab}
        handleSelectTab={handleSelectTab}
      />
      <SectionContainer flex={10}>
        {selectedTab === "WORKING_TIME" && <WorkTimeTab workType={workType} />}
        {selectedTab === "RECORDING_TIME" && (
          <RecordingTime workType={workType} />
        )}
        {selectedTab === "CONTROL_ALARM" && <ControlNotiSetting />}
        {selectedTab === "LEAVE_WORK_MANAGEMENT" && <AbsenceManagement />}
        {selectedTab === "ETC_OPERATING_TIME" && <EtcOperatingTime />}
        {selectedTab === "WIDGET_SETTING" && <WidgetSetting />}
      </SectionContainer>
    </MainContainer>
  );
}

export default WSTabs;
