import * as ReactTable from "react-table";
import TData from "./t_data";
import TRow from "./t_row";
import { colors } from "../GlobalStyle/GlobalStyle";
import { useCallback } from "react";

export interface IRow<P extends {}> extends ReactTable.Row<P> {
  isSelected?: boolean;
  toggleRowSelected?: (set: boolean) => void;
}
export interface TRenderRowProps<P extends {}> {
  prepareRow: (row: IRow<P>) => void;
  handleSelectRow?: (row?: IRow<P>) => void;
  handleContextMenu?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  selectedRow?: IRow<P>;
  flexIndex?: number;
  isCustomSelect?: boolean;
  row: IRow<P>;
  data: IRow<P>[];
}

function TRenderRow<P extends {}>({
  prepareRow,
  handleSelectRow,
  handleContextMenu,
  selectedRow,
  flexIndex,
  row,
  data,
  isCustomSelect = false
}: TRenderRowProps<P>) {
  prepareRow(row);
  const multiSelect = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!isCustomSelect) {
        const contextButton = 2;
        if (event.type === "contextmenu" || event.button === contextButton) {
          event.preventDefault();
        }
        if (row.toggleRowSelected) {
          if (
            row.isSelected &&
            (event.type !== "contextmenu" || event.button !== contextButton)
          ) {
            data.forEach(item => {
              if (item.isSelected && item.toggleRowSelected) {
                item.toggleRowSelected(false);
              }
            });
            row.toggleRowSelected(false);
          } else if (selectedRow && (event.metaKey || event.ctrlKey)) {
            row.toggleRowSelected(true);
            if (
              (event.type === "contextmenu" ||
                event.button === contextButton) &&
              handleContextMenu
            ) {
              handleContextMenu(event);
            }
          } else if (selectedRow && event.shiftKey) {
            data.forEach(item => {
              if (
                parseInt(selectedRow.id) < parseInt(item.id) &&
                parseInt(item.id) <= parseInt(row.id) &&
                item.toggleRowSelected
              ) {
                item.toggleRowSelected(true);
                if (
                  (event.type === "contextmenu" ||
                    event.buttons === contextButton) &&
                  handleContextMenu
                ) {
                  handleContextMenu(event);
                } else if (handleSelectRow) {
                  handleSelectRow(row);
                }
              }
              if (
                parseInt(item.id) < parseInt(selectedRow.id) &&
                parseInt(row.id) <= parseInt(item.id) &&
                item.toggleRowSelected
              ) {
                item.toggleRowSelected(true);
                if (
                  (event.type === "contextmenu" ||
                    event.buttons === contextButton) &&
                  handleContextMenu
                ) {
                  handleContextMenu(event);
                }
              }
              if (
                (event.type !== "contextmenu" ||
                  event.buttons !== contextButton) &&
                handleSelectRow
              ) {
                handleSelectRow(row);
              }
            });
          } else {
            if (
              (event.type === "contextmenu" ||
                event.buttons === contextButton) &&
              handleContextMenu
            ) {
              if (row.isSelected) {
                handleContextMenu(event);
              } else {
                data.forEach(item => {
                  if (item.isSelected && item.toggleRowSelected) {
                    item.toggleRowSelected(false);
                  }
                });
                row.toggleRowSelected(true);
                handleContextMenu(event);
              }
            } else {
              if (selectedRow) {
                data.forEach(item => {
                  if (item.isSelected && item.toggleRowSelected) {
                    item.toggleRowSelected(false);
                  }
                });
              }
              row.toggleRowSelected(true);
              if (handleSelectRow) {
                handleSelectRow(row);
              }
            }
          }
        }
      }
    },
    [row, handleContextMenu, selectedRow, isCustomSelect]
  );
  return (
    <TRow {...row.getRowProps()} className="tr">
      {row.cells.map((cell, rowIndex) => (
        <TData
          className="td"
          {...cell.getCellProps()}
          key={`${row.id} ${Math.random() * 10}`}
          id={`${row.id}-context-menu`}
          onContextMenu={multiSelect}
          onClick={multiSelect}
          $isFlex={flexIndex === rowIndex}
          $isLastFlex={flexIndex && flexIndex >= 0 ? false : true}
          $isSelected={row.isSelected}
        >
          {cell.render("Cell")}
        </TData>
      ))}
    </TRow>
  );
}

export default TRenderRow;
