import SectionContainer from "../../shared/section_container/section_container";
import DisplayMonthGraph from "../../time_graph/display_month_graph/display_month_graph";
import GraphBasicInfo from "../graph_basic_info/graph_basic_info";
import styled from "styled-components";
import { TitleContainer } from "../../main-view/styled";
import SubTitle from "../../shared/sub-title/sub-title";
import FBWorkHourGraph from "../flexible_work_graph/fb_work_hour_graph/fb_work_hour_graph";
import useWorkingTemplateGraph from "../../../hooks/working_system_hooks/use_working_template_graph/use_working_template_graph";
import {
  Day_Type,
  Work_Type,
  WtGraphBasicEntity,
  WtGraphWeekEntity,
  useGetWtGraphLazyQuery
} from "../../../generated/graphql";
import * as ReactTable from "react-table";
import { WorkingTemplateEntityColumn } from "../../../screens/working_system.screen";
import { useEffect, useMemo, useState } from "react";
import handleApolloErrorLog from "../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../Utils/handle_error_log/handle_error_log";
// import StdBasicInfo from "./std_basic_info";
// import StdWorkDaysGraph from "./std_workdays_graph";
// import StdWorkHourGraph from "./std_work_hour_graph";

interface IProps {
  workingTemplateIdx?: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  border: 3px solid ${props => props.theme.colors.frameGrey};
  padding: 20px 20px;
  justify-content: center;
  user-select: none;
`;

function StdWorkHour({ workingTemplateIdx }: IProps) {
  const [selectedDayIndex, setSelectedDayIndex] = useState<number>();
  const [basicInfo, setBasicInfo] = useState<WtGraphBasicEntity>();
  const [weekDayOfWeekInfo, setWeekDayOfWeekInfo] = useState<
    WtGraphWeekEntity[]
  >([]);

  const [getWTGraph] = useGetWtGraphLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (data.getWTGraph.ok) {
        if (data.getWTGraph.basicInfo) {
          setBasicInfo(data.getWTGraph.basicInfo);
        }
        if (data.getWTGraph.weekDayOfWeekInfo) {
          setWeekDayOfWeekInfo(data.getWTGraph.weekDayOfWeekInfo);
        }
      } else if (!data.getWTGraph.ok && data.getWTGraph.error) {
        handleErrorLog(data.getWTGraph.error);
      }
    }
  });

  const { month } = useWorkingTemplateGraph({
    weekDayOfWeekInfo,
    workType: Work_Type.SFCommutingPolicy,
    selectedDayIndex
  });

  const selectedDay = useMemo(() => {
    if (selectedDayIndex !== undefined && workingTemplateIdx) {
      const workWeekSelect = Math.ceil((selectedDayIndex + 1) / 7);
      let dayOfWeek = Day_Type.Mon;
      switch (month[selectedDayIndex].title) {
        case "화":
          dayOfWeek = Day_Type.Tue;
          break;
        case "수":
          dayOfWeek = Day_Type.Wed;
          break;
        case "목":
          dayOfWeek = Day_Type.Thr;
          break;
        case "금":
          dayOfWeek = Day_Type.Fri;
          break;
        case "토":
          dayOfWeek = Day_Type.Sat;
          break;
        case "일":
          dayOfWeek = Day_Type.Sun;
          break;
      }
      const newDay = {
        workWeekSelect,
        dayOfWeek: dayOfWeek,
        workingTemplateIdx: workingTemplateIdx
      };
      return newDay;
    }
  }, [selectedDayIndex, month, workingTemplateIdx]);

  useEffect(() => {
    if (workingTemplateIdx) {
      getWTGraph({
        variables: {
          workingTemplateIdx
        }
      });
    }
  }, [workingTemplateIdx]);

  return (
    <SectionContainer justifyContent="space-around">
      {/* <StdBasicInfo />
      <StdWorkHourGraph />
      <StdWorkDaysGraph /> */}
      <GraphBasicInfo title="표준" basicInfo={basicInfo} />
      <Container>
        <TitleContainer>
          <SubTitle title="근무시간 그래프" />
        </TitleContainer>
        <ContentContainer>
          <DisplayMonthGraph
            month={month}
            setSelectedDayIndex={setSelectedDayIndex}
          />
          <FBWorkHourGraph selectedDay={selectedDay} />
        </ContentContainer>
      </Container>
    </SectionContainer>
  );
}

export default StdWorkHour;
