import styled from "styled-components";
import CompactTable from "../../compact-table/compact-table";
import * as ReactTable from "react-table";
import { Fragment, useCallback, useContext, useMemo } from "react";
import { ICompactRow } from "../../compact-table/compact-table-render-row";
import {
  ApprovalLineControlType,
  ApprovalLineUserEntity,
  Approval_User_Type,
  EditApprovalLineMutationVariables,
  ListOfApprovalUser,
  useEditApprovalLineMutation
} from "../../../generated/graphql";
import { Cell } from "../../../../types/@react-table/react-table/react-table";
import ApprovalProcessSelect from "./approval-process-select";
import CheckBoxUi from "../../globalComponents/CheckBoxUi";
import PositionController, { TPositionControl } from "./position-controller";
import Button from "../../globalComponents/Button";
import AsonicDialog from "../../asonic-dialog/asonic-dialog";
import useOpenDialog from "../../../hooks/use-open-dialog";
import { IApprovalLineManagementTabs } from "../approval-setting/approval-process-management/approval-process-management";
import ApprovalLineContext from "../approval-setting/approval-line-context";
import ConfirmDialog from "../../confirm-dialog/confirm-dialog";
import useConfirmDialog from "../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import ToastMessage, { MessageTypes } from "../../toast-message/toast-message";
import AddApprovalLineDialog from "./add-approval-line-dialog";
import useOpenToastMessage from "../../../hooks/toast-message-hook/use-open-toast-message";
import { useSelector } from "react-redux";
import { Reducers } from "../../../../types/reducers";

export interface IEmployeeInformation extends Partial<ListOfApprovalUser> {
  employeeId: string;
  name: string;
  userPosition: string;
  department: string;
  approvalType: Approval_User_Type;
  checked?: boolean;
}
interface IProps {
  selectedTab: IApprovalLineManagementTabs;
  listOfUser: IEmployeeInformation[];
  handleAddedUser: (user: string) => void;
  handleChangeUserInfoInListOfApprovalUser: (
    payload: IEmployeeInformation
  ) => void;
  listOfAddedUser: string[];
  handleAllAddedUser: (users: string[]) => void;
  handleChangeTheOrderUser: (type: TPositionControl) => void;
}

const COLUMN_FOR_VALUE = {
  checked: "선택된 결재자",
  approvalType: "구분",
  name: "결재자정보"
} as const;

type TYPE_OF_EMPLOYEE = keyof typeof COLUMN_FOR_VALUE;

const Container = styled.div`
  display: flex;
  flex: 3;
  gap: 10px;
  padding-top: 32px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const TableContainer = styled.div`
  display: flex;
  flex: 3;
  border-left: 1px solid ${props => props.theme.colors.grey};
  border-bottom: 1px solid ${props => props.theme.colors.grey};
  border-right: 1px solid ${props => props.theme.colors.grey};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

function ListOfSelectedApprovalUser({
  selectedTab,
  listOfUser,
  handleAddedUser,
  handleChangeUserInfoInListOfApprovalUser,
  listOfAddedUser,
  handleAllAddedUser,
  handleChangeTheOrderUser
}: IProps) {
  const store = useContext(ApprovalLineContext);
  const {
    signInReducer: { employee_id }
  } = useSelector((state: Reducers) => state);

  const { isOpen: isOpenDialog, handleOpenDialog } = useOpenDialog();

  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const [editApprovalLine, { client }] = useEditApprovalLineMutation({
    onError(error) {
      handleToast("선택된 결재선을 변경하지 못했습니다.", MessageTypes.ERROR);
      handleOpenDialog(false);
    },
    update(_, { data }, { variables }) {
      if (data?.editApprovalLine.ok && variables) {
        client.resetStore();
        handleToast(
          `선택된 결재선을 결재자 정보들을 성공적으로 변경하였습니다.`,
          MessageTypes.SUCCESS
        );
      } else if (data?.editApprovalLine.error) {
        handleToast(data?.editApprovalLine.error, MessageTypes.ERROR);
      }
      handleOpenDialog(false);
    }
  });

  const columns: ReactTable.Column<IEmployeeInformation>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    const newListOfColumn: ReactTable.Column<IEmployeeInformation>[] =
      listOfColumn.map(item => {
        let width = 50;
        if (
          COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] === COLUMN_FOR_VALUE.name
        ) {
          width = 200;
        }
        return {
          Header(header) {
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
              COLUMN_FOR_VALUE.checked
            ) {
              return (
                <CheckBoxUi
                  checked={
                    header.data.length > 0 &&
                    header.data.length === listOfAddedUser.length
                  }
                  htmlFor={COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE]}
                  onChange={() => {
                    const newData: string[] = header.data.map(
                      (item: IEmployeeInformation) => item.employeeId
                    );
                    handleAllAddedUser(newData);
                  }}
                />
              );
            }
            return COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE];
          },
          accessor: item as TYPE_OF_EMPLOYEE,
          Cell(cell: Cell<IEmployeeInformation>) {
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
              COLUMN_FOR_VALUE.name
            ) {
              const { name, userPosition, department } = cell.row.original;
              let result = `${name} / ${department}`;
              if (userPosition.trim()) {
                result = `${name} / ${userPosition} / ${department}`;
              }
              return result;
            }
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
              COLUMN_FOR_VALUE.approvalType
            ) {
              return (
                <ApprovalProcessSelect
                  cell={cell}
                  handleChangeUserInfoInListOfApprovalUser={
                    handleChangeUserInfoInListOfApprovalUser
                  }
                />
              );
            }
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
              COLUMN_FOR_VALUE.checked
            ) {
              return (
                <CheckBoxUi
                  checked={
                    !!listOfAddedUser.find(
                      item => item === cell.row.original.employeeId
                    )
                  }
                  htmlFor={cell.value}
                  onChange={() => {
                    handleAddedUser(cell.row.original.employeeId);
                  }}
                  name={cell.value}
                />
              );
            }
          },
          width
        };
      });
    return newListOfColumn;
  }, [
    handleChangeUserInfoInListOfApprovalUser,
    listOfAddedUser,
    handleAddedUser,
    handleAllAddedUser
  ]);

  const userData: IEmployeeInformation[] = useMemo(() => {
    return listOfUser.map(item => {
      return {
        employeeId: item.employeeId,
        approvalType: item.approvalType,
        name: item.name,
        department: item.department,
        userPosition: item?.userPosition ?? ""
      };
    });
  }, [listOfUser]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = ReactTable.useTable<any>(
    {
      columns,
      data: userData
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const selectedRow: ICompactRow<IEmployeeInformation> | undefined =
    useMemo(() => {
      if (selectedFlatRows.length > 0) {
        return selectedFlatRows[selectedFlatRows.length - 1];
      }
      return;
    }, [selectedFlatRows]);

  const handleEditApprovalLine = useCallback(() => {
    if (store?.selectedApprovalLine) {
      const newList: ApprovalLineUserEntity[] = userData?.map((item, index) => {
        return {
          employeeid: item.employeeId,
          order: index + 1,
          type: item.approvalType
        };
      });
      const payload: EditApprovalLineMutationVariables = {
        approvalLineControlType: ApprovalLineControlType.Edit,
        user: employee_id,
        approvalLineName: store.selectedApprovalLine.newTitle,
        isDefaultApprovalLine: store.selectedApprovalLine.basicLine,
        count: newList.length,
        list: newList,
        lineIdx: store.selectedApprovalLine.lineIdx
      };
      editApprovalLine({ variables: payload });
    }
  }, [editApprovalLine, userData]);

  const handleConfirm = useCallback(() => {
    handleEditApprovalLine();
    handleIsOpen(false);
  }, [handleIsOpen, handleEditApprovalLine]);

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  return (
    <Container>
      <Content>
        <TableContainer>
          <CompactTable
            title="결재자 정보 목록"
            isLoading={false}
            prepareRow={prepareRow}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            rows={rows}
            selectedRow={selectedRow}
            isTitleBar={false}
          />
        </TableContainer>
        <ButtonContainer>
          {selectedTab ===
            IApprovalLineManagementTabs.PERSONAL_APPROVAL_PROCESS &&
            store?.selectedApprovalLine && (
              <Fragment>
                {store?.selectedPersonalApprovalLine && (
                  <Button
                    disabled={userData.length < 1}
                    onClick={() => {
                      handleConfirmMessage({
                        title: "저장",
                        p: "설정 내역을 저장하시겠습니까?",
                        messageTypes: MessageTypes.INFO
                      });
                      handleIsOpen(true);
                    }}
                  >
                    저장
                  </Button>
                )}
              </Fragment>
            )}
          <Button
            disabled={userData.length < 1}
            onClick={() => {
              handleOpenDialog(true);
            }}
          >
            새로저장
          </Button>
        </ButtonContainer>
      </Content>
      <PositionController handleChangeTheOrder={handleChangeTheOrderUser} />
      {isOpenDialog && (
        <AsonicDialog
          title={"개인결재선"}
          handleClose={(value: boolean) => {
            handleOpenDialog(value);
          }}
          width="420px"
          minWidth="420px"
          height="200px"
          minHeight="200px"
        >
          <AddApprovalLineDialog
            handleOpenDialog={handleOpenDialog}
            userData={userData}
            handleToast={handleToast}
          />
        </AsonicDialog>
      )}
      {isOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.INFO}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ListOfSelectedApprovalUser;
