import { useCallback, useState } from "react";
import styled from "styled-components";
import useDateTime from "../../../../../../hooks/detail-view-hook/use-date-time";
import AsonicDialog from "../../../../../asonic-dialog/asonic-dialog";
import DateWithTimeContainer from "../../../../../DateWithTime/DateWithTimeContainer";
import Button from "../../../../../globalComponents/Button";
import { MessageTypes } from "../../../../../toast-message/toast-message";
import {
  Device_Type,
  CommuteType,
  useAddCommuteDateTimeMutation,
  CommuteManagement
} from "../../../../../../generated/graphql";

type SelectOnchange = React.ChangeEvent<HTMLSelectElement>;

interface IProps {
  handleClose: (value: boolean) => void;
  selectedRow?: CommuteManagement;
  handleIsOpenToast: (value: boolean) => void;
  handleMessage: (value: string) => void;
  handleToastMessageType: (value: MessageTypes) => void;
  listIdForCache?: string | null;
  listOfEmployeeId: string[];
}

const Container = styled.div`
  flex: 14;
  display: flex;
  flex-direction: column;
  padding: 2%;
`;

const TopSection = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 267px;
`;

const BottomSection = styled.section`
  display: flex;
  justify-content: space-between;
  max-width: 267px;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  max-width: 267px;
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
`;

const Select = styled.select`
  margin: 5px;
`;

const Option = styled.option``;

const Label = styled.label`
  min-width: max-content;
`;

export const AddCommuteHistoryDialog = ({
  handleClose,
  selectedRow,
  handleIsOpenToast,
  handleMessage,
  handleToastMessageType,
  listIdForCache,
  listOfEmployeeId
}: IProps) => {
  const { startDateTime: dateWithTime, handleDateTime } = useDateTime();
  const [commuteType, setCommuteType] = useState<CommuteType>(
    CommuteType.Attendance
  );
  const [deviceType, setDeviceType] = useState<Device_Type>(
    Device_Type.Desktop
  );

  const [addCommuteDateTime, { client }] = useAddCommuteDateTimeMutation({
    async update(cache, { data }) {
      let messsage = "출근기록을 추가하였습니다.";
      if (commuteType === CommuteType.Leave) {
        messsage = "퇴근기록을 추가하였습니다.";
      }
      if (data?.addCommuteDateTime.ok && selectedRow && listIdForCache) {
        handleToastMessageType(MessageTypes.SUCCESS);
        handleMessage(messsage);
        cache.modify({
          id: `CountListOfEmployeeCommuteManagementOutput`,
          fields: {
            total(prev) {
              return prev + 1;
            }
          }
        });
      } else if (data?.addCommuteDateTime.error) {
        handleMessage(data?.addCommuteDateTime.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsOpenToast(true);
      handleClose(false);
      await client.resetStore();
    }
  });

  const handleCommuteType = useCallback((event: SelectOnchange) => {
    if (event.target.value === CommuteType.Attendance) {
      setCommuteType(CommuteType.Attendance);
    } else {
      setCommuteType(CommuteType.Leave);
    }
  }, []);

  const handleDeviceType = useCallback((event: SelectOnchange) => {
    if (event.target.value === Device_Type.Desktop) {
      setDeviceType(Device_Type.Desktop);
    } else {
      setDeviceType(Device_Type.App);
    }
  }, []);

  const handleConfirm = useCallback(() => {
    const selectedEmployeeId = selectedRow
      ? [selectedRow.employeeId]
      : listOfEmployeeId;

    addCommuteDateTime({
      variables: {
        selectedEmployeeId: selectedEmployeeId,
        checkDateTime: dateWithTime,
        deviceType,
        commuteType
      }
    });
  }, [
    addCommuteDateTime,
    selectedRow,
    dateWithTime,
    deviceType,
    commuteType,
    listOfEmployeeId
  ]);

  return (
    <AsonicDialog
      title={`출퇴근 기록 추가`}
      handleClose={handleClose}
      width="330px"
      height="20%"
      minHeight="200px"
    >
      <Container>
        <TopSection>
          <DateWithTimeContainer
            title="출/퇴근 시간"
            dateWithTime={dateWithTime}
            handleDateWithTime={handleDateTime("start")}
          />
        </TopSection>
        <BottomSection>
          <SelectContainer>
            <Label htmlFor="COMMUTE">출/퇴근</Label>
            <Select id="COMMUTE" onChange={handleCommuteType}>
              <Option value={CommuteType.Attendance}>출근</Option>
              <Option value={CommuteType.Leave}>퇴근</Option>
            </Select>
          </SelectContainer>
          <SelectContainer>
            <Label htmlFor="DEVICE">기기선택</Label>
            <Select id="DEVICE" onChange={handleDeviceType}>
              <Option value={Device_Type.Desktop}>PC</Option>
              <Option value={Device_Type.App}>APP</Option>
            </Select>
          </SelectContainer>
        </BottomSection>
        <Footer>
          <Button onClick={handleConfirm}>확인</Button>
        </Footer>
      </Container>
    </AsonicDialog>
  );
};
