import styled from "styled-components";
import UserMonthlyWorkHeader from "./user_monthly_work_header";
import {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import Spinner from "../../../../shared/spinner";
import * as ReactTable from "react-table";
import moment from "moment";
import usePageControl from "../../../../../hooks/use-page-control/use-page-control";
import { useReactiveVar } from "@apollo/client";
import listOfApolloVar from "../../../../../apollo/apollo-var";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import PageController from "../../../../table/page_controller";
import ButtonContainer from "../../../../shared/button_container";
import StyledButton from "../../../../shared/styled_button";
import {
  Is_Active,
  SendUserMonthlyWorkInfo,
  UserMonthlyWorkPlanEntity,
  useCountUserMonthlyWorkPlanLazyQuery,
  useGetUserMonthlyWorkExcelPlanLazyQuery,
  useGetUserMonthlyWorkPlanLazyQuery,
  useSendUserMonthlyWorkInfoMutation
} from "../../../../../generated/graphql";
import StyledFlexContainer from "../../../../shared/styled_flex_container";
import StyledInput from "../../../../shared/styled_input";
import { Cell } from "../../../../../../types/@react-table/react-table/react-table";
import ConfirmDialog from "../../../../confirm-dialog/confirm-dialog";
import useConfirmDialog from "../../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import useOpenDialog from "../../../../../hooks/use-open-dialog/use-open-dialog";
import EditUserMonthlyWorkPlanDialog from "./edit_user_monthly_work_plan_dialog";
import { downloadFileFromServer } from "../../../statistics/Utils";
import TableV2 from "../../../../table_v2/table_v2";
import { AutoSizer } from "react-virtualized";
import useFixedColumn from "../../../../../hooks/use_fixed_column/use_fixed_column";
import { useSticky } from "react-table-sticky";

const COLUMN_FOR_VALUE = {
  isCheck: "선택",
  departmentName: "부서",
  employeeId: "사번(ERP)",
  employeeName: "이름(ERP)",
  monthStartDay: "시작날짜",
  monthLastDay: "종료날짜",
  restPlanMin: "휴게시간(계획)",
  recognitionPlanTime: "근무시간(계획)",
  restAdmitMin: "휴게시간(인정)",
  recognitionAdmitTime: "근무시간(인정)",
  payTime: "급여시간",
  isSendStatus: "전송유무",
  isCheckStatus: "확인유무",
  isClosingStatus: "마감여부"
} as const;

type TYPE_OF_EMPLOYEE = keyof typeof COLUMN_FOR_VALUE;

export interface IUserMonthlyWorkPlanEntity
  extends Partial<UserMonthlyWorkPlanEntity> {
  isCheck?: boolean;
}

interface IProps {
  isVisibility: boolean;
}

const Container = styled.div<{ $isVisibility: boolean }>`
  display: flex;
  flex: ${props => (props.$isVisibility ? "1" : "0")};
  overflow-x: hidden;
  flex-direction: column;
  visibility: ${props => (props.$isVisibility ? "visible" : "hidden")};
`;

const TableContainer = styled.div`
  display: flex;
  flex: 10;
  will-change: scroll-position;
`;

const TableFooter = styled.div`
  display: flex;
  flex: 1;
`;

const CheckBoxContainer = styled(StyledFlexContainer)`
  justify-content: center;
`;

const BtnContainer = styled(ButtonContainer)`
  padding: 10px;
`;

function UserMonthlyWork({ isVisibility }: IProps) {
  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber
  } = useFixedColumn();
  const [columnVisibility, setColumnVisibility] = useState({});
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [isSearch, setIsSearch] = useState<boolean>(true);
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const selectedListOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );

  const { isOpen, handleOpenDialog } = useOpenDialog();

  const {
    confirmTitle,
    confirmParagraph,
    isOpen: isConfirmOpen,
    handleIsOpen: handleIsConfirmOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const [getUserMonthlyWorkPlan, { data, loading }] =
    useGetUserMonthlyWorkPlanLazyQuery({
      fetchPolicy: "no-cache",
      onError(error) {
        console.log(error);
        setIsSearch(false);
      },
      onCompleted() {
        setIsSearch(false);
      }
    });

  const [countUserMonthlyWorkPlan, { data: totalCount }] =
    useCountUserMonthlyWorkPlanLazyQuery({
      fetchPolicy: "no-cache",
      onError(error) {
        console.log(error);
      }
    });

  const [sendUserMonthlyWorkInfo, { client }] =
    useSendUserMonthlyWorkInfoMutation({
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
        handleToast(
          "알수없는 이유로 선택된 월별 근무 계획을 앱으로 전송하지 못했습니다.",
          MessageTypes.ERROR
        );
        handleIsConfirmOpen(false);
      },
      update(_, { data }) {
        if (data?.sendUserMonthlyWorkInfo.ok) {
          handleToast(
            "성공적으로 선택된 월별 근무 계획을 앱으로 전송하였습니다.",
            MessageTypes.SUCCESS
          );
          client.resetStore();
        } else if (data?.sendUserMonthlyWorkInfo.error) {
          handleToast(data?.sendUserMonthlyWorkInfo.error, MessageTypes.ERROR);
        }
        handleIsConfirmOpen(false);
      }
    });

  const [getUserMonthlyWorkExcelPlan] = useGetUserMonthlyWorkExcelPlanLazyQuery(
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
        handleToast(
          "알수없는 이유로 선택된 월별 근무 계획을 엑셀로 다운로드 하지 못했습니다.",
          MessageTypes.ERROR
        );
      },
      onCompleted(data) {
        if (
          data.getUserMonthlyWorkExcelPlan.ok &&
          data.getUserMonthlyWorkExcelPlan.excel
        ) {
          downloadFileFromServer(
            data.getUserMonthlyWorkExcelPlan.excel,
            `${moment().format(
              "YYYY-MM-DD-hh-mm-ss"
            )})_list_of_user_monthly_work.csv`
          );
        } else if (data.getUserMonthlyWorkExcelPlan.error) {
          handleToast(
            data.getUserMonthlyWorkExcelPlan.error,
            MessageTypes.ERROR
          );
        }
      }
    }
  );

  const downloadExcel = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      getUserMonthlyWorkExcelPlan({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          startDate,
          endDate
        }
      });
    }
  }, [getUserMonthlyWorkExcelPlan, selectedListOfEmployeeId]);

  const count: number = useMemo(() => {
    return totalCount?.countUserMonthlyWorkPlan.count ?? 0;
  }, [totalCount]);

  const list: UserMonthlyWorkPlanEntity[] = useMemo(() => {
    return data?.getUserMonthlyWorkPlan.list ?? [];
  }, [data]);

  const columns: ReactTable.Column<IUserMonthlyWorkPlanEntity>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      const smallWidth = 70;
      let newListOfColumn: ReactTable.Column<IUserMonthlyWorkPlanEntity>[] =
        listOfColumn.map((item, index) => {
          let width: number = 120;
          if (
            COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
            COLUMN_FOR_VALUE.isCheck
          ) {
            width = smallWidth;
          }
          let sticky = "";
          if (sFixedColumnNumber) {
            if (index + 1 <= sFixedColumnNumber) {
              sticky = "left";
            }
          }

          return {
            Header(header) {
              if (
                COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
                COLUMN_FOR_VALUE.isCheck
              ) {
                return (
                  <CheckBoxContainer>
                    <StyledInput
                      type="checkbox"
                      checked={header.isAllRowsSelected}
                      onChange={() => {
                        if (header.isAllRowsSelected) {
                          header.toggleAllRowsSelected(false);
                        } else {
                          header.toggleAllRowsSelected(true);
                        }
                      }}
                    />
                    <span>{COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE]}</span>
                  </CheckBoxContainer>
                );
              }
              return COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE];
            },
            accessor: item as TYPE_OF_EMPLOYEE,
            Cell(cell: Cell<IUserMonthlyWorkPlanEntity>) {
              if (
                COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
                COLUMN_FOR_VALUE.isCheck
              ) {
                return (
                  <StyledInput
                    type="checkbox"
                    checked={cell.row.isSelected}
                    onChange={event => {
                      event.stopPropagation();
                      if (cell.row.isSelected) {
                        cell.row.toggleRowSelected(false);
                      } else {
                        cell.row.toggleRowSelected(true);
                      }
                    }}
                  />
                );
              }
              if (
                COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
                COLUMN_FOR_VALUE.isCheckStatus
              ) {
                return cell.value === Is_Active.Active ? "확인" : "미확인";
              }
              if (
                COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
                COLUMN_FOR_VALUE.isSendStatus
              ) {
                return cell.value === Is_Active.Active ? "전송" : "미전송";
              }
              if (
                COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
                COLUMN_FOR_VALUE.isClosingStatus
              ) {
                return cell.value === Is_Active.Active ? "마감" : "미마감";
              }
              return cell.value ?? "";
            },
            width,
            sticky
          };
        });
      return newListOfColumn;
    }, [sFixedColumnNumber]);

  const table = ReactTable.useTable<IUserMonthlyWorkPlanEntity>(
    {
      columns,
      data: list,
      state: {
        columnVisibility
      },
      onColumnVisibilityChange: setColumnVisibility
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const selectedRow: ReactTable.Row<IUserMonthlyWorkPlanEntity> | undefined =
    useMemo(() => {
      if (table.selectedFlatRows.length > 0) {
        return table.selectedFlatRows[table.selectedFlatRows.length - 1];
      }
      return;
    }, [table.selectedFlatRows]);

  const handleSendUserMonthlyWorkInfo = useCallback(() => {
    const info: SendUserMonthlyWorkInfo[] = table.selectedFlatRows.map(item => {
      const { monthStartDay, monthLastDay, employeeId } = item.original;
      return {
        monthDate: monthStartDay ?? monthLastDay ?? "",
        employeeId: employeeId ?? ""
      };
    });
    sendUserMonthlyWorkInfo({
      variables: {
        info
      }
    });
  }, [sendUserMonthlyWorkInfo, table.selectedFlatRows]);

  useEffect(() => {
    setIsSearch(true);
  }, [currentPage]);

  useEffect(() => {
    if (selectedListOfEmployeeId.length > 0 && isSearch) {
      countUserMonthlyWorkPlan({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          startDate,
          endDate
        }
      });
    }
  }, [
    countUserMonthlyWorkPlan,
    startDate,
    endDate,
    selectedListOfEmployeeId,
    isSearch
  ]);

  useEffect(() => {
    if (selectedListOfEmployeeId.length > 0 && isSearch) {
      getUserMonthlyWorkPlan({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          startDate,
          endDate,
          page: currentPage,
          take
        }
      });
    }
  }, [
    getUserMonthlyWorkPlan,
    startDate,
    endDate,
    selectedListOfEmployeeId,
    isSearch,
    currentPage,
    take
  ]);

  return (
    <Container $isVisibility={isVisibility}>
      <UserMonthlyWorkHeader
        startDate={startDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        columns={table.columns as TColumn<IUserMonthlyWorkPlanEntity>[]}
        table={table}
        setIsSearch={setIsSearch}
        title="월별근무"
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={count}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
      />
      <Suspense fallback={<Spinner />}>
        <TableContainer>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <TableV2
                  table={table}
                  title="월별근무"
                  selectedRow={selectedRow}
                  height={height}
                  width={width}
                  loading={loading}
                  isCustomSelect
                />
              );
            }}
          </AutoSizer>
        </TableContainer>
      </Suspense>
      <TableFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(count / take)}
          handleCurrentPage={handleCurrentPage}
        />
        <BtnContainer>
          <StyledButton
            disabled={table.selectedFlatRows.length < 1}
            onClick={event => {
              event.preventDefault();
              handleConfirmMessage({
                title: "월별 근무 계획 앱 전송",
                p: "선택된 월별 근무 계획을 앱으로 전송 하시겠습니까?",
                messageTypes: MessageTypes.INFO
              });
              handleIsConfirmOpen(true);
            }}
          >{`앱 전송`}</StyledButton>
          <StyledButton
            disabled={table.selectedFlatRows.length < 1}
            onClick={event => {
              event.preventDefault();
              handleOpenDialog(true);
            }}
          >{`수정`}</StyledButton>
        </BtnContainer>
      </TableFooter>
      {isOpen && (
        <EditUserMonthlyWorkPlanDialog
          handleOpenDialog={handleOpenDialog}
          handleToast={handleToast}
          selectedWorkInfoList={table.selectedFlatRows}
        />
      )}
      {isConfirmOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.INFO}
          handleIsOpen={handleIsConfirmOpen}
          handleConfirm={handleSendUserMonthlyWorkInfo}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default UserMonthlyWork;
