import styled from "styled-components";

export const DvideContentContainer = styled.div`
  display: flex;
  height: 100%;
  width: 50%;
  border: 1px solid rgb(0, 0, 0, 0.4);
  padding: 10px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
`;
