import StyleInput from "../../../inputs/style-input";
import FormRow from "../../../shared/form-row/form-row";
import RadioContainer from "../../../shared/radio_container/radio_container";
import SectionContainer from "../../../shared/section_container/section_container";
import TitleContainer from "../../../shared/title_container/title_container";
import SubTitle from "../../../shared/sub-title/sub-title";
import ContentContainer from "../../../shared/grid_content_container/grid_content_container";
import SSelect from "../../../shared/s_select/s_select";
import useTimeList, {
  HOUR_TYPE
} from "../../../../hooks/use_time_list/use_time_list";
import Button from "../../../globalComponents/Button";
import AAhelp from "./a_a_help";
import WSBottomContainer from "../../w_s_bottom_container/w_s_bottom_container";
import {
  Break_Time_Type,
  Day_Of_Week_Type,
  Day_Type,
  useGetAutonomyWorkingHourInfoLazyQuery
} from "../../../../generated/graphql";
import { useContext, useEffect, useState } from "react";
import handleApolloErrorLog from "../../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../../Utils/handle_error_log/handle_error_log";
import { WS_STATE } from "../../../../screens/asonic-working-system.screen";
import useTimeControl from "../../../../hooks/use_time_control/use_time_control";
import moment from "moment";

function AutonomyAttendance() {
  const wSState = useContext(WS_STATE);

  const { hourList, minList, hourTypeList } = useTimeList();
  const { displayHours, makeHoursType, makeHours } = useTimeControl();

  const [dayOfWeekType, setDayOfWeekType] = useState<Day_Of_Week_Type>(
    Day_Of_Week_Type.MonToFri
  );
  const [dayOfWeek, setDayOfWeek] = useState<Day_Type[]>([]);

  const [startTime, setStartTime] = useState<string>("09:00");
  const [endTime, setEndTime] = useState<string>("09:00");
  const [startBreakTime, setStartBreakTime] = useState<string>("09:00");
  const [endBreakTime, setEndBreakTime] = useState<string>("09:00");
  const [workEndingTimeRange, setWorkEndingTimeRange] = useState("");

  const [breakTimeType, setBreakTimeType] = useState<Break_Time_Type>(
    Break_Time_Type.Basic
  );

  const [getAutonomyWorkingHourInfo] = useGetAutonomyWorkingHourInfoLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (
        data.getAutonomyWorkingHourInfo.ok &&
        data.getAutonomyWorkingHourInfo.basicInfo
      ) {
        const {
          dayOfWeekType,
          dayOfWeek,
          startTime,
          endTime,
          startBreakTime,
          endBreakTime,
          breakTimeType
        } = data.getAutonomyWorkingHourInfo.basicInfo;
        setDayOfWeekType(dayOfWeekType);
        setDayOfWeek(dayOfWeek);

        setStartTime(startTime);
        setEndTime(endTime);

        setStartBreakTime(startBreakTime);
        setEndBreakTime(endBreakTime);
        setBreakTimeType(breakTimeType);
      } else {
        if (data.getAutonomyWorkingHourInfo.error) {
          handleErrorLog(data.getAutonomyWorkingHourInfo.error);
        }
      }
    }
  });

  useEffect(() => {
    switch (dayOfWeekType) {
      case Day_Of_Week_Type.All:
        setDayOfWeek([
          Day_Type.Mon,
          Day_Type.Tue,
          Day_Type.Wed,
          Day_Type.Thr,
          Day_Type.Fri,
          Day_Type.Sat,
          Day_Type.Sun
        ]);
        break;
      case Day_Of_Week_Type.MonToSat:
        setDayOfWeek([
          Day_Type.Mon,
          Day_Type.Tue,
          Day_Type.Wed,
          Day_Type.Thr,
          Day_Type.Fri,
          Day_Type.Sat
        ]);
        break;
      case Day_Of_Week_Type.MonToFri:
        setDayOfWeek([
          Day_Type.Mon,
          Day_Type.Tue,
          Day_Type.Wed,
          Day_Type.Thr,
          Day_Type.Fri
        ]);
        break;
    }
  }, [dayOfWeekType]);

  useEffect(() => {
    if (wSState?.wSBasicInfo?.dayMaxWorkHour) {
      const format = "HH:mm";
      const startTimeMoment = moment(startTime, format);
      const startTimeAfterAddWorkHourMoment = moment(startTime, format).add(
        wSState?.wSBasicInfo?.dayMaxWorkHour,
        "hours"
      );
      const endTimeAfterAddWorkHourMoment = moment(endTime, format).add(
        wSState?.wSBasicInfo?.dayMaxWorkHour,
        "hours"
      );
      const endTimeMoment = moment(endTime, format);
      const startBreakTimeMoment = moment(startBreakTime, format);
      const endBreakTimeMoment = moment(endBreakTime, format);

      if (
        startTimeMoment.isBefore(startBreakTimeMoment) ||
        startTimeAfterAddWorkHourMoment.isAfter(endBreakTimeMoment)
      ) {
        startTimeMoment.add(1, "hour");
      }

      if (
        endTimeMoment.isBefore(startBreakTimeMoment) ||
        endTimeAfterAddWorkHourMoment.isAfter(endBreakTimeMoment)
      ) {
        endTimeMoment.add(1, "hour");
      }
      startTimeMoment.add(wSState?.wSBasicInfo?.dayMaxWorkHour, "hours");
      endTimeMoment.add(wSState?.wSBasicInfo?.dayMaxWorkHour, "hours");
      let startWorkEndingTimeString = startTimeMoment.format("hh:mm");
      let endWorkEndingTimeString = endTimeMoment.format("hh:mm");
      const standardTime = moment("12:00", format);

      // 각 국가에서 쓰이는 단위를 Intl 객체를 통해 변경할수있다.
      const formatter = new Intl.DateTimeFormat("ko-KR", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });

      startWorkEndingTimeString = formatter.format(startTimeMoment.toDate());
      endWorkEndingTimeString = formatter.format(endTimeMoment.toDate());

      const newWorkEndingTimeRange = `${startWorkEndingTimeString} ~ ${endWorkEndingTimeString}`;
      setWorkEndingTimeRange(newWorkEndingTimeRange);
    }
  }, [wSState, startTime, endTime, startBreakTime, endBreakTime]);

  useEffect(() => {
    if (wSState?.workingTemplateIdx) {
      getAutonomyWorkingHourInfo({
        variables: {
          workingTemplateIdx: wSState?.workingTemplateIdx
        }
      });
    }
  }, [wSState?.workingTemplateIdx]);

  useEffect(() => {
    if (wSState?.setAutonomyWorkingHourInfo && wSState.workingTemplateIdx) {
      wSState?.setAutonomyWorkingHourInfo({
        workingTemplateIdx: wSState.workingTemplateIdx,
        startTime,
        endTime,
        dayOfWeekType,
        dayOfWeek,
        breakTimeType,
        startBreakTime,
        endBreakTime
      });
    }
  }, [
    wSState?.workingTemplateIdx,
    wSState?.setAutonomyWorkingHourInfo,
    startTime,
    endTime,
    dayOfWeekType,
    dayOfWeek,
    breakTimeType,
    startBreakTime,
    endBreakTime
  ]);

  return (
    <SectionContainer>
      <TitleContainer flex={0}>
        <SubTitle title={"기본 설정"} />
        <AAhelp />
      </TitleContainer>
      <ContentContainer flex={0}>
        <FormRow title={"근무요일 설정"}>
          <RadioContainer>
            <StyleInput
              type="radio"
              id="allDay"
              minWidth="15px"
              maxWidth="15px"
              name="daySetting"
              checked={dayOfWeekType === Day_Of_Week_Type.All}
              onChange={() => {
                setDayOfWeekType(Day_Of_Week_Type.All);
              }}
            />
            <label htmlFor="allDay">{`모든 요일`}</label>
            <StyleInput
              type="radio"
              id="weekDay"
              minWidth="15px"
              maxWidth="15px"
              name="daySetting"
              checked={dayOfWeekType === Day_Of_Week_Type.MonToFri}
              onChange={() => {
                setDayOfWeekType(Day_Of_Week_Type.MonToFri);
              }}
            />
            <label htmlFor="weekDay">{`주중(월~금)`}</label>
            <StyleInput
              type="radio"
              id="weekDayWithSat"
              minWidth="15px"
              maxWidth="15px"
              name="daySetting"
              checked={dayOfWeekType === Day_Of_Week_Type.MonToSat}
              onChange={() => {
                setDayOfWeekType(Day_Of_Week_Type.MonToSat);
              }}
            />
            <label htmlFor="weekDayWithSat">{`주중(월~토)`}</label>
            <StyleInput
              type="radio"
              id="self"
              minWidth="15px"
              maxWidth="15px"
              name="daySetting"
              checked={dayOfWeekType === Day_Of_Week_Type.InputMethod}
              onChange={() => {
                setDayOfWeekType(Day_Of_Week_Type.InputMethod);
              }}
            />
            <label htmlFor="self">{`직접선택`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title={"근무요일 선택"}>
          <RadioContainer>
            <StyleInput
              type="checkbox"
              id="autonomy_monday"
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Mon)}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Mon)) {
                    return list.filter(day => day !== Day_Type.Mon);
                  }
                  return [...list, Day_Type.Mon];
                });
              }}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            />
            <label htmlFor="autonomy_monday">{`월`}</label>
            <StyleInput
              type="checkbox"
              id="autonomy_tuesday"
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Tue)}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Tue)) {
                    return list.filter(day => day !== Day_Type.Tue);
                  }
                  return [...list, Day_Type.Tue];
                });
              }}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            />
            <label htmlFor="autonomy_tuesday">{`화`}</label>
            <StyleInput
              type="checkbox"
              id="autonomy_wednesday"
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Wed)}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Wed)) {
                    return list.filter(day => day !== Day_Type.Wed);
                  }
                  return [...list, Day_Type.Wed];
                });
              }}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            />
            <label htmlFor="autonomy_wednesday">{`수`}</label>
            <StyleInput
              type="checkbox"
              id="autonomy_thursday"
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Thr)}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Thr)) {
                    return list.filter(day => day !== Day_Type.Thr);
                  }
                  return [...list, Day_Type.Thr];
                });
              }}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            />
            <label htmlFor="autonomy_thursday">{`목`}</label>
            <StyleInput
              type="checkbox"
              id="autonomy_friday"
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Fri)}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Fri)) {
                    return list.filter(day => day !== Day_Type.Fri);
                  }
                  return [...list, Day_Type.Fri];
                });
              }}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            />
            <label htmlFor="autonomy_friday">{`금`}</label>
            <StyleInput
              type="checkbox"
              id="autonomy_saturday"
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Sat)}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Sat)) {
                    return list.filter(day => day !== Day_Type.Sat);
                  }
                  return [...list, Day_Type.Sat];
                });
              }}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            />
            <label htmlFor="autonomy_saturday">{`토`}</label>
            <StyleInput
              type="checkbox"
              id="autonomy_sunday"
              minWidth="15px"
              maxWidth="15px"
              name="selectDay"
              checked={dayOfWeek.includes(Day_Type.Sun)}
              onChange={() => {
                setDayOfWeek(list => {
                  if (list.includes(Day_Type.Sun)) {
                    return list.filter(day => day !== Day_Type.Sun);
                  }
                  return [...list, Day_Type.Sun];
                });
              }}
              disabled={dayOfWeekType !== Day_Of_Week_Type.InputMethod}
            />
            <label htmlFor="autonomy_sunday">{`일`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="출근 가능 시간">
          <SSelect
            name="attendanceStartHourType"
            id="attendanceStartHourType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(startTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: startTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setStartTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="attendanceStartHour"
            id="attendanceStartHour"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(startTime.split(":")[0])}
            onChange={event => {
              const hours = startTime.split(":")[0];
              const minute = startTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setStartTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="attendanceStartHour">{`시`}</label>
          <SSelect
            name="attendanceStartMin"
            id="attendanceStartMin"
            minWidth="50px"
            maxWidth="50px"
            value={startTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setStartTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="attendanceStartMin">{`분`}</label>
          <span>{`~`}</span>
          <SSelect
            name="attendanceEndHourType"
            id="attendanceEndHourType"
            minWidth="60px"
            maxWidth="60px"
            value={
              parseInt(endTime.split(":")[0]) < 12 ? HOUR_TYPE.AM : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: endTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setEndTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="attendanceEndHour"
            id="attendanceEndHour"
            minWidth="50px"
            maxWidth="50px"
            value={displayHours(endTime.split(":")[0])}
            onChange={event => {
              const hours = endTime.split(":")[0];
              const minute = endTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setEndTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="attendanceEndHour">{`시`}</label>
          <SSelect
            name="attendanceEndMin"
            id="attendanceEndMin"
            minWidth="50px"
            maxWidth="50px"
            value={endTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setEndTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="attendanceEndMin">{`분`}</label>
        </FormRow>
        <FormRow title="퇴근 시간 범위">
          <span>{workEndingTimeRange}</span>
        </FormRow>
        <FormRow title="휴게시간">
          <RadioContainer>
            <StyleInput
              type="radio"
              id="designatedPeriod"
              minWidth="15px"
              maxWidth="15px"
              name="breakTime"
              checked={breakTimeType === Break_Time_Type.Basic}
              onChange={event => {
                setBreakTimeType(Break_Time_Type.Basic);
              }}
            />
            <label htmlFor="designatedPeriod">{`지정시간`}</label>
            <StyleInput
              type="radio"
              id="workTimeSelf"
              minWidth="15px"
              maxWidth="15px"
              name="breakTime"
              checked={breakTimeType === Break_Time_Type.SeparateSetting}
              onChange={event => {
                setBreakTimeType(Break_Time_Type.SeparateSetting);
              }}
            />
            <label htmlFor="workTimeSelf">{`근무자 자율편성`}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title="휴게시간 설정">
          <SSelect
            name="startHourType"
            id="startHourType"
            minWidth="60px"
            maxWidth="60px"
            disabled={breakTimeType === Break_Time_Type.SeparateSetting}
            value={
              parseInt(startBreakTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: startBreakTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setStartBreakTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="startHour"
            id="startHour"
            minWidth="50px"
            maxWidth="50px"
            disabled={breakTimeType === Break_Time_Type.SeparateSetting}
            value={displayHours(startBreakTime.split(":")[0])}
            onChange={event => {
              const hours = startBreakTime.split(":")[0];
              const minute = startBreakTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setStartBreakTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="startHour">{`시`}</label>
          <SSelect
            name="startMin"
            id="startMin"
            minWidth="50px"
            maxWidth="50px"
            disabled={breakTimeType === Break_Time_Type.SeparateSetting}
            value={startBreakTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setStartBreakTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="startMin">{`분`}</label>
          <span>{`~`}</span>
          <SSelect
            name="endHourType"
            id="endHourType"
            minWidth="60px"
            maxWidth="60px"
            disabled={breakTimeType === Break_Time_Type.SeparateSetting}
            value={
              parseInt(endBreakTime.split(":")[0]) < 12
                ? HOUR_TYPE.AM
                : HOUR_TYPE.PM
            }
            onChange={event => {
              const newTime = makeHoursType({
                originH: endBreakTime,
                newType: event.currentTarget.value as HOUR_TYPE
              });
              setEndBreakTime(newTime);
            }}
          >
            {hourTypeList.map(item => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </SSelect>
          <SSelect
            name="endHour"
            id="endHour"
            minWidth="50px"
            maxWidth="50px"
            disabled={breakTimeType === Break_Time_Type.SeparateSetting}
            value={displayHours(endBreakTime.split(":")[0])}
            onChange={event => {
              const hours = endBreakTime.split(":")[0];
              const minute = endBreakTime.split(":")[1];
              let parsedHours = makeHours({
                originH: hours,
                newH: event.currentTarget.value
              });
              setEndBreakTime(`${parsedHours}:${minute}`);
            }}
          >
            {hourList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="endHour">{`시`}</label>
          <SSelect
            name="endMin"
            id="endMin"
            minWidth="50px"
            maxWidth="50px"
            disabled={breakTimeType === Break_Time_Type.SeparateSetting}
            value={endBreakTime.split(":")[1]}
            onChange={event => {
              const minute = event.currentTarget.value;
              setEndBreakTime(item => {
                const hours = item.split(":")[0];
                return `${hours}:${minute}`;
              });
            }}
          >
            {minList.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SSelect>
          <label htmlFor="endMin">{`분`}</label>
        </FormRow>
      </ContentContainer>
      <WSBottomContainer>
        <Button
          maxWidth="100%"
          customMinWidth="100%"
          onClick={() => {
            if (wSState?.handleIsSave) {
              wSState?.handleIsSave(true);
            }
          }}
        >{`저장`}</Button>
      </WSBottomContainer>
    </SectionContainer>
  );
}

export default AutonomyAttendance;
