import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { AutoSizer } from "react-virtualized";
import { FixedSizeList as List } from "react-window";
import usePageControl from "../../../../hooks/use-page-control/use-page-control";
import {
  ICustomQuery,
  IStatisticsState,
  QUERY_COUNT_STATISTICS_INFO,
  QUERY_DOWNLOAD_STATISTICS_TO_EXCEL,
  QUERY_STATISTICS_INFO
} from "../admin-statistics/admin-statistics";
import StatisticsBox from "../statistics-box/statistics-box";
import StatisticsDetail, {
  IStatisticsProps
} from "../statistics-detail/statistics-detail";
import ListOfStatistics from "../list-of-statistics/list-of-statistics";
import moment from "moment";
import AsonicTable from "../../../asonic-table/asonic-table";
import { useLazyQuery } from "@apollo/client";
import {
  StatisticsInfo,
  StatisticsInfoVariables
} from "../../../../__generated__/StatisticsInfo";
import {
  CountStatisticsInfo,
  CountStatisticsInfoVariables
} from "../../../../__generated__/CountStatisticsInfo";
import * as ReactTable from "react-table";
import useDnd from "../../../../hooks/use-dnd/use-dnd";
import { TColumn } from "../../../../hooks/use-hide-columns/use-hide-columns";
import {
  DownloadStatisticsToExcel,
  DownloadStatisticsToExcelVariables
} from "../../../../__generated__/DownloadStatisticsToExcel";
import { downloadFileFromServer } from "../Utils";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import useFixedColumn from "../../../../hooks/use_fixed_column/use_fixed_column";
import { useSticky } from "react-table-sticky";
import AdminStatisticsHeader from "../admin-statistics/admin_statistics_header";
import TableLayoutContainer from "../../../table_layout/table_layout_container";
import TableV2 from "../../../table_v2/table_v2";
import TableLayoutFooter from "../../../table_layout/table_layout_footer";
import PageController from "../../../table/page_controller";

interface IProps
  extends Pick<IStatisticsProps, "handleCustomQueryList" | "refreshData"> {
  customQueryList: Partial<ICustomQuery>[];
  employeeId?: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
  flex-direction: column;
  gap: 20px;
  overflow-x: hidden;
`;

const BoxContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  min-height: 300px;
`;

const TableContainer = styled.div`
  display: flex;
  border-top: 2px solid ${props => props.theme.colors.black};
  flex: 2;
  overflow-x: hidden;
  flex-direction: column;
`;

function UserStatistics(props: IProps) {
  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName,
    isActiveFilter,
    setIsActiveFilter
  } = useFixedColumn();

  const isMount = useRef<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const handleShowResult = useCallback((value: boolean) => {
    setShowResult(value);
  }, []);

  const [getListOfStatisticsInfo, { data, loading }] = useLazyQuery<
    StatisticsInfo,
    StatisticsInfoVariables
  >(QUERY_STATISTICS_INFO, {
    onCompleted(data) {
      if (!data.statisticsInfo.ok) {
        handleToastMessageType(MessageTypes.ERROR);
        handleMessage(
          `${selectedQuery?.title} 통계 정보를 가져오지 못 했습니다.`
        );
        handleIsToastMessageOpen(true);
      }
      if (
        data.statisticsInfo.ok &&
        data.statisticsInfo.list &&
        data.statisticsInfo.list?.length < 1
      ) {
        handleToastMessageType(MessageTypes.WARNING);
        handleMessage(`${selectedQuery?.title} 통계 정보가 존재하지 않습니다.`);
        handleIsToastMessageOpen(true);
      }
    },
    onError() {
      handleToastMessageType(MessageTypes.ERROR);
      handleMessage(
        `${selectedQuery?.title} 통계 정보를 가져오지 못 했습니다.`
      );
      handleIsToastMessageOpen(true);
    }
  });

  const [getCountOfStatisticsInfo, { data: totalData, loading: totalLoading }] =
    useLazyQuery<CountStatisticsInfo, CountStatisticsInfoVariables>(
      QUERY_COUNT_STATISTICS_INFO
    );

  const [downloadStatisticsToExcel] = useLazyQuery<
    DownloadStatisticsToExcel,
    DownloadStatisticsToExcelVariables
  >(QUERY_DOWNLOAD_STATISTICS_TO_EXCEL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (
        data.downloadStatisticsToExcel.ok &&
        data.downloadStatisticsToExcel.excel
      ) {
        downloadFileFromServer(
          data.downloadStatisticsToExcel.excel,
          `${moment().format("YYYY-MM-DD-hh-mm-ss")}-list-of-${
            selectedQuery?.title
          }-statistics.csv`
        );
      }
    }
  });

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();

  const [statisticsState, setStatisticsState] = useState<IStatisticsState>({
    title: "",
    description: "",
    base_query: "",
    employees: [],
    departments: [],
    approval_result: undefined,
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    publish: 0
  });

  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));

  const handleStartDate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.value) {
        const newStatisticsState: IStatisticsState = {
          ...statisticsState,
          start_date: event.currentTarget.value
        };
        setStatisticsState(newStatisticsState);
        setStartDate(event.currentTarget.value);
        setShowResult(false);
      }
    },
    [statisticsState]
  );

  const handleEndDate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.value) {
        const newStatisticsState: IStatisticsState = {
          ...statisticsState,
          end_date: event.currentTarget.value
        };
        setStatisticsState(newStatisticsState);
        setEndDate(event.currentTarget.value);
        setShowResult(false);
      }
    },
    [statisticsState]
  );

  const handleStatisticsState = useCallback(
    (payload: Partial<IStatisticsState>) => {
      const newStatisticsState = { ...statisticsState, ...payload };
      setStatisticsState(newStatisticsState);
      setStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
      setEndDate(moment().format("YYYY-MM-DD"));
      setShowResult(false);
    },
    [statisticsState]
  );

  const [selectedQuery, setSelectedQuery] = useState<ICustomQuery>();

  const handleSelectedQuery = useCallback(
    (payload?: ICustomQuery) => {
      const newStatisticsState: Partial<IStatisticsState> = {
        title: payload?.title,
        description: payload?.description,
        base_query: payload?.base_query,
        publish: payload?.publish,
        start_date: startDate,
        end_date: endDate
      };

      handleStatisticsState(newStatisticsState);
      setSelectedQuery(payload);
    },
    [handleStatisticsState, startDate, endDate]
  );

  const total = useMemo(() => {
    if (!showResult) {
      return 0;
    }
    if (
      totalData?.countStatisticsInfo.ok &&
      totalData?.countStatisticsInfo.total
    ) {
      return totalData?.countStatisticsInfo.total;
    }
    return 0;
  }, [totalData, showResult]);

  const list: any[] = useMemo(() => {
    if (!showResult) {
      return [];
    }
    return data?.statisticsInfo.list || [];
  }, [data, showResult]);

  const columns: ReactTable.Column<any>[] = useMemo(() => {
    const width = 180;
    let newColumns: any[] = [];
    const list = data?.statisticsInfo.list;
    if (list && showResult) {
      newColumns = Object.keys(list[0]).map(content => {
        let sticky = "";
        if (sFixedColumnNumber !== undefined) {
          if (fixedColumnName.length > 0) {
            fixedColumnName.forEach((fColumn, fColumnIndex) => {
              if (fColumn === content && fColumnIndex < sFixedColumnNumber) {
                sticky = "left";
              }
            });
          }
        }
        return {
          Header: content,
          accessor: content,
          width,
          sticky
        };
      });
    }
    return newColumns;
  }, [data, showResult, sFixedColumnNumber, fixedColumnName]);

  const table = ReactTable.useTable<any>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const downloadExcel = useCallback(() => {
    if (selectedQuery && list.length > 0) {
      downloadStatisticsToExcel({
        variables: {
          queryContent: {
            index: selectedQuery.index,
            start_date: statisticsState.start_date,
            end_date: statisticsState.end_date,
            base_query: selectedQuery.base_query,
            employees: statisticsState.employees,
            departments: statisticsState.departments
          }
        }
      });
    }
  }, [selectedQuery, statisticsState, downloadStatisticsToExcel, list]);

  const selectedRow: ReactTable.Row<any> | undefined = useMemo(() => {
    if (table.selectedFlatRows.length > 0) {
      return table.selectedFlatRows[table.selectedFlatRows.length - 1];
    }
    return;
  }, [table.selectedFlatRows]);

  const { moveColumn } = useDnd<any>({
    columns: table.visibleColumns,
    setColumnOrder: table.setColumnOrder,
    title: `${selectedQuery?.title ?? "사용자 통게"}-for-ordering-column`
  });

  useEffect(() => {
    if (props.customQueryList.length > 0 && !isMount.current) {
      const firstQuery = props.customQueryList[0] as ICustomQuery;
      handleSelectedQuery(firstQuery);
      isMount.current = true;
    }
  }, [handleSelectedQuery, props.customQueryList]);

  useEffect(() => {
    if (selectedQuery && statisticsState && showResult) {
      getCountOfStatisticsInfo({
        variables: {
          queryContent: {
            index: selectedQuery.index,
            start_date: statisticsState.start_date,
            end_date: statisticsState.end_date,
            base_query: selectedQuery.base_query,
            employees: statisticsState.employees,
            departments: statisticsState.departments
          }
        }
      });
    }
  }, [selectedQuery, statisticsState, getCountOfStatisticsInfo, showResult]);

  useEffect(() => {
    if (selectedQuery && statisticsState && showResult) {
      getListOfStatisticsInfo({
        variables: {
          page: currentPage,
          take,
          queryContent: {
            index: selectedQuery.index,
            start_date: statisticsState.start_date,
            end_date: statisticsState.end_date,
            base_query: selectedQuery.base_query,
            employees: statisticsState.employees,
            departments: statisticsState.departments
          }
        }
      });
    }
  }, [
    selectedQuery,
    statisticsState,
    getListOfStatisticsInfo,
    currentPage,
    take,
    showResult
  ]);

  const listOFColumn = useMemo(() => {
    if (list && list.length > 0) {
      return Object.keys(list[0]);
    }
    return [];
  }, [list]);

  useEffect(() => {
    if (list && list.length > 0) {
      const listOfColumn = Object.keys(list[0]);
      setFixedColumnName(listOfColumn);
    }
  }, [setFixedColumnName, list]);

  useEffect(() => {
    if (isActiveFilter) {
      setIsActiveFilter(false);
      let newColumns = table.visibleColumns.map(item => item.id);
      setFixedColumnName(newColumns);
    }
  }, [isActiveFilter, table.visibleColumns]);

  return (
    <Container>
      <BoxContainer>
        <StatisticsBox title="사용자 통계 목록">
          <AutoSizer>
            {({ height, width }) => {
              return (
                <List
                  height={height}
                  itemCount={props.customQueryList.length}
                  itemData={props.customQueryList as ICustomQuery[]}
                  itemSize={30}
                  width={width}
                >
                  {ListOfStatistics({ selectedQuery, handleSelectedQuery })}
                </List>
              );
            }}
          </AutoSizer>
        </StatisticsBox>
        <StatisticsBox title="사용자 통계 상세">
          <StatisticsDetail
            selectedQuery={selectedQuery}
            handleCustomQueryList={props.handleCustomQueryList}
            handleSelectedQuery={handleSelectedQuery}
            handleStatisticsState={handleStatisticsState}
            statisticsState={statisticsState}
            refreshData={props.refreshData}
            startDate={startDate}
            endDate={endDate}
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
            handleShowResult={handleShowResult}
          />
        </StatisticsBox>
      </BoxContainer>
      <TableContainer>
        <AdminStatisticsHeader<any>
          columns={table.columns as TColumn<any>[]}
          table={table}
          title={selectedQuery?.title ?? "관리자 통계"}
          headerTitleList={listOFColumn}
          take={take}
          handleTake={handleTake}
          count={total}
          handleCurrentPage={handleCurrentPage}
          downloadExcel={downloadExcel}
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
          setIsActiveFilter={setIsActiveFilter}
        />
        <TableLayoutContainer>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <TableV2
                  table={table}
                  title={selectedQuery?.title ?? "사용자 통계"}
                  height={height}
                  width={width}
                  moveColumn={moveColumn}
                  loading={loading}
                  selectedRow={selectedRow}
                />
              );
            }}
          </AutoSizer>
        </TableLayoutContainer>
        <TableLayoutFooter>
          <PageController
            currentPage={currentPage}
            totalPage={Math.ceil(total / take)}
            handleCurrentPage={handleCurrentPage}
          />
        </TableLayoutFooter>
        {/* <AsonicTable<any>
          title={selectedQuery?.title ?? "관리자 통계"}
          currentPage={currentPage}
          handleCurrentPage={handleCurrentPage}
          take={take}
          handleTake={handleTake}
          total={total}
          totalPage={Math.ceil(total / take)}
          downloadExcel={downloadExcel}
          isLoading={loading || totalLoading}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          selectedRow={selectedRow}
          columns={adminStatisticsColumns as TColumn<any>[]}
          toggleHideColumn={toggleHideColumn}
          moveColumn={moveColumn}
        /> */}
      </TableContainer>
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default UserStatistics;
