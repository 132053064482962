import styled from "styled-components";
import SubTitle from "../../../shared/sub-title/sub-title";
import Button from "../../../globalComponents/Button";
import WDAdditionalSettingHelp from "./w_d_additional_setting_help";
import TitleContainer from "../../../shared/title_container/title_container";
import { OtWorkingStandardHoursEntity } from "../../../../generated/graphql";
import WDAdditionalRow from "./w_d_additional_row";
import { WS_STATE } from "../../../../screens/asonic-working-system.screen";
import { useContext, useMemo } from "react";

interface IProps {
  list: OtWorkingStandardHoursEntity[];
  addExtraDaySettingList: () => void;
  deleteExtraDaySettingList: (payload: OtWorkingStandardHoursEntity) => void;
  handleList: ({
    template,
    index
  }: {
    template: OtWorkingStandardHoursEntity;
    index: number;
  }) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  label {
    :last-child {
      margin-right: 10px;
    }
  }
`;

const ContentContainer = styled.div<{ flex?: number; gap?: string }>`
  display: flex;
  flex: ${props => props.flex ?? 1};
  gap: ${props => props.gap ?? ""};
  flex-direction: column;
`;

function WDAdditionalSetting({
  list,
  addExtraDaySettingList,
  deleteExtraDaySettingList,
  handleList
}: IProps) {
  const wsState = useContext(WS_STATE);
  const isAddButtonDisable = useMemo(() => {
    if (wsState?.isSave || wsState?.workingTemplateIdx) {
      return false;
    }
    return true;
  }, [wsState]);
  return (
    <Container>
      <TitleContainer flex={0}>
        <SubTitle
          title={"근무일 추가설정"}
          description={
            "(추가설정은 기본 근무시간보다 우선 적용되며, 일단위로 반영됩니다.)"
          }
        />
        <Button
          customMinWidth="25px"
          maxWidth="25x"
          customMinHeight="20px"
          disabled={isAddButtonDisable}
          onClick={addExtraDaySettingList}
        >{`+`}</Button>
        <WDAdditionalSettingHelp />
      </TitleContainer>
      <ContentContainer gap={"10px"}>
        {list.map((_, index) => (
          <WDAdditionalRow
            index={index}
            data={list}
            key={`w_d_additional:${index}`}
            deleteExtraDaySettingList={deleteExtraDaySettingList}
            handleList={handleList}
          />
        ))}
      </ContentContainer>
    </Container>
  );
}

export default WDAdditionalSetting;
