import AsonicTable from "../../../../asonic-table/asonic-table";
import * as ReactTable from "react-table";
import { memo, useCallback, useContext, useMemo } from "react";
import EachWeekRow from "./each_week_row";
import styled from "styled-components";
import EachWeekHelp from "./each_week_help";
import { WS_STORE } from "../../../../../screens/asonic-working-system.screen";
import {
  Day_Type,
  OtWorkingFlexibleHoursEntity,
  Work_Hours_Type
} from "../../../../../generated/graphql";
import moment from "moment";

interface IProps {
  extraList: OtWorkingFlexibleHoursEntity[];
  dayOfWeekList: Day_Type[];
  workHoursType: Work_Hours_Type;
  startTime: string;
  endTime: string;
  startBreakTime: string;
  endBreakTime: string;
  settlementSectionHour: number;
}

export interface IEachWeekWS {
  week?: string;
  type?: string;
  weekSetting?: string;
  WEEK: {
    startTime: string;
    endTime: string;
    startBreakTime: string;
    endBreakTime: string;
    workHour: number;
  };
  FRI: OtWorkingFlexibleHoursEntity;
  MON: OtWorkingFlexibleHoursEntity;
  SAT: OtWorkingFlexibleHoursEntity;
  SUN: OtWorkingFlexibleHoursEntity;
  THR: OtWorkingFlexibleHoursEntity;
  TUE: OtWorkingFlexibleHoursEntity;
  WED: OtWorkingFlexibleHoursEntity;
  weekSequence: number;
}

const TableContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 400px;
  > div > div:first-child {
    padding: 10px 0px;
    gap: 10px;
  }
`;

const Empty = styled.div`
  width: 0px;
`;

function EachWeekWS({
  extraList,
  dayOfWeekList,
  workHoursType,
  startTime,
  endTime,
  startBreakTime,
  endBreakTime,
  settlementSectionHour
}: IProps) {
  const { day } = useContext(WS_STORE);
  const makeDays = useCallback(() => {
    const width = 0;
    const days = [
      {
        Header: "월",
        accessor: Day_Type.Mon,
        width,
        Cell() {
          return <Empty />;
        }
      },
      {
        Header: "화",
        width,
        accessor: Day_Type.Tue,
        Cell() {
          return <Empty />;
        }
      },
      {
        Header: "수",
        width,
        accessor: Day_Type.Wed,
        Cell() {
          return <Empty />;
        }
      },
      {
        Header: "목",
        width,
        accessor: Day_Type.Thr,
        Cell() {
          return <Empty />;
        }
      },
      {
        Header: "금",
        accessor: Day_Type.Fri,
        width,
        Cell() {
          return <Empty />;
        }
      },
      {
        Header: "토",
        accessor: Day_Type.Sat,
        width,
        Cell() {
          return <Empty />;
        }
      },
      {
        Header: "일",
        accessor: Day_Type.Sun,
        width,
        Cell() {
          return <Empty />;
        }
      }
    ];
    let indexFound = 0;
    // 컨셉 변경으로 인한 요일변경 기능 보류(시작기준요일 )
    // switch (day) {
    //   case Day_Type.Mon:
    //     indexFound = 0;
    //     break;
    //   case Day_Type.Tue:
    //     indexFound = 1;
    //     break;
    //   case Day_Type.Wed:
    //     indexFound = 2;
    //     break;
    //   case Day_Type.Thr:
    //     indexFound = 3;
    //     break;
    //   case Day_Type.Fri:
    //     indexFound = 4;
    //     break;
    //   case Day_Type.Sat:
    //     indexFound = 5;
    //     break;
    //   default:
    //     indexFound = 6;
    // }

    const first = days.filter((item, index) => {
      if (index >= indexFound) {
        return true;
      }
      return false;
    });
    const last = days.filter((item, index) => {
      if (index < indexFound) {
        return true;
      }
      return false;
    });

    return [...first, ...last];
  }, [day]);

  const handleDay = useCallback(
    ({
      day,
      weekSequence,
      payload
    }: {
      day: Day_Type;
      weekSequence: number;
      payload?: OtWorkingFlexibleHoursEntity;
    }): OtWorkingFlexibleHoursEntity => {
      const start = moment(startTime, "HH:mm");
      const end = moment(endTime, "HH:mm");
      const startBreak = moment(startBreakTime, "HH:mm");
      const endBreak = moment(endBreakTime, "HH:mm");
      const workHour = Math.abs(moment.duration(end.diff(start)).asHours());
      const workBreakHour = Math.abs(
        moment.duration(endBreak.diff(startBreak)).asHours()
      );
      const totalWorkHour = workHour - workBreakHour;
      return payload?.dayOfWeek === day && payload
        ? payload
        : {
            workHour: totalWorkHour,
            dayOfWeek: day,
            startTime: moment(startTime, "HH:mm:ss").format("HH:mm"),
            endTime: moment(endTime, "HH:mm:ss").format("HH:mm"),
            startBreakTime: moment(startBreakTime, "HH:mm:ss").format("HH:mm"),
            endBreakTime: moment(endBreakTime, "HH:mm:ss").format("HH:mm"),
            weekSequence
          };
    },
    [startTime, endTime, startBreakTime, endBreakTime]
  );

  const columns: ReactTable.Column<IEachWeekWS>[] = useMemo(() => {
    const width = 0;
    return [
      {
        Header: "주차",
        accessor: "week",
        width,
        Cell({ cell }) {
          return (
            <EachWeekRow
              workTimeSettingType={workHoursType}
              data={cell.row.original}
              dayOfWeekList={dayOfWeekList}
            />
          );
        }
      },
      {
        Header: "구분",
        accessor: "type",
        width,
        Cell() {
          return <Empty />;
        }
      },
      {
        Header: "주별 설정",
        accessor: "weekSetting",
        width,
        Cell() {
          return <Empty />;
        }
      },
      ...makeDays()
    ];
  }, [makeDays, workHoursType, dayOfWeekList]);

  const list = useMemo(() => {
    let newList: IEachWeekWS[] = [];
    extraList.forEach(day => {
      const isExistWeekIndex = newList.findIndex(
        item => item.weekSequence === day.weekSequence
      );
      let payload = {
        ...day,
        endBreakTime: moment(day.endBreakTime, "HH:mm:ss").format("HH:mm"),
        endTime: moment(day.endTime, "HH:mm:ss").format("HH:mm"),
        startBreakTime: moment(day.startBreakTime, "HH:mm:ss").format("HH:mm"),
        startTime: moment(day.startTime, "HH:mm:ss").format("HH:mm")
      };
      // const start = moment(startTime, "HH:mm");
      // const end = moment(endTime, "HH:mm");
      // const startBreak = moment(startBreakTime, "HH:mm");
      // const endBreak = moment(endBreakTime, "HH:mm");
      // const workHour = Math.abs(moment.duration(end.diff(start)).asHours());
      // const workBreakHour = Math.abs(
      //   moment.duration(endBreak.diff(startBreak)).asHours()
      // );
      // const totalWorkHour = workHour - workBreakHour;

      if (isExistWeekIndex !== -1) {
        newList[isExistWeekIndex][day.dayOfWeek] = payload;
      } else {
        const weekStartTime = payload.startTime;
        const weekEndTime = payload.endTime;
        const weekStartBreakTime = payload.startBreakTime;
        const weekEndBreakTime = payload.endBreakTime;
        const weekWorkHour = payload.workHour;
        // 근무시간 설정방식이 주별 근무시간 및 주별 출/퇴근시간 이면 주에 맞는 시간으로 셋팅해주기
        // if (
        //   workHoursType === Work_Hours_Type.EachWeekWorkTime ||
        //   workHoursType === Work_Hours_Type.EachWeekTotalAndQuittingTime
        // ) {
        //   weekStartTime = payload.startTime;
        //   weekEndTime = payload.endTime;
        //   weekStartBreakTime = payload.startBreakTime;
        //   weekEndBreakTime = payload.endBreakTime;
        //   weekWorkHour = payload.workHour;
        // }
        const newDay: IEachWeekWS = {
          WEEK: {
            startTime: weekStartTime,
            endTime: weekEndTime,
            startBreakTime: weekStartBreakTime,
            endBreakTime: weekEndBreakTime,
            workHour: weekWorkHour
          },
          FRI: handleDay({
            payload,
            day: Day_Type.Fri,
            weekSequence: day.weekSequence
          }),
          MON: handleDay({
            payload,
            day: Day_Type.Mon,
            weekSequence: day.weekSequence
          }),
          SAT: handleDay({
            payload,
            day: Day_Type.Sat,
            weekSequence: day.weekSequence
          }),
          SUN: handleDay({
            payload,
            day: Day_Type.Sun,
            weekSequence: day.weekSequence
          }),
          THR: handleDay({
            payload,
            day: Day_Type.Thr,
            weekSequence: day.weekSequence
          }),
          TUE: handleDay({
            payload,
            day: Day_Type.Tue,
            weekSequence: day.weekSequence
          }),
          WED: handleDay({
            payload,
            day: Day_Type.Wed,
            weekSequence: day.weekSequence
          }),
          weekSequence: day.weekSequence
        };
        newList.push(newDay);
      }
    });

    if (newList.length < settlementSectionHour) {
      for (let i = newList.length; i < settlementSectionHour; i++) {
        const start = moment(startTime, "HH:mm");
        const end = moment(endTime, "HH:mm");
        const startBreak = moment(startBreakTime, "HH:mm");
        const endBreak = moment(endBreakTime, "HH:mm");
        const workHour = Math.abs(moment.duration(end.diff(start)).asHours());
        const workBreakHour = Math.abs(
          moment.duration(endBreak.diff(startBreak)).asHours()
        );
        const totalWorkHour = workHour - workBreakHour;
        newList.push({
          WEEK: {
            startTime: moment(startTime, "HH:mm:ss").format("HH:mm"),
            endTime: moment(endTime, "HH:mm:ss").format("HH:mm"),
            startBreakTime: moment(startBreakTime, "HH:mm:ss").format("HH:mm"),
            endBreakTime: moment(endBreakTime, "HH:mm:ss").format("HH:mm"),
            workHour: totalWorkHour
          },
          FRI: handleDay({ day: Day_Type.Fri, weekSequence: i + 1 }),
          MON: handleDay({ day: Day_Type.Mon, weekSequence: i + 1 }),
          SAT: handleDay({ day: Day_Type.Sat, weekSequence: i + 1 }),
          SUN: handleDay({ day: Day_Type.Sun, weekSequence: i + 1 }),
          THR: handleDay({ day: Day_Type.Thr, weekSequence: i + 1 }),
          TUE: handleDay({ day: Day_Type.Tue, weekSequence: i + 1 }),
          WED: handleDay({ day: Day_Type.Wed, weekSequence: i + 1 }),
          weekSequence: i + 1
        });
      }
    } else if (newList.length > settlementSectionHour) {
      newList = newList.slice(0, settlementSectionHour);
    }
    return newList;
  }, [
    extraList,
    handleDay,
    settlementSectionHour,
    startTime,
    endTime,
    startBreakTime,
    endBreakTime,
    workHoursType
  ]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = ReactTable.useTable<IEachWeekWS>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const selectedRow: ReactTable.Row<IEachWeekWS> | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  return (
    <TableContainer>
      <AsonicTable<IEachWeekWS>
        title="주차별 근무설정"
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
        listOfFlexForHeader={[
          "주차",
          "구분",
          "주별 설정",
          "월",
          "화",
          "수",
          "목",
          "금",
          "토",
          "일"
        ]}
        isOneColumn
        itemSize={100}
        maxWidthForHeader={[
          { name: "주차", $maxWidth: "50px" },
          { name: "구분", $maxWidth: "50px" }
        ]}
      >
        <EachWeekHelp />
      </AsonicTable>
    </TableContainer>
  );
}

export default memo(EachWeekWS);
