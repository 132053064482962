import * as React from "react";
import ViewPanel, { ViewType } from "../ViewPanel";
import { Container } from "./styled";
import PlaceFreeManagement from "./place-free-management";
import AdditionalInformationManagement from "./additional-information-management";
import ApprovalNotificationManagement from "./approval-notification-management/approval-notification-management";
import PcInfoSection from "../pc-info-section/pc-info-section";
import WorkViewSection from "../work-view-section/work-view-section";
import CommuteViewSection from "../commute-view-section/commute-view-section";
import styled from "styled-components";
import WorkTimeInformation from "./work_time_information/work_time_information";
import WorkingTemplate from "./working_template/working_template";
import AnnualStatus from "./annual_status/annual_status";
import ApprovalStatus from "./approval_status/approval_status";

type Props = {
  viewType: ViewType;
};

const ContentContainer = styled.div`
  display: flex;
  flex: 8;
  overflow-x: auto;
  > div {
    width: 0px;
  }
`;

const DetailView = <P extends Props>(props: P) => {
  const { viewType } = props;

  const handleComponent = React.useCallback(() => {
    let RenderComponent;
    switch (viewType) {
      case ViewType.WORKING_TEMPLATE:
        RenderComponent = WorkingTemplate;
        break;
      case ViewType.PC:
        RenderComponent = PcInfoSection;
        break;
      case ViewType.WORK_MANAGEMENT:
        RenderComponent = WorkViewSection;
        break;
      case ViewType.COMMUTE:
        RenderComponent = CommuteViewSection;
        break;
      case ViewType.PLACE_FREE_MANAGEMENT:
        RenderComponent = PlaceFreeManagement;
        break;
      case ViewType.ADDITIONAL_INFORMATION_MANAGEMENT:
        RenderComponent = AdditionalInformationManagement;
        break;
      case ViewType.APPROVAL_NOTIFICATION:
        RenderComponent = ApprovalNotificationManagement;
        break;
      case ViewType.WORK_TIME_INFORMATION:
        RenderComponent = WorkTimeInformation;
        break;
      case ViewType.ANNUAL_STATUS:
        RenderComponent = AnnualStatus;
        break;
      case ViewType.APPROVAL_STATUS:
        RenderComponent = ApprovalStatus;
        break;
      default:
        RenderComponent = PcInfoSection;
    }
    return RenderComponent;
  }, [viewType]);

  const RenderComponent = React.useMemo(handleComponent, [handleComponent]);

  return (
    <Container>
      <ViewPanel />
      <ContentContainer>
        <RenderComponent />
      </ContentContainer>
    </Container>
  );
};

export default DetailView;
