import { GetEmployeeInformation_getEmployeeInformation_listOfEmployee } from "../__generated__/GetEmployeeInformation";
import { IAsonicRow } from "../components/asonic-table/asonic-render-row";
import { makeVar } from "@apollo/client";
import {
  ApprovalEntity,
  ListOfApprovalTemporaryStorageEntity
} from "../generated/graphql";
import { ApprovalType } from "../components/approval/approval-detail-popup";

interface IDepartMentInfo {
  FullGName: string;
  WorkingName: string;
  count_employee: number;
  count_endpoint: number;
  department_id: number;
  dept_sort: number;
  insert_type: number;
  level: string;
  memo1?: string;
  memo2?: string;
  name: string;
  parentdepartment_id: number;
}

export enum CHAT_OPEN {
  OPEN = "OPEN",
  CLOSE = "CLOSE"
}

export interface CHAT_POSITION {
  top: string;
  left: string;
}

const userTokenVar = makeVar<string>("");
const chatOpenVar = makeVar<CHAT_OPEN>(CHAT_OPEN.CLOSE);
const chatServerIp = makeVar<string>("");

const selectedDepartmentVar = makeVar<string>("");
const selectedDepartmentInfoVar = makeVar<IDepartMentInfo | undefined>(
  undefined
);
const selectedListOfEmployeeIdVar = makeVar<string[]>([]);
const selectedListOfEmployeeVar = makeVar<
  IAsonicRow<GetEmployeeInformation_getEmployeeInformation_listOfEmployee>[]
>([]);

const chatPosition = makeVar<CHAT_POSITION>({
  top: "37vh",
  left: "78.5vw"
});

const approvalDataVar = makeVar<ApprovalEntity | undefined>(undefined);
const approvalTypeVar = makeVar<ApprovalType>(
  ApprovalType.APPROVAL_RECEIVE_REFERENCE
);
const approvalTemporaryStorageVar = makeVar<
  ListOfApprovalTemporaryStorageEntity | undefined
>(undefined);

const makeFormName = (): Map<number, string> => {
  const newFormName = new Map<number, string>();
  newFormName.set(1, "휴가원");
  newFormName.set(2, "연차 신청");
  newFormName.set(3, "오전반차 신청");
  newFormName.set(4, "오후반차 신청");
  newFormName.set(5, "시간외근무 신청");
  newFormName.set(6, "긴급PC사용");
  newFormName.set(7, "근무제변경 신청");
  newFormName.set(8, "보고서");
  newFormName.set(9, "업무 보고서");
  return newFormName;
};

const approvalFormNameVar = makeVar<Map<number, string>>(makeFormName());

const listOfApolloVar = {
  userTokenVar,
  chatOpenVar,
  chatServerIp,
  selectedDepartmentVar,
  selectedDepartmentInfoVar,
  selectedListOfEmployeeIdVar,
  selectedListOfEmployeeVar,
  chatPosition,
  approvalDataVar,
  approvalTypeVar,
  approvalFormNameVar,
  approvalTemporaryStorageVar
};

export default listOfApolloVar;
