import styled from "styled-components";
import Button from "../../globalComponents/Button";

interface IProps {
  handleOpenDeleteRequestDialog: (value: boolean) => void;
  deleteRequestController: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}
const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.p`
  white-space: pre-line;
  font-size: 14px;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
`;

function ApprovalDeleteRequestDialog({
  handleOpenDeleteRequestDialog,
  deleteRequestController
}: IProps) {
  return (
    <Container>
      <Content>
        {`결재선의 전원이 열람 전 일때만
        회수가 가능합니다. 
        결재 문서를 회수 하시겠습니까?`}
      </Content>
      <ButtonContainer>
        <Button customMinWidth="50px" onClick={deleteRequestController}>
          예
        </Button>
        <Button
          customMinWidth="50px"
          onClick={() => {
            handleOpenDeleteRequestDialog(false);
          }}
        >
          아니오
        </Button>
      </ButtonContainer>
    </Container>
  );
}

export default ApprovalDeleteRequestDialog;
