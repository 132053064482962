import styled from "styled-components";
import StyledFlexContainer from "../../shared/styled_flex_container";
import StyledInput from "../../shared/styled_input";
import IconContainer from "../../shared/icon_container";
import { Icon } from "@iconify/react";
import MagnifyIcon from "@iconify/icons-mdi/magnify";
import moment from "moment";
import ButtonContainer from "../../shared/button_container";
import StyledButton from "../../shared/styled_button";
import StyledColumnContainer from "../../shared/styled_column_container";
import {
  EditUserDailyWorkCheckInfoMutationVariables,
  Is_Active,
  UserDailyWorkPlanEntity,
  useEditUserDailyWorkCheckInfoMutation
} from "../../../generated/graphql";
import * as ReactTable from "react-table";
import { useCallback } from "react";
import { Row } from "../../../../types/@react-table/react-table/react-table";
import { IUserDailyWorkPlanEntity } from "./m_monthly_work_info";
import ToastMessage, { MessageTypes } from "../../toast-message/toast-message";
import useOpenToastMessage from "../../../hooks/toast-message-hook/use-open-toast-message";

interface IProps {
  employeeId?: string;
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  table: ReactTable.TableInstance<IUserDailyWorkPlanEntity>;
}

const Container = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
  h3 {
    min-width: max-content;
    font-size: 15px;
    margin: 0px;
  }
`;

const LeftSection = styled(StyledFlexContainer)`
  gap: 5px;
`;

const SearchContainer = styled(StyledFlexContainer)`
  flex: 0;
  min-width: max-content;
  gap: 5px;
  justify-self: flex;
`;

function MUserDailyWorkHeader({
  startDate,
  setStartDate,
  setEndDate,
  setIsSearch,
  table,
  employeeId
}: IProps) {
  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const [editUserDailyWorkCheckInfo, { client }] =
    useEditUserDailyWorkCheckInfoMutation({
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
        handleToast(
          "알수없는 이유로 선택된 일별 근무 계획 확인유무 설정을 저장하지 못했습니다.",
          MessageTypes.ERROR
        );
      },
      update(_, { data }) {
        if (data?.editUserDailyWorkCheckInfo.ok) {
          handleToast(
            "성공적으로 선택된 일별 근무 확인유무 설정을 저장하였습니다",
            MessageTypes.SUCCESS
          );
          client.resetStore();
        } else if (data?.editUserDailyWorkCheckInfo.error) {
          handleToast(
            data?.editUserDailyWorkCheckInfo.error,
            MessageTypes.ERROR
          );
        }
      }
    });

  const handleSave = useCallback(() => {
    if (employeeId) {
      const payload: EditUserDailyWorkCheckInfoMutationVariables = {
        employeeId,
        dailyCheckInfo: table.rows.map(item => {
          const row = item as Row<IUserDailyWorkPlanEntity>;
          return {
            date: row.original.startDate ?? "",
            isCheckStatus: row.isSelected
              ? Is_Active.Active
              : Is_Active.UnActive
          };
        })
      };
      editUserDailyWorkCheckInfo({
        variables: payload
      });
    }
  }, [editUserDailyWorkCheckInfo, employeeId, table]);
  return (
    <Container>
      <StyledColumnContainer>
        <h3>{`<월간 근무>`}</h3>
        <StyledFlexContainer>
          <LeftSection>
            <SearchContainer>
              <StyledInput
                type="month"
                value={startDate.slice(0, 7)}
                onChange={event => {
                  const newStartDate = `${event.currentTarget.value}-01`;
                  setStartDate(newStartDate);
                  const newEndDate = moment(newStartDate)
                    .endOf("month")
                    .format("YYYY-MM-DD");
                  setEndDate(newEndDate);
                }}
              />
              <IconContainer
                onClick={() => {
                  setIsSearch(true);
                }}
              >
                <Icon icon={MagnifyIcon} />
              </IconContainer>
            </SearchContainer>
          </LeftSection>
          <ButtonContainer>
            <StyledButton
              onClick={() => {
                if (table.isAllRowsSelected) {
                  table.toggleAllRowsSelected(false);
                } else {
                  table.toggleAllRowsSelected(true);
                }
              }}
            >{`전체체크`}</StyledButton>
            <StyledButton onClick={handleSave}>{`저장`}</StyledButton>
          </ButtonContainer>
        </StyledFlexContainer>
      </StyledColumnContainer>
      <ToastMessage
        message={message}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        messageTypes={toastMessageType}
        widget
      />
    </Container>
  );
}

export default MUserDailyWorkHeader;
