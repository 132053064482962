import * as ReactTable from "react-table";
import styled from "styled-components";
import DetailViewContext from "../../../../../containers/main-view/WorkManagementInfoContainer/DetailViewContainer/DetailViewContext";
import PFMForm from "./pfm-form";
import useOpenDialog from "../../../../../hooks/use-open-dialog";
import PFMRightMenu from "./pfm-right-menu";
import useCheckWebAuthFn from "../../../../../hooks/use-check-web-auth-fn";
import { SignInReducer } from "../../../../../../types/reducers";
import gql from "graphql-tag";
import { useLazyQuery, useReactiveVar } from "@apollo/client";
import {
  ListOfPlaceFreeManagement,
  ListOfPlaceFreeManagementVariables,
  ListOfPlaceFreeManagement_listOfPlaceFreeManagement_list
} from "../../../../../__generated__/ListOfPlaceFreeManagement";
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import moment from "moment";
import usePageControl from "../../../../../hooks/use-page-control/use-page-control";
import {
  CountListOfPlaceFreeManagement,
  CountListOfPlaceFreeManagementVariables
} from "../../../../../__generated__/CountListOfPlaceFreeManagement";
import {
  DownloadListOfePlaceFreeManagementExcel,
  DownloadListOfePlaceFreeManagementExcelVariables
} from "../../../../../__generated__/DownloadListOfePlaceFreeManagementExcel";
import { downloadFileFromServer } from "../../../statistics/Utils";
import useContextMenu from "../../../../../hooks/use-context-menu/use-context-menu";
import ContextMenu from "../../../../context-menu/context-menu";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import useDnd from "../../../../../hooks/use-dnd/use-dnd";
import listOfApolloVar from "../../../../../apollo/apollo-var";
import useFixedColumn from "../../../../../hooks/use_fixed_column/use_fixed_column";
import { useSticky } from "react-table-sticky";
import PlaceFreeManagementHeader from "./place_free_management_header";
import PageController from "../../../../table/page_controller";
import { AutoSizer } from "react-virtualized";
import TableV2 from "../../../../table_v2/table_v2";
import useNewSortBy from "../../../../../hooks/use-new-sort-by/use-new-sort-by";
import TableLayoutContainer from "../../../../table_layout/table_layout_container";
import TableLayoutFooter from "../../../../table_layout/table_layout_footer";
interface Store {
  signInReducer: SignInReducer;
  pcEmployeeIds: string[];
}

enum COLUMN_FOR_VALUE {
  employeeId = "아이디",
  name = "성명",
  departName = "부서명",
  startDateTime = "이석시작",
  endDateTime = "이석종료",
  categoryName = "이석구분",
  logPlace = "이석장소",
  logAttendee = "참여자",
  logDesc = "사유",
  startDateTimeModify = "이석시작(수정)",
  endDateTimeModify = "이석종료(수정)",
  categoryNameModify = "이석구분(수정)",
  logPlaceModify = "이석장소(수정)",
  logAttendeeModify = "참여자(수정)",
  logDescModify = "사유(수정)"
}

type TYPE_OF_PLACE_FREE_MANAGEMENT = keyof typeof COLUMN_FOR_VALUE;

const Container = styled.div`
  display: flex;
  flex: 10;
  overflow-x: hidden;
  flex-direction: column;
`;

const IS_ADMIN = 0;

const QUERY_LIST_OF_PLACE_FREE_MANAGEMENT = gql`
  query ListOfPlaceFreeManagement(
    $listOfEmployeeId: [String!]!
    $startDate: String!
    $endDate: String!
    $page: Float
    $take: Float
    $fieldSort: FieldSort
  ) {
    listOfPlaceFreeManagement(
      listOfEmployeeId: $listOfEmployeeId
      startDate: $startDate
      endDate: $endDate
      page: $page
      take: $take
      fieldSort: $fieldSort
    ) {
      ok
      error
      list {
        leftSeatLogIdx
        employeeId
        name
        departName
        startDateTime
        endDateTime
        categoryName
        logPlace
        logAttendee
        logDesc
        startDateTimeModify
        endDateTimeModify
        categoryNameModify
        logPlaceModify
        logAttendeeModify
        logDescModify
      }
      cacheId
    }
  }
`;

const QUERY_COUNT_LIST_OF_PLACE_FREE_MANAGEMENT = gql`
  query CountListOfPlaceFreeManagement(
    $listOfEmployeeId: [String!]!
    $startDate: String!
    $endDate: String!
  ) {
    countListOfPlaceFreeManagement(
      listOfEmployeeId: $listOfEmployeeId
      startDate: $startDate
      endDate: $endDate
    ) {
      ok
      error
      total
      cacheId
    }
  }
`;

const QUERY_DOWNLOAD_LIST_Of_PLACE_FREE_MANAGEMENT_EXCEL = gql`
  query DownloadListOfePlaceFreeManagementExcel(
    $listOfEmployeeId: [String!]!
    $startDate: String!
    $endDate: String!
  ) {
    downloadListOfePlaceFreeManagementExcel(
      listOfEmployeeId: $listOfEmployeeId
      startDate: $startDate
      endDate: $endDate
    ) {
      ok
      error
      excel
    }
  }
`;

const title = "이석관리";

const PlaceFreeManagement = () => {
  const isMount = useRef(false);
  const store: Store = useContext(DetailViewContext);
  const { isOpen, handleOpenDialog } = useOpenDialog();
  const listOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );
  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber,
    fixedColumnName,
    setFixedColumnName
  } = useFixedColumn();

  const [isSearch, setIsSearch] = useState<boolean>(true);
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const {
    isOpen: isOpenToast,
    handleIsOpen: handleIsOpenToast,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();
  const { fieldSort, oldFieldSort, handleFieldSort } = useNewSortBy();
  const { handleContextMenu, isContextMenu, ctxMenuPosition } =
    useContextMenu();

  const [startDate, setStartDate] = useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [searchStartDate, setSearchStartDate] = useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );
  const [searchEndDate, setSearchEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const { webMenuAuthList } = useCheckWebAuthFn({
    workingAuthWeb: store?.signInReducer?.checkWebAuthFn?.working_auth_web,
    webEtcFlag: store?.signInReducer?.menuList[0]?.web_etc_flag
  });

  const [getListOfPlaceFreeManagement, { data, loading }] = useLazyQuery<
    ListOfPlaceFreeManagement,
    ListOfPlaceFreeManagementVariables
  >(QUERY_LIST_OF_PLACE_FREE_MANAGEMENT, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
      setIsSearch(false);
    },
    onCompleted() {
      setIsSearch(false);
    }
  });

  const [countListOfPlaceFreeManagement, { data: totalData }] = useLazyQuery<
    CountListOfPlaceFreeManagement,
    CountListOfPlaceFreeManagementVariables
  >(QUERY_COUNT_LIST_OF_PLACE_FREE_MANAGEMENT, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
    }
  });

  const [downloadListOfePlaceFreeManagementExcel] = useLazyQuery<
    DownloadListOfePlaceFreeManagementExcel,
    DownloadListOfePlaceFreeManagementExcelVariables
  >(QUERY_DOWNLOAD_LIST_Of_PLACE_FREE_MANAGEMENT_EXCEL, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (
        data.downloadListOfePlaceFreeManagementExcel.ok &&
        data.downloadListOfePlaceFreeManagementExcel.excel
      ) {
        downloadFileFromServer(
          data.downloadListOfePlaceFreeManagementExcel.excel,
          `${moment().format("YYYY-MM-DD-hh-mm-ss")}-place-free-management.csv`
        );
      }
    }
  });

  const total: number = useMemo(() => {
    return totalData?.countListOfPlaceFreeManagement.total ?? 0;
  }, [totalData]);

  const downloadExcel = useCallback(() => {
    if (listOfEmployeeId) {
      downloadListOfePlaceFreeManagementExcel({
        variables: {
          listOfEmployeeId,
          startDate: searchStartDate,
          endDate: searchEndDate
        }
      });
    }
  }, [
    downloadListOfePlaceFreeManagementExcel,
    listOfEmployeeId,
    searchStartDate,
    searchEndDate
  ]);

  const columns: ReactTable.Column<ListOfPlaceFreeManagement_listOfPlaceFreeManagement_list>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      return listOfColumn.map((item, index) => {
        let width = 120;
        if (
          item === "startDateTime" ||
          item === "endDateTime" ||
          item === "categoryName" ||
          item === "logDesc" ||
          item === "categoryNameModify" ||
          item === "logDescModify" ||
          item === "startDateTimeModify" ||
          item === "endDateTimeModify"
        ) {
          width = 150;
        }
        let sticky = "";
        if (sFixedColumnNumber !== undefined) {
          if (fixedColumnName.length > 0) {
            fixedColumnName.forEach((fColumn, fColumnIndex) => {
              if (fColumn === item && fColumnIndex < sFixedColumnNumber) {
                sticky = "left";
              }
            });
          }
        }
        return {
          Header: COLUMN_FOR_VALUE[item as TYPE_OF_PLACE_FREE_MANAGEMENT],
          accessor: item as TYPE_OF_PLACE_FREE_MANAGEMENT,
          width,
          sticky
        };
      });
    }, [sFixedColumnNumber, fixedColumnName]);

  const list: ListOfPlaceFreeManagement_listOfPlaceFreeManagement_list[] =
    useMemo(() => {
      return data?.listOfPlaceFreeManagement.list || [];
    }, [data]);

  const table = ReactTable.useTable(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const { moveColumn } =
    useDnd<ListOfPlaceFreeManagement_listOfPlaceFreeManagement_list>({
      columns: table.visibleColumns,
      setColumnOrder: table.setColumnOrder,
      title: `${title}-for-ordering-column`,
      setFixedColumnName
    });

  const selectedRow:
    | ReactTable.Row<ListOfPlaceFreeManagement_listOfPlaceFreeManagement_list>
    | undefined = useMemo(() => {
    if (table.selectedFlatRows.length > 0) {
      return table.selectedFlatRows[table.selectedFlatRows.length - 1];
    }
    return;
  }, [table.selectedFlatRows]);

  useEffect(() => {
    if (listOfEmployeeId) {
      countListOfPlaceFreeManagement({
        variables: {
          listOfEmployeeId,
          startDate: searchStartDate,
          endDate: searchEndDate
        }
      });
    }
  }, [
    listOfEmployeeId,
    countListOfPlaceFreeManagement,
    searchStartDate,
    searchEndDate
  ]);

  useEffect(() => {
    if (listOfEmployeeId) {
      getListOfPlaceFreeManagement({
        variables: {
          listOfEmployeeId,
          startDate: searchStartDate,
          endDate: searchEndDate,
          take,
          page: currentPage,
          fieldSort: oldFieldSort
        }
      });
    }
  }, [
    listOfEmployeeId,
    getListOfPlaceFreeManagement,
    take,
    currentPage,
    searchStartDate,
    searchEndDate,
    oldFieldSort
  ]);

  useEffect(() => {
    setIsSearch(true);
  }, [currentPage]);

  useEffect(() => {
    if (isSearch) {
      setSearchStartDate(startDate);
      setSearchEndDate(endDate);
    }
  }, [isSearch, startDate, endDate]);

  useEffect(() => {
    if (
      !data?.listOfPlaceFreeManagement.ok &&
      data?.listOfPlaceFreeManagement.error
    ) {
      handleToastMessageType(MessageTypes.WARNING);
      handleMessage(data.listOfPlaceFreeManagement.error);
      handleIsOpenToast(true);
    }
  }, [data, handleToastMessageType, handleMessage, handleIsOpenToast]);

  useLayoutEffect(() => {
    if (table.visibleColumns && !isMount.current) {
      const newFixedColumnName = table.visibleColumns.map(item => item.id);
      setFixedColumnName(newFixedColumnName);
      isMount.current = true;
    }
  }, [table.visibleColumns, isMount]);

  return (
    <Container>
      <PlaceFreeManagementHeader<ListOfPlaceFreeManagement_listOfPlaceFreeManagement_list>
        columns={
          table.columns as TColumn<ListOfPlaceFreeManagement_listOfPlaceFreeManagement_list>[]
        }
        table={table}
        setIsSearch={setIsSearch}
        title="이석관리"
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={total}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
      />
      <TableLayoutContainer>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <TableV2
                table={table}
                title="이석관리"
                selectedRow={selectedRow}
                fieldSort={fieldSort}
                handleFieldSort={handleFieldSort}
                height={height}
                width={width}
                moveColumn={moveColumn}
                handleContextMenu={handleContextMenu}
                loading={loading}
              />
            );
          }}
        </AutoSizer>
      </TableLayoutContainer>
      {/* <AsonicTable<ListOfPlaceFreeManagement_listOfPlaceFreeManagement_list>
        title={title}
        currentPage={currentPage}
        handleCurrentPage={handleCurrentPage}
        take={take}
        handleTake={handleTake}
        total={total}
        totalPage={Math.ceil(total / take)}
        downloadExcel={downloadExcel}
        handleSelectRow={() => {}}
        isLoading={loading}
        prepareRow={table.prepareRow}
        getTableProps={table.getTableProps}
        headerGroups={table.headerGroups}
        getTableBodyProps={table.getTableBodyProps}
        rows={table.rows}
        selectedRow={selectedRow}
        handleDate={handleDate}
        handleContextMenu={handleContextMenu}
        fieldSort={fieldSort}
        handleFieldSort={handleFieldSort}
        columns={
          table.columns as TColumn<ListOfPlaceFreeManagement_listOfPlaceFreeManagement_list>[]
        }
        toggleHideColumn={table.toggleHideColumn}
        moveColumn={moveColumn}
      /> */}
      <TableLayoutFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(total / take)}
          handleCurrentPage={handleCurrentPage}
        />
      </TableLayoutFooter>
      {isOpen && (
        <PFMForm
          employeeId={store?.signInReducer.employee_id}
          handleOpenDialog={handleOpenDialog}
          leftSeatLogIdx={selectedRow?.original?.leftSeatLogIdx}
          handleToastMessageType={handleToastMessageType}
          handleMessage={handleMessage}
          handleIsOpenToast={handleIsOpenToast}
        />
      )}
      {webMenuAuthList.awayInformationChange &&
        store?.signInReducer?.loginIdInfo &&
        store?.signInReducer?.loginIdInfo >= IS_ADMIN && (
          <ContextMenu isContextMenu={isContextMenu} {...ctxMenuPosition}>
            <PFMRightMenu
              handleOpenDialog={handleOpenDialog}
              isSelectedItem={!!selectedRow}
            />
          </ContextMenu>
        )}
      <ToastMessage
        message={message}
        isOpen={isOpenToast}
        handleIsOpen={handleIsOpenToast}
        messageTypes={toastMessageType}
      />
    </Container>
  );
};

export default PlaceFreeManagement;
