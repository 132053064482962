import styled from "styled-components";
import SectionContainer from "../../shared/section_container/section_container";
import DisplayMonthGraph from "../../time_graph/display_month_graph/display_month_graph";
import GraphBasicInfo from "../graph_basic_info/graph_basic_info";
import FBWorkHourGraph from "./fb_work_hour_graph/fb_work_hour_graph";
import TitleContainer from "../../shared/title_container/title_container";
import SubTitle from "../../shared/sub-title/sub-title";
import useWorkingTemplateGraph from "../../../hooks/working_system_hooks/use_working_template_graph/use_working_template_graph";
import { useEffect, useMemo, useState } from "react";
import {
  Work_Type,
  WtGraphBasicEntity,
  WtGraphWeekEntity,
  useGetWtGraphLazyQuery
} from "../../../generated/graphql";
import handleApolloErrorLog from "../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../Utils/handle_error_log/handle_error_log";
import * as ReactTable from "react-table";
import { WorkingTemplateEntityColumn } from "../../../screens/working_system.screen";

interface IProps {
  workingTemplateIdx?: number;
}

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  border: 3px solid ${props => props.theme.colors.frameGrey};
  padding: 20px 20px;
  justify-content: center;
  user-select: none;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

function FlexibleWorkGraph({ workingTemplateIdx }: IProps) {
  const [selectedDayIndex, setSelectedDayIndex] = useState<number>();
  const [basicInfo, setBasicInfo] = useState<WtGraphBasicEntity>();
  const [weekDayOfWeekInfo, setWeekDayOfWeekInfo] = useState<
    WtGraphWeekEntity[]
  >([]);

  const [getWTGraph] = useGetWtGraphLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (data.getWTGraph.ok) {
        if (data.getWTGraph.basicInfo) {
          setBasicInfo(data.getWTGraph.basicInfo);
        }
        if (data.getWTGraph.weekDayOfWeekInfo) {
          setWeekDayOfWeekInfo(data.getWTGraph.weekDayOfWeekInfo);
        }
      } else if (!data.getWTGraph.ok && data.getWTGraph.error) {
        handleErrorLog(data.getWTGraph.error);
      }
    }
  });
  const { month } = useWorkingTemplateGraph({
    weekDayOfWeekInfo,
    workType: Work_Type.FlexibleWorkingHoursPolicy,
    selectedDayIndex
  });

  const selectedDay = useMemo(() => {
    if (selectedDayIndex !== undefined && workingTemplateIdx) {
      const workWeekSelect = Math.ceil((selectedDayIndex + 1) / 7);
      const newDay = {
        workWeekSelect,
        dayOfWeek: weekDayOfWeekInfo[selectedDayIndex].dayOfWeek,
        workingTemplateIdx: workingTemplateIdx
      };
      return newDay;
    }
  }, [selectedDayIndex, weekDayOfWeekInfo, workingTemplateIdx]);

  useEffect(() => {
    if (workingTemplateIdx) {
      getWTGraph({
        variables: {
          workingTemplateIdx: workingTemplateIdx
        }
      });
    }
  }, [workingTemplateIdx]);

  return (
    <SectionContainer justifyContent="space-around">
      <GraphBasicInfo title="탄력" basicInfo={basicInfo} />
      <Container>
        <TitleContainer>
          <SubTitle title="근무시간 그래프" />
        </TitleContainer>
        <ContentContainer>
          <DisplayMonthGraph
            month={month}
            setSelectedDayIndex={setSelectedDayIndex}
          />
          <FBWorkHourGraph selectedDay={selectedDay} />
        </ContentContainer>
      </Container>
    </SectionContainer>
  );
}

export default FlexibleWorkGraph;
