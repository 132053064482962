import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
  ApprovalLineEntity,
  Approval_User_Type,
  EmployeeInformation,
  GroupEntity,
  UserOfSearchedApprovalLineEntity
} from "../../../../generated/graphql";
import { ICompactRow } from "../../../compact-table/compact-table-render-row";
import ApprovalButtonController from "../../approval-process/approval-button-controller";
import ListOfSelectedApprovalUser, {
  IEmployeeInformation
} from "../../approval-process/list-of-selected-approval-user";
import {
  PositionControl,
  TPositionControl
} from "../../approval-process/position-controller";
import ApprovalLineContext from "../approval-line-context";
import ApprovalProcessView from "./approval-process-view";

interface IProps {
  handleSetListOfUser?: (list: IEmployeeInformation[]) => void;
}

export const IApprovalLineManagementTabs = {
  ORGANIZATION_CHART: "조직도",
  PERSONAL_APPROVAL_PROCESS: "개인결재선"
} as const;

export type IApprovalLineManagementTabs =
  typeof IApprovalLineManagementTabs[keyof typeof IApprovalLineManagementTabs];

export interface IReceiveAndReference {
  employeeId: string;
}

export interface IApprovalLIneListOfUser {
  type: Approval_User_Type;
  user: EmployeeInformation;
}

export interface IUserOfSearchedApprovalLineEntity
  extends Omit<UserOfSearchedApprovalLineEntity, "__typename"> {}

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 10px;
`;

const Content = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.colors.grey};
  padding: 10px;
  height: 75%;
  min-height: 550px;
  gap: 10px;
  flex: 1;
  max-width: 50%;
  min-width: 983px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

function ApprovalProcessManagement({ handleSetListOfUser }: IProps) {
  const [selectedPersonalApprovalLine, setSelectedPersonalApprovalLine] =
    useState<ApprovalLineEntity | undefined>();

  const handlePersonalApprovalLine = useCallback(
    (payload?: ApprovalLineEntity) => {
      setSelectedPersonalApprovalLine(payload);
    },
    []
  );

  const [selectedTab, setSelectedTab] = useState<IApprovalLineManagementTabs>(
    IApprovalLineManagementTabs.ORGANIZATION_CHART
  );
  const handleSelectTab = useCallback(
    (payload: IApprovalLineManagementTabs) => {
      setSelectedTab(payload);
    },
    []
  );
  const [selectedUser, setSelectedUser] =
    useState<ICompactRow<EmployeeInformation>>();
  // 결재자 및 합의자 목록에 추가된 사용자 목록
  const [listOfUser, setListOfUser] = useState<IEmployeeInformation[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<GroupEntity>();

  // 체크박스에 추가된 사용자
  const [listOfAddedUser, setListOfAddedUser] = useState<string[]>([]);

  const [selectedApprovalLine, setSelectedApprovalLine] =
    useState<ApprovalLineEntity>();
  const handleSelectedApprovalLine = useCallback((item: ApprovalLineEntity) => {
    setSelectedApprovalLine(item);
  }, []);

  const handleAddedUser = useCallback((user: string) => {
    setListOfAddedUser(list => {
      const isExcited = list.find(item => item === user);
      if (isExcited) {
        return list.filter(item => item !== user);
      }
      return [...list, user];
    });
  }, []);

  const handleAllAddedUser = useCallback((users: string[]) => {
    setListOfAddedUser(list => {
      if (list.length === users.length) {
        return [];
      }
      return users;
    });
  }, []);

  const handleSelectedDepartment = useCallback((department: GroupEntity) => {
    setSelectedDepartment(department);
  }, []);

  const handleAddUser = useCallback(
    (user?: ICompactRow<EmployeeInformation>) => {
      setSelectedUser(user);
    },
    []
  );

  const handleLoadListOfPersonalApprovalLine = useCallback(
    (data: IEmployeeInformation[]) => {
      setListOfUser(data);
    },
    []
  );

  const handleAddUserInListOfApprovalUser = useCallback(
    (type: Approval_User_Type) => {
      if (selectedUser) {
        setListOfUser(list => {
          const newList = list.filter(row => {
            return row.employeeId !== selectedUser.original.employeeId;
          });
          newList.push({
            approvalType: type,
            name: selectedUser.original.name,
            department: selectedUser.original.departName,
            userPosition: selectedUser.original.userPosition ?? "",
            employeeId: selectedUser.original.employeeId
          });
          return newList;
        });
      }
    },
    [selectedUser]
  );

  const handleDeleteUserInListOfApprovalUser = useCallback(() => {
    if (listOfAddedUser) {
      setListOfUser(list => {
        const newList = list.filter(row => {
          const isExist = listOfAddedUser.find(item => item === row.employeeId);
          if (isExist) {
            return false;
          }
          return true;
        });
        return newList;
      });
      setListOfAddedUser([]);
    }
  }, [listOfAddedUser]);

  const handleChangeUserInfoInListOfApprovalUser = useCallback(
    (payload: IEmployeeInformation) => {
      setListOfUser(list => {
        const newList = [...list];
        const foundUserIndex = newList.findIndex(
          item => item.employeeId === payload.employeeId
        );
        newList.splice(foundUserIndex, 1, payload);
        return newList;
      });
    },
    []
  );

  const handleChangeTheOrderUser = useCallback(
    (type: TPositionControl) => {
      if (listOfAddedUser.length > 0) {
        setListOfUser(originList => {
          let index: number = -1;
          let lastItem = "";
          const selectedListOfUser = originList.filter(
            (user, originalIndex) => {
              const foundUser = listOfAddedUser.find(
                checkedUser => checkedUser === user.employeeId
              );
              if (foundUser) {
                if (type === PositionControl.TOP && index === -1) {
                  index = originalIndex;
                } else if (type === PositionControl.BOTTOM) {
                  index = originalIndex;
                }
                return true;
              }
              if (index !== -1 && index === originalIndex - 1) {
                lastItem = user.employeeId;
              }
              return false;
            }
          );
          const newList = originList.filter(user => {
            const foundUser = listOfAddedUser.find(
              checkedUser => checkedUser === user.employeeId
            );
            if (foundUser) {
              return false;
            }
            return true;
          });

          if (type === PositionControl.TOP) {
            if (index !== 0) {
              index -= 1;
            }
            newList.splice(index, 0, ...selectedListOfUser);
          } else if (type === PositionControl.BOTTOM) {
            let findIndex =
              newList.findIndex(user => user.employeeId === lastItem) + 1;
            const lastIndex = 0;
            if (findIndex === lastIndex) {
              findIndex = originList.length - 1;
            }
            newList.splice(findIndex, 0, ...selectedListOfUser);
          }

          return newList;
        });
      }
    },
    [listOfAddedUser]
  );

  const initListOfUser = useCallback(() => {}, [setListOfUser]);

  useEffect(() => {
    if (handleSetListOfUser) {
      handleSetListOfUser(listOfUser);
    }
  }, [listOfUser]);

  return (
    <ApprovalLineContext.Provider
      value={{
        selectedPersonalApprovalLine,
        handlePersonalApprovalLine,
        initListOfUser,
        handleLoadListOfPersonalApprovalLine,
        selectedApprovalLine,
        handleSelectedApprovalLine
      }}
    >
      <Container>
        <Content>
          <ApprovalProcessView
            handleSelectedDepartment={handleSelectedDepartment}
            selectedDepartment={selectedDepartment?.departmentId}
            handleAddUser={handleAddUser}
            selectedTab={selectedTab}
            handleSelectTab={handleSelectTab}
          />
          <ButtonContainer>
            <ApprovalButtonController
              firstControl={() => {
                handleAddUserInListOfApprovalUser(Approval_User_Type.Approval);
              }}
              secondControl={() => {
                handleAddUserInListOfApprovalUser(Approval_User_Type.Agreement);
              }}
              list={listOfAddedUser}
              isSelectedUser={!!selectedUser}
              deleteControl={handleDeleteUserInListOfApprovalUser}
              firstButtonName="결재자"
              secondButtonName="합의자"
            />
          </ButtonContainer>
          <ListOfSelectedApprovalUser
            selectedTab={selectedTab}
            listOfUser={listOfUser}
            handleAddedUser={handleAddedUser}
            handleChangeUserInfoInListOfApprovalUser={
              handleChangeUserInfoInListOfApprovalUser
            }
            listOfAddedUser={listOfAddedUser}
            handleAllAddedUser={handleAllAddedUser}
            handleChangeTheOrderUser={handleChangeTheOrderUser}
          />
        </Content>
      </Container>
    </ApprovalLineContext.Provider>
  );
}

export default ApprovalProcessManagement;
