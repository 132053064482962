import { Fragment, useCallback, useMemo } from "react";
import { UseFormHandleSubmit } from "react-hook-form";
import styled from "styled-components";
import {
  Type_Control_Approval_Form,
  useControlApprovalFormMutation,
  useDeleteApprovalTemplateFormMutation,
  Approval_Sort
} from "../../../generated/graphql";
import useConfirmDialog from "../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import useOpenToastMessage from "../../../hooks/toast-message-hook/use-open-toast-message";
import { ICompactRow } from "../../compact-table/compact-table-render-row";
import ConfirmDialog from "../../confirm-dialog/confirm-dialog";
import Button from "../../globalComponents/Button";
import ToastMessage, { MessageTypes } from "../../toast-message/toast-message";
import { IApprovalTemplate } from "./approval-template-detail";
import { IApprovalTemplateEntity } from "./list-of-approval-template";
import { Row } from "./list-of-approval-template";
interface IProps {
  checkedRows: Row<IApprovalTemplateEntity>[];
  controlType: Type_Control_Approval_Form;
  handleControlType: (type: Type_Control_Approval_Form) => void;
  handleSubmit: UseFormHandleSubmit<IApprovalTemplate>;
  selectedRow?: ICompactRow<IApprovalTemplateEntity>;
  handleInitCheckedRows: () => void;
}

const Container = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  gap: 10px;
`;

function ApprovalSettingButtons({
  checkedRows,
  controlType,
  handleControlType,
  handleSubmit,
  selectedRow,
  handleInitCheckedRows
}: IProps) {
  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    handleMessage,
    toastMessageType,
    handleToastMessageType
  } = useOpenToastMessage();

  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();
  const listOfFormTemplateIdx: number[] = useMemo(() => {
    return checkedRows.map(template => template.original.formTemplateIdx);
  }, [checkedRows]);

  const [deleteApprovalTemplateForm] = useDeleteApprovalTemplateFormMutation({
    onError(error) {
      console.log(error);
      handleMessage("템플릿을 삭제하지 못했습니다.");
      handleToastMessageType(MessageTypes.ERROR);
      handleIsToastMessageOpen(true);
    },
    update(cache, { data }, { variables }) {
      if (data?.deleteApprovalTemplateForm.ok) {
        if (Array.isArray(variables?.listOfFormTemplateIdx)) {
          variables?.listOfFormTemplateIdx.forEach(templateIdx => {
            cache.evict({ id: `ApprovalTemplateEntity:${templateIdx}` });
          });
        }
        handleMessage(`선택된 결재 템플릿들을 삭제하였습니다.`);
        handleToastMessageType(MessageTypes.SUCCESS);
        handleInitCheckedRows();
      } else if (data?.deleteApprovalTemplateForm.error) {
        handleMessage(data.deleteApprovalTemplateForm.error);
        handleToastMessageType(MessageTypes.ERROR);
      }
      handleIsToastMessageOpen(true);
    }
  });

  const [controlApprovalForm, { client }] = useControlApprovalFormMutation({
    onError(error) {
      console.log(error);
      handleMessage("템플릿을 수정하거나 추가하지 못했습니다.");
      handleToastMessageType(MessageTypes.ERROR);
      handleIsToastMessageOpen(true);
    },
    update(_, { data }, { variables }) {
      if (data?.controlApprovalForm.ok && variables) {
        if (
          variables.controlApprovalFormType === Type_Control_Approval_Form.Edit
        ) {
          handleMessage(`성공적으로 선택된 결재 템플릿을 수정하였습니다.`);
        } else {
          handleMessage(`성공적으로 결재 템플릿을 추가했습니다.`);
        }
        client.resetStore();
        handleToastMessageType(MessageTypes.SUCCESS);
        handleIsToastMessageOpen(true);
      } else if (data?.controlApprovalForm.error) {
        handleMessage(data?.controlApprovalForm.error);
        handleToastMessageType(MessageTypes.ERROR);
        handleIsToastMessageOpen(true);
      }
    }
  });

  const handleConfirm = useCallback(() => {
    deleteApprovalTemplateForm({
      variables: {
        listOfFormTemplateIdx
      }
    });
    handleIsOpen(false);
  }, [handleIsOpen, listOfFormTemplateIdx, deleteApprovalTemplateForm]);

  const handleEdit = useCallback(
    (data: IApprovalTemplate) => {
      if (selectedRow) {
        const categoryId =
          typeof data.otTImeTypeCategoryId === "string"
            ? parseInt(data.otTImeTypeCategoryId)
            : data.otTImeTypeCategoryId;
        const formIdx =
          typeof data.formIdx === "string"
            ? parseInt(data.formIdx)
            : data.formIdx;
        controlApprovalForm({
          variables: {
            controlApprovalFormType: Type_Control_Approval_Form.Edit,
            templateName: data.templateTitle,
            categoryId,
            approvalSort: data.approvalType as Approval_Sort,
            formTemplateIdx: selectedRow.original.formTemplateIdx,
            formIdx,
            description: data.description
          }
        });
        handleControlType(Type_Control_Approval_Form.Delete);
      }
    },
    [handleControlType, selectedRow, controlApprovalForm]
  );

  const handleAdd = useCallback(
    (data: IApprovalTemplate) => {
      const categoryId =
        typeof data.otTImeTypeCategoryId === "string"
          ? parseInt(data.otTImeTypeCategoryId)
          : data.otTImeTypeCategoryId;
      const formIdx =
        typeof data.formIdx === "string"
          ? parseInt(data.formIdx)
          : data.formIdx;
      controlApprovalForm({
        variables: {
          controlApprovalFormType: Type_Control_Approval_Form.Add,
          templateName: data.templateTitle,
          categoryId,
          approvalSort: data.approvalType as Approval_Sort,
          formIdx,
          description: data.description
        }
      });
      handleControlType(Type_Control_Approval_Form.Delete);
    },
    [handleControlType, controlApprovalForm]
  );

  return (
    <Container>
      {controlType === Type_Control_Approval_Form.Delete && (
        <Fragment>
          <Button
            disabled={checkedRows.length < 1}
            onClick={event => {
              event.preventDefault();
              handleConfirmMessage({
                title: "결재 양식 삭제",
                p: "선택된 결재 양식들을 삭제하시겠습니까?",
                messageTypes: MessageTypes.WARNING
              });
              handleIsOpen(true);
            }}
          >
            삭제
          </Button>
          <Button
            onClick={() => {
              handleControlType(Type_Control_Approval_Form.Edit);
            }}
          >
            수정
          </Button>
          <Button
            onClick={() => {
              handleControlType(Type_Control_Approval_Form.Add);
            }}
          >
            추가
          </Button>
        </Fragment>
      )}
      {controlType !== Type_Control_Approval_Form.Delete && (
        <Button
          onClick={() => {
            handleControlType(Type_Control_Approval_Form.Delete);
          }}
        >
          취소
        </Button>
      )}
      {controlType === Type_Control_Approval_Form.Edit && (
        <Button onClick={handleSubmit(handleEdit)}>수정</Button>
      )}
      {controlType === Type_Control_Approval_Form.Add && (
        <Button onClick={handleSubmit(handleAdd)}>추가</Button>
      )}
      {isOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.WARNING}
          handleIsOpen={handleIsOpen}
          handleConfirm={handleConfirm}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default ApprovalSettingButtons;
