import styled from "styled-components";
import AsonicDialog from "../../../../asonic-dialog/asonic-dialog";
import FormRow from "../../../../shared/form-row/form-row";
import StyledColumnContainer from "../../../../shared/styled_column_container";
import StyledFlexContainer from "../../../../shared/styled_flex_container";
import StyledInput from "../../../../shared/styled_input";
import * as ReactTable from "react-table";
import { MessageTypes } from "../../../../toast-message/toast-message";
import ButtonContainer from "../../../../shared/button_container";
import StyledButton from "../../../../shared/styled_button";
import {
  EditUserMonthlyWorkInfo,
  Is_Active,
  useEditUserMonthlyWorkInfoMutation
} from "../../../../../generated/graphql";
import { useState } from "react";
import { IUserMonthlyWorkPlanEntity } from "./user_monthly_work";

interface IProps {
  handleOpenDialog: (value: boolean) => void;
  handleToast: (message: string, type: MessageTypes) => void;
  selectedWorkInfoList: ReactTable.Row<IUserMonthlyWorkPlanEntity>[];
}

const Container = styled(StyledColumnContainer)`
  justify-content: center;

  ${ButtonContainer} {
    margin-top: 10px;
    justify-content: flex-end;
  }
`;

const ContentContainer = styled(StyledFlexContainer)`
  gap: 5px;
`;

function EditUserMonthlyWorkPlanDialog({
  handleOpenDialog,
  selectedWorkInfoList,
  handleToast
}: IProps) {
  const [isClosingStatus, setIsClosingStatus] = useState<Is_Active>(
    Is_Active.Active
  );
  const [isSendStatus, setIsSendStatus] = useState<Is_Active>(Is_Active.Active);
  const [editUserMonthlyWorkInfo, { client }] =
    useEditUserMonthlyWorkInfoMutation({
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
        handleToast(
          "알수없는 이유로 선택된 월별 근무 계획을 삭제하지 못했습니다.",
          MessageTypes.ERROR
        );
      },
      update(_, { data }) {
        if (data?.editUserMonthlyWorkInfo.ok) {
          handleToast(
            "성공적으로 선택된 월별 근무 계획을 수정 하였습니다",
            MessageTypes.SUCCESS
          );
          client.resetStore();
          handleOpenDialog(false);
        } else if (data?.editUserMonthlyWorkInfo.error) {
          handleToast(data?.editUserMonthlyWorkInfo.error, MessageTypes.ERROR);
        }
      }
    });
  return (
    <AsonicDialog
      title={"월별 근무정보 수정"}
      handleClose={(value: boolean) => {
        handleOpenDialog(value);
      }}
      width="650px"
      minWidth="650px"
      height="180px"
      minHeight="180px"
    >
      <Container>
        <FormRow title="전송유무">
          <ContentContainer>
            <StyledInput
              type="radio"
              id={`sendStatus_${Is_Active.UnActive}`}
              name="sendStatus"
              value={Is_Active.UnActive}
              checked={isSendStatus === Is_Active.UnActive}
              onChange={() => setIsSendStatus(Is_Active.UnActive)}
            />
            <label
              htmlFor={`sendStatus_${Is_Active.UnActive}`}
            >{`미전송`}</label>
            <StyledInput
              type="radio"
              id={`sendStatus_${Is_Active.Active}`}
              name="sendStatus"
              value={Is_Active.Active}
              checked={isSendStatus === Is_Active.Active}
              onChange={() => setIsSendStatus(Is_Active.Active)}
            />
            <label htmlFor={`sendStatus_${Is_Active.Active}`}>{`전송`}</label>
          </ContentContainer>
        </FormRow>
        <FormRow title="마감여부">
          <ContentContainer>
            <StyledInput
              type="radio"
              id={`closingStatus_${Is_Active.UnActive}`}
              name="closingStatus"
              value={Is_Active.UnActive}
              checked={isClosingStatus === Is_Active.UnActive}
              onChange={() => setIsClosingStatus(Is_Active.UnActive)}
            />
            <label
              htmlFor={`closingStatus_${Is_Active.UnActive}`}
            >{`미마감`}</label>
            <StyledInput
              type="radio"
              id={`closingStatus_${Is_Active.Active}`}
              name="closingStatus"
              value={Is_Active.Active}
              checked={isClosingStatus === Is_Active.Active}
              onChange={() => setIsClosingStatus(Is_Active.Active)}
            />
            <label
              htmlFor={`closingStatus_${Is_Active.Active}`}
            >{`마감`}</label>
          </ContentContainer>
        </FormRow>
        <ButtonContainer>
          <StyledButton
            $maxWidth="50px"
            onClick={() => {
              if (selectedWorkInfoList.length > 0) {
                const info: EditUserMonthlyWorkInfo[] =
                  selectedWorkInfoList.map(item => ({
                    employeeId: item.original.employeeId ?? "",
                    isClosingStatus,
                    isSendStatus,
                    monthDate: item.original.monthStartDay ?? ""
                  }));
                editUserMonthlyWorkInfo({
                  variables: {
                    info
                  }
                });
              }
            }}
          >{`저장`}</StyledButton>
        </ButtonContainer>
      </Container>
    </AsonicDialog>
  );
}

export default EditUserMonthlyWorkPlanDialog;
