import * as React from "react";
import {
  ILoginIdInfo,
  LoginIdInfo,
  WorkingAuthWeb
} from "../../use-check-web-auth-fn";
import { WEB_ETC_FLAG_VALUES } from "../../use-menu-by-etc-flag/use-menu-by-web-etc-flag";

enum IsShow {
  SHOW = 1,
  HIDE
}

type Data = {
  title: string;
  key: string;
  status: number;
  isCheck: boolean;
};

export type ListItem = {
  mainTitle: string;
  data: Data[];
};

type WebMenuList = {
  web_approval_flag: number;
  web_commutegps_flag: number;
  web_otmng_flag: number;
  web_report_flag: number;
  web_etc_flag: number;
  web_vehicle_flag: number;
};

type Props = {
  webMenuStatus: WebMenuList[];
  loginIdInfo: ILoginIdInfo;
};

type HandleWebListPayload = {
  title: string;
  key: string;
  value: string;
  list: WebMenuList;
  isChecked: boolean;
};

type HandleLoginInfoPayload = {
  title: string;
  key: string;
  value: string;
  list: ILoginIdInfo;
  isChecked: boolean;
};

type HandleWebEtcFlagMenuPayload = {
  title: string;
  value: number;
  isChecked: boolean;
};

type CalculateValuePayload = {
  value: number;
  operand: number;
};

enum MainTitle {
  MAIN_FUNCTION = "메인기능",
  webApprovalFlag = "결재관리",
  webCommutegpsFlag = "출퇴근gps반경설정",
  webOtmngFlag = "근무관리정보",
  webReportFlag = "통계",
  webCalendar = "달력",
  webVehicleDrivingManagementFlag = "차량주행관리",
  webReservationManagementFlag = "예약관리",
  CHAT_MENU = "채팅"
}

enum Configuration {
  CONFIGURATION_SETTINGS = "환경설정",
  setSMTPForEmailNotification = "Email 알림을 보내기위한 SMTP설정",
  setCreatorListForEmailNotification = "Email 알림을 보내기 위한 Email과 참조자 목록 설정",
  designateProxyApprover = "대리자 결재자를 지정"
}

enum SERVICE_MANAGEMENT_INFO {
  WORKING_TEMPLATE = "근무제정보",
  PC_INFO = "PC정보",
  SERVICE_MANAGEMENT_INFO = "근무관리정보",
  COMMUTING_MANAGEMENT_INFO = "출퇴근관리정보",
  PLACE_FREE_MANAGEMENT = "이석관리",
  ADDITIONAL_INFORMATION_MANAGEMENT = "추가관리정보",
  APPROVAL_NOTIFICATION = "결재알림관리",
  WORK_TIME_INFORMATION = "근무시간정보",
  ANNUAL_STATUS = "연차현황",
  APPROVAL_STATUS = "결재현황"
}

// 추가관리정보
enum ADDITIONAL_MANAGEMENT_INFO {
  ADDITIONAL_MANAGEMENT_INFO = "추가관리정보",
  EDIT_PERSONAL_INFO_BUTTON = "개인정보수정버튼"
}

const useWebMenuList = <P extends Props>({ webMenuStatus, loginIdInfo }: P) => {
  const calculateWebMenu = React.useCallback(
    (webEtcFlag: number, specificWebFlag: number, specificEtcFlag: number) => {
      const etcFlag = Boolean((webEtcFlag ?? 0) & specificEtcFlag);
      const authFlag = specificWebFlag === IsShow.SHOW ? true : false;

      return etcFlag && authFlag;
    },
    []
  );

  const webList = React.useMemo(() => {
    if (webMenuStatus && webMenuStatus.length > 0) {
      const listKeys = Object.entries(webMenuStatus[0]);
      const webEtcFlag = listKeys[4][1];
      return [
        {
          mainTitle: MainTitle.MAIN_FUNCTION,
          data: [
            {
              title: MainTitle.webApprovalFlag,
              key: listKeys[0][0],
              status: listKeys[0][1],
              isCheck: listKeys[0][1] === IsShow.SHOW ? true : false
            },
            {
              title: MainTitle.webReportFlag,
              key: listKeys[1][0],
              status: listKeys[1][1],
              isCheck: listKeys[1][1] === IsShow.SHOW ? true : false
            },
            {
              title: MainTitle.webOtmngFlag,
              key: listKeys[2][0],
              status: listKeys[2][1],
              isCheck: listKeys[2][1] === IsShow.SHOW ? true : false
            },
            {
              title: MainTitle.CHAT_MENU,
              key: listKeys[7][0],
              status: listKeys[7][1],
              isCheck: calculateWebMenu(
                webEtcFlag,
                listKeys[7][1],
                WEB_ETC_FLAG_VALUES.WEB_CHAT_ETC_FLAG
              )
            },
            {
              title: MainTitle.webCommutegpsFlag,
              key: listKeys[3][0],
              status: listKeys[3][1],
              isCheck: listKeys[3][1] === IsShow.SHOW ? true : false
            },
            {
              title: MainTitle.webCalendar,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] & WEB_ETC_FLAG_VALUES.CALENDAR ? true : false
            },
            {
              title: MainTitle.webVehicleDrivingManagementFlag,
              key: listKeys[5][0],
              status: listKeys[5][1],
              isCheck: listKeys[5][1] === IsShow.SHOW ? true : false
            },
            {
              title: MainTitle.webReservationManagementFlag,
              key: listKeys[6][0],
              status: listKeys[6][1],
              isCheck: listKeys[6][1] === IsShow.SHOW ? true : false
            }
          ]
        },
        {
          mainTitle: Configuration.CONFIGURATION_SETTINGS,
          data: [
            {
              title: Configuration.setSMTPForEmailNotification,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] &
                WEB_ETC_FLAG_VALUES.SET_SMTP_FOR_EMAIL_NOTIFICATION
                  ? true
                  : false
            },
            {
              title: Configuration.setCreatorListForEmailNotification,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] &
                WEB_ETC_FLAG_VALUES.SET_CREATOR_LIST_FOR_EMAIL_NOTIFICATION
                  ? true
                  : false
            },
            {
              title: Configuration.designateProxyApprover,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] & WEB_ETC_FLAG_VALUES.DESIGNATE_PROXY_APPROVER
                  ? true
                  : false
            }
          ]
        },
        {
          mainTitle: SERVICE_MANAGEMENT_INFO.SERVICE_MANAGEMENT_INFO,
          data: [
            {
              title: SERVICE_MANAGEMENT_INFO.WORKING_TEMPLATE,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] & WEB_ETC_FLAG_VALUES.WORKING_TEMPLATE
                  ? true
                  : false
            },
            {
              title: SERVICE_MANAGEMENT_INFO.PC_INFO,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] & WEB_ETC_FLAG_VALUES.PC_INFO ? true : false
            },
            {
              title: SERVICE_MANAGEMENT_INFO.SERVICE_MANAGEMENT_INFO,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] &
                WEB_ETC_FLAG_VALUES.SERVICE_MANAGEMENT_INFO_VALUE
                  ? true
                  : false
            },
            {
              title: SERVICE_MANAGEMENT_INFO.COMMUTING_MANAGEMENT_INFO,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] &
                WEB_ETC_FLAG_VALUES.COMMUTING_MANAGEMENT_INFO_VALUE
                  ? true
                  : false
            },
            {
              title: SERVICE_MANAGEMENT_INFO.PLACE_FREE_MANAGEMENT,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] & WEB_ETC_FLAG_VALUES.PLACE_FREE_MANAGEMENT_VALUE
                  ? true
                  : false
            },
            {
              title: SERVICE_MANAGEMENT_INFO.ADDITIONAL_INFORMATION_MANAGEMENT,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] &
                WEB_ETC_FLAG_VALUES.ADDITIONAL_INFORMATION_MANAGEMENT
                  ? true
                  : false
            },
            {
              title: SERVICE_MANAGEMENT_INFO.APPROVAL_NOTIFICATION,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] & WEB_ETC_FLAG_VALUES.APPROVAL_NOTIFICATION_VALUE
                  ? true
                  : false
            },
            {
              title: SERVICE_MANAGEMENT_INFO.WORK_TIME_INFORMATION,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] & WEB_ETC_FLAG_VALUES.WORK_TIME_INFORMATION
                  ? true
                  : false
            },
            {
              title: SERVICE_MANAGEMENT_INFO.ANNUAL_STATUS,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] & WEB_ETC_FLAG_VALUES.ANNUAL_STATUS
                  ? true
                  : false
            },
            {
              title: SERVICE_MANAGEMENT_INFO.APPROVAL_STATUS,
              key: listKeys[4][0],
              status: listKeys[4][1],
              isCheck:
                listKeys[4][1] & WEB_ETC_FLAG_VALUES.APPROVAL_STATUS
                  ? true
                  : false
            }
          ]
        },
        {
          mainTitle: SERVICE_MANAGEMENT_INFO.ANNUAL_STATUS,
          data: [
            {
              title: ADDITIONAL_MANAGEMENT_INFO.EDIT_PERSONAL_INFO_BUTTON,
              key: LoginIdInfo.WorkingAuthWeb,
              status: loginIdInfo.working_auth_web,
              isCheck: Boolean(
                loginIdInfo.working_auth_web &
                  WorkingAuthWeb.isUpdateBasicInformationManagementButton
              )
            }
          ]
        }
      ];
    }
  }, [webMenuStatus, loginIdInfo, calculateWebMenu]);

  // WebEtcFlagMenu 값 셋팅을 위한 함수
  const handleWebEtcFlagMenu = React.useCallback(
    ({ title, value, isChecked }: HandleWebEtcFlagMenuPayload) => {
      let newValue: number = value;
      const calculateValue = ({ value, operand }: CalculateValuePayload) => {
        if (isChecked) {
          return value + operand;
        } else {
          return value - operand;
        }
      };
      switch (title) {
        case MainTitle.webCalendar:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.CALENDAR
          });
          break;
        case Configuration.setSMTPForEmailNotification:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.SET_SMTP_FOR_EMAIL_NOTIFICATION
          });
          break;
        case Configuration.setCreatorListForEmailNotification:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.SET_CREATOR_LIST_FOR_EMAIL_NOTIFICATION
          });
          break;
        case Configuration.designateProxyApprover:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.DESIGNATE_PROXY_APPROVER
          });
          break;
        case SERVICE_MANAGEMENT_INFO.WORKING_TEMPLATE:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.WORKING_TEMPLATE
          });
          break;
        case SERVICE_MANAGEMENT_INFO.PC_INFO:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.PC_INFO
          });
          break;
        case SERVICE_MANAGEMENT_INFO.SERVICE_MANAGEMENT_INFO:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.SERVICE_MANAGEMENT_INFO_VALUE
          });
          break;
        case SERVICE_MANAGEMENT_INFO.COMMUTING_MANAGEMENT_INFO:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.COMMUTING_MANAGEMENT_INFO_VALUE
          });
          break;
        case SERVICE_MANAGEMENT_INFO.PLACE_FREE_MANAGEMENT:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.PLACE_FREE_MANAGEMENT_VALUE
          });
          break;
        case SERVICE_MANAGEMENT_INFO.ADDITIONAL_INFORMATION_MANAGEMENT:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.ADDITIONAL_INFORMATION_MANAGEMENT
          });
          break;
        case SERVICE_MANAGEMENT_INFO.APPROVAL_NOTIFICATION:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.APPROVAL_NOTIFICATION_VALUE
          });
          break;
        case SERVICE_MANAGEMENT_INFO.WORK_TIME_INFORMATION:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.WORK_TIME_INFORMATION
          });
          break;
        case SERVICE_MANAGEMENT_INFO.ANNUAL_STATUS:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.ANNUAL_STATUS
          });
          break;
        case SERVICE_MANAGEMENT_INFO.APPROVAL_STATUS:
          newValue = calculateValue({
            value,
            operand: WEB_ETC_FLAG_VALUES.APPROVAL_STATUS
          });
          break;
        default:
          break;
      }
      return newValue;
    },
    []
  );

  // 체크박스 컴포넌트에서 다룰 함수
  const handleWebList = React.useCallback(
    ({ key, title, value, list, isChecked }: HandleWebListPayload) => {
      const newState: any = { ...list };
      if (key === "web_etc_flag") {
        const parserIntValue = parseInt(value);
        newState[key] = handleWebEtcFlagMenu({
          title,
          value: parserIntValue,
          isChecked
        });
      } else {
        newState[key] = value === "1" ? IsShow.HIDE : IsShow.SHOW;
      }
      return newState;
    },
    [handleWebEtcFlagMenu]
  );

  const handleWorkingAuthWeb = React.useCallback(
    ({ title, value, isChecked }: HandleWebEtcFlagMenuPayload) => {
      let newValue: number = value;
      const calculateValue = ({ value, operand }: CalculateValuePayload) => {
        if (isChecked) {
          return value + operand;
        } else {
          return value - operand;
        }
      };
      switch (title) {
        case ADDITIONAL_MANAGEMENT_INFO.EDIT_PERSONAL_INFO_BUTTON:
          newValue = calculateValue({
            value,
            operand: WorkingAuthWeb.isUpdateBasicInformationManagementButton
          });
          break;
        default:
          break;
      }
      return newValue;
    },
    []
  );

  const handleLoginIdInfo = React.useCallback(
    ({ key, title, value, list, isChecked }: HandleLoginInfoPayload) => {
      const newLoginInfo: any = { ...list };
      if (key === LoginIdInfo.WorkingAuthWeb) {
        const parserIntValue = parseInt(value);
        newLoginInfo[key] = handleWorkingAuthWeb({
          title,
          value: parserIntValue,
          isChecked
        });
      }
      return newLoginInfo;
    },
    [handleWorkingAuthWeb]
  );

  return { webList, handleWebList, handleLoginIdInfo };
};

export default useWebMenuList;
