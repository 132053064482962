import { useMemo } from "react";
import styled from "styled-components";
import {
  ApprovalTemplateEntity,
  Approval_Sort
} from "../../../generated/graphql";
import { ICompactRow } from "../../compact-table/compact-table-render-row";
import FormRow from "../../shared/form-row/form-row";
import FormTextRow from "../../shared/form-text-row/form-text-row";

interface IProps {
  selectedTemplate?: ApprovalTemplateEntity;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Section = styled.section`
  display: flex;
  :last-child {
    flex: 1;
  }
  h4 {
    font-weight: bold;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

function ApprovalFormDetailInfo({ selectedTemplate }: IProps) {
  const approvalType = useMemo(() => {
    if (selectedTemplate?.approvalType) {
      return selectedTemplate.approvalType === Approval_Sort.ApprovalLine
        ? "결재선"
        : "자동결재";
    }
    return "";
  }, [selectedTemplate]);
  return (
    <Container>
      <Section>
        <Content>
          <FormRow title="이름" isCenter>
            <span>{selectedTemplate?.templateTitle}</span>
          </FormRow>
          <FormRow title="결재방식" isCenter>
            <span>{approvalType}</span>
          </FormRow>
        </Content>
        <Content>
          <FormRow title="정보구분" isCenter>
            <span>{selectedTemplate?.name}</span>
          </FormRow>
          <FormRow title="결재 양식" isCenter>
            <span>{selectedTemplate?.formName}</span>
          </FormRow>
        </Content>
      </Section>
      <Section>
        <FormTextRow title="설명" isCenter>
          <p>{`${selectedTemplate?.description ?? ""}`}</p>
        </FormTextRow>
      </Section>
    </Container>
  );
}

export default ApprovalFormDetailInfo;
