import { forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styled from "styled-components";

export interface ITextInputProps extends UseFormRegisterReturn {
  title: string;
  type?: string;
  minWidth?: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
`;

const Input = styled.input<{ minWidth?: string }>`
  display: flex;
  flex: 12;
  min-width: ${props => props.minWidth || ""};
`;

const Label = styled.label`
  font-size: 12px;
  color: ${props => props.theme.colors.darkGrey};
  flex: 1;
  min-width: max-content;
`;

function CTextInputWithLabel(props: ITextInputProps, ref: any) {
  return (
    <Container>
      {props.required && <span>*</span>}
      <Label htmlFor={props.title}>{props.title}</Label>
      <Input
        placeholder={props.title}
        {...props}
        ref={ref}
        id={props.title}
        type={props.type ?? "text"}
        minWidth={props.minWidth}
      />
    </Container>
  );
}

const TextInputWithLabel = forwardRef(CTextInputWithLabel);
export default TextInputWithLabel;
