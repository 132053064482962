import styled from "styled-components";
import THeader from "./t_header";

const TData = styled(THeader)<{ $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${props =>
    props.$isSelected ? props.theme.colors.green : props.theme.colors.white};
  color: ${props =>
    props.$isSelected ? props.theme.colors.white : props.theme.colors.black};
  z-index: 0;
  border: none;
  &:last-child {
    margin-right: 0px;
  }
  padding: 6.4px 0px;
`;

export default TData;
