import StyledFlexContainer from "../../../../shared/styled_flex_container";
import PageSet from "../../../../table/set_page";
import { Icon } from "@iconify/react";
import MagnifyIcon from "@iconify/icons-mdi/magnify";
import HideColumn, { IHideColumnProps } from "../../../../table/hide_column";
import PointTitle from "../../../../shared/point_title";
import styled from "styled-components";
import StyledInput from "../../../../shared/styled_input";
import IconContainer from "../../../../shared/icon_container";
import moment from "moment";
import AsonicExcel from "../../../../asonic-table/asonic-excel";
import FixedColumnDropbox, {
  IFixedColumnDropbox
} from "../../../../table_v2/fixed_column_dropbox";

interface IProps<P extends object>
  extends IHideColumnProps<P>,
    IFixedColumnDropbox {
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  take: number;
  handleTake: (value: string) => void;
  count: number;
  handleCurrentPage: (page: number) => void;
  downloadExcel?: () => void;
}

const Container = styled.div`
  display: flex;
  padding: 10px;
  gap: 10px;
`;

const LeftSection = styled(StyledFlexContainer)`
  gap: 5px;
`;

const SearchContainer = styled(StyledFlexContainer)`
  flex: 0;
  min-width: max-content;
  gap: 5px;
  justify-self: flex;
`;

const FileContainer = styled.div`
  display: flex;
  height: 100%;
  gap: 5px;
  justify-content: center;
  align-items: center;
  > div {
    height: 16px;
  }
`;

function UserMonthlyWorkHeader<P extends object>({
  columns,
  table,
  title,
  startDate,
  setStartDate,
  setEndDate,
  setIsSearch,
  headerTitleList,
  take,
  handleTake,
  count,
  handleCurrentPage,
  downloadExcel,
  fixedColumnNumber,
  selectedFixedColumnNumber,
  handleSelectedFCN
}: IProps<P>) {
  return (
    <Container>
      <LeftSection>
        <PointTitle title="검색결과" subTitle={`(전체 ${count}개)`} />
        <HideColumn
          columns={columns}
          table={table}
          title={title}
          headerTitleList={headerTitleList}
        />
        <FixedColumnDropbox
          fixedColumnNumber={fixedColumnNumber}
          selectedFixedColumnNumber={selectedFixedColumnNumber}
          handleSelectedFCN={handleSelectedFCN}
        />
        <SearchContainer>
          <StyledInput
            type="month"
            value={moment(startDate, "YYYY-MM-DD").format("YYYY-MM")}
            onChange={event => {
              const newStartDate = moment(event.currentTarget.value, "YYYY-MM")
                .startOf("month")
                .format("YYYY-MM-DD");
              const newEndDate = moment(event.currentTarget.value, "YYYY-MM")
                .endOf("month")
                .format("YYYY-MM-DD");
              setStartDate(newStartDate);
              setEndDate(newEndDate);
            }}
          />
          <IconContainer
            onClick={() => {
              setIsSearch(true);
              handleCurrentPage(1);
            }}
          >
            <Icon icon={MagnifyIcon} />
          </IconContainer>
        </SearchContainer>
      </LeftSection>
      <PageSet take={take} handleTake={handleTake} />
      <FileContainer>
        {downloadExcel && <AsonicExcel downloadExcel={downloadExcel} />}
      </FileContainer>
    </Container>
  );
}

export default UserMonthlyWorkHeader;
