import styled from "styled-components";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import Spinner from "../../../../shared/spinner";
import * as ReactTable from "react-table";
import {
  Is_Active,
  UserDailyWorkInfo,
  UserDailyWorkPlanEntity,
  useDeleteUserDailyWorkInfoMutation,
  useGetUserDailyWorkPlanCountLazyQuery,
  useGetUserDailyWorkPlanExcelLazyQuery,
  useGetUserDailyWorkPlanLazyQuery
} from "../../../../../generated/graphql";
import UserDailyWorkHeader from "./user_daily_work_header";
import { TColumn } from "../../../../../hooks/use_hide_table_columns/use_hide_table_columns";
import { useReactiveVar } from "@apollo/client";
import listOfApolloVar from "../../../../../apollo/apollo-var";
import moment from "moment";
import { Cell } from "../../../../../../types/@react-table/react-table/react-table";
import StyledInput from "../../../../shared/styled_input";
import StyledFlexContainer from "../../../../shared/styled_flex_container";
import PageController from "../../../../table/page_controller";
import usePageControl from "../../../../../hooks/use-page-control/use-page-control";
import StyledButton from "../../../../shared/styled_button";
import useOpenDialog from "../../../../../hooks/use-open-dialog/use-open-dialog";
import AddUserDailyWorkPlanDialog from "./add_user_daily_work_plan_dialog";
import EditUserDailyWorkPlanDialog from "./edit_user_daily_work_plan_dialog";
import useConfirmDialog from "../../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import ConfirmDialog from "../../../../confirm-dialog/confirm-dialog";
import ToastMessage, {
  MessageTypes
} from "../../../../toast-message/toast-message";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import ButtonContainer from "../../../../shared/button_container";
import { downloadFileFromServer } from "../../../statistics/Utils";
import { useSticky } from "react-table-sticky";
import TableV2 from "../../../../table_v2/table_v2";
import { AutoSizer } from "react-virtualized";
import useFixedColumn from "../../../../../hooks/use_fixed_column/use_fixed_column";

const COLUMN_FOR_VALUE = {
  isCheck: "선택",
  departmentName: "부서",
  employeeId: "사번(ERP)",
  employeeName: "이름(ERP)",
  startDate: "날짜",
  weekDayText: "요일",
  startTime: "출근(계획)",
  endTime: "퇴근(계획)",
  inCheckDatetime: "출근(인정)",
  outCheckDatetime: "퇴근(인정)",
  restAdmitMin: "휴게시간(인정)",
  breakTimeAdjustmentMin: "휴게시간(추가인정)",
  recognitionAdmitTime: "근무시간(인정)",
  payTime: "급여시간",
  isCheckStatus: "확인유무"
} as const;

type TYPE_OF_EMPLOYEE = keyof typeof COLUMN_FOR_VALUE;

export interface IUserDailyWorkPlanEntity
  extends Partial<UserDailyWorkPlanEntity> {
  isCheck?: boolean;
}

interface IProps {
  isVisibility: boolean;
}

const Container = styled.div<{ $isVisibility: boolean }>`
  display: flex;
  flex: ${props => (props.$isVisibility ? "1" : "0")};
  overflow-x: hidden;
  flex-direction: column;
  visibility: ${props => (props.$isVisibility ? "visible" : "hidden")};
`;

const TableContainer = styled.div`
  display: flex;
  flex: 10;
  will-change: scroll-position;
`;

const CheckBoxContainer = styled(StyledFlexContainer)`
  justify-content: center;
`;

const TableFooter = styled.div`
  display: flex;
  flex: 1;
`;

const BtnContainer = styled(ButtonContainer)`
  padding: 10px;
`;

function UserDailyWork({ isVisibility }: IProps) {
  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber
  } = useFixedColumn();
  const [columnVisibility, setColumnVisibility] = useState({});
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [isSearch, setIsSearch] = useState<boolean>(true);
  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const selectedListOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );
  const { isOpen, handleOpenDialog } = useOpenDialog();
  const { isOpen: editIsOpen, handleOpenDialog: handleOpenEditDialog } =
    useOpenDialog();

  const {
    confirmTitle,
    confirmParagraph,
    isOpen: isConfirmOpen,
    handleIsOpen: handleIsConfirmOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const [getUserDailyWorkPlanCount, { data: totalCountData }] =
    useGetUserDailyWorkPlanCountLazyQuery({
      fetchPolicy: "no-cache",
      onError(error) {
        console.log(error);
      }
    });

  const [getUserDailyWorkPlan, { data, loading }] =
    useGetUserDailyWorkPlanLazyQuery({
      fetchPolicy: "no-cache",
      onError(error) {
        console.log(error);
        setIsSearch(false);
      },
      onCompleted() {
        setIsSearch(false);
      }
    });

  const [deleteUserDailyWorkInfo, { client }] =
    useDeleteUserDailyWorkInfoMutation({
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onError(error) {
        console.log(error);
        handleToast(
          "알수없는 이유로 선택된 일별 근무 계획을 삭제하지 못했습니다.",
          MessageTypes.ERROR
        );
        handleIsConfirmOpen(false);
      },
      update(_, { data }) {
        if (data?.deleteUserDailyWorkInfo.ok) {
          handleToast(
            "성공적으로 선택된 일별 근무 계획을 삭제하였습니다",
            MessageTypes.SUCCESS
          );
          client.resetStore();
        } else if (data?.deleteUserDailyWorkInfo.error) {
          handleToast(data?.deleteUserDailyWorkInfo.error, MessageTypes.ERROR);
        }
        handleIsConfirmOpen(false);
      }
    });

  const [getUserDailyWorkPlanExcel] = useGetUserDailyWorkPlanExcelLazyQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
      handleToast(
        "알수없는 이유로 선택된 일별 근무 계획을 엑셀로 다운로드 하지 못했습니다.",
        MessageTypes.ERROR
      );
    },
    onCompleted(data) {
      if (
        data.getUserDailyWorkPlanExcel.ok &&
        data.getUserDailyWorkPlanExcel.excel
      ) {
        downloadFileFromServer(
          data.getUserDailyWorkPlanExcel.excel,
          `${moment().format(
            "YYYY-MM-DD-hh-mm-ss"
          )})_list_of_user_daily_work.csv`
        );
      } else if (data.getUserDailyWorkPlanExcel.error) {
        handleToast(data.getUserDailyWorkPlanExcel.error, MessageTypes.ERROR);
      }
    }
  });

  const downloadExcel = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      getUserDailyWorkPlanExcel({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          startDate,
          endDate
        }
      });
    }
  }, [getUserDailyWorkPlanExcel, selectedListOfEmployeeId, startDate, endDate]);

  const count: number = useMemo(() => {
    return totalCountData?.getUserDailyWorkPlanCount.count ?? 0;
  }, [totalCountData]);

  const columns: ReactTable.Column<IUserDailyWorkPlanEntity>[] = useMemo(() => {
    const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
    const smallWidth = 70;
    let newListOfColumn: ReactTable.Column<IUserDailyWorkPlanEntity>[] =
      listOfColumn.map((item, index) => {
        let width: number = 120;
        if (
          COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
            COLUMN_FOR_VALUE.isCheck ||
          COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
            COLUMN_FOR_VALUE.weekDayText
        ) {
          width = smallWidth;
        }
        let sticky = "";
        if (sFixedColumnNumber) {
          if (index + 1 <= sFixedColumnNumber) {
            sticky = "left";
          }
        }
        return {
          Header(header) {
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
              COLUMN_FOR_VALUE.isCheck
            ) {
              return (
                <CheckBoxContainer>
                  <StyledInput
                    type="checkbox"
                    checked={header.isAllRowsSelected}
                    onChange={() => {
                      if (header.isAllRowsSelected) {
                        header.toggleAllRowsSelected(false);
                      } else {
                        header.toggleAllRowsSelected(true);
                      }
                    }}
                  />
                  <span>{COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE]}</span>
                </CheckBoxContainer>
              );
            }
            return COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE];
          },
          accessor: item as TYPE_OF_EMPLOYEE,
          Cell(cell: Cell<IUserDailyWorkPlanEntity>) {
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
              COLUMN_FOR_VALUE.isCheck
            ) {
              return (
                <StyledInput
                  type="checkbox"
                  checked={cell.row.isSelected}
                  onChange={event => {
                    event.stopPropagation();
                    if (cell.row.isSelected) {
                      cell.row.toggleRowSelected(false);
                    } else {
                      cell.row.toggleRowSelected(true);
                    }
                  }}
                />
              );
            }
            if (
              COLUMN_FOR_VALUE[item as TYPE_OF_EMPLOYEE] ===
              COLUMN_FOR_VALUE.isCheckStatus
            ) {
              return cell.value === Is_Active.Active ? "확인" : "미확인";
            }
            return cell.value ?? "";
          },
          width,
          sticky
        };
      });
    return newListOfColumn;
  }, [sFixedColumnNumber]);

  const list = useMemo(() => {
    return data?.getUserDailyWorkPlan.list ?? [];
  }, [data]);

  const table = ReactTable.useTable<IUserDailyWorkPlanEntity>(
    {
      columns,
      data: list,
      state: {
        columnVisibility
      },
      onColumnVisibilityChange: setColumnVisibility
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const handleDeleteUDWI = useCallback(() => {
    const info: UserDailyWorkInfo[] = table.selectedFlatRows.map(item => {
      const { startDate: date, employeeId } = item.original;
      return { date: date ?? "", employeeId: employeeId ?? "" };
    });
    deleteUserDailyWorkInfo({
      variables: {
        info
      }
    });
  }, [deleteUserDailyWorkInfo, table.selectedFlatRows]);

  const selectedRow: ReactTable.Row<IUserDailyWorkPlanEntity> | undefined =
    useMemo(() => {
      if (table.selectedFlatRows.length > 0) {
        return table.selectedFlatRows[table.selectedFlatRows.length - 1];
      }
      return;
    }, [table.selectedFlatRows]);

  useEffect(() => {
    setIsSearch(true);
  }, [currentPage]);

  useEffect(() => {
    if (selectedListOfEmployeeId.length > 0 && isSearch) {
      getUserDailyWorkPlan({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          startDate,
          endDate,
          page: currentPage,
          take
        }
      });
    }
  }, [
    getUserDailyWorkPlan,
    startDate,
    endDate,
    selectedListOfEmployeeId,
    isSearch,
    currentPage,
    take
  ]);

  useEffect(() => {
    if (selectedListOfEmployeeId.length > 0 && isSearch) {
      getUserDailyWorkPlanCount({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          startDate,
          endDate
        }
      });
    }
  }, [getUserDailyWorkPlanCount, selectedListOfEmployeeId, isSearch]);

  return (
    <Container $isVisibility={isVisibility}>
      <UserDailyWorkHeader<IUserDailyWorkPlanEntity>
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        columns={table.columns as TColumn<IUserDailyWorkPlanEntity>[]}
        table={table}
        setIsSearch={setIsSearch}
        title="일별근무"
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={count}
        handleToast={handleToast}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
      />
      <Suspense fallback={<Spinner />}>
        <TableContainer>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <TableV2
                  table={table}
                  title="일별근무"
                  selectedRow={selectedRow}
                  height={height}
                  width={width}
                  loading={loading}
                  isCustomSelect
                />
              );
            }}
          </AutoSizer>
        </TableContainer>
      </Suspense>
      <TableFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(count / take)}
          handleCurrentPage={handleCurrentPage}
        />
        <BtnContainer>
          <StyledButton
            onClick={() => {
              handleOpenDialog(true);
            }}
          >{`추가`}</StyledButton>
          <StyledButton
            onClick={() => {
              handleOpenEditDialog(true);
            }}
            disabled={table.selectedFlatRows.length !== 1}
          >{`수정`}</StyledButton>
          <StyledButton
            disabled={table.selectedFlatRows.length < 1}
            onClick={() => {
              handleConfirmMessage({
                title: "일별 근무 계획 삭제",
                p: "선택된 일별 근무 계획을 삭제 하시겠습니까?",
                messageTypes: MessageTypes.WARNING
              });
              handleIsConfirmOpen(true);
            }}
          >{`삭제`}</StyledButton>
        </BtnContainer>
      </TableFooter>
      {isOpen && (
        <AddUserDailyWorkPlanDialog
          handleToast={handleToast}
          handleOpenDialog={handleOpenDialog}
        />
      )}
      {editIsOpen && (
        <EditUserDailyWorkPlanDialog
          handleOpenDialog={handleOpenEditDialog}
          selectedWorkInfo={table.selectedFlatRows[0]}
          handleToast={handleToast}
        />
      )}
      {isConfirmOpen && (
        <ConfirmDialog
          confirmTitle={confirmTitle}
          confirmParagraph={confirmParagraph}
          confirmType={confirmType}
          messageTypes={MessageTypes.WARNING}
          handleIsOpen={handleIsConfirmOpen}
          handleConfirm={handleDeleteUDWI}
        />
      )}
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default UserDailyWork;
