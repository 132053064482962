import { useEffect, useState } from "react";
import {
  OtSelfWorkingStandardHoursEntity,
  WtGraphBasicEntity,
  useGetSelfDeterminedWorkHourInfoLazyQuery
} from "../../../generated/graphql";
import { WorkingTemplateEntityColumn } from "../../../screens/working_system.screen";
import SectionContainer from "../../shared/section_container/section_container";
import ChoiceBasicInfo from "./choice_basic_info/choice_basic_info";
import ChoiceBasicTemplate from "./choice_basic_template/choice_basic_template";
import ChoiceDayHourGraph from "./choice_day_hour_graph/choice_day_hour_graph";
import * as ReactTable from "react-table";
import handleApolloErrorLog from "../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../Utils/handle_error_log/handle_error_log";

interface IProps {
  workingTemplateIdx?: number;
}

function ChoiceWorkGraph({ workingTemplateIdx }: IProps) {
  const [basicInfo, setBasicInfo] = useState<WtGraphBasicEntity>();
  const [otWSHoursList, setOtWSHoursList] = useState<
    OtSelfWorkingStandardHoursEntity[]
  >([]);
  const [selectedTemplate, setSelectedTemplate] =
    useState<ReactTable.Row<OtSelfWorkingStandardHoursEntity>>();

  const [getSelfDeterminedWorkHourInfo] =
    useGetSelfDeterminedWorkHourInfoLazyQuery({
      onError(error) {
        handleApolloErrorLog(error);
      },
      onCompleted(data) {
        if (data.getSelfDeterminedWorkHourInfo.ok) {
          if (data.getSelfDeterminedWorkHourInfo.basicInfo) {
            setBasicInfo(data.getSelfDeterminedWorkHourInfo.basicInfo);
          }
          if (data.getSelfDeterminedWorkHourInfo.otWSHoursList) {
            setOtWSHoursList(data.getSelfDeterminedWorkHourInfo.otWSHoursList);
          }
        } else if (
          !data.getSelfDeterminedWorkHourInfo.ok &&
          data.getSelfDeterminedWorkHourInfo.error
        ) {
          handleErrorLog(data.getSelfDeterminedWorkHourInfo.error);
        }
      }
    });

  useEffect(() => {
    if (workingTemplateIdx) {
      getSelfDeterminedWorkHourInfo({
        variables: {
          workingTemplateIdx
        }
      });
    }
  }, [workingTemplateIdx, getSelfDeterminedWorkHourInfo]);
  return (
    <SectionContainer flex={0}>
      <ChoiceBasicInfo basicInfo={basicInfo} />
      <ChoiceBasicTemplate
        otWSHoursList={otWSHoursList}
        setSelectedTemplate={setSelectedTemplate}
      />
      <ChoiceDayHourGraph selectedTemplate={selectedTemplate} />
    </SectionContainer>
  );
}

export default ChoiceWorkGraph;
