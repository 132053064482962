import SectionContainer from "../../shared/section_container/section_container";
import SubTitle from "../../shared/sub-title/sub-title";
import ContentContainer from "../../shared/grid_content_container/grid_content_container";
import FormRow from "../../shared/form-row/form-row";
import SSelect from "../../shared/s_select/s_select";
import TitleContainer from "../../shared/title_container/title_container";
import Button from "../../globalComponents/Button";
import ControlNotiSettingHelp from "./control_noti_setting_help";
import WSBottomContainer from "../w_s_bottom_container/w_s_bottom_container";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  CtrlMsgTemplateEntity,
  useGetControlAlertLazyQuery,
  useGetControlNotificationCategoryQuery
} from "../../../generated/graphql";
import handleApolloErrorLog from "../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../Utils/handle_error_log/handle_error_log";
import { WS_STATE } from "../../../screens/asonic-working-system.screen";
import { SInput } from "../work_time_tab/choice_w_s/choice_basic_w_s/choice_basic_w_s";

function ControlNotiSetting() {
  const wSState = useContext(WS_STATE);
  const [beforeStartWorkingCtrlMsg, setBeforeStartWorkingCtrlMsg] =
    useState<number>(0);
  const [beforeStartWorkingMin, setBeforeStartWorkingMin] = useState<number>(0);
  const [beforeEndWorkingCtrlMsg, setBeforeEndWorkingCtrlMsg] =
    useState<number>(0);
  const [beforeEndWorkingMin, setBeforeEndWorkingMin] = useState<number>(0);
  const [afterEndWorkingCtrlMsg, setAfterEndWorkingCtrlMsg] =
    useState<number>(0);

  // 근무시간 초과 1
  const [exceedCtrlMsgBeforeOne, setExceedCtrlMsgBeforeOne] =
    useState<number>(0);
  const [exceedCtrlMsgBeforeMinOne, setExceedCtrlMsgBeforeMinOne] =
    useState<number>(0);

  // 근무시간 초과 2
  const [exceedCtrlMsgBeforeTwo, setExceedCtrlMsgBeforeTwo] =
    useState<number>(0);
  const [exceedCtrlMsgBeforeMinTwo, setExceedCtrlMsgBeforeMinTwo] =
    useState<number>(0);

  // 근무시간 초과 3
  const [exceedCtrlMsgAfter, setExceedCtrlMsgAfter] = useState<number>(0);

  const [breakTimeCtrlMsg, setBreakTimeCtrlMsg] = useState<number>(0);

  const { data } = useGetControlNotificationCategoryQuery();
  const templateList: CtrlMsgTemplateEntity[] = useMemo(() => {
    if (data?.getControlNotificationCategory.error) {
      if (data?.getControlNotificationCategory.error) {
        handleErrorLog(data?.getControlNotificationCategory.error);
      }
    }
    const newList: CtrlMsgTemplateEntity[] = [
      { ctrlMsgTemplateIdx: 0, name: "미사용" },
      ...(data?.getControlNotificationCategory.list
        ? data?.getControlNotificationCategory.list
        : [])
    ];
    return newList;
  }, [data]);
  const [getControlAlert] = useGetControlAlertLazyQuery({
    onError(error) {
      handleApolloErrorLog(error);
    },
    onCompleted(data) {
      if (data.getControlAlert.info) {
        const {
          beforeStartWorkingCtrlMsg,
          beforeStartWorkingMin,
          beforeEndWorkingCtrlMsg,
          beforeEndWorkingMin,
          afterEndWorkingCtrlMsg,
          exceedCtrlMsgBeforeOne,
          exceedCtrlMsgBeforeMinOne,
          exceedCtrlMsgBeforeTwo,
          exceedCtrlMsgBeforeMinTwo,
          exceedCtrlMsgAfter,
          breakTimeCtrlMsg
        } = data.getControlAlert.info;
        setBeforeStartWorkingCtrlMsg(beforeStartWorkingCtrlMsg ?? 0);
        setBeforeStartWorkingMin(beforeStartWorkingMin);
        setBeforeEndWorkingCtrlMsg(beforeEndWorkingCtrlMsg ?? 0);
        setBeforeEndWorkingMin(beforeEndWorkingMin ?? 0);
        setAfterEndWorkingCtrlMsg(afterEndWorkingCtrlMsg ?? 0);

        setExceedCtrlMsgBeforeOne(exceedCtrlMsgBeforeOne ?? 0);
        setExceedCtrlMsgBeforeMinOne(exceedCtrlMsgBeforeMinOne ?? 0);
        setExceedCtrlMsgBeforeTwo(exceedCtrlMsgBeforeTwo ?? 0);
        setExceedCtrlMsgBeforeMinTwo(exceedCtrlMsgBeforeMinTwo ?? 0);
        setExceedCtrlMsgAfter(exceedCtrlMsgAfter ?? 0);
        setBreakTimeCtrlMsg(breakTimeCtrlMsg ?? 0);
      } else if (!data.getControlAlert.ok && data.getControlAlert.error) {
        handleErrorLog(data.getControlAlert.error);
      }
    }
  });
  const checkUnUsed = useCallback((key: string, value: number) => {
    if (value === 0) {
      return {};
    }
    const newPayload: any = {};
    newPayload[key] = value;
    return newPayload;
  }, []);
  useEffect(() => {
    if (wSState?.setControlNotInfo && wSState?.workingTemplateIdx) {
      wSState?.setControlNotInfo({
        workingTemplateIdx: wSState?.workingTemplateIdx,
        beforeStartWorkingMin,
        ...checkUnUsed("beforeStartWorkingCtrlMsg", beforeStartWorkingCtrlMsg),
        beforeEndWorkingMin,
        ...checkUnUsed("beforeEndWorkingCtrlMsg", beforeEndWorkingCtrlMsg),
        ...checkUnUsed("afterEndWorkingCtrlMsg", afterEndWorkingCtrlMsg),
        ...checkUnUsed("breakTimeCtrlMsg", breakTimeCtrlMsg),
        exceedCtrlMsgBeforeMinOne,
        ...checkUnUsed("exceedCtrlMsgBeforeOne", exceedCtrlMsgBeforeOne),
        exceedCtrlMsgBeforeMinTwo,
        ...checkUnUsed("exceedCtrlMsgBeforeTwo", exceedCtrlMsgBeforeTwo),
        ...checkUnUsed("exceedCtrlMsgAfter", exceedCtrlMsgAfter)
      });
    }
  }, [
    wSState?.workingTemplateIdx,
    wSState?.setControlNotInfo,
    beforeStartWorkingMin,
    beforeStartWorkingCtrlMsg,
    beforeEndWorkingMin,
    beforeEndWorkingCtrlMsg,
    afterEndWorkingCtrlMsg,
    breakTimeCtrlMsg,
    exceedCtrlMsgBeforeMinOne,
    exceedCtrlMsgBeforeOne,
    exceedCtrlMsgBeforeMinTwo,
    exceedCtrlMsgBeforeTwo,
    exceedCtrlMsgAfter
  ]);

  useEffect(() => {
    if (wSState?.workingTemplateIdx) {
      getControlAlert({
        variables: {
          workingTemplateIdx: wSState?.workingTemplateIdx
        }
      });
    }
  }, [wSState?.workingTemplateIdx, getControlAlert]);
  return (
    <SectionContainer>
      <TitleContainer flex={0}>
        <SubTitle title={"제어(알림) 설정"} />
        <ControlNotiSettingHelp />
      </TitleContainer>
      <ContentContainer flex={0}>
        <FormRow title="출근 시간">
          <SInput
            type="number"
            name="attendance"
            id="attendance"
            value={String(beforeStartWorkingMin).padStart(2, "0")}
            onChange={event => {
              setBeforeStartWorkingMin(parseInt(event.currentTarget.value));
            }}
            max={59}
            min={0}
          />
          <label htmlFor="attendance">{`분전,`}</label>
          <label htmlFor="attendanceNoti">{`제어(알림)`}</label>
          <SSelect
            name="attendanceNoti"
            id="attendanceNoti"
            minWidth="150px"
            maxWidth="150px"
            value={beforeStartWorkingCtrlMsg}
            onChange={event => {
              setBeforeStartWorkingCtrlMsg(Number(event.currentTarget.value));
            }}
          >
            {templateList.map(item => (
              <option
                value={item.ctrlMsgTemplateIdx}
                key={`attendance_${item.ctrlMsgTemplateIdx}`}
              >
                {item.name}
              </option>
            ))}
          </SSelect>
        </FormRow>
        <FormRow title="근무시간 초과1">
          <SInput
            type="number"
            name="overTIme1"
            id="overTIme1"
            value={String(exceedCtrlMsgBeforeMinOne).padStart(2, "0")}
            onChange={event => {
              setExceedCtrlMsgBeforeMinOne(Number(event.currentTarget.value));
            }}
            max={59}
            min={0}
          />
          <label htmlFor="overTIme1">{`분전,`}</label>
          <label htmlFor="overTimeNoti1">{`제어(알림)`}</label>
          <SSelect
            name="overTimeNoti1"
            id="overTimeNoti1"
            minWidth="150px"
            maxWidth="150px"
            value={exceedCtrlMsgBeforeOne}
            onChange={event => {
              setExceedCtrlMsgBeforeOne(Number(event.currentTarget.value));
            }}
          >
            {templateList.map(item => (
              <option
                value={item.ctrlMsgTemplateIdx}
                key={`overTIme1_${item.ctrlMsgTemplateIdx}`}
              >
                {item.name}
              </option>
            ))}
          </SSelect>
        </FormRow>
        <FormRow title="퇴근 시간">
          <SInput
            type="number"
            name="leaveWorkTime"
            id="leaveWorkTime"
            value={String(beforeEndWorkingMin).padStart(2, "0")}
            onChange={event => {
              setBeforeEndWorkingMin(parseInt(event.currentTarget.value));
            }}
            max={59}
            min={0}
          />
          <label htmlFor="leaveWorkTime">{`분전, `}</label>
          <label htmlFor="leaveWorkNoti">{`제어(알림)`}</label>
          <SSelect
            name="leaveWorkNoti"
            id="leaveWorkNoti"
            minWidth="150px"
            maxWidth="150px"
            value={beforeEndWorkingCtrlMsg}
            onChange={event => {
              setBeforeEndWorkingCtrlMsg(Number(event.currentTarget.value));
            }}
          >
            {templateList.map(item => (
              <option
                value={item.ctrlMsgTemplateIdx}
                key={`leaveWorkNoti_${item.ctrlMsgTemplateIdx}`}
              >
                {item.name}
              </option>
            ))}
          </SSelect>
        </FormRow>
        <FormRow title="근무시간 초과2">
          <SInput
            type="number"
            name="overTIme2"
            id="overTIme2"
            value={String(exceedCtrlMsgBeforeMinTwo).padStart(2, "0")}
            onChange={event => {
              setExceedCtrlMsgBeforeMinTwo(Number(event.currentTarget.value));
            }}
            max={59}
            min={0}
          />
          <label htmlFor="overTIme2">{`분전,`}</label>
          <label htmlFor="overTimeNoti2">{`제어(알림)`}</label>
          <SSelect
            name="overTimeNoti2"
            id="overTimeNoti2"
            minWidth="150px"
            maxWidth="150px"
            value={exceedCtrlMsgBeforeTwo}
            onChange={event => {
              setExceedCtrlMsgBeforeTwo(Number(event.currentTarget.value));
            }}
          >
            {templateList.map(item => (
              <option
                value={item.ctrlMsgTemplateIdx}
                key={`overTimeNoti2_${item.ctrlMsgTemplateIdx}`}
              >
                {item.name}
              </option>
            ))}
          </SSelect>
        </FormRow>
        <FormRow title="퇴근시간 이후">
          <span>{`퇴근 후 부터 출근 시간 전까지,`}</span>
          <label htmlFor="leaveWorkTime">{`제어(알림)`}</label>
          <SSelect
            name="leaveWorkTimeAfter"
            id="leaveWorkTimeAfter"
            minWidth="150px"
            maxWidth="150px"
            value={afterEndWorkingCtrlMsg}
            onChange={event => {
              setAfterEndWorkingCtrlMsg(Number(event.currentTarget.value));
            }}
          >
            {templateList.map(item => (
              <option
                value={item.ctrlMsgTemplateIdx}
                key={`leaveWorkTimeAfter_${item.ctrlMsgTemplateIdx}`}
              >
                {item.name}
              </option>
            ))}
          </SSelect>
        </FormRow>
        <FormRow title="근무시간 초과3">
          <span>{`근무시간 초과 이후,`}</span>
          <label htmlFor="overTimeNoti3">{`제어(알림)`}</label>
          <SSelect
            name="overTimeNoti3"
            id="overTimeNoti3"
            minWidth="150px"
            maxWidth="150px"
            value={exceedCtrlMsgAfter}
            onChange={event => {
              setExceedCtrlMsgAfter(Number(event.currentTarget.value));
            }}
          >
            {templateList.map(item => (
              <option
                value={item.ctrlMsgTemplateIdx}
                key={`overTimeNoti3_${item.ctrlMsgTemplateIdx}`}
              >
                {item.name}
              </option>
            ))}
          </SSelect>
        </FormRow>
        <FormRow title="휴게시간 제어알림">
          <SSelect
            name="breakTimeControlNoti"
            id="breakTimeControlNoti"
            minWidth="150px"
            maxWidth="150px"
            value={breakTimeCtrlMsg}
            onChange={event => {
              setBreakTimeCtrlMsg(Number(event.currentTarget.value));
            }}
          >
            {templateList.map(item => (
              <option
                value={item.ctrlMsgTemplateIdx}
                key={`breakTimeControlNoti_${item.ctrlMsgTemplateIdx}`}
              >
                {item.name}
              </option>
            ))}
          </SSelect>
        </FormRow>
        <FormRow title="">
          <span></span>
        </FormRow>
      </ContentContainer>
      <WSBottomContainer>
        <Button
          maxWidth="100%"
          customMinWidth="100%"
          disabled={!wSState?.workingTemplateIdx}
          onClick={() => {
            wSState?.handleIsSave(true);
          }}
        >
          {`저장`}
        </Button>
      </WSBottomContainer>
    </SectionContainer>
  );
}

export default ControlNotiSetting;
