import styled from "styled-components";

const SectionContainer = styled.section<{
  flex?: number;
  justifyContent?: string;
}>`
  display: flex;
  flex: ${props => props.flex ?? 1};
  background-color: ${props => props.theme.colors.white};
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  justify-content: ${props => props.justifyContent ?? ""};
`;

export default SectionContainer;
