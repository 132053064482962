import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Reducers } from "../../../../../types/reducers";
import menuSettingsApi from "../../../../api/menu-settings-api";
import settingsApi from "../../../../api/settings-api";
import useOpenToastMessage from "../../../../hooks/toast-message-hook/use-open-toast-message";
import {
  ILoginIdInfo,
  LoginIdInfo
} from "../../../../hooks/use-check-web-auth-fn";
import useWebMenuList from "../../../../hooks/use-super-admin/use-web-menu-list";
import { setUserInfo } from "../../../../redux/modules/sign-in/signInReducer";
import ToastMessage, {
  MessageTypes
} from "../../../toast-message/toast-message";
import MenuCheckBoxList from "../MenuCheckBoxList";
import CheckBoxListContainer from "./checkbox-list-container";
import { WebMenuList } from "./MenuSettingsContainer";

type OnChange = React.ChangeEvent<HTMLInputElement>;
const WEB = "WEB";

function WebMenuSettings() {
  const isMount = useRef<boolean>(false);
  const {
    signInReducer: {
      webMenuList,
      mobileMenuList,
      checkWebAuthFn: { working_auth_web: workingAuthWeb }
    }
  } = useSelector((state: Reducers) => state);
  const [webMenuStatus, setWebMenuStatus] = useState<WebMenuList>(webMenuList);
  const [loginIdInfo, setLoginIdInfo] = useState<ILoginIdInfo>({
    working_auth_web: workingAuthWeb
  });

  const { webList, handleWebList, handleLoginIdInfo } = useWebMenuList({
    webMenuStatus,
    loginIdInfo
  });

  const handleOnChange = useCallback(
    () => (event: OnChange) => {
      if (isMount.current) {
        if (webMenuStatus && event.target.name !== LoginIdInfo.WorkingAuthWeb) {
          const newState = handleWebList({
            title: event.target.title,
            key: event.target.name,
            value: event.target.value,
            isChecked: event.target.checked,
            list: webMenuStatus[0]
          });
          console.log(newState);
          setWebMenuStatus([newState]);
        } else {
          const newLoginInfo = handleLoginIdInfo({
            title: event.target.title,
            key: event.target.name,
            value: event.target.value,
            isChecked: event.target.checked,
            list: loginIdInfo
          });
          setLoginIdInfo(newLoginInfo);
        }
      }
    },
    [webMenuStatus, handleWebList, handleLoginIdInfo, loginIdInfo]
  );

  const { isOpen, handleIsOpen, message, toastMessageType, handleToast } =
    useOpenToastMessage();

  const dispatch = useDispatch();
  const setUser = useCallback(
    userInfo => dispatch(setUserInfo(userInfo)),
    [dispatch]
  );

  const updateMenuList = useCallback(async () => {
    try {
      const menuSettingsResult = await menuSettingsApi.updateWorkingAuthWeb({
        workingAuthWeb: loginIdInfo.working_auth_web
      });
      if (menuSettingsResult.data.ok) {
        const { data } = await settingsApi.updateWebMenuList(webMenuStatus[0]);

        if (data.ok) {
          setUser({
            menuList: [{ ...webMenuStatus[0], ...mobileMenuList[0] }],
            webMenuList: webMenuStatus,
            mobileMenuList,
            checkWebAuthFn: loginIdInfo
          });
          handleToast(
            "웹 메뉴 리스트를 업데이트 했습니다.",
            MessageTypes.SUCCESS
          );
        } else {
          handleToast(data.error, MessageTypes.ERROR);
        }
      }
    } catch (error) {
      console.log(error);
      handleToast(
        "웹 메뉴 리스트를 업데이트를 하지 못했습니다.",
        MessageTypes.ERROR
      );
    }
  }, [setUser, handleToast, webMenuStatus, mobileMenuList, loginIdInfo]);

  useEffect(() => {
    if (webMenuList && webMenuList.length > 0) {
      isMount.current = true;
      setWebMenuStatus(webMenuList);
    }
    return () => {
      isMount.current = false;
    };
  }, [webMenuList]);

  return (
    <CheckBoxListContainer>
      <MenuCheckBoxList
        title={WEB}
        list={webList}
        handleOnChange={handleOnChange()}
        updateMenuList={updateMenuList}
      />
      <ToastMessage
        isOpen={isOpen}
        messageTypes={toastMessageType}
        message={message}
        handleIsOpen={handleIsOpen}
      />
    </CheckBoxListContainer>
  );
}

export default WebMenuSettings;
