import SectionContainer from "../../shared/section_container/section_container";
import styled from "styled-components";
import ButtonContainer from "../../shared/button_container/button_container";
import Button from "../../globalComponents/Button";
import AsonicTable from "../../asonic-table/asonic-table";
import { IAsonicRow } from "../../asonic-table/asonic-render-row";
import * as ReactTable from "react-table";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import StyleInput from "../../inputs/style-input";
import EtcOperatingTimeHelp from "./etc_operating_time_help";
import {
  useGetEtcOperatingHourListQuery,
  useGetEtcOperatingHoursLazyQuery
} from "../../../generated/graphql";
import { useParams } from "react-router-dom";
import handleApolloErrorLog from "../../../Utils/handle_apollo_error_log/handle_apollo_error_log";
import handleErrorLog from "../../../Utils/handle_error_log/handle_error_log";
import { EtcOperatingHourEntity } from "../../../generated/graphql";
import { WS_STATE } from "../../../screens/asonic-working-system.screen";
import { Is_Use } from "../../../generated/graphql";

interface IEtcOperatingHourEntity extends EtcOperatingHourEntity {
  checked?: boolean;
}

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

function EtcOperatingTime() {
  const wsState = useContext(WS_STATE);
  const { data } = useGetEtcOperatingHourListQuery();
  const [checkedList, setCheckedList] = useState<EtcOperatingHourEntity[]>([]);
  const [otHolidayTemplateNewAutoUse, setOtHolidayTemplateNewAutoUse] =
    useState<Is_Use>(
      wsState?.wSBasicInfo?.otHolidayTemplateNewAutoUse ?? Is_Use.UnUse
    );

  const [getEtcOperatingHours, { data: info }] =
    useGetEtcOperatingHoursLazyQuery({
      onError(error) {
        handleApolloErrorLog(error);
      },
      onCompleted(data) {
        if (data.getEtcOperatingHours.error) {
          handleErrorLog(data.getEtcOperatingHours.error);
        }
      }
    });

  const columns: ReactTable.Column<IEtcOperatingHourEntity>[] = useMemo(() => {
    const width = 80;
    return [
      {
        Header(header) {
          return (
            <StyleInput
              type="checkbox"
              checked={header.data.length === checkedList.length}
              onChange={() => {
                setCheckedList(list => {
                  if (list.length === header.data.length) {
                    return [];
                  }
                  return header.data;
                });
              }}
              name="isAll"
            />
          );
        },
        accessor: "checked",
        width: 40,
        Cell(cell) {
          return (
            <StyleInput
              type="checkbox"
              checked={
                !!checkedList.find(
                  item =>
                    item.holidayTemplateIdx ===
                    cell.row.original.holidayTemplateIdx
                )
              }
              onChange={() => {
                setCheckedList(list => {
                  const newList = [...list];
                  const { holidayTemplateIdx } = cell.row.original;
                  const foundIndex = list.findIndex(
                    item => item.holidayTemplateIdx === holidayTemplateIdx
                  );

                  if (foundIndex > -1) {
                    newList.splice(foundIndex, 1);
                  } else {
                    newList.push(cell.row.original);
                  }

                  return newList;
                });
              }}
            />
          );
        }
      },
      { Header: "기타운영시간 이름", accessor: "name" },
      { Header: "적용 주차(월)", accessor: "weekSequence", width },
      { Header: "구분(요일/날짜)", accessor: "daySetType", width },
      { Header: "요일/날짜", accessor: "dayDateOrWeek" },
      { Header: "시작시간", accessor: "startDateTime", width },
      { Header: "종료시간", accessor: "endDateTime", width },
      { Header: "제어(알림)", accessor: "ctrlName" }
    ];
  }, [checkedList]);
  const list: IEtcOperatingHourEntity[] = useMemo(() => {
    let newList: IEtcOperatingHourEntity[] = [];
    if (info?.getEtcOperatingHours.info && data?.getEtcOperatingHourList.list) {
      const newCheckedList: EtcOperatingHourEntity[] = [];
      newList = data?.getEtcOperatingHourList.list.map(item => {
        const foundTemplate = info?.getEtcOperatingHours.info?.find(
          etc => etc.holidayTemplateIdx === item.holidayTemplateIdx
        );
        if (foundTemplate) {
          newCheckedList.push(item);
        }
        const checked = foundTemplate ? true : false;
        return { ...item, checked };
      });
      setCheckedList(newCheckedList);
    }
    return newList;
  }, [data, info]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = ReactTable.useTable<any>(
    {
      columns,
      data: list
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const selectedRow: ReactTable.Row<any> | undefined = useMemo(() => {
    if (selectedFlatRows.length > 0) {
      return selectedFlatRows[selectedFlatRows.length - 1];
    }
    return;
  }, [selectedFlatRows]);

  const handleSelectRow = useCallback((row?: IAsonicRow<any>) => {}, []);

  useEffect(() => {
    wsState?.handleOtHolidayTemplateNewAutoUse(otHolidayTemplateNewAutoUse);
  }, [wsState?.handleOtHolidayTemplateNewAutoUse, otHolidayTemplateNewAutoUse]);

  useEffect(() => {
    if (wsState?.setEtcOperatingHours && wsState?.workingTemplateIdx) {
      wsState?.setEtcOperatingHours({
        workingTemplateIdx: wsState?.workingTemplateIdx,
        otHolidayTemplateNewAutoUse,
        holidayTemplateIdx: checkedList.map(item => item.holidayTemplateIdx)
      });
    }
  }, [
    wsState?.workingTemplateIdx,
    otHolidayTemplateNewAutoUse,
    wsState?.setEtcOperatingHours,
    checkedList
  ]);

  useEffect(() => {
    if (wsState?.workingTemplateIdx) {
      getEtcOperatingHours({
        variables: {
          workingTemplateIdx: wsState?.workingTemplateIdx
        }
      });
    }
  }, [getEtcOperatingHours, wsState?.workingTemplateIdx]);

  return (
    <SectionContainer>
      <AsonicTable<any>
        title="기타운영시간 설정"
        handleSelectRow={handleSelectRow}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        selectedRow={selectedRow}
        listOfFlexForHeader={[
          "기타운영시간 이름",
          "적용 주차(월)",
          "요일/날짜"
        ]}
      >
        <EtcOperatingTimeHelp />
      </AsonicTable>
      <CheckBoxContainer>
        <StyleInput
          type="checkbox"
          id="autoNewEtcOperatingTime"
          minWidth="20px"
          maxWidth="20px"
          name="autoNewEtcOperatingTime"
          checked={otHolidayTemplateNewAutoUse === Is_Use.Use}
          onChange={() => {
            setOtHolidayTemplateNewAutoUse(isUse => {
              if (isUse === Is_Use.Use) {
                return Is_Use.UnUse;
              }
              return Is_Use.Use;
            });
          }}
        />
        <label htmlFor="autoNewEtcOperatingTime">{`신규 기타 운영시간 자동 적용`}</label>
      </CheckBoxContainer>
      <ButtonContainer>
        <Button
          maxWidth="100%"
          customMinWidth="100%"
          disabled={!wsState?.workingTemplateIdx}
          onClick={() => {
            wsState?.handleIsSave(true);
          }}
        >
          {`저장`}
        </Button>
      </ButtonContainer>
    </SectionContainer>
  );
}

export default EtcOperatingTime;
