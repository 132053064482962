import SInfoIcon from "@iconify/icons-carbon/information-filled";
import { Icon } from "@iconify/react";
import styled from "styled-components";
import useOpenDialog from "../../../hooks/use-open-dialog";
import HelpIcon from "@iconify-icons/fluent/book-question-mark-24-filled";
import { colors } from "../../GlobalStyle/GlobalStyle";

interface IDialogProps {
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
}

interface IProps extends IDialogProps {
  title: string;
  children?: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.colors.darkBlue};
  background-color: ${props => props.theme.colors.lightBlue};
  border-radius: 4px;
  cursor: help;
  svg {
    font-size: 20px;
  }
  :hover {
    background-color: ${props => props.theme.colors.darkCobaltBlue};
  }
  align-items: center;
`;

const DialogContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  height: ${(props: IDialogProps) => props.height ?? "50%"};
  width: ${(props: IDialogProps) => props.width ?? "max-content"};
  min-height: ${(props: IDialogProps) => props.minHeight ?? "500px"};
  min-width: ${(props: IDialogProps) => props.minWidth ?? "max-content"};
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.frameGrey};
  z-index: 10;
`;

const TitleBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.green};
  color: white;
  padding: 5px 20px 5px 20px;
  font-size: 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const XIcon = styled.div`
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.tomato};
  }
  user-select: none;
  :active {
    transform: scale(0.98);
  }
`;

const TitleBar = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.colors.darkGrey};
  svg {
    font-size: 30px;
  }
  align-items: center;
  gap: 5px;
`;

const Title = styled.h3`
  padding: none;
  margin: 0px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px;
`;

const Body = styled.section`
  display: flex;
  flex: 1;
  padding: 10px;
`;

function InfoIcon({
  title,
  width,
  minHeight,
  minWidth,
  height,
  children
}: IProps) {
  const { isOpen, handleOpenDialog } = useOpenDialog();

  return (
    <Container>
      <Icon
        icon={SInfoIcon}
        onMouseEnter={() => {
          handleOpenDialog(true);
        }}
        onMouseLeave={() => {
          handleOpenDialog(false);
        }}
        color={colors.darkBlue}
      />
      {isOpen && (
        <DialogContainer
          title={"결재회수"}
          width={width}
          minWidth={minWidth}
          height={height}
          minHeight={minHeight}
        >
          <TitleBarContainer>
            <span>{`도움말`}</span>
          </TitleBarContainer>
          <Content>
            <TitleBar>
              <Icon icon={HelpIcon} color={colors.green} />
              <Title>{`${title}`}</Title>
            </TitleBar>
            <Body>{children}</Body>
          </Content>
        </DialogContainer>
      )}
    </Container>
  );
}

export default InfoIcon;
